import React, { useState } from "react";
import Heading from "../../../shared/Heading/Heading";
import ImageCarousel from "../../../shared/ImageCarousel/ImageCarousel";
import InformationText from "../../../shared/InformationText/InformationText";
import RestaurantCardLogic from "../RestaurantCardLogic";
import RestaurantDetailsContainer from "../../../shared/Containers/RestaurantBoxDetails/RestaurantDetailsContainer";
import VenueBanner from "../../../shared/VenueBanner/VenueBanner";
import WindowWidth from "../../../hooks/WindowWidth";

import styles from "./RestaurantMobileNew.module.css";
import { goToPage } from "../../../helpingFunctions/linkChange";

const RestaurantMobileNew = ({
  restaurantData,
  selectedState,
  clickedResId,
  manageRestaurantMobileClicked,
  isFullyBooked,
  view,
  availability,
  skiped,
}) => {
  const { width } = WindowWidth();
  const [firstImage] = restaurantData.Image;
  const {
    handleClick,
    selectedDate,
    isGrayedOut,
    FullyBookedBanner,
    cardBannerContent,
    isBooked,
    hasSelection,
    setShowRestDetails,
    showRestDetails,
  } = RestaurantCardLogic(
    [restaurantData.id],
    isFullyBooked,
    selectedState,
    view,
    availability
  );

  const isChosen = selectedState === "selected";
  const isSelected =
    (isChosen || clickedResId === restaurantData.id) && !isGrayedOut;

  return (
    <>
      <div
        style={
          isSelected
            ? { border: "10px solid var(--color-primary)" }
            : { padding: "10px" }
        }
      >
        <article
          className={`${styles.RestaurantMobileNew} ${
            isSelected ? styles.RestaurantMobileNew__focused : null
          } ${isGrayedOut && hasSelection ? "fadeElement" : ""}
      ${isSelected ? styles.focusedRestaurant : null}
      ${skiped ? styles.disabled : null}      
      `}
          style={isGrayedOut ? { pointerEvents: "auto" } : null}
          onClick={
            (hasSelection &&
              (selectedState === "selected" ||
                (selectedState === "default" && !isGrayedOut))) ||
            !hasSelection
              ? handleClick.bind(
                  this,
                  [restaurantData.id],
                  selectedDate,
                  restaurantData.Title,
                  "restaurant"
                )
              : null
          }
        >
          <div
            className={`${styles.RestaurantMobileNew__preview} ${
              isGrayedOut ? "fadeElement" : ""
            }`}
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(28,28,28) 0%, rgba(168,168,168,0.1) 100%), url(${firstImage?.formats?.small?.url})`,
            }}
          >
            <Heading
              txt={restaurantData.Title}
              new_styles={{
                color: "#fff",
                fontSize: "20px",
                paddingTop: FullyBookedBanner ? "30px" : "0px",
                textAlign: "center",
              }}
            />
          </div>
          <div className={styles.RestaurantMobileNew__details}>
            <div className={styles.RestaurantMobileNew__carousel}>
              {isSelected ? (
                <VenueBanner
                  content={
                    <>
                      {isBooked
                        ? cardBannerContent.Booked
                        : cardBannerContent.Selected}
                    </>
                  }
                  new_styles_txt={{ fontWeight: "bold", gap: "10px" }}
                  customIcon={
                    <>
                      <img
                        className={styles.RestaurantCardDesktop__linkIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRestDetails(true);
                        }}
                        src="/img/info_icons/info-information-white.svg"
                        alt={""}
                      />
                    </>
                  }
                />
              ) : null}
              <ImageCarousel
                sliderImages={restaurantData.Image}
                autoSlide={true}
              />
            </div>
            <div
              className={styles.RestaurantMobileNew__information}
              style={{ zIndex: 140 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  zIndex: 140,
                }}
              >
                <Heading
                  txt={restaurantData.Title}
                  new_styles={{
                    color: "var(--color-primary)",
                    fontSize: "24px",
                  }}
                />
              </div>
              <InformationText
                content={restaurantData.ShortDescription}
                new_styles={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              />
              <span
                className={styles.RestaurantMobileNew__menu}
                target={"_blank"}
                style={{
                  pointerEvents: restaurantData.Menu ? "pointer" : "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  goToPage(restaurantData.Menu);
                }}
              >
                <img
                  className={styles.RestaurantCardDesktop__linkIcon}
                  src="/img/info_icons/open_link.jpg"
                  alt=""
                />
                View Sample Menu
              </span>
              <ul className={styles.RestaurantMobileNew__categories}>
                {restaurantData.Details?.map(({ icon, iconDescription }) =>
                  icon && iconDescription ? (
                    <li className={styles.RestaurantMobileNew__categorie}>
                      <img
                        className={styles.RestaurantMobileNew__categorie_icon}
                        src={icon?.formats.thumbnail.url}
                        alt="no img"
                      />
                      <p className={styles.RestaurantMobileNew__categorie_txt}>
                        {iconDescription}
                      </p>
                    </li>
                  ) : null
                )}
              </ul>
              {/* <div>
          <Button
            txt={`${isChosen ? "Deselect" : "Choose"} this restaurant`}
            new_styles={{
              color: "#fff",
              backgroundColor: "var(--color-primary)",
            }}
            onClick={handleChoosing}
          />
          </div> */}
            </div>
          </div>
          {FullyBookedBanner("40px", "18px")}
        </article>
      </div>

      {showRestDetails ? (
        <RestaurantDetailsContainer
          details={{
            Title: restaurantData.Title,
            Images: restaurantData.Image,
            ShortDescription: restaurantData.ShortDescription,
            LongDescription: restaurantData.LongDescription,
          }}
          toggleDetailsDialogHandler={setShowRestDetails}
          width={width}
        />
      ) : null}
    </>
  );
};

export default RestaurantMobileNew;

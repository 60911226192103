const SelfServicesLogic = (setView) => {
  
  const removeModal = () => {
    try {
      setView((prevState) => {
        return {
          ...prevState,
          modalPage: "",
          stopRequests: true,
        };
      });
    } catch (error) {
      console.error(
        `${new Date()} Error in removeModal func inside RegisteredCardLogic.js file ${
          error.message
        }`
      );
    }
  };

  return {removeModal}
}

export default SelfServicesLogic
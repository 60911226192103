import React from "react";

import SelectBoxLogic from "./SelectBoxLogic";

import styles from "./select_box.module.css";

const SelectBox = ({
  setFormData,
  name,
  content,
  icon,
  height,
  selectedItem,
  setSelectedItem = () => {},
  activityTimeLimit,
  placeholder,
}) => {
  const { dropDown, handleItemClicked, setdropDown } = SelectBoxLogic(
    setFormData,
    selectedItem,
    setSelectedItem,
    name,
    activityTimeLimit
  );

  return (
    <div
      className={`${styles.selectBoxWrapper} ${
        dropDown
          ? name === "hotelId"
            ? styles.dropDownModeHotel
            : styles.dropDownModeQuantity
          : ""
      } ${name === "Quantity" ? styles.selectBoxWrapperQuantity : ""} ${
        ["treatQuantity", "treatWhere"].includes(name)
          ? styles.dropDownModeAddOn
          : ""
      }`}
    >
      <div
        className={styles.selectBox}
        onClick={setdropDown.bind(this, !dropDown)}
        style={{ height }}
      >
        <div 
          className={
            placeholder === selectedItem 
              ? styles.placeholder
              : styles.selectedOption
          }
        >
          {selectedItem}
        </div>
        <div
          className={styles.dropDownArrow}
          style={{
            width: ["treatQuantity", "treatWhere"].includes(name)
              ? "40px"
              : "15%",
            paddingRight: ["treatQuantity", "treatWhere"].includes(name)
              ? "5px"
              : "15px",
          }}
        >
          <div className={styles.arrowImageWraper}>
            <img src={`/img/${icon}`} alt="no img" />
          </div>
        </div>
      </div>
      <ul className={styles.dropDown} style={{zIndex: dropDown ? "11" : "1"}}>
        {content?.map(({ id, Title }) => (
          <li
            key={id}
            onClick={handleItemClicked.bind(this, id, name, Title)}
            className={`${
              selectedItem === Title ? styles.currentSelection : ""
            } ${
              activityTimeLimit &&
              parseInt(activityTimeLimit.Availability) < parseInt(id)
                ? styles.nonClickableElement
                : null
            }`}
          >
            {Title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectBox;

import React, { useEffect, useState, useContext } from "react";
import SelectBox from "../../../shared/SelectBox/SelectBox";
import WindowWidth from "../../../hooks/WindowWidth";
import TreatsPriceField from "./TreatsPriceField";
import Button from "../../../shared/Button/Button";
import { isAddOnBookingCancellable } from "../../../helpingFunctions/utilities";
import TreatsSelectionFormRow from "./TreatsSelectionFormRow";
import DropDownBox from "../../../shared/DropdownBox/DropDownBox";
import styles from "./TreatsSelectionForm.module.css";
import { DataContext } from "../../../context/DataContext";

const TreatsSelectionForm = ({
  clickedObject,
  selectedDate,
  handleAddOnSelection,
  selectionForm,
  setSelectionForm,
  processSelections,
  hasUnconfirmedChanges,
  handleAddOnDiscard,
  hasLocationInBasket,
  reservation,
  totalPriceToAdd,
  totalPriceToRemove,
  checkAddOnDiningBiding,
  showInformationalMessage,
  setShowInformationalMessage,
}) => {
  const { width } = WindowWidth();
  const { itineraryData } = useContext(DataContext);
  const [treatLocationOpts, setTreatLocationOpts] = useState([]);

  const treatQuantityOpts = [
    { id: 0, Title: 0 },
    { id: 1, Title: 1 },
    { id: 2, Title: 2 },
    { id: 3, Title: 3 },
    { id: 4, Title: 4 },
    { id: 5, Title: 5 },
  ];

  const getWhen = () => {
    const location = selectionForm.Location.includes("In-room")
      ? "Treats"
      : selectionForm.Location.includes("Lunch")
      ? "Lunch"
      : "Dinner";
    const diningBooking = checkAddOnDiningBiding(location, itineraryData, selectedDate);
    let when = "";
    if (diningBooking) {
      when = diningBooking.Time.substring(0, 5);
    }
    setShowInformationalMessage(when === "");
    return when;
  };

  useEffect(() => {
    const locationOpts = [];

    if (clickedObject.LunchServiceCode) {
      locationOpts.push({
        id: "Lunch at Restaurant",
        Title: "Lunch at Restaurant",
      });
    }

    if (selectedDate !== reservation.DepartureDate.split("T")[0] && clickedObject.DinnerServiceCode) {
      locationOpts.push({
        id: "Dinner at Restaurant",
        Title: "Dinner at Restaurant",
      });
    }
    
    if (clickedObject.ServiceCode && selectedDate === reservation.ArrivalDate.split("T")[0]) {
      locationOpts.push({ id: "In-room", Title: "In-room" });
    }

    setTreatLocationOpts(locationOpts);
  }, [selectedDate]);

  return (
    <>
      <div className={styles.TreatsFormWrapper}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <DropDownBox
            selectedItem={selectionForm.Quantity}
            label={"How many"}
            dropdownIconSource={"/img/drop-down-arrow_1.svg"}
            dropdownNewStyles={{
              wrapper: {
                display: "flex",
                alignItems: "center",
                gap: "15px",
                padding: "20px 15px",
              },
              label: {},
              field: {
                height: "48px",
                backgroundColor: "white",
                width: "100px",
              },
            }}
          >
            {treatQuantityOpts?.map(({ id, Title }) => (
              <li
                onClick={() =>
                  setSelectionForm((prevState) => {
                    return { ...prevState, Quantity: Title };
                  })
                }
                key={id}
              >
                {Title}
              </li>
            ))}
          </DropDownBox>
          <span style={{ padding: "20px 15px" }}>
            <strong>{`Price: £${clickedObject.Price}`}</strong>
          </span>
        </div>

        <DropDownBox
          selectedItem={
            treatLocationOpts.length === 0
              ? "No options"
              : selectionForm.Location
          }
          label={"Where"}
          dropdownIconSource={"/img/drop-down-arrow_1.svg"}
          dropdownNewStyles={{
            wrapper: {
              display: "flex",
              alignItems: "center",
              gap: "15px",
              padding: "20px 15px",
              flexWrap: "wrap",
            },
            label: {},
            field: {
              height: "48px",
              backgroundColor: "white",
              opacity: treatLocationOpts.length === 0 ? "0.4" : "1",
              pointerEvents: treatLocationOpts.length === 0 ? "none" : "auto",
              width: width < 340 ? "200px" : "250px",
              minWidth: "180px",
              maxWidth: "250px",
            },
          }}
        >
          {treatLocationOpts?.map(({ id, Title }) => (
            <li
              onClick={() =>
                setSelectionForm((prevState) => {
                  return { ...prevState, Location: Title };
                })
              }
              key={id}
            >
              {Title}
            </li>
          ))}
        </DropDownBox>

        {selectionForm.Location !== "In-room" && selectionForm.Location !== "" && (
          <TreatsSelectionFormRow
            name={"treatWhen"}
            label={"When"}
            selectedItem={getWhen()}
            selectionBoxNewStyles={{
              opacity: "0.4",
              pointerEvents: "none",
            }}
          />
        )}
      </div>
      <TreatsPriceField
        totalPriceToRemove={totalPriceToRemove}
        totalPriceToAdd={totalPriceToAdd}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          marginBottom: "15px",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            width: "100%",
            flexDirection: "row",
            alignContent: "space-around",
            paddingBottom: "20px",
            gap: "30px",
          }}
        >
          <Button
            txt={hasUnconfirmedChanges ? "Discard changes" : "Cancel"}
            color={"var(--color-primary)"}
            backgroundColor={"var(--color-secondary)"}
            onClick={() => handleAddOnDiscard()}
          />
          {hasUnconfirmedChanges && (
            <Button
              txt={`Confirm bookings`}
              backgroundColor={"var(--color-primary)"}
              color={"var(--color-secondary)"}
              onClick={() => processSelections()}
            />
          )}
        </div>
      </div>
      {selectionForm.Location !== "" &&
        selectionForm.Quantity > 0 &&
        !isAddOnBookingCancellable(
          selectedDate,
          selectionForm.Location.includes("In-room") ? "In-room" : "Restaurant"
        ) && (
          <div style={{ width: "100%", display: "flex", marginBottom: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                width: "100%",
                flexDirection: "row",
                alignContent: "space-around",
                paddingBottom: "20px",
                gap: "30px",
                opacity:
                  showInformationalMessage &&
                  selectionForm.Location.includes("Restaurant")
                    ? "0.4"
                    : "1",
                pointerEvents:
                  showInformationalMessage &&
                  selectionForm.Location.includes("Restaurant")
                    ? "none"
                    : "auto",
              }}
            >
              <Button
                txt={hasLocationInBasket ? "Modify" : `Add to basket`}
                backgroundColor={"var(--color-primary)"}
                color={"var(--color-secondary)"}
                onClick={() => handleAddOnSelection()}
              />
            </div>
          </div>
        )}
    </>
  );
};

export default TreatsSelectionForm;

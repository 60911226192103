import React from "react";
import styles from "./entertainment-preview.module.css";
import EntertainmentPreviewDesktop from "./EntertainmentPreviewDesktop";
import EntertainmentPreviewMobile from "./EntertainmentPreviewMobile";
import EntertainmentPreviewLogic from "./EntertainmentPreviewLogic";
import EntertainmentDetailsBox from "../EntertainmentDetailsBox/EntertainmentDetailsBox";
import InformationalBoxHeavy from "../../shared/Containers/InformationalBoxHeavy";
import { formatDate, getDayOfWeek } from "../../helpingFunctions/utilities";

const EntertainmentPreview = ({
  stayDates,
  width,
  entertainmentStaticContent,
}) => {
  const {
    prepared,
    showDetails,
    actDetails,
    toggleDetailsDialogHandler,
    firstDetails,
    secondDetails,
    availabilityCount,
    retrieveAvailability,
    checkIsConfirmed,
    selectedDate,
    handleSelection,
    selections,
    bookingNotSelected,
    entertainmentData,
  } = EntertainmentPreviewLogic(stayDates);

  if (Object.keys(prepared).length === 0) {
    return <p>Loading...</p>;
  } else if (
    prepared.second[selectedDate].length === 0 &&
    prepared.first[selectedDate].length === 0
  ) {
    return (
      <div className={styles.infoWrapper}>
        <div style={{ width: "100%", marginBottom: "50px" }}>
          {selectedDate ? (
            <div className={styles.date}>
              <p
                className={styles.date}
                style={{ flexDirection: width <= 500 ? "column" : null }}
              >
                {"You are making a selection for "}{" "}
                <p style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  {getDayOfWeek(selectedDate)}: {formatDate(selectedDate)}
                </p>
              </p>
            </div>
          ) : null}
          {["Thursday", "Saturday"].includes(getDayOfWeek(selectedDate)) &&
          entertainmentData.length > 0 ? (
            <p className={styles.rulesText}>
              {entertainmentStaticContent?.ThAndSatRestriction}
            </p>
          ) : null}
          {entertainmentData.length > 0 ? (
            <p className={styles.rulesText}>
              {entertainmentStaticContent?.EveryDayRestriction}
            </p>
          ) : null}
          {!["Thursday", "Saturday"].includes(getDayOfWeek(selectedDate)) &&
          entertainmentData.length > 0 ? (
            <p className={styles.rulesText}>
              {entertainmentStaticContent?.VenuesOpeningTimeText}
            </p>
          ) : null}
        </div>
        <InformationalBoxHeavy
          title={"No entertainment confirmed yet"}
          content={
            "Sorry, we don't seem to have any confirmed booking for this day. Please check back closer to the break date to see who we have confirmed to entertain you."
          }
        />
      </div>
    );
  } else {
    return (
      <>
        {width > 900 ? (
          <EntertainmentPreviewDesktop
            firstVenueDetails={firstDetails}
            secondVenueDetails={secondDetails}
            prepared={prepared}
            selectedDate={selectedDate}
            selections={selections}
            bookingNotSelected={bookingNotSelected}
            toggleDetailsDialogHandler={toggleDetailsDialogHandler}
            handleSelection={handleSelection}
            retrieveAvailability={retrieveAvailability}
            availabilityCount={availabilityCount}
            checkIsConfirmed={checkIsConfirmed}
            width={width}
            entertainmentStaticContent={entertainmentStaticContent}
          />
        ) : (
          <EntertainmentPreviewMobile
            firstVenueDetails={firstDetails}
            secondVenueDetails={secondDetails}
            prepared={prepared}
            selectedDate={selectedDate}
            selections={selections}
            bookingNotSelected={bookingNotSelected}
            toggleDetailsDialogHandler={toggleDetailsDialogHandler}
            handleSelection={handleSelection}
            retrieveAvailability={retrieveAvailability}
            availabilityCount={availabilityCount}
            checkIsConfirmed={checkIsConfirmed}
            width={width}
            entertainmentStaticContent={entertainmentStaticContent}
          />
        )}

        {showDetails ? (
          <EntertainmentDetailsBox
            details={actDetails}
            toggleDetailsDialogHandler={toggleDetailsDialogHandler}
            width={width}
          />
        ) : null}
      </>
    );
  }
};

export default EntertainmentPreview;

import { useEffect, useState } from "react";

import { SelectionProvider } from "../context/SelectionContext";
import { DiningTypes } from "../constants/constants";
import { WhatsOnDataProvider } from "../context/WhatsOnDataContext";

import ActivitesDesktop from "../views/Activities/ActivitiesDekstop/ActvitiesDesktop";
import MainTitle from "../components/MainTitle/MainTitle";
import ActivitesMobile from "../views/Activities/ActivitiesMobile/ActivitesMobile";
import ActivityPreviewDesktop from "../views/ActivityPreview/ActivityPreviewDesktop/ActivityPreviewDesktop";
import SpaPreviewDesktop from "../views/ActivityPreview/ActivityPreviewDesktop/SpaPreviewDesktop";
import ActivityPreviewMobile from "../views/ActivityPreview/ActivityPreviewMobile/ActivityPreviewMobile";
import SpaPreviewMobile from "../views/ActivityPreview/ActivityPreviewMobile/SpaPreviewMobile";
import EntertainmentsDesktop from "../views/Entertainments/EntertainmentsDekstop/EntertainmentsDekstop";
import EntertainmentsMobile from "../views/Entertainments/EntertainmentsMobile/EntertainmentsMobile";
import TreatsCategoriesDesktop from "../views/TreatYourselfPage/TreatYourselfDesktop/TreatsCategoriesDesktop";
import TreatsCategoriesMobile from "../views/TreatYourselfPage/TreatYourselfMobile/TreatsCategoriesMobile";
import BookingView from "../views/BookingView/BookingView";
import GroupBooking from "../views/GroupBooking/GroupBooking";
import ItineraryNew from "../views/Itinerary/ItineraryNew";
import LandingPage from "../views/LandingPage/LandingPage";
import SpaMobile from "../views/Spa/SpaMobile/SpaMobile";
import SpaDesktop from "../views/Spa/SpaDesktop/SpaDesktop";
import Golf from "../views/Golf/Golf";
import DiningPreview from "../views/DiningPage/DiningPreview";
import WhatsOnGuide from "../views/WhatsOnGuide/WhatsOnGuide";
import HotelMapImage from "../views/HotelMapImage/HotelMapImage";
import GiftCards from "../views/GiftCards/GiftCards";
import { ROUTES } from "./routeConstants";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";


const RouteLogic = () => {
  const [view, setView] = useState({ type: "" });
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      ROUTES[view?.type] !== location.pathname &&
      Object.values(ROUTES).includes(location.pathname)
    ) {
      if (view.type) {
        history.push(
          `${ROUTES[view.type]}`
        );
      } else if (location.pathname !== ROUTES.Home) {
        history.push(
          `${ROUTES.Home}`
        );
      }
    }
  }, [view]);

  const switchPages = (bookingType) => {
    const isDesktop = window.innerWidth > 1324;
    if (DiningTypes.includes(bookingType?.type)) {
      return (
        <SelectionProvider>
          <BookingView setView={(v) => setView(v)} view={view.type} />
        </SelectionProvider>
      );
    }

    switch (bookingType.type) {
      case "itinerary":
        return (
          <SelectionProvider>
            <>
              <ItineraryNew
                setView={(v) => setView(v)}
                showType={"itinerary"}
              />
            </>
          </SelectionProvider>
        );
      case "Dining":
        return (
          <SelectionProvider>
            <DiningPreview view={view.type} setView={(v) => setView(v)} />
          </SelectionProvider>
        );
      case "Treats":
        return (
          <SelectionProvider>
            {isDesktop ? (
              <TreatsCategoriesDesktop
                view={view}
                setView={(v) => setView(v)}
                showType={"Treats"}
              />
            ) : (
              <TreatsCategoriesMobile
                view={view}
                setView={(v) => setView(v)}
                showType={"Treats"}
              />
            )}
          </SelectionProvider>
        );
      case "Golf":
        return <Golf setView={() => setView({})} />;
      case "Activities":
        return (
          <>
            {isDesktop ? (
              <ActivitesDesktop
                setView={(v) => setView(v)}
                showType={"Activity"}
              />
            ) : (
              <ActivitesMobile
                setView={(v) => setView(v)}
                showType={"Activity"}
              />
            )}
          </>
        );
      case "Spas":
        return (
          <>
            {isDesktop ? (
              <SpaDesktop setView={(v) => setView(v)} showType={"Spa"} />
            ) : (
              <SpaMobile setView={(v) => setView(v)} showType={"Spa"} />
            )}
          </>
        );
      case "Activity":
        return (
          <SelectionProvider>
            {isDesktop ? (
              <ActivityPreviewDesktop
                id={bookingType.id}
                view={view.type}
                setView={(v) => setView(v)}
                goTo={"Activities"}
              />
            ) : (
              <ActivityPreviewMobile
                id={bookingType.id}
                view={view.type}
                setView={(v) => setView(v)}
                goTo={"Activities"}
              />
            )}
          </SelectionProvider>
        );
      case "Spa": {
        return (
          <SelectionProvider>
            {window.innerWidth > 1324 ? (
              <SpaPreviewDesktop
                id={bookingType.id}
                view={view.type}
                setView={(v) => setView(v)}
                goTo={"Spas"}
              />
            ) : (
              <SpaPreviewMobile
                id={bookingType.id}
                view={view.type}
                setView={(v) => setView(v)}
                goTo={"Spas"}
              />
            )}
          </SelectionProvider>
        );
      }
      case "Entertainment": {
        return (
          <SelectionProvider>
            {isDesktop ? (
              <EntertainmentsDesktop
                setView={(v) => setView(v)}
                showType={"Entertainment"}
              />
            ) : (
              <EntertainmentsMobile
                setView={(v) => setView(v)}
                showType={"Entertainment"}
              />
            )}
          </SelectionProvider>
        );
      }
      case "group-booking": {
        return (
          <SelectionProvider>
            <GroupBooking setView={setView} view={view} />
          </SelectionProvider>
        );
      }
      case "WhatsOnGuide": {
        return (
          <SelectionProvider>
            <WhatsOnDataProvider>
              <WhatsOnGuide view={view} setView={setView} />
            </WhatsOnDataProvider>
          </SelectionProvider>
        );
      }
      case "HotelMapImage": {
        return (
              <HotelMapImage view={view} setView={setView} />
        );
      }
      case "GiftCards": {
        return (
          <GiftCards />
        )
      }
      default: {
        if (location.pathname === ROUTES.Home) {
          return (
            <>
              <MainTitle
                height={"130px"}
                logoHeight={"100%"}
                logoWidth={"100%"}
              />
              <LandingPage view={view} setView={(v) => setView(v)} />
            </>
          );
        }
      return null;
      }
    }
  };

  return { view, setView, switchPages };
};

export default RouteLogic;

import { useEffect, useState, useContext } from "react";
import SaveBookingHook from "../../../hooks/SaveBookingHook";
import { DataContext } from "../../../context/DataContext";
import { SelectionContext } from "../../../context/SelectionContext";
import { getStayingDates } from "../../../helpingFunctions/utilities";
import AddOnsSharedFunc from "../../../hooks/AddOnsSharedFunc";

const TreatsLogic = (
  view,
  showType,
  clickedObject,
  setSelectedCategory,
  handleCardClick,
  findAcitivities
) => {
  const [showOpt, setShowOpt] = useState(false);
  const [hasSelectedAddOnDining, setHasSelectedAddOnDining] = useState(true);
  const [selectionForm, setSelectionForm] = useState({
    Quantity: 0,
    Location: "",
    Time: "",
  });
  const [allDays, setAllDays] = useState([]);
  const {
    totalPriceToRemove,
    totalPriceToAdd,
    setTotalPriceToAdd,
    setTotalPriceToRemove,
    handleAddOnsTotalPriceChanges,
    checkAddOnDiningBiding
  } = AddOnsSharedFunc();
  const [quantity, setQuantity] = useState({});
  const { saveBookings } = SaveBookingHook(showType);
  const { bookingStatus, setBookingStatus, setBookingSelections } =
    useContext(SelectionContext);
  const {
    addOnsBookings,
    setAddOnsBookings,
    reservation,
    selectedDate,
    setSelectedDate,
    facilitiesData,
    itineraryData,
  } = useContext(DataContext);

  const toggleOptionsScreen = () => {
    setShowOpt(!showOpt);
    let selDate = "";
    if (selectedDate && selectedDate.includes("I")) {
      selDate = selectedDate.split("I")[0];
    } else {
      selDate = allDays[0];
    }
    setSelectedDate(selDate);

    // let quantityIn = {};
    // allDays.forEach((day) => {
    //   const booked = addOnsBookings[clickedObject?.ServiceCode]?.find(
    //     (row) =>
    //       row.Date === day && row.ServiceCode === clickedObject.ServiceCode
    //   );

    //   quantityIn[day] = booked ? booked.Quantity : 0;
    // });

    // setQuantity(quantityIn);
  };

  const processSelections = () => {
    try {
      if (totalPriceToRemove > 0 || totalPriceToAdd > 0) {
        const oldBookings = [];
        const prepBookings = [];

        Object.entries(addOnsBookings).forEach(([key, value]) => {
          value.forEach((val) => {
            if (val.action !== "none") {
              const now = new Date().toISOString().split("T");
              const servCode =
                val.Location === "Dinner"
                  ? clickedObject.DinnerServiceCode
                  : val.Location === "Lunch"
                  ? clickedObject.LunchServiceCode
                  : clickedObject.ServiceCode;

              const diningBooking = checkAddOnDiningBiding(val.Location, itineraryData, selectedDate);
              val.ServiceCode = servCode;
              val.Comment = `Add-on | Location | ${
                val.Location === "Treats"
                  ? "In-room"
                  : val.Location === "Lunch"
                  ? "Restaurant - Lunch"
                  : "Restaurant - Dinner"
              } | Request for |  ${val.Date} - ${diningBooking ? diningBooking.Time.substring(0, 5) : ""} | Qty | ${
                val.Quantity
              } | Type - Service code | ${
                clickedObject.Title
              } - ${servCode} | Booked on | ${now[0]} - ${now[1].substring(
                0,
                5
              )} |><`;
            }

            prepBookings.push(val);
          });
        });
        saveBookings(false, [...prepBookings, ...oldBookings]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddOnSelection = () => {
    try {
      setHasSelectedAddOnDining(true);
      const addOnsBookingsCopy = JSON.parse(JSON.stringify(addOnsBookings));
      const bookSels = addOnsBookings[clickedObject?.ServiceCode]
        ? addOnsBookings[clickedObject?.ServiceCode]
        : [];

      const location = selectionForm.Location.includes("In-room")
        ? "Treats"
        : selectionForm.Location.includes("Lunch")
        ? "Lunch"
        : "Dinner";

      const servCode =
        location === "Lunch"
          ? clickedObject.LunchServiceCode
          : location === "Dinner"
          ? clickedObject.DinnerServiceCode
          : clickedObject.ServiceCode;

      if (["Lunch", "Dinner"].includes(location)) {
        if (!checkAddOnDiningBiding(location, itineraryData, selectedDate)) {
          setHasSelectedAddOnDining(false);
          return;
        }
      }

      const existingIndex = bookSels?.findIndex(
        (row) =>
          row.ServiceCode === servCode &&
          row.Date === selectedDate &&
          row.Location === location
      );

      if (existingIndex > -1) {
        if (
          bookSels[existingIndex].action === "add" &&
          selectionForm.Quantity === 0
        ) {
          bookSels.splice(existingIndex, 1);
        } else {
          bookSels[existingIndex].Quantity = selectionForm.Quantity;
          bookSels[existingIndex].Location = location;

          bookSels[existingIndex].title = clickedObject.Title;
          const isSame =
            bookSels[existingIndex].Quantity ===
            bookSels[existingIndex].QuantityBooked;

          if (bookSels[existingIndex].action !== "add") {
            bookSels[existingIndex].action =
              selectionForm.Quantity === 0
                ? "remove"
                : isSame
                ? "none"
                : "edit";
          }

          if (isSame) delete bookSels[existingIndex].status;
          else bookSels[existingIndex].status = "toBook";
        }
      } else {
        const prepObj = {
          Date: selectedDate,
          UnitPrice: clickedObject.Price,
          ServiceCode: servCode,
          CurrencyCode: "gbp",
          Comment: ``,
          id: clickedObject.id,
          title: clickedObject.Title,
          status: "toBook",
          action: "add",
        };

        prepObj.Quantity = selectionForm.Quantity;

        prepObj.Location = location;

        bookSels.push(prepObj);
      }
      addOnsBookingsCopy[clickedObject?.ServiceCode] = bookSels;
      handleAddOnsTotalPriceChanges(addOnsBookingsCopy);
      setAddOnsBookings(addOnsBookingsCopy);
      resetForm();
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const handleAddOnDiscard = () => {
    setShowOpt(false);

    let prepBookings = JSON.parse(JSON.stringify(addOnsBookings));

    prepBookings[clickedObject?.ServiceCode] = prepBookings[
      clickedObject?.ServiceCode
    ]?.filter((row) => row.action !== "add");
    prepBookings[clickedObject?.ServiceCode]?.forEach((row) => {
      row.action = "none";
      row.Quantity = row.QuantityBooked;
    });

    setAddOnsBookings(prepBookings);
    resetForm();
  };

  const handleRowOptions = (action, date, location) => {
    const addOnsBookingsCopy = JSON.parse(JSON.stringify(addOnsBookings));
    const foundIndex = addOnsBookingsCopy[clickedObject?.ServiceCode].findIndex(
      (row) => row.Date === date && row.Location === location
    );
    addOnsBookingsCopy[clickedObject?.ServiceCode][foundIndex].status =
      "toBook";

    switch (action) {
      case "remove":
        addOnsBookingsCopy[clickedObject?.ServiceCode].splice(foundIndex, 1);
        break;
      case "cancel":
        addOnsBookingsCopy[clickedObject?.ServiceCode][foundIndex].action =
          "remove";
        addOnsBookingsCopy[clickedObject?.ServiceCode][foundIndex].Quantity = 0;
        break;
      case "edit":
        const diningBooking = checkAddOnDiningBiding(location, itineraryData, selectedDate);
        let when = "";
        console.log(diningBooking);
        if (diningBooking) {
          when = diningBooking.Time.substring(0, 5);
        }
        const formObj = {};
        formObj.Location =
          location === "Treats"
            ? "In-room"
            : location === "Lunch"
            ? "Lunch at Restaurant"
            : "Dinner at Restaurant";
        formObj.Quantity =
          addOnsBookingsCopy[clickedObject?.ServiceCode][foundIndex].Quantity;
        formObj.Time = when;
        setSelectionForm(formObj);
        break;
      case "undo":
        addOnsBookingsCopy[clickedObject?.ServiceCode][foundIndex].Quantity =
          addOnsBookingsCopy[clickedObject?.ServiceCode][
            foundIndex
          ].QuantityBooked;
        addOnsBookingsCopy[clickedObject?.ServiceCode][foundIndex].action =
          "none";
        delete addOnsBookingsCopy[clickedObject?.ServiceCode][foundIndex]
          .status;
        break;
      default:
        console.info(`Unknown action ${action}`);
        break;
    }

    handleAddOnsTotalPriceChanges(addOnsBookingsCopy);
    setAddOnsBookings(addOnsBookingsCopy);
  };

  const closePopUp = () => {
    setBookingStatus(null);
    setShowOpt(false);
    setBookingSelections([]);
    setTotalPriceToAdd(0);
    setTotalPriceToRemove(0);
  };

  const resetForm = () => {
    setSelectionForm({
      Quantity: 0,
      Location: "",
      Time: "",
    });
  };

  useEffect(() => {
    setAllDays(
      getStayingDates(
        reservation.ArrivalDate,
        reservation.DepartureDate,
        showType
      )
    );
  }, []);

  useEffect(() => {
    setTotalPriceToAdd(0);
    setTotalPriceToRemove(0);
  }, [showOpt]);

  useEffect(() => {
    if (selectedDate && selectedDate.includes("I") && allDays.length > 0) {
      toggleOptionsScreen();
    }
  }, [allDays, selectedDate]);

  useEffect(() => {
    if (selectedDate && selectedDate.includes("I")) {
      const addOnDetails = facilitiesData.find(
        (row) => row.objType === "Treats" && row.id === view.id
      );

      setSelectedCategory(addOnDetails.TreatCategoryId);
      const treatsStructured = findAcitivities();
      const detailsOfSel = treatsStructured.find(
        (row) => row.TreatCategoryId === addOnDetails.TreatCategoryId
      );
      const clickedIndex = detailsOfSel.objects.findIndex(
        (row) => row.id === addOnDetails.id
      );
      handleCardClick(addOnDetails, clickedIndex);
    }
    resetForm();
  }, [selectedDate]);

  useEffect(() => {
    setHasSelectedAddOnDining(true);
  }, [selectionForm]);

  return {
    showOpt,
    setShowOpt,
    allDays,
    setAllDays,
    quantity,
    setQuantity,
    totalPriceToRemove,
    totalPriceToAdd,
    bookingStatus,
    toggleOptionsScreen,
    processSelections,
    closePopUp,
    addOnsBookings,
    handleAddOnSelection,
    itineraryData,
    selectionForm,
    hasSelectedAddOnDining,
    setSelectionForm,
    handleAddOnDiscard,
    setSelectedDate,
    handleRowOptions,
    reservation,
    checkAddOnDiningBiding,
  };
};

export default TreatsLogic;

import React, { useContext, useState } from "react";
import { SelectionContext } from "../../context/SelectionContext";
import SpaSharedLogic from "./SpaSharedLogic";

const SpaExtraTableLogic = (
  setShowCnxDialog,
  setShowSpaExtraSection,
  setUnfinishedSelection,
  setTermsChecked,
  setSpaFormData,
  unfinishedSelection,
  spaSelection,
  setSpaSelection,
  setIsEditMode,
  isEditMode,
  termsChecked,
  selectedDate
) => {
  const [cxnIndex, setCxnIndex] = useState(-1);

  const {
    removeSpaSelection,
    getSpaSelection,
    bookingSelections,
    createSpaSelection,
  } = useContext(SelectionContext);

  const { handleSpaAvailabilityOnAction } = SpaSharedLogic();

  const showCancellationDialog = (
    facilityId,
    day,
    time,
    isParticipant,
    lastName,
    participantLastName,
    firstName,
    participantFirstName
  ) => {
    const foundIndex = getSpaSelection(
      facilityId,
      day,
      time,
      isParticipant,
      lastName,
      participantLastName,
      firstName,
      participantFirstName
    );
    setCxnIndex(foundIndex);

    const selection = bookingSelections[foundIndex];

    if (selection.action === "add") {
      // Remove
      handleSpaAvailabilityOnAction(
        "add",
        foundIndex,
        spaSelection,
        setSpaSelection
      );
      removeSpaSelection(foundIndex);
      cancelEditMode();
    } else if (selection.action === "none") {
      // Cancel
      setShowCnxDialog(true);
    } else if (selection.action === "remove") {
      // Revert
      createSpaSelection({ foundIndex });
      handleSpaAvailabilityOnAction(
        "remove",
        foundIndex,
        spaSelection,
        setSpaSelection
      );
    }
  };

  const handleRemove = () => {
    handleSpaAvailabilityOnAction(
      "add",
      cxnIndex,
      spaSelection,
      setSpaSelection
    );

    removeSpaSelection(cxnIndex);
    setShowCnxDialog(false);
    cancelEditMode();
  };

  const cancelEditMode = () => {
    const spaOptions = { ...spaSelection };

    if (unfinishedSelection) {
      const previousSelectionIndex = spaOptions[selectedDate].findIndex(
        (row) => row.Time === unfinishedSelection.time
      );
      spaOptions[selectedDate][previousSelectionIndex].selected = false;
    }

    setIsEditMode(null);
    setTermsChecked(false);
    setUnfinishedSelection(null);
    setShowSpaExtraSection(false);
    setSpaSelection(spaOptions);

    setSpaFormData({
      firstName: "",
      lastName: "",
    });
  };

  const showEditMode = (
    facilityId,
    day,
    time,
    isParticipant,
    lastName,
    firstName,
    participantLastName,
    participantFirstName,
    rowIndex,
    action
  ) => {
    const index = spaSelection[day].findIndex((row) => row.Time === time);
    if (index !== -1) {
      const spaOptions = { ...spaSelection };

      if (unfinishedSelection) {
        const previousSelectionIndex = spaOptions[day].findIndex(
          (row) => row.Time === unfinishedSelection.time
        );
        spaOptions[day][previousSelectionIndex].selected = false;
      }

      spaOptions[day][index].selected = !spaOptions[day][index].selected;
      setUnfinishedSelection({ date: day, time: time });
      setTermsChecked(!isParticipant);
      setShowSpaExtraSection(true);

      setSpaFormData({
        firstName: participantFirstName,
        lastName: participantLastName,
      });
      setIsEditMode({
        facilityId,
        day,
        time,
        isParticipant,
        lastName,
        firstName,
        participantLastName,
        participantFirstName,
        rowIndex,
        action,
        termsChecked,
      });

      setSpaSelection(spaOptions);
    }
  };

  return {
    showCancellationDialog,
    handleRemove,
    showEditMode,
    isEditMode,
  };
};

export default SpaExtraTableLogic;

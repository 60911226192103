const INTERNAL_REMOVE_BOOKED_TXT = "Remove from itinerary";

const DEFAULT_FILTER_PLACEHOLDER = "Please select an option";
const FILTER_ERROR = "Currently there is no activity for the selected filter"
const NOTHING_CREATED_ERROR = "Currently there is no activity created for the day"

const TV_TITLE = "tv";
const DESKTOP_TITLE = "desktop";
const MOBILE_TITLE = "mobile";
const VERTICAL_ORDER = "vertical";
const HORIZONTAL_ORDER = "horizontal";

const ALL_DAY_ACTIVITY = "all_day_activity";

export {
    DEFAULT_FILTER_PLACEHOLDER,
    FILTER_ERROR,
    NOTHING_CREATED_ERROR,
    INTERNAL_REMOVE_BOOKED_TXT,
    TV_TITLE,
    DESKTOP_TITLE,
    MOBILE_TITLE,
    VERTICAL_ORDER,
    HORIZONTAL_ORDER,
    ALL_DAY_ACTIVITY
}
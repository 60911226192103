import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../context/DataContext";
import { DayUseContext } from "../../../../context/DayUseContext";
import axiosInstance from "../../../../url/createAxios";

const CARD_AUTHORIZATION_STEP = {
  headline: "Step 1 - Card Authorisation",
  desc: "Your card is being authorised!",
  status: "initial",
};

const CARD_ADD_TO_RESERVATION_STEP = {
  headline: "Step 2 - Card added to booking",
  desc: "Adding your card to reservation!",
  status: "initial",
};

const CARD_ADD_DAY_USE = {
  headline: "Verifying. . .",
  desc: "We are verifying the details provided!",
  status: "initial",
};

const CardRegistrationLogic = (changeModalPage) => {
  const instance = axiosInstance();
  const [loaders, setLoaders] = useState([]);
  const { setReservation } = useContext(DataContext);
  const { setDayUseCCDetails } = useContext(DayUseContext);
  const isDayUse = window.location.href.includes("dayexperiences");

  useEffect(() => {
    const interval = setInterval(async () => {
      const { data } = await instance.get(`/pxp/getLastTransactionStatus`, {
        withCredentials: true,
      });

      if (!isDayUse) {
        setLoaders([CARD_AUTHORIZATION_STEP]);
      } else {
        setLoaders([CARD_ADD_DAY_USE]);
      }

      if (data.hasRegisteredCard) {
        try {
          const { data: status } = await instance.post(`/pxp/callback`, {
            withCredentials: true,
            callbackStatus: "success",
          });

          if (isDayUse) {
            setDayUseCCDetails(data);
            setLoaders([]);
            if (status.status === "success") {
              changeModalPage({ page: "CardConfirmation", details: data });
            } else {
              changeModalPage({ page: "CardRetry", details: data });
            }
          } else {
            setTimeout(async () => {
              setLoaders([
                { ...CARD_AUTHORIZATION_STEP, status: "success" },
                CARD_ADD_TO_RESERVATION_STEP,
              ]);

              if (status === "success") {
                setTimeout(() => {
                  setReservation((prevState) => {
                    return {
                      ...prevState,
                      CreditCard: true,
                    };
                  });
                  setLoaders((prevState) => [
                    ...prevState,
                    { ...CARD_ADD_TO_RESERVATION_STEP, status },
                  ]);
                  changeModalPage({ page: "CardConfirmation", details: data });
                }, 2000);
              } else {
                setTimeout(() => {
                  setLoaders((prevState) => [
                    ...prevState,
                    { ...CARD_ADD_TO_RESERVATION_STEP, status },
                  ]);
                  changeModalPage({ page: "CardRetry", details: data });
                }, 2000);
              }
            }, 3100);
          }
        } catch (e) {
          changeModalPage({ page: "CardRetry", details: data });
          clearInterval(interval);
        }
      }
    }, 20000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return { loaders };
};

export default CardRegistrationLogic;

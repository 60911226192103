import React from "react";

import Button from "../../../../shared/Button/Button";
import InformationText from "../../../../shared/InformationText/InformationText";
import LogoAndTitle from "../../components/LogoAndTitle/LogoAndTitle";

import AddCardLogic from "./AddCardLogic";

const AddCard = ({ handleClick, removeModal }) => {
  const { pageData } = AddCardLogic();
  const isDayUse = window.location.href.includes("dayexperiences");

  return (
    <>
      <LogoAndTitle
        removeModal={removeModal}
        title={"Register your payment card"}
      />
      {!isDayUse && (
        <InformationText
          content={pageData.CC_Registration_Introduction_Text}
          new_styles={{
            marginTop: "80px",
          }}
          markdown
        />
      )}
      <Button
        txt={`Add card now`}
        onClick={handleClick}
        new_styles={{
          backgroundColor: "var(--color-primary)",
          color: "var(--color-secondary)",
          marginTop: "25px",
        }}
      />
    </>
  );
};

export default AddCard;

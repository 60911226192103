import React from "react";
import GrayOutBackground from "../UI/GrayOutBackground/GrayOutBackground";

import styles from "../../css/info_box.module.css";

const AlertBox = ({ txt, button, boxNewStyles, customContent, customClassName }) => {
  return (
    <GrayOutBackground>
      <div 
        className={`${styles.alertBox} ${customClassName && styles[customClassName]}`} 
        style={{ ...boxNewStyles }}
      >
        {customContent ? customContent : <p>{txt}</p>}

        <div className={styles.alertBoxButtons}>{button}</div>
      </div>
    </GrayOutBackground>
  );
};

export default AlertBox;

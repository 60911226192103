import React, { useState, useEffect } from "react";
import { formatDate, getDayOfWeek } from "../../helpingFunctions/utilities";
import styles from "./spa-extra-section.module.css";
import Button from "../../shared/Button/Button";
import AlertBox from "../../components/AlertBox/AlertBox";
import SpaExtraTableLogic from "./SpaExtraTableLogic";
import { editPencil, xSign, undoSign } from "../../assets/SVGIcons";

const SpaExtraTable = ({
  filteredBookings,
  policyLink,
  servicePrice,
  selectedDate,
  setShowSpaExtraSection,
  setUnfinishedSelection,
  setTermsChecked,
  setSpaFormData,
  unfinishedSelection,
  spaSelection,
  setSpaSelection,
  setIsEditMode,
  isEditMode,
  termsChecked,
  width,
}) => {
  const [showCnxDialog, setShowCnxDialog] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const { showCancellationDialog, handleRemove, showEditMode } =
    SpaExtraTableLogic(
      setShowCnxDialog,
      setShowSpaExtraSection,
      setUnfinishedSelection,
      setTermsChecked,
      setSpaFormData,
      unfinishedSelection,
      spaSelection,
      setSpaSelection,
      setIsEditMode,
      isEditMode,
      termsChecked,
      selectedDate
    );

  const checkIfCancelable = (date, time, action) => {
    const before48h = new Date().getTime() + 1 * 48 * 60 * 60 * 1000;
    const bookingEpoch = new Date(`${date}T${time}`).getTime();

    if (before48h > bookingEpoch && action !== "add") {
      return before48h > bookingEpoch && action !== "add";
    } else {
      return spaSelection[selectedDate].find((row) => row.Time === time)?.ProviderCode.length === 0 && action === "remove"
    }
  };

  const obtainTotalPrice = () => {
    let price = 0;
    filteredBookings.forEach((row) => {
      if (row.action !== "remove") {
        if (row.hasOwnProperty("ConfirmedPrice")) {
          price += row.ConfirmedPrice;
        } else {
          price += Number(servicePrice);
        }
      }
    });
    setTotalPrice(price);
  };

  useEffect(() => {
    obtainTotalPrice();
  }, [selectedDate, filteredBookings]);

  return (
    <>
      {width > 1324 ? (
        <>
          <div
            style={{
              width: "100%",
              background: "white",
              padding: "20px 0",
              border: "1px solid rgb(181, 181, 183)",
            }}
          >
            <p style={{ paddingBottom: "20px", paddingLeft: "20px" }}>
              <strong>Selected treatments:</strong>
            </p>

            <div className={styles.spaBookingsTableWrapper}>
              {filteredBookings.map(
                (
                  {
                    firstName,
                    lastName,
                    FirstName,
                    LastName,
                    time,
                    day,
                    facilityTitle,
                    facilityGUID,
                    action,
                    isParticipant,
                    participantFirstName,
                    participantLastName,
                    ConfirmedPrice,
                  },
                  index
                ) => (
                  <>
                    <div
                      key={`${index}_${lastName ? lastName : LastName}`}
                      className={styles.spaBookingsTableRow}
                    >
                      <p
                        style={{
                          textDecoration:
                            action === "remove" ? "line-through" : "",
                        }}
                        className={styles.spaBookingsTableGuestNameCell}
                      >{`${
                        isParticipant
                          ? participantLastName
                          : lastName
                          ? lastName
                          : LastName
                      }, ${
                        isParticipant
                          ? participantFirstName
                          : firstName
                          ? firstName
                          : FirstName
                      }`}</p>

                      <p
                        style={{
                          textDecoration:
                            action === "remove" ? "line-through" : "",
                        }}
                        className={styles.spaBookingsTableTreatmentNameCell}
                      >
                        <strong>{`${facilityTitle}`}</strong>
                      </p>
                      <p
                        style={{
                          textDecoration:
                            action === "remove" ? "line-through" : "",
                        }}
                        className={styles.spaBookingsTableDateCell}
                      >{`${time.substring(0, 5)} ${getDayOfWeek(
                        day
                      )}: ${formatDate(day)}`}</p>
                      <p
                        style={{
                          textDecoration:
                            action === "remove" ? "line-through" : "",
                        }}
                        className={styles.spaBookingsTablePriceCell}
                      >
                        <strong>{`£${
                          ConfirmedPrice ? ConfirmedPrice : servicePrice
                        }`}</strong>
                      </p>
                      <div className={`${styles.spaBookingsTableButtonsCell}`}>
                        {action !== "remove" &&
                        !checkIfCancelable(day, time, action) ? (
                          <div
                            style={{
                              width: "50%",
                              textDecoration: "underline",
                              color: "var(--color-info)",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              showEditMode(
                                facilityGUID[0],
                                day,
                                time,
                                isParticipant,
                                lastName,
                                firstName,
                                participantLastName,
                                participantFirstName,
                                Number(index),
                                action
                              )
                            }
                          >
                            {isEditMode && isEditMode.rowIndex === Number(index)
                              ? null
                              : "edit"}
                          </div>
                        ) : null}
                        {!checkIfCancelable(day, time, action) ? (
                          <div
                            style={{
                              width: "50%",
                              textDecoration: "underline",
                              color: "var(--color-info)",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              showCancellationDialog(
                                facilityGUID[0],
                                day,
                                time,
                                isParticipant,
                                lastName,
                                participantLastName,
                                firstName,
                                participantFirstName
                              )
                            }
                          >
                            {action === "add"
                              ? "remove"
                              : action === "none"
                              ? "cancel"
                              : "undo"}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                )
              )}
            </div>

            <div
              style={{
                width: "100%",
                height: "50px",
                padding: "20px 20px",
                alignItems: "center",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <h3>{`Total cost: £${totalPrice} (incl. VAT)`}</h3>
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ paddingBottom: "15px", paddingTop: "15px" }}>
            <h3 style={{ paddingBottom: "10px", color: "var(--color-primary)" }}>
              <strong>Selected treatments</strong>
            </h3>
            <div style={{ paddingBottom: "10px" }}>
              {filteredBookings.map(
                (
                  {
                    firstName,
                    lastName,
                    FirstName,
                    LastName,
                    time,
                    day,
                    facilityTitle,
                    facilityGUID,
                    action,
                    isParticipant,
                    participantFirstName,
                    participantLastName,
                    ConfirmedPrice,
                  },
                  index
                ) => (
                  <>
                    <div
                      key={`${index}_${lastName ? lastName : LastName}`}
                      style={{
                        backgroundColor: "white",
                        height: "5px",
                        width: "100%",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "var(--color-secondary)",
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{
                          justifyContent: "right",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {action !== "remove" &&
                        !isEditMode &&
                        !checkIfCancelable(day, time, action) ? (
                          <span
                            style={{}}
                            onClick={() =>
                              showEditMode(
                                facilityGUID[0],
                                day,
                                time,
                                isParticipant,
                                lastName,
                                firstName,
                                participantLastName,
                                participantFirstName,
                                Number(index),
                                action
                              )
                            }
                            className={styles.editIcon}
                          >
                            {editPencil}{" "}
                          </span>
                        ) : null}

                        <span
                          style={{
                            paddingLeft: "20px",
                            paddingRight: "10px",
                          }}
                          onClick={() =>
                            showCancellationDialog(
                              facilityGUID[0],
                              day,
                              time,
                              isParticipant,
                              lastName,
                              participantLastName,
                              firstName,
                              participantFirstName
                            )
                          }
                          className={styles.editIcon}
                        >
                          {checkIfCancelable(day, time, action)
                            ? null
                            : action === "remove"
                            ? undoSign
                            : xSign()}{" "}
                        </span>
                      </div>

                      <p>
                        <strong>{facilityTitle}</strong>

                        {action !== "add" ? (
                          <img
                            style={{
                              position: "absolute",
                              paddingLeft: "10px",
                            }}
                            src={`/img/info_icons/check_circle_blue.svg`}
                            alt=""
                          />
                        ) : null}
                      </p>
                      <p
                        style={{
                          textDecoration:
                            action === "remove" ? "line-through" : "",
                        }}
                      >
                        {`${
                          isParticipant
                            ? participantLastName
                            : lastName
                            ? lastName
                            : LastName
                        }, ${
                          isParticipant
                            ? participantFirstName
                            : firstName
                            ? firstName
                            : FirstName
                        }`}
                      </p>

                      <p
                        style={{
                          textDecoration:
                            action === "remove" ? "line-through" : "",
                        }}
                      >{`${getDayOfWeek(day)}, ${formatDate(
                        day
                      )}; ${time.substring(0, 5)}`}</p>

                      <p
                        style={{
                          textDecoration:
                            action === "remove" ? "line-through" : "",
                        }}
                      >
                        <strong>{`£${
                          ConfirmedPrice ? ConfirmedPrice : servicePrice
                        }`}</strong>
                      </p>
                    </div>
                  </>
                )
              )}
            </div>

            <div
              style={{
                width: "100%",
                height: "50px",
                padding: "20px 20px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h3>{`Total cost: £${totalPrice} (incl. VAT)`}</h3>
            </div>
          </div>
        </>
      )}
      {showCnxDialog ? (
        <AlertBox
          customContent={
            <div>
              <p>{`Are you sure?`}</p>
              <p>
                {`Have you read our `}{" "}
                <strong>
                  <a
                    href={`${policyLink}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline", color: "var(--color-info)" }}
                  >
                    Cancellation policy
                  </a>
                </strong>
                {"?"}
              </p>
            </div>
          }
          button={
            <>
              <Button
                txt={"Cancel"}
                backgroundColor={"var(--color-secondary)"}
                color={"var(--color-primary)"}
                onClick={() => setShowCnxDialog(false)}
              />
              <Button
                txt={"Confirm"}
                backgroundColor={"var(--color-primary)"}
                color={"var(--color-secondary)"}
                onClick={handleRemove}
              />
            </>
          }
          // boxNewStyles={{ width: "640px", minWidth: "640px" }}
        />
      ) : null}
    </>
  );
};

export default SpaExtraTable;

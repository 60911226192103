import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import RegisteredCard from "./components/RegisteredCard/RegisteredCard";
import SelfServicesLogic from "./SelfServicesLogic";

import styles from "./SelfServices.module.css";
import BookingFolio from "../BookingFolio/BookingFolio";

const SelfServices = ({ view, setView }) => {
  const { search } = useLocation();
  const { removeModal } = SelfServicesLogic(setView);

  let CardPopUp = null;
  const parsedQuery = queryString.parse(search);
  if (parsedQuery.sessionExpired === "true") {
    return null;
  }

  if (["View your registered card", "Register your payment card"].includes(view.modalPage)) {
    CardPopUp = <RegisteredCard removeModal={removeModal} view={view} />;
  } else if (view.modalPage === "Room Account") {
    CardPopUp = <BookingFolio removeModal={removeModal} />;
  }

  return <div className={styles.SelfServices}>{CardPopUp}</div>;
};

export default SelfServices;

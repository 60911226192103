import React from "react";

import BlurryWrapper from "../../../../components/UI/BulurryWrapper/BlurryWrapper";
import Heading from "../../../../shared/Heading/Heading";
import InformationText from "../../../../shared/InformationText/InformationText";

const LoginInfoBox = ({
  title,
  dsc,
  loginInfoClickHandler,
  isLoginInfoOpened,
}) => {
  const infoHighliter = () => {
    loginInfoClickHandler(!isLoginInfoOpened);
  };
  
  return (
    <BlurryWrapper
      new_styles={{
        transition: "1s all ease-in-out",
        width: "500px",
        maxHeight: "2000px",
        marginTop: "73vh",
        marginLeft: "49.2vw",
        backdropFilter: "blur(15px)",
        borderRadius: "5px",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
        cursor: "pointer",
      }}
      clickFunction={infoHighliter}
      whiteShadowStyle={{
        padding: "40px 25px 90px",
        background: `rgba(255, 255, 255, ${
          isLoginInfoOpened ? "0.5" : "0.7"
        })`,
      }}
    >
      <Heading
        txt={title}
        new_styles={{ fontSize: "28px", color: "#3C3C3C", textAlign: "center" }}
      />
      <InformationText
        content={dsc}
        new_styles={{
          color: "#3C3C3C",
          fontSize: "16px",
          textAlign: "center",
          marginTop: "35px",
        }}
      />
    </BlurryWrapper>
  );
};

export default LoginInfoBox;

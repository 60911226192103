import { useState } from "react";

const FAQSearchLogic = (manageSearch) => {
  const [searchValue, setSearchValue] = useState("");
  const [isPlaceHolder, setIsPlaceHolder] = useState(true);

  const handleChange = (e) => {
    try {
      const { value } = e.target;
      setSearchValue(value);
      manageSearch(null, value);

      if (!value || value === "") setIsPlaceHolder(true);
    } catch (e) {
      console.log(
        `${new Date()} Error in handleChange func in FAQSearchLogic.js file ${e}`
      );
    }
  };
  
  const removePlaceHolder = () => {
    setIsPlaceHolder(false);
  };

  const displayPlaceHolder = () => {
    if (searchValue) return;

    setIsPlaceHolder(true);
  };

  return {
    handleChange,
    displayPlaceHolder,
    removePlaceHolder,
    searchValue,
    isPlaceHolder,
  };
};

export default FAQSearchLogic;

import React from "react";
import { Route, Switch } from "react-router-dom";

import useMaintenance from "../hooks/useMaintenance";
import NavBar from "../components/NavBar/NavBar";
import Login from "../views/Login/Login";
import Header from "../components/Header/Header";
import RedirectToLandingPage from "../views/RedirectToLandingPage/RedirectToLandingPage";
import MainFooter from "../components/MainFooter/MainFooter";
import PrivacyAndPolicies from "../views/PrivacyAndPolicies/PrivacyAndPolicies";
import DayUseProvider from "../context/DayUseContext";
import CookiePolicies from "../views/CookiePolicy/CookiePolicy";
import ScrollToTop from "../hooks/ScrollToTop";
import HelpPage from "../views/HelpPage/HelpPage";
import PromotionalPage from "../views/PromotionalPage/PromotionalPage";
import Maintenance from "../views/Maintenance/Maintenance";
import WhatsOnGuide from "../views/WhatsOnGuide/WhatsOnGuide";
import Kiosk from "../views/Kiosk/Kiosk";
import RouteLogic from "./RouteLogic";
import DayUse from "../views/DayUsePage/DayUse";
import PXPForm from "../components/PXPForm/PXPForm";
import PXPResult from "../views/PXPResult/PXPResult";

import { WhatsOnDataProvider } from "../context/WhatsOnDataContext";
import { SelectionProvider } from "../context/SelectionContext";

import SelfServices from "../views/SelfServices/SelfServices";
import LoginOld from "../views/LoginOld/Login";
import { ROUTES } from "./routeConstants";

const Index = () => {
  const { view, setView, switchPages } = RouteLogic();
  const isDayUse = window.location.href.includes("dayexperiences") || window.location.href.includes("day-use");
  const {} = useMaintenance(setView);
  
  return (
    <>
      <ScrollToTop view={view} />
      {!isDayUse && (
        <>
          <Header
            landingPage={true}
            view={view.type}
            setView={(v) => setView(v)}
          />
          <NavBar setView={(v) => setView(v)} view={view.type} />
        </>
      )}
      <Switch>
        <Route
          exact={true}
          path={"/pxp/error"}
          component={() => (
            <PXPResult view={view} setView={setView} callbackStatus={"error"} />
          )}
        />
        <Route
          exact={true}
          path={"/pxp/refused"}
          component={() => () =>
            (
              <PXPResult
                view={view}
                setView={setView}
                callbackStatus={"refused"}
              />
            )}
        />
        <Route
          exact={true}
          path={"/pxp/cancel"}
          component={() => (
            <PXPResult
              view={view}
              setView={setView}
              callbackStatus={"cancel"}
            />
          )}
        />
        <Route
          exact={true}
          path={"/pxp/success"}
          component={() => (
            <PXPResult
              view={view}
              setView={setView}
              callbackStatus={"success"}
            />
          )}
        />
        <Route
          exact={true}
          path={"/"}
          component={() => {
            return process.env.REACT_APP_DAY_USE_ENABLED !== "0" ? (
              <Login view={view} setView={setView} />
            ) : (
              <LoginOld view={view} setView={setView} />
            )
          }}
        />
        {process.env.REACT_APP_DAY_USE_ENABLED !== "0" && isDayUse && (
          <DayUseProvider
            view={view.type === "" ? "show-activities" : view.type}
            setView={setView}
          >
            <Route
              exact={true}
              path={"/dayexperiences/:hotel?"}
              component={DayUse}
            />
            <Route exact={true} path={"/day-use-view"} component={DayUse} />
          </DayUseProvider>
        )}
        <Route path={"/privacy"} component={PrivacyAndPolicies} />
        <Route path={"/cookie"} component={CookiePolicies} />
        <Route path={"/maintenance"} component={() => <Maintenance />} />
        <Route path={"/help"} component={HelpPage} />
        <Route path={"/serve"} component={PromotionalPage} />
        <Route path={"/kiosk"} component={Kiosk} />
        <Route path={"/temp"} exact={true} component={PXPForm} />
        {Object.values(ROUTES).map(path => {
          return (
          <Route
            key="Home"
            exact={true}
            path={path}
            component={() => switchPages(view)}
          />
        )})}
        
        <Route
          path={"/:maestroId/:bookingId/:surname"}
          exact={true}
          component={RedirectToLandingPage}
        />{" "}
        <SelectionProvider>
          <WhatsOnDataProvider>
            <Route
              path={"/:hotel/whatsonguidepublic"}
              component={() => <WhatsOnGuide view={view} setView={setView} />}
            />
            <Route
              path={"/:hotel/whatsonguideteam"}
              component={() => <WhatsOnGuide view={view} setView={setView} />}
            />
          </WhatsOnDataProvider>
        </SelectionProvider>
        <Route
          path={"/:maestroId/:bookingId?/:surname?"}
          component={RedirectToLandingPage}
        />
      </Switch>
      {view.modalPage ? <SelfServices setView={setView} view={view} /> : null}
      {isDayUse ? window.location.pathname !== '/' && <MainFooter /> : <MainFooter />}
    </>
  );
};

export default Index;

import { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { validateForm } from "../../helpingFunctions/helpingFunctions";
import ValidateLogInHook from "../../hooks/ValidateLoginInHook";
import queryString from "query-string";
import axiosInstance from "../../url/createAxios";
import { DataContext } from "../../context/DataContext";

import { ROUTES } from "../../routes/routeConstants";
import { HOTEL_SELECTION_PACEHOLDER } from "../../constants/constants";

const LoginLogic = () => {
  const instance = axiosInstance();
  const history = useHistory();
  const { search } = useLocation();
  const { isKioskRedirect } = useContext(DataContext);

  const [formData, setFormData] = useState();
  const [loginPageData, setLoginPageData] = useState({});
  const [bannerTexts, setBannerTexts] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(HOTEL_SELECTION_PACEHOLDER);
  const [isError, displayError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isWaitingToLogIn, setIsWaitingToLogin] = useState(false);
  const [hasSessionExpired, setHasSessionExpired] = useState(false);
  const [wrongCRSAlert, setWrongCRSAlert] = useState(false);

  const { handleLogIn } = ValidateLogInHook();

  const manageFormData = (key, value) => {
    if (key === "bookingId" && ["2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(value[0])) {
      setWrongCRSAlert(true);
      value = "";
    }

    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  const prepareErrorMessages = (keys, length) => {
    if (length === 3) {
      return "Please select the hotel from the list, then enter the surname and booking ID to log in";
    }

    if (length === 1) {
      // eslint-disable-next-line default-case
      switch (keys[0]) {
        case "hotelId":
          return "Please select the hotel from the list to help us find your booking";
        case "bookingId":
          return "Please enter the booking ID to log in";
        case "surname":
          return "Please enter your surname to log in";
      }
      return;
    }

    if (keys.includes("hotelId")) {
      return `Please select the hotel from the list, then enter the ${
        keys[0] === "bookingId" ? "booking ID" : keys[0]
      } to log in`;
    } else {
      return `Please enter your surname and booking ID to log in`;
    }
  };

  const handleRequest = async (e, formData) => {
    e.preventDefault();
    try {
      if (!formData) {
        displayError(
          "Please select the hotel from the list then enter the surname and booking ID to log in"
        );
        return;
      }

      const { hotelId, bookingId, surname } = formData;
      const undefinedValues = validateForm({ bookingId, surname, hotelId });
      const undefinedKeys = Object.keys(undefinedValues);

      if (undefinedKeys.length > 0) {
        const errorMsg = prepareErrorMessages(
          undefinedKeys,
          undefinedKeys.length
        );
        displayError(errorMsg);

        return;
      }

      setIsWaitingToLogin(true);
      const res = await handleLogIn(formData);
      setIsWaitingToLogin(false);

      if (res.success) {
        history.push(ROUTES.Home);
        return;
      }

      displayError(res.error);
    } catch (err) {
      console.log(
        `${new Date()} Error in handleRequest function in LoginLogic.js file ${
          err.message
        }`
      );
    }
  };

  const getLogInPageData = async () => {
    try {
      const { data } = await instance.get("/login-page/initial");

      if (data.UserLoggedIn) {
        history.push(ROUTES.Home);
        return;
      }

      const filteredHotels = data.Hotels;
      data.Hotels = filteredHotels.sort((a, b) =>
        a.Title.toLowerCase().localeCompare(b.Title.toLowerCase())
      );

      if (isKioskRedirect) {
        const heythropDetails = filteredHotels.find((row) =>
          row.Title.includes("Heythrop")
        );
        if (heythropDetails) {
          manageFormData("hotelId", heythropDetails.id);
          setSelectedHotel(heythropDetails.Title);
        }
      }

      setBannerTexts(data.BannerTexts);
      setLoginPageData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const checkForSessionError = () => {
    const parsedQuery = queryString.parse(search);
    if (parsedQuery.sessionExpired === "true") {
      setHasSessionExpired(true);
    }
  };
  useEffect(() => {
    localStorage.setItem("groupPopUp", "initial");
  }, []);
  useEffect(() => {
    checkForSessionError();
    getLogInPageData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    manageFormData,
    handleRequest,
    prepareErrorMessages,
    setSelectedHotel,
    isWaitingToLogIn,
    formData,
    loginPageData,
    selectedHotel,
    isError,
    hasSessionExpired,
    setHasSessionExpired,
    loading,
    bannerTexts,
    wrongCRSAlert,
    setWrongCRSAlert,
  };
};

export default LoginLogic;

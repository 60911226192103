import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../../context/DataContext";
import {
  isInclusive,
  obtainPrice,
  checkAvailability
} from "../../../helpingFunctions/facilityAndServices";
import { ActivitiesTypes } from "../../../constants/constants";
import Heading from "../../../shared/Heading/Heading";
import IndicatiorCircle from "../../../shared/IndicatorNumber/IndicatiorCircle";
import WindowWidth from "../../../hooks/WindowWidth";
import VenueBanner from "../../../shared/VenueBanner/VenueBanner";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";

import styles from "./activity-card.module.css";

const ActivityCardMobile = ({
  id,
  Title,
  ShortDescription,
  Image,
  onClick,
  Details,
  objType,
  Price,
  view,
}) => {
  const { availibility, facilitiesData } =
    useContext(DataContext);
  const { width } = WindowWidth();
  const [hasAvailability, setHasAvailability] = useState(false);

  useEffect(() => {
    if (availibility && ActivitiesTypes.includes(objType)) {
      checkAvailability(availibility, id, setHasAvailability);
    }
  }, [availibility]);

  return (
    <div
      className={styles.activityCardMobile}
      onClick={onClick}
      style={{
        marginTop: ActivitiesTypes.includes(objType) ? "0" : "70px",
        minHeight: ActivitiesTypes.includes(objType) && width < 425 ? "360px" : "390px",
      }}
    >
      {view !== "Treats"  && objType !== "Spa" && objType !== "Activity" && (
        <VenueBanner
          new_styles={{
            width: "100%",
            height: "13%",
            left: 0,
            top: 0,
            backgroundColor: "var(--color-primary)",
          }}
          textNewStyles={{
            fontSize: "16px",
          }}
          content={
            <>
              {hasAvailability === "" ? (
                <LoadingContainer />
              ) : (
                <>
                  {hasAvailability
                    ? "Slots available"
                    : "Currently no availability"}
                </>
              )}
            </>
          }
        />
      )}
      <div
        className={styles.activityCardMobile__img}
        style={{
          backgroundImage: `url(${Image[0]?.formats?.small?.url})`,
        }}
      >
        {Details?.map(({ icon }) =>
          icon ? (
            <IndicatiorCircle
              key={icon.id}
              txt={
                <img
                  width={"66%"}
                  height={"57%"}
                  src={icon.formats.thumbnail?.url}
                  alt={""}
                />
              }
              backgroundColor={"#fff"}
              color={"#5f5f5f"}
              border={"1px solid #5f5f5f"}
              width={"29px"}
              height={"29px"}
              fontSize={"1.2rem"}
            />
          ) : null
        )}
      </div>
      <div className={styles.activityCardMobile__txt}>
        <Heading txt={Title} fontSize={"18px"} />
        <p className={styles.txt__price}>
          {objType === "Spa"
            ? isInclusive(Price)
            : isInclusive(
                obtainPrice(id, objType, facilitiesData, availibility),
                objType
              )}
        </p>
        <p>{ShortDescription}</p>
      </div>
    </div>
  );
};

export default ActivityCardMobile;

import React, { useContext, useState } from "react";

import FooterLogic from "./FooterLogic";
import Button from "../../shared/Button/Button";
import { SelectionContext } from "../../context/SelectionContext";
import { getDayOfWeek } from "../../helpingFunctions/utilities";
import AllSelectionsPopUp from "../../shared/AllSelectionPopUp/AllSelectionsPopUp";
import { DataContext } from "../../context/DataContext";
import WindowWidth from "../../hooks/WindowWidth";
import AlertBox from "../AlertBox/AlertBox";
import LoadingContainer from "../../shared/LoadingContainer/LoadingContainer";
import ConflictAlertBox from "../../shared/validation/ConflictAlertBox";

import styles from "./footer.module.css";

const EntertainmentFooter = ({ dates, setView, view }) => {
  const [popupText, setPopupText] = useState(null);
  const { width } = WindowWidth();

  const {
    bookingStatus,
    setBookingStatus,
    bookingSelections,
    hasExistingChanges,
    setHasExistingChanges,
    skipedDays,
  } = useContext(SelectionContext);
  const { selectedDate, setSelectedDate, reservation } =
    useContext(DataContext);

  const {
    handleBackClick,
    manageSave,
    nextDayIndex,
    alertMessage,
    alertStatus,
    conflict,
    showConflict,
  } = FooterLogic(dates, setView, view);

  const closePopUp = () => {
    setBookingStatus(null);
    setHasExistingChanges(false);
  };

  const handlePopupAlert = () => {
    setPopupText(null);
  };

  const handleConfirm = () => {
    const departureDate = reservation.DepartureDate.split("T");
    let confirmBookings = true;
    let missingDate = null;

    dates.forEach((date) => {
      if (date !== departureDate) {
        const hasBooking = bookingSelections.find(
          (row) => row.day === date && row.action !== "remove"
        );
        if (!hasBooking && !skipedDays.includes(date)) {
          confirmBookings = false;
          missingDate = date;
          return;
        }
      }
    });

    if (confirmBookings) {
      manageSave();
    } else {
      setPopupText(
        `You haven't selected your preference for day ${missingDate}, please select a preference to complete your booking`
      );
    }
  };

  const selectedDateTransformed = new Date(
    reservation.DepartureDate.split("T")[0]
  );

  const formatTransformedDate = (date) => {
    let formattedDate = "";
    formattedDate += selectedDateTransformed.getFullYear();
    formattedDate += '-';
    formattedDate += selectedDateTransformed.getMonth() + 1 < 10 ? '0' + (selectedDateTransformed.getMonth() + 1) : selectedDateTransformed.getMonth() + 1;
    formattedDate += '-';
    formattedDate += selectedDateTransformed.getDate() < 10 ? '0' + selectedDateTransformed.getDate() : selectedDateTransformed.getDate();
    return formattedDate;
  };
  selectedDateTransformed.setDate(selectedDateTransformed.getDate() - 1);

  return (
    <div className={styles.footer}>
      <div className={styles.footerLeft}>
        {width > 1324 ? (
          <Button
            txt={"View itinerary"}
            onClick={() => setView({ type: "itinerary" })}
            color={"var(--color-primary)"}
            backgroundColor={"var(--color-secondary)"}
          />
        ) : null}
      </div>
      <div className={styles.footerRight}>
        <Button
          txt={`Back`}
          onClick={handleBackClick}
          color={"var(--color-primary)"}
          backgroundColor={"var(--color-secondary)"}
        />
        {hasExistingChanges && selectedDate === formatTransformedDate(selectedDateTransformed) ? (
          <Button
            txt={`${
              bookingSelections.find(
                (row) => row.action === "none" || row.action === "remove"
              )
                ? "Confirm time changes"
                : width > 1324
                ? "Confirm all your selections"
                : "Book"
            }`}
            onClick={handleConfirm}
            backgroundColor={"var(--color-primary)"}
            color={"var(--color-secondary)"}
          />
        ) : null}
        {selectedDate !== formatTransformedDate(selectedDateTransformed) ||
        !hasExistingChanges ? (
          <Button
            txt={`Continue to ${getDayOfWeek(dates[nextDayIndex + 1])}`}
            onClick={() => setSelectedDate(dates[nextDayIndex + 1])}
            backgroundColor={"var(--color-primary)"}
            color={"var(--color-secondary)"}
          />
        ) : null}
      </div>
      {bookingStatus && bookingSelections.length > 0 ? (
        <AllSelectionsPopUp
          setView={(v) => setView(v)}
          closePopUp={closePopUp}
          view={view}
        />
      ) : bookingStatus ? (
        <AlertBox
          customContent={
            <>
              <h3 style={{ textAlign: "center" }}>
                {bookingStatus?.results === "skiped"
                  ? "We skipped your entertainment. You can amend anytime if you change your mind"
                  : "We are skipping your entertainment."}
              </h3>

              {bookingStatus?.results !== "skiped" ? (
                <LoadingContainer />
              ) : null}

              {bookingStatus?.results === "skiped" ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    txt={"Close"}
                    color={"var(--color-primary)"}
                    backgroundColor={"var(--color-secondary)"}
                    onClick={() => setBookingStatus(null)}
                  />
                  <Button
                    txt={`Continue to itinerary`}
                    backgroundColor={"var(--color-primary)"}
                    color={"var(--color-secondary)"}
                    onClick={() => setView({ type: "itinerary" })}
                  />
                </div>
              ) : null}
            </>
          }
        />
      ) : null}
      {["strong-conflict", "soft-conflict", "average-conflict"].includes(
        alertStatus
      ) ? (
        <ConflictAlertBox
          alertMessage={alertMessage}
          alertStatus={alertStatus}
          showConflict={showConflict}
        />
      ) : null}
      {popupText ? (
        <AlertBox
          txt={popupText}
          button={
            <>
              <Button
                txt={"Go Back"}
                color={"var(--color-primary)"}
                backgroundColor={"var(--color-secondary)"}
                onClick={handlePopupAlert}
              />
            </>
          }
        />
      ) : null}
      
      {conflict ? (
        <AlertBox txt={conflict.txt} button={conflict.buttons} />
      ) : null}
    </div>
  );
};

export default EntertainmentFooter;

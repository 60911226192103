import { useEffect, useRef, useState } from "react";

const DropDownBoxLogic = (closeSubDropDowns, setShouldCloseDropDown) => {
  const [clickedItem, setClickedItem] = useState(null);
  const dropDownRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (!dropDownRef.current) return;
    if (!dropDownRef.current.contains(e.target)) manageDropDownClick(null);
  };

  const manageDropDownClick = (txt) => {
    if (clickedItem === txt) {
      setClickedItem(null);
      closeSubDropDowns({})
    } else {
      setClickedItem(txt);
      setShouldCloseDropDown(false)
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick, true);
  }, []);
  return { manageDropDownClick, clickedItem, dropDownRef };
};

export default DropDownBoxLogic;

import React from "react";
import MainTitle from "../MainTitle/MainTitle";
import BlurryWrapper from "../UI/BulurryWrapper/BlurryWrapper";

import styles from "./InformationPage.module.css";

const InformationPage = ({
  children,
  image,
  new_styles,
  backdropFilter,
  setView,
  enableHomeButton
}) => {
  return (
    <>
      <MainTitle
        height={"116px"}
        logoHeight={"130px"}
        logoWidth={"400px"}
        cursor={"pointer"}
        setView={setView}
        enableHomeButton={enableHomeButton}
      />
      <div
        className={styles.InformationPage}
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          ...new_styles,
        }}
      >
        <BlurryWrapper
          imgUrl={image}
          new_styles={{
            background: "rgba(0, 0, 0, 0.2)",
            width: "66.198vw",
            minHeight: "63.241vh",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "20px",
            paddingBottom: "20px",
            backdropFilter,
            WebkitBackdropFilter: backdropFilter
          }}
          new_class={"BlurryWrapper__helper_maintenance"}
        >
          {children}
        </BlurryWrapper>
      </div>
    </>
  );
};

export default InformationPage;

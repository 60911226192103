import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";

import WindowWidth from "../../../hooks/WindowWidth";
import Heading from "../../../shared/Heading/Heading";
import ImageCarousel from "../../../shared/ImageCarousel/ImageCarousel";
import InformationText from "../../../shared/InformationText/InformationText";

import styles from "./Details.module.css";
import { xSign } from "../../../assets/SVGIcons";
import Button from "../../../shared/Button/Button";

const Details = ({
  object = {},
  closeFunc,
  priceTxt,
  previewFunctionality,
}) => {
  const [isContraindicationsAccepted, setIsContraindicationsAccepted] =
    useState(false);

  const showContraindications =
    !isContraindicationsAccepted && !!object.Contraindications;

  const { width } = WindowWidth();

  useEffect(() => {
    setIsContraindicationsAccepted(false);
  }, [object]);

  let NavigateButton = null;
  if (previewFunctionality) {
    NavigateButton = (
      <>
        {object.Contraindications ? (
          <div className={styles.checkBoxWrapper}>
            <Heading
              txt={"Contraindications"}
              new_styles={{
                paddingBottom: "20px",
                paddingTop: "20px",
                fontSize: "20px",
                width: "100%",
              }}
            />
            <Markdown>{object.Contraindications}</Markdown>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "right",
                paddingTop: "15px",
                alignItems: "center",
              }}
            >
              <div
                className={styles.checkBox}
                onClick={() =>
                  setIsContraindicationsAccepted(!isContraindicationsAccepted)
                }
                style={{
                  marginRight: "10px",
                }}
              >
                {isContraindicationsAccepted ? (
                  <img src={"/img/info_icons/done_check.svg"} alt={""} />
                ) : null}
              </div>
              <p>{"I Accept"}</p>
            </div>
          </div>
        ) : null}
        <div className={styles.Details__PrecedeBtn}>
          <Button
            txt={
              object.objType === "Treats"
                ? "Book now / Add to stay"
                : "Check availability"
            }
            onClick={previewFunctionality}
            new_styles={{
              color: "var(--color-secondary)",
              backgroundColor: "var(--color-primary)",
              border: "2px solid var(--color-primary)",
              minWidth: "200px",
              marginTop: "15px",
              pointerEvents: object.objType === "Treats" ? "1" : showContraindications ? "none" : "auto",
              opacity: object.objType === "Treats" ? "auto" : showContraindications ? "0.4" : "1",
            }}
          />
        </div>
      </>
    );
  }
  return (
    <>
      <div className={styles.Details__title}>
        <Heading
          txt={object.Title}
          paddingTop={width >= 700 ? "40px" : "20px"}
          fontFamily="Georgia"
          textTransform="uppercase" 
          new_styles={{
            fontSize: "24px",
            paddingBottom: "20px",
            paddingTop: width >= 700 ? "40px" : "20px",
          }}
        />
        <div className={styles.Details__close} onClick={closeFunc}>
          {xSign()}
        </div>
      </div>
      <div className={styles.Details__slider}>
        <ImageCarousel onClick={() => {}} sliderImages={object.Image} />
      </div>
      <div className={styles.Details_desc}>
        <Heading
          txt={priceTxt}
          new_styles={{
            fontSize: "22px",
            paddingTop: "20px",
            paddingBottom: "20px",
            color: "var(--color-primary)",
          }}
        />
        <InformationText
          content={
            object.LongDescription ? (
              <Markdown>{object.LongDescription}</Markdown>
            ) : (
              ""
            )
          }
          new_styles={{
            fontSize: "18px",
          }}
        />
        {NavigateButton}
      </div>
    </>
  );
};

export default Details;

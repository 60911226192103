import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { TIME_FILTER_OPTIONS } from "../../../../../assets/StaticObjects";
import { inBetween } from "../../../../../helpingFunctions/helpingFunctions";
import { initialDate } from "../../../../../constants/constants";
import {
  TV_TITLE,
  VERTICAL_ORDER,
  HORIZONTAL_ORDER,
} from "../../../constants/constants";
import { WhatsOnDataContext } from "../../../../../context/WhatsOnDataContext";

const AUTOSCROLL_DISTANCE = 100;
const END_REACHED_INFO = "end";
const TOP_REACHED_INFO = "begin";

const HORIZONTAL_SCROLL_ATTR = {
  scrollSide: "scrollLeft",
  scrollSize: "scrollWidth",
  clientSize: "clientWidth",
  offset: "offsetLeft",
};

const VERTICAL_SCROLL_ATTR = {
  scrollSide: "scrollTop",
  scrollSize: "scrollHeight",
  clientSize: "clientHeight",
  offset: "offsetTop",
};

const GuideTvCarouselLogic = (data) => {
  const { whatsOnGuideAllDayActivities } =useContext(WhatsOnDataContext);
  const { search } = useLocation();
  const [activityDayTime, setActivityDayTime] = useState("");
  const longDeviceSliderRef = useRef(null);

  const { orientation, screenView } = queryString.parse(search);

  const isInTVHorizontal =
    screenView === TV_TITLE && orientation === HORIZONTAL_ORDER;
  const isInTVVertical =
    screenView === TV_TITLE && orientation === VERTICAL_ORDER;

  const isVisible =  (ele, container, attr) => {
    if(!ele) {
      return false
    }

    const eleTop = ele[attr.offset];
    const eleBottom = eleTop + ele[attr.clientSize];

    const containerTop = container[attr.scrollSide];
    const containerBottom = containerTop + container[attr.clientSize];

    return (
      (eleTop >= containerTop && eleBottom <= containerBottom) ||
      (eleTop < containerTop && containerTop < eleBottom) ||
      (eleTop < containerBottom && containerBottom < eleBottom)
    );
  };

  const handleDisplayDayTime = (sliderRef, attr) => {
    const visibleItems = data.concat(whatsOnGuideAllDayActivities).filter(({ timeId }) => {
      const visibleItem = isVisible(
        timeId ? document.getElementById(timeId) : document.getElementById("All Day Activities"),
        sliderRef.current,
        attr
      );
      return visibleItem;
    });

    const [lastVisibleItem] = visibleItems.slice(-1);
    
    const visibleItemMoment = new Date(
      `${initialDate}T${lastVisibleItem?.time}:00`
    ).getTime();

    const day = TIME_FILTER_OPTIONS.find(({ startHour, endHour }) => {
      const startMoment = new Date(`${initialDate}T${startHour}:00`).getTime();
      const endMoment = new Date(`${initialDate}T${endHour}:00`).getTime();

      return inBetween(visibleItemMoment, startMoment, endMoment);
    });

    setActivityDayTime(day ? day.dayTime : "All Day Activities");
  };

  const scrollLogic = (status, sliderRef, attr) => {
    try {
      if (status === END_REACHED_INFO) {
        sliderRef.current[attr.scrollSide] -= AUTOSCROLL_DISTANCE;
      } else if (status === TOP_REACHED_INFO) {
        sliderRef.current[attr.scrollSide] += AUTOSCROLL_DISTANCE;
      }

      const scrollingPos =
        sliderRef.current[attr.clientSize] + sliderRef.current[attr.scrollSide];
      const endingReached =
        Math.abs(scrollingPos - sliderRef.current[attr.scrollSize]) < 2;

      handleDisplayDayTime(sliderRef, attr);

      if (endingReached) {
        sliderRef.current[attr.scrollSide] = 0;
      }
      return status;
    } catch (error) {
      console.error(
        `${new Date()} Error in scrollLogic in GuideTvCarouselLogic.js ${
          error.message
        }`
      );
    }
  };

  const handleEndlessScrolling = (sliderRef) => {
    try {
      if (!sliderRef || (data.length <= 0 && whatsOnGuideAllDayActivities.length <=0)) {
        return;
      }
      let status = TOP_REACHED_INFO;

      const attrObj = isInTVHorizontal
        ? HORIZONTAL_SCROLL_ATTR
        : VERTICAL_SCROLL_ATTR;

      setInterval(() => {
        status = scrollLogic(status, sliderRef, attrObj);
      }, 3000);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleEndlessScrolling func in WhatsOnGuideLogic file ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    handleEndlessScrolling(longDeviceSliderRef);
  }, [data]);

  return {
    longDeviceSliderRef,
    activityDayTime,
    isInTVHorizontal,
    isInTVVertical,
  };
};

export default GuideTvCarouselLogic;

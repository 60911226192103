import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";
import { findParticipants } from "../../helpingFunctions/groups";
import { calcTotalGuests } from "../../helpingFunctions/groups";

const ManageBookingLogic = (itineraryType, setTotalGuests) => {
  const { itineraryData, selectedDate, reservation, groupData } =
    useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [participants, setParticipants] = useState([]);

  const findGroup = async () => {
    try {
      const cleanedDate = selectedDate.endsWith("I")
        ? selectedDate.substr(0, selectedDate.length - 1)
        : selectedDate;

      const allParticipants = findParticipants(
        cleanedDate,
        itineraryData,
        itineraryType
      );

      setIsLoading(false);
      setParticipants([reservation.ReservationNumber, ...allParticipants]);
    } catch (e) {
      console.error(
        `${new Date()} Error in getGroup func in ManageBookingLogic.js file ${e}`
      );
    }
  };

  const findTotalGuests = () => {
    try {
      const presentInCurrentDay = groupData.items.filter((groupItem) =>
        participants.includes(groupItem.ReservationNumber)
      );

      setTotalGuests(calcTotalGuests(presentInCurrentDay));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      participants.length > 0 &&
      participants.length < groupData.items.length
    ) {
      findTotalGuests();
    }
  }, [participants]);

  useEffect(() => {
    findGroup();
  }, []);

  return { participants, isLoading };
};

export default ManageBookingLogic;

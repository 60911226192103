import React, { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";
import { SelectionContext } from "../../../context/SelectionContext";
import { getSelectionByDate } from "../../../helpingFunctions/selections";
import SortJSX from "../../../helpingFunctions/SortJSX";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import TimeButton from "../../../shared/TimeButton/TimeButton";

import styles from "./activity-time-slots.module.css";

const ActivityTimeSlotsDesktop = ({
  data,
  type,
  loading,
  spaLoading,
  id,
  paddingLeft,
  borderLeft,
  view,
  setActivityTimeLimit,
  setSpaSelection,
  spaSelection,
  setUnfinishedSelection,
  unfinishedSelection,
}) => {
  const { selectedDate, itineraryData } = useContext(DataContext);
  const { bookingSelections } = useContext(SelectionContext);
  const [timesByType, setTimesByType] = useState([]);
  const [quanitity, setQuantity] = useState(0);

  const filterTimes = () => {
    const filteredTimes = data.filter(({ Time }) =>
      type === "Morning"
        ? parseInt(Time?.substr(0, 2)) < 12
        : parseInt(Time?.substr(0, 3)) >= 12
    );

    setTimesByType(filteredTimes);
  };

  const findQuantity = () => {
    const selection = getSelectionByDate(bookingSelections, selectedDate);
    setQuantity(selection?.Quantity ? selection.Quantity : 0);
  };

  const activityTimeLimitHandler = () => {
    try {
      setActivityTimeLimit(null);
      setTimeout(() => {
        const selection = getSelectionByDate(bookingSelections, selectedDate);

        const timeObj = data.find(({ Time }) => Time === selection?.time);

        if (timeObj?.Availability) {
          setActivityTimeLimit({
            ...{
              status: "constructed",
              Availability: parseInt(timeObj.Availability),
            },
          });
        } else {
          setActivityTimeLimit(null);
        }
      }, 10);
    } catch (e) {
      console.log(
        `${new Date()} Error in activityTimeLimitHandler func in ActivityTimeSlotsDesktop.js file ${e}`
      );
    }
  };

  useEffect(() => {
    filterTimes();
  }, [data]);

  useEffect(() => {
    findQuantity();
  }, [bookingSelections, data]);
 
  useEffect(() => {
    activityTimeLimitHandler();
  }, [selectedDate, bookingSelections, data]);

  useEffect(() => {
    if (unfinishedSelection) {
      const index = spaSelection[unfinishedSelection.date].findIndex(
        (row) => row.Time === unfinishedSelection.time
      );

      if (index !== -1) {
        const spaOptions = { ...spaSelection };

        spaOptions[unfinishedSelection.date][index].selected = false;
        setSpaSelection(spaOptions);
      }
      setUnfinishedSelection(null);
    }
  }, [selectedDate]);

  return (
    <div
      className={styles.timeSlots}
      style={{
        paddingLeft,
        borderLeft,
      }}
    >
      <h3 className={styles.timeSlotTitle}>{type}</h3>
      <div className={styles.timeWrapper}>
        {(
          view === "Spa"
            ? !spaLoading[selectedDate]?.isLoading && spaSelection.hasOwnProperty(selectedDate)
            : itineraryData && !loading
        ) ? (
          <SortJSX by="time">
            {timesByType.map(({ Time, Availability }) => (
              <TimeButton
                key={Time}
                time={Time}
                Availability={Availability}
                facilityGUID={[id]}
                available={parseInt(Availability) >= quanitity}
                width={"100px"}
                height={"45px"}
                border={"2px solid var(--color-primary)"}
                setActivityTimeLimit={setActivityTimeLimit}
                view={view}
                setSpaSelection={setSpaSelection}
                spaSelection={spaSelection}
                setUnfinishedSelection={setUnfinishedSelection}
                unfinishedSelection={unfinishedSelection}
              />
            ))}
          </SortJSX>
        ) : (
          <LoadingContainer />
        )}
      </div>
    </div>
  );
};

export default ActivityTimeSlotsDesktop;

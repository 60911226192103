import { useContext } from "react";
import { DataContext } from "../../context/DataContext";

const BookingTypeLogic = (setView, view) => {
  const { landingPage } = useContext(DataContext);
  const handleClick = (type, setCrossBurgerDiv) => {
    if (view === type) {
      return;
    }

    setView({ type });
    setCrossBurgerDiv(false);
  };

  return { handleClick, landingPage };
};

export default BookingTypeLogic;

import React from "react";
import { useContext } from "react";
import AlertBox from "../../components/AlertBox/AlertBox";
import { DataContext } from "../../context/DataContext";
import Button from "../Button/Button";

const GroupBookingAlert = ({ setView }) => {
  const { createGroupAlert, setCreateGroupAlert, reservation } =
    useContext(DataContext);

  if (
    reservation.Breakfast ||
    reservation.Dinner ||
    reservation.PartOfGroup ||
    createGroupAlert.status !== "initial"
  ) {
    return null;
  }

  return (
    <AlertBox
      txt={createGroupAlert.message}
      button={
        <>
          <Button
            txt={"Yes"}
            onClick={setView.bind(this, {
              type: "group-booking",
              id: 1,
              fromManage: false,
            })}
            new_styles={{
              backgroundColor: "var(--color-primary)",
              color: "var(--color-secondary)",
            }}
          />
          <Button
            txt={"No"}
            onClick={() => {
              localStorage.setItem("groupPopUp", "negative");
              setCreateGroupAlert({
                status: "negative",
                message: null,
              });
            }}
            new_styles={{
              backgroundColor: "var(--color-secondary)",
              color: "var(--color-primary)",
            }}
          />
        </>
      }
    />
  );
};

export default GroupBookingAlert;

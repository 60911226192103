import React from "react";

import Button from "../../../shared/Button/Button";
import Heading from "../../../shared/Heading/Heading";
import { stopPropagationAndCallback } from "../../../helpingFunctions/helpingFunctions";
import WindowWidth from "../../../hooks/WindowWidth";

import styles from "./CategoryBanner.module.css";

const CategoryBanner = ({
  handleClick,
  buttonClick,
  isSelected,
  categoryBannersRef,
  additionalData,
}) => {
  const { content, image, ServiceTypeCode, TreatCategoryId, index } = additionalData;
  const { width } = WindowWidth();
  let GoToTopButton = null;

  let mobileSettings = {};
  if (width <= 700) {
    mobileSettings = {
      paddingLeft: "15px",
      paddingRight: "15px",
      fontSize: "16px"
    };
  }

  if (buttonClick) {
    GoToTopButton = (
      <Button
        txt={"Go to top"}
        onClick={stopPropagationAndCallback.bind(this, buttonClick)}
        new_styles={{
          color: "var(--color-primary)",
          backgroundColor: "var(--color-secondary)",
          border: "3px solid var(--color-primary)",
          minWidth: "120px",
          ...mobileSettings,
        }}
      />
    );
  }

  return (
    <article
      className={styles.CategoryBanner}
      style={{
        backgroundImage: `linear-gradient(
        0deg,
        rgba(28, 28, 28, 1) 0%,
        rgba(168, 168, 168, 0.2) 100%
      ), url(${image})`,
      }}
      onClick={handleClick}
      ref={(el) =>
        (categoryBannersRef.current[index] = {
          el,
          ServiceTypeCode: ServiceTypeCode,
          TreatCategoryId: TreatCategoryId,
        })
      }
    >
      <Heading
        txt={
          <>
            <img
              className={`${styles.CategoryBanner__arrow} ${
                isSelected ? styles.CategoryBanner__arrow_up : ""
              }`}
              width={"35px"}
              height={"35px"}
              src={`/img/drop-down-arrow_1.svg`}
              alt="no img"
            />
            {content}
          </>
        }
        new_styles={{
          fontSize: width > 700 ? "24px" : "18px",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "60%"
        }}
      />
      {GoToTopButton}
    </article>
  );
};

export default CategoryBanner;

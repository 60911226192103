import React, { useContext, useEffect } from "react";

import DesktopDetails from "../../../components/ActivityAndSpa_Details/DeatailsDesktop/Details";
import MobileDetails from "../../../components/ActivityAndSpa_Details/DetailsMobile/Details";
import { DataContext } from "../../../context/DataContext";
import { managePriceTxt } from "../../../helpingFunctions/facilityAndServices";
import WindowWidth from "../../../hooks/WindowWidth";

import styles from "./DetailsBox.module.css";

const DetailsBox = ({
  clickedObject,
  gridSettings = {},
  unselectCard,
  previewFunctionality,
  dimensionClass
}) => {
  const { facilitiesData, availibility } = useContext(DataContext);
  const { width } = WindowWidth();
  
  return (
    <article
      className={styles[dimensionClass]}
      style={{
        gridRowStart: gridSettings.gridRowNum,
        gridRowEnd: gridSettings.gridRowNum,
        gridColumnEnd: gridSettings.gridColEnd,
      }}
    >
      {width > 1324 ? (
        <DesktopDetails
          object={clickedObject}
          priceTxt={managePriceTxt(clickedObject, facilitiesData, availibility)}
          closeFunc={unselectCard}
          previewFunctionality={previewFunctionality}
          sliderWrapperSize={{
            width: gridSettings.sliderWidth,
            height: gridSettings.sliderHeight,
          }}
          smallerType={true}
        />
      ) : (
        <MobileDetails
          object={clickedObject}
          priceTxt={managePriceTxt(clickedObject, facilitiesData, availibility)}
          closeFunc={unselectCard}
          previewFunctionality={previewFunctionality}
          sliderWrapperSize={{
            width: gridSettings.sliderWidth,
            height: gridSettings.sliderHeight,
          }}
        />
      )}
    </article>
  );
};

export default DetailsBox;

import React from 'react';

import styles from './vertical_line.module.css';

const VerticalLine = ({ height, marginRight, marginLeft, bgColor }) => {
    return (
        <span 
            className={styles.verticalLine}
            style={{height, marginRight, marginLeft, backgroundColor: bgColor}}
        >

            </span>
    )   
};

export default VerticalLine;
import React, { useContext, useState } from "react";

import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import SpaSharedLogic from "./SpaSharedLogic";

const SpaExtraSectionLogic = (
  unfinishedSelection,
  setActionAlertObj,
  spaFormData,
  optionDetails,
  termsChecked,
  setTermsChecked,
  setSpaFormData,
  setShowSpaExtraSection,
  setUnfinishedSelection,
  setSpaSelection,
  spaSelection,
  setIsEditMode
) => {
  const {
    bookingSelections,
    setBookingSelections,
    createSpaSelection,
    getSpaSelection,
  } = useContext(SelectionContext);

  const { reservation } = useContext(DataContext);

  const { handleSpaAvailabilityOnAction } = SpaSharedLogic();

  const handleCheckBox = () => {
    setTermsChecked((prevState) => !prevState);
  };

  const validateSpaForm = () => {
    if (!unfinishedSelection) {
      setActionAlertObj({
        status: "missing-atr",
        alertMessage: "Please select a time preference before proceeding.",
      });
      return false;
    }

    if (!termsChecked) {
      spaFormData.lastName = spaFormData?.lastName?.trim();
      spaFormData.firstName = spaFormData?.firstName?.trim();
    }

    const hasSameDaySameHourSameName = bookingSelections.find((row) => {
      if (
        row.facilityGUID[0] === optionDetails.id &&
        row.day === unfinishedSelection.date &&
        row.time === unfinishedSelection.time
      ) {
        if (termsChecked) {
          if (
            !row.isParticipant &&
            (row.firstName ? row.firstName : row.FirstName === reservation.FirstName &&
            row.lastName ? row.lastName : row.LastName === reservation.LastName)
          ) {
            return row;
          }
        } else {
          if (
            row.participantFirstName === spaFormData.firstName &&
            row.participantLastName === spaFormData.lastName
          ) {
            return row;
          }
        }

        // if (row.time === row.timeBooked && row.isParticipant === !termsChecked) {
        //   return row;
        // }
      }
      return undefined;
    });

    if (hasSameDaySameHourSameName) {
      const guestName = hasSameDaySameHourSameName.isParticipant
        ? `${hasSameDaySameHourSameName.participantLastName}, ${hasSameDaySameHourSameName.participantFirstName}`
        : `${hasSameDaySameHourSameName.lastName ? hasSameDaySameHourSameName.lastName : hasSameDaySameHourSameName.LastName}, ${hasSameDaySameHourSameName.firstName ? hasSameDaySameHourSameName.firstName : hasSameDaySameHourSameName.FirstName}`;
      setActionAlertObj({
        status: "missing-atr",
        alertMessage: (
          <div>
            Guest <strong>{guestName}</strong> has{" "}
            <strong>{hasSameDaySameHourSameName.facilityTitle}</strong>{" "}
            treatment selection at{" "}
            {hasSameDaySameHourSameName.time.substring(0, 5)} on{" "}
            {hasSameDaySameHourSameName.day}
          </div>
        ),
      });
      return false;
    }

    if (!termsChecked) {
      if (spaFormData?.lastName === "" || spaFormData?.firstName === "") {
        setActionAlertObj({
          status: "missing-atr",
          alertMessage: "Please add both guest names before proceeding.",
        });
        return false;
      }

      if (
        spaFormData?.lastName.length < 2 ||
        spaFormData?.firstName.length < 2
      ) {
        setActionAlertObj({
          status: "missing-atr",
          alertMessage: "Minimum 2 characters required per guest name.",
        });
        return false;
      }

      const specialCharacterPattern =
        /[0-9\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\"\|\~\`\_\-]/;
      if (
        spaFormData?.lastName.match(specialCharacterPattern) ||
        spaFormData?.firstName.match(specialCharacterPattern)
      ) {
        setActionAlertObj({
          status: "missing-atr",
          alertMessage: "Special characters are not allowed.",
        });
        return false;
      }
    }

    return true;
  };

  const handleAddAction = () => {
    const foundIndex = bookingSelections.findIndex(
      (row) =>
        row.day === unfinishedSelection.date &&
        row.time === unfinishedSelection.time &&
        row.action === "remove"
    );

    if (foundIndex !== -1) {
      if (bookingSelections[foundIndex].isParticipant === !termsChecked) {
        createSpaSelection({ foundIndex });
        handleSpaAvailabilityOnAction(
          "remove",
          foundIndex,
          spaSelection,
          setSpaSelection
        );

        const previousSelectionIndex = spaSelection[
          bookingSelections[foundIndex].day
        ].findIndex((row) => row.Time === unfinishedSelection.time);
        spaSelection[bookingSelections[foundIndex].day][
          previousSelectionIndex
        ].selected = false;

        setIsEditMode(null);
        setTermsChecked(false);
        setUnfinishedSelection(null);
        setShowSpaExtraSection(false);
        setSpaSelection(spaSelection);

        setSpaFormData({
          firstName: "",
          lastName: "",
        });
        return;
      }
    }

    let prepared = {
      day: unfinishedSelection.date,
      time: unfinishedSelection.time,
      LastName: reservation.LastName,
      FirstName: reservation.FirstName,
      ReservationNumber: reservation.ReservationNumber,
      Quantity: 1,
      providerCode: "",
      facilityGUID: [optionDetails.id],
      facilityTitle: [optionDetails.Title],
      action: "add",
      title: optionDetails.Title,
      isParticipant: false,
      type: "Spa",
    };

    if (!termsChecked) {
      prepared.participantLastName = spaFormData.lastName;
      prepared.participantFirstName = spaFormData.firstName;
      prepared.isParticipant = true;
    }

    const spaOptions = { ...spaSelection };

    const index = spaOptions[unfinishedSelection.date].findIndex(
      (row) => row.Time === unfinishedSelection.time
    );

    setTimeout(() => {
      prepared.providerCode = spaOptions[unfinishedSelection.date][index].ProviderCode.pop();
      
      spaOptions[unfinishedSelection.date][index].Availability = spaOptions[unfinishedSelection.date][index].ProviderCode.length;
      spaOptions[unfinishedSelection.date][index].selected = false;

      createSpaSelection(prepared);
      setSpaSelection(spaOptions);
      setUnfinishedSelection(null);
      setShowSpaExtraSection(false);
    }, 0);

    if (!termsChecked) {
      setSpaFormData({ lastName: "", firstName: "" });
    }
  };

  const handleModification = (obj) => {
    const isValid = validateSpaForm();
    if (!isValid) return;

    const toModifyIndex = getSpaSelection(
      obj.facilityId,
      obj.day,
      obj.time,
      obj.isParticipant,
      obj.lastName,
      obj.participantLastName,
      obj.firstName,
      obj.participantFirstName
    );

    const toModify = bookingSelections[toModifyIndex];

    let items = [...bookingSelections];
    let item = { ...items[toModifyIndex] };
    if (toModify.action === "add") {
      if (item.isParticipant) {
        item.participantFirstName = spaFormData.firstName;
        item.participantLastName = spaFormData.lastName;
      }

      item.time = unfinishedSelection.time;

      if (termsChecked && item.isParticipant) {
        item.isParticipant = false;
        delete item.participantFirstName;
        delete item.participantLastName;
      }

      if (!termsChecked && !item.isParticipant) {
        item.isParticipant = true;
        item.participantFirstName = spaFormData.firstName;
        item.participantLastName = spaFormData.lastName;
      }

      const spaOptions = { ...spaSelection };

      const index = spaOptions[unfinishedSelection.date].findIndex(
        (row) => row.Time === unfinishedSelection.time
      );
      spaOptions[unfinishedSelection.date][index].selected = false;
      item.providerCode = handleSpaAvailabilityOnAction(
        "edit",
        toModifyIndex,
        spaSelection,
        setSpaSelection,
        toModify.time,
        unfinishedSelection.time
      );
      items[toModifyIndex] = item;
      setSpaFormData({ firstName: "", lastName: "" });
      setBookingSelections(items);
    } else {
      item.action = "remove";
      items[toModifyIndex] = item;
      setBookingSelections(items);
      handleAddAction();
      
      const spaOptions = { ...spaSelection };
      const unselection = bookingSelections[toModifyIndex];
      const oldTimeAvail = spaOptions[unselection.day].findIndex(
        (row) => row.Time === toModify.time
      );
      spaOptions[unselection.day][oldTimeAvail].ProviderCode.push(
        unselection.providerCode
      );
      spaOptions[unselection.day][oldTimeAvail].Availability = spaOptions[unselection.day][oldTimeAvail].ProviderCode.length;
      setSpaSelection(spaOptions);
    }
    setIsEditMode(null);
    setShowSpaExtraSection(false);
    setTermsChecked(false);
  };

  return {
    validateSpaForm,
    handleCheckBox,
    handleAddAction,
    handleModification,
  };
};

export default SpaExtraSectionLogic;

import moment from "moment";
import { pickBy } from "lodash";
import levenshtein from "fast-levenshtein";

const cloneObject = (objectRef = null) => {
  try {
    if (!objectRef) {
      return;
    }
    const clone = JSON.parse(JSON.stringify(objectRef));

    return clone;
  } catch (e) {
    console.error(
      `${new Date()} Error at cloneObject function in helpingFunctions.js file: `,
      e
    );
  }
};

const assignFirstDate = (dates, setSelectedDate) => {
  if (moment(dates[0]).isBefore(moment())) {
    setSelectedDate(moment().format('YYYY-MM-DD'));
    return;
  }
  setSelectedDate(dates[0]);
};

const restrictionPopUp = (array, conflictType) => {
  try {
    let txt = "You have ";

    array.map(
      (
        {
          activityToBeDeletedPrice,
          activityToBeDeletedDate,
          activityToBeDeletedTitle,
          activityToBeDeletedTime,
        },
        index
      ) => {
        let singleText = null;
        if (conflictType === "same_time") {
          singleText = `${activityToBeDeletedTitle}, price: ${activityToBeDeletedPrice}, booked for ${moment(
            activityToBeDeletedDate
          ).format("dddd")}
          at ${activityToBeDeletedTime.substr(0, 5)}`;
        } else {
          singleText = `${activityToBeDeletedTitle} booked for ${moment(
            activityToBeDeletedDate
          ).format("dddd")}`;
        }
        txt += constructStringWithComma(singleText, index, array.length);
      }
    );

    return txt;
  } catch (e) {
    console.error(
      `${new Date()} Error in consturctPopUpStringNonDates at helpingFunctions.js file: ${e}`
    );
  }
};

const validateForm = (originalObject) =>
  pickBy(originalObject, (v) => v === undefined || v === "");

const filterTimes = (Times) =>
  Times.filter(({ Time }) => parseInt(Time?.substr(0, 3)) >= 12);

const constructStringWithComma = (value, index, listLength) => {
  if (index === 0) {
    return `${value}${listLength > 2 ? ", " : ""}`;
  } else if (index === listLength - 1) {
    return ` and ${value}`;
  } else {
    return `${value}${index === listLength - 2 ? "" : ","} `;
  }
};

const checkForMatch = (storedTxt, inputTxt, maxDiff, minDiff) => {
  return (
    levenshtein.get(storedTxt.toLowerCase(), inputTxt.toLowerCase()) <=
      maxDiff &&
    levenshtein.get(storedTxt.toLowerCase(), inputTxt.toLowerCase()) >= minDiff
  );
};
const stopPropagationAndCallback = (callback, e) => {
  e.stopPropagation();

  callback();
};

const sumNumbers = (list, attr) => {
  const numbers = list.map((listItem) => Number(listItem[attr]));

  return numbers.reduce((a, b) => a + b, 0);
};

const inBetween = (compareValue, startValue, endValue) =>
  startValue <= compareValue && endValue >= compareValue;

const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const handleNullDisplay = (compareVal, valueAdditionalTxt, secondText) => {
  try {
    return compareVal ? `${valueAdditionalTxt}${compareVal}` : `${secondText}`;
  } catch (error) {
    console.error(
      `${new Date()} Error in handleNullDisplay func inside helpingFunctions.js file ${
        error.message
      }`
    );
  }
};

const checkCurrentTime = (arrivalDate) => {
  const startTime = moment(new Date(arrivalDate));
  const endTime = moment(new Date());

  const hourInterval = startTime.diff(endTime, "hours");

  return hourInterval <= 0;
};

export {
  cloneObject,
  assignFirstDate,
  restrictionPopUp,
  validateForm,
  filterTimes,
  constructStringWithComma,
  checkForMatch,
  stopPropagationAndCallback,
  sumNumbers,
  inBetween,
  delay,
  handleNullDisplay,
  checkCurrentTime,
};

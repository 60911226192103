import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";
import { SelectionContext } from "../../../context/SelectionContext";
import Activity_Spa_PreviewLogic from "../Activity_Spa_PreviewLogic";
import { filterTimes } from "../../../helpingFunctions/helpingFunctions";

const SpaPreviewMultGuestsLogic = (id, view, setView, goTo) => {
  const [isEditMode, setIsEditMode] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [spaSelection, setSpaSelection] = useState({});
  const [unfinishedSelection, setUnfinishedSelection] = useState(null);
  const [showSpaExtraSection, setShowSpaExtraSection] = useState(true);
  const [actionAlertObj, setActionAlertObj] = useState(null);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [isOnEditModePerSelectedDate, setIsOnEditModePerSelectedDate] =
    useState(false);
  const [showBookConfirmation, setShowBookConfirmation] = useState("hide");
  const [showBookAnotherTreatmentDialog, setShowBookAnotherTreatmentDialog] =
    useState(false);

  const { bookingSelections, editMode, bookingStatus } =
    useContext(SelectionContext);
  const { reservation, selectedDate } = useContext(DataContext);

  const {
    availableTimes,
    setSpaFormData,
    spaAvailability,
    object,
    availableDates,
    loading,
    spaLoading,
    alertStatus,
    alertMessage,
    priceTxt,
    setActivityTimeLimit,
    manageBooking,
    goBack,
    showConflict,
    closeAlert,
    spaFormData,
    manageSpaFormData,
    isReservationIn3Months,
  } = Activity_Spa_PreviewLogic(id, view, setView, goTo);

  const handleShowSpaExtraSection = () => {
    setShowSpaExtraSection(true);
  };

  const createSpaSelectionObj = () => {
    let timeTest = spaAvailability.find(
      (row) => row.Date === selectedDate
    )?.Times;

    if (selectedDate === reservation.ArrivalDate.split("T")[0]) {
      timeTest = filterTimes(timeTest);
    }

    if (timeTest) {
      const objTest = { ...spaSelection };

      for (let row of timeTest) {
        if (
          unfinishedSelection &&
          unfinishedSelection.time.substring(0, 5) === row.Time.substring(0, 5)
        ) {
          continue;
        }

        row.selected = false;
      }
      objTest[selectedDate] = timeTest;
      setSpaSelection(objTest);
    }
  };

  const closeActionAlert = () => {
    setActionAlertObj(null);
  };

  const prompSpaConfirmation = (answer) => {
    setShowBookConfirmation((prevState) => (prevState = answer));
  };

  const checkUnconfirmChanges = () => {
    const unconfirmedChanges = bookingSelections.filter(
      (row) => row.action !== "none"
    );

    if (unconfirmedChanges.length > 0) {
      setShowBookAnotherTreatmentDialog(true);
    } else {
      goBack();
    }
  };

  useEffect(() => {
    if (
      selectedDate &&
      spaLoading.hasOwnProperty(selectedDate) &&
      !spaLoading[selectedDate].isLoading
    ) {
      createSpaSelectionObj();
    }
  }, [selectedDate, spaLoading]);

  useEffect(() => {
    let newSpaSelection = {};
    for (const row of spaAvailability) {
      let times = row.Times;
      if (row.Date === reservation.ArrivalDate.split("T")[0]) {
        times = filterTimes(times);
      }
      newSpaSelection = { ...spaSelection, ...{[row.Date]: times } };
    }
    setSpaSelection(newSpaSelection);
  }, [spaAvailability]);

  const hasBeenSaved = (selectedDate) => {
    const isSavedActions = ["remove", "edit"];
    const bookedSelection = bookingSelections.find(
      ({ day, action }) =>
        new Date(day).getTime() === new Date(selectedDate).getTime() &&
        isSavedActions.includes(action)
    ) || false

    setIsOnEditModePerSelectedDate(bookedSelection);

  };

  useEffect(() => {
    if (selectedDate) {
      hasBeenSaved(selectedDate);
      const hasSelectionChanged = bookingSelections.find(
        (row) => row.action !== "none" && row.day === selectedDate
      );
      const hasChangedCofirmed =
        bookingSelections.length > 0 && hasSelectionChanged;
      setHasChanges(hasChangedCofirmed);

      const filterBookings = bookingSelections.filter(
        (row) => row.day === selectedDate
      );

      setFilteredBookings(filterBookings.filter((row, index, self) => {
        const _row = JSON.stringify(row);
        return row.type === view && index === self.findIndex(t => JSON.stringify(t) === _row);
      }));
      setTermsChecked(false);
    }
  }, [selectedDate, bookingSelections]);

  useEffect(() => {
    setSpaFormData({ lastName: "", firstName: "" });
    setIsEditMode(null);
    setShowSpaExtraSection(false);
  }, [selectedDate]);

  return {

    spaSelection,
    unfinishedSelection,
    showSpaExtraSection,
    filteredBookings,
    reservation,
    termsChecked,
    bookingStatus,
    editMode,
    spaAvailability,
    object,
    availableDates,
    loading,
    spaLoading,
    alertStatus,
    alertMessage,
    priceTxt,
    isOnEditModePerSelectedDate,
    spaFormData,
    availableTimes,
    selectedDate,
    showBookAnotherTreatmentDialog,
    actionAlertObj,
    hasChanges,
    showBookConfirmation,
    isEditMode,
    setTermsChecked,
    setShowSpaExtraSection,
    setActionAlertObj,
    setShowBookConfirmation,
    setIsEditMode,
    handleShowSpaExtraSection,
    prompSpaConfirmation,
    closeActionAlert,
    setSpaSelection,
    setUnfinishedSelection,
    checkUnconfirmChanges,
    setShowBookAnotherTreatmentDialog,
    setSpaFormData,
    setActivityTimeLimit,
    manageBooking,
    goBack,
    showConflict,
    closeAlert,
    manageSpaFormData,
    isReservationIn3Months,
  };
};

export default SpaPreviewMultGuestsLogic;

import React, { useState, createContext } from "react";
import { isInItinerary } from "../helpingFunctions/bookingHelp";

export const SavedPreSelectionsContext = createContext();

export const SavedPreSelectionsProvider = ({ children }) => {
  const [activityPreSelectionsSaved, setActivityPreSelectionsSaved] = useState(
    []
  );
  const [dinningPreSelectionsSaved, setDinningPreSelectionsSaved] = useState(
    []
  );

  const manage_Activity_Spa_Selections = (
    bookingSelections,
    date,
    times,
    itinerary,
    type, id
  ) => {
    if (type === "Spa") {
      const preSelections = itinerary.itineraryDates.find(
        ({ Data }) => Data === date
      );

      preSelections.Spa.forEach((preSelection) => {
        if (!preSelection || !preSelection.Quantity || preSelection.Id !== id) return;
        const { Time } = preSelection;

        const selectedTime = Time;

        const timeIndex = times.Times.findIndex(
          ({ Time }) => Time === selectedTime
        );

        if (timeIndex >= 0) {
          if (!times.Times[timeIndex].manipulated) {
            times.Times[timeIndex].Availability =
              times.Times[timeIndex].ProviderCode.length;
            times.Times[timeIndex].manipulated = true;
          }
        } else {
          times.Times.push({
            Time: selectedTime,
            Availability: 0,
            ProviderCode: [],
            manipulated: true,
          });
        }
      });
    } else {
      const preSelection = bookingSelections.find(({ day, facilityGUID }) => day === date && facilityGUID.includes(id));
      const isBookedHour = isInItinerary(preSelection, itinerary, date, type);

      if (!preSelection || !preSelection.Quantity || !isBookedHour) return;
      const { time, timeBooked, Quantity, ProviderCode } = preSelection;

      const selectedTime = time ? time : timeBooked;

      const timeIndex = times.findIndex(({ Time }) => Time === selectedTime);
      const selectedQuantity = parseInt(Quantity);

      if (timeIndex >= 0) {
        const existingTime = times[timeIndex];
        const availability = parseInt(existingTime.Availability);
        const totalAvailability = selectedQuantity + availability;

        if (!times[timeIndex].manipulated) {
          times[timeIndex].Availability = `${totalAvailability}`;
          times[timeIndex].manipulated = true;
        }
      } else {
        times.push({
          Time: selectedTime,
          Availability: selectedQuantity,
          ProviderCode: ProviderCode ? ProviderCode : [],
          manipulated: true,
        });
      }
    }
  };

  return (
    <SavedPreSelectionsContext.Provider
      value={{
        activityPreSelectionsSaved,
        dinningPreSelectionsSaved,
        setActivityPreSelectionsSaved,
        setDinningPreSelectionsSaved,
        manage_Activity_Spa_Selections,
      }}
    >
      {children}
    </SavedPreSelectionsContext.Provider>
  );
};

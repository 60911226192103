import React, { useContext, useState } from "react";
import { SelectionContext } from "../../context/SelectionContext";

const SpaSharedLogic = () => {
  const {
    bookingSelections,
  } = useContext(SelectionContext);

  const handleSpaAvailabilityOnAction = (bookingAction, initialIndex, spaSelection, setSpaSelection, oldTime, newTime) => {
    const unselection = bookingSelections[initialIndex];
    const spaOptions = { ...spaSelection };
    const index = spaOptions[unselection.day].findIndex(
      (row) => row.Time === unselection.time
    );

    if (bookingAction === "add") {
      spaOptions[unselection.day][index].ProviderCode.push(
        unselection.providerCode
      );
      spaOptions[unselection.day][index].Availability = spaOptions[unselection.day][index].ProviderCode.length;
      setSpaSelection(spaOptions);
      return;
    } else if (bookingAction === "remove") {
      const provider = spaOptions[unselection.day][index].ProviderCode.pop();
      spaOptions[unselection.day][index].Availability = spaOptions[unselection.day][index].ProviderCode.length;
      setSpaSelection(spaOptions);
      return provider;
    } else if (bookingAction === "edit") {
      const oldTimeAvail = spaOptions[unselection.day].findIndex(
        (row) => row.Time === oldTime
      );
      const newTimeAvail = spaOptions[unselection.day].findIndex(
        (row) => row.Time === newTime
      );
      spaOptions[unselection.day][oldTimeAvail].ProviderCode.push(
        unselection.providerCode
      );
      spaOptions[unselection.day][oldTimeAvail].Availability = spaOptions[unselection.day][oldTimeAvail].ProviderCode.length;

      const provider = spaOptions[unselection.day][newTimeAvail].ProviderCode.pop();
      spaOptions[unselection.day][newTimeAvail].Availability = spaOptions[unselection.day][newTimeAvail].ProviderCode.length;
      setSpaSelection(spaOptions);

      return provider;
    }
  };

  return {
    handleSpaAvailabilityOnAction,
  };
};

export default SpaSharedLogic;

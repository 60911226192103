import React, { useContext, useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";

import { DataContext } from "../../context/DataContext";
import MainTitle from "../MainTitle/MainTitle";
import { DiningTypes } from "../../constants/constants";

import styles from "./header.module.css";
import { ROUTES } from "../../routes/routeConstants";

const Header = ({ view, setView }) => {
  const { reservation } = useContext(DataContext);
  const location = useLocation();

  const backArrowHandler = (event) => {
    event.preventDefault();
    setView({ type: "" });
  };

  useEffect(() => {
    window.addEventListener("popstate", backArrowHandler, false);
    return () =>
      window.removeEventListener("popstate", backArrowHandler, false);
  }, [view]);

  if (
    !view ||
    !location.pathname.toLowerCase().includes(ROUTES.Home.toLowerCase()) ||
    (DiningTypes.includes(view) && window.outerHeight > 1000) ||
    ["Golf", "Dining", "WhatsOnGuide", "HotelMapImage"].includes(view)
  )
    return null;

  return (
    <div className={styles.headLandingPage}>
      <MainTitle
        height={"60px"}
        logoHeight={"48px"}
        logoWidth={!view ? "400px" : "205px"}
        cursor={"pointer"}
        setView={setView}
        // hotelTitle={reservation.Hotel.Title}
      />
      {reservation?.Hotel.Image?.large?.url ? (
        <div
          className={styles.hotelImage}
          style={{
            backgroundImage: `url(${reservation?.Hotel.Image?.large?.url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      ) : null}
    </div>
  );
};

export default withRouter(Header);

import React from "react";

import ImageCarouselLogic from "./ImageCarouselLogic";
import { leftArrow, rightArrow } from "../../assets/SVGIcons";
import IndicatiorCircle from "../IndicatorNumber/IndicatiorCircle";
import LoginInfoBox from "../../views/Login/components/LoginInfoBox/LoginInfoBox";

import styles from "./image-carousel.module.css";
import WindowWidth from "../../hooks/WindowWidth";

const ImageCarousel = ({
  onClick,
  sliderImages = [],
  autoSlide = false,
  view,
  containImg,
  customStyle,
  imageIcons,
  imageCustomStyle,
  fillImg,
  newSliderStyles,
  newImageStyles,
  new_styles,
  format = "small",
}) => {
  const { width } = WindowWidth();
  const {
    handleSliderLeft,
    handleSliderRight,
    handleSliderSkip,
    imageDisplayed,
    loginInfoClickHandler,
    leftPosition,
    isLoginInfoOpened,
  } = ImageCarouselLogic(sliderImages.length - 1, autoSlide);

  const getSideArrowPosition = () => {
    if (view === "Entertainment" && customStyle) {
      const pic = sliderImages[Math.abs(leftPosition) / 100];
      if (pic?.formats?.small?.width > pic?.formats?.small?.height) {
        return `${Number(pic.formats.small.height) / 2}px`;
      }
    }
    return `calc(50% - 15px)`;
  };

  let ImageIcons = null;
  if (imageIcons) {
    ImageIcons = (
      <div className={styles.slider__image_icons}>
        {imageIcons.map(({ icon }, index) =>
          icon ? (
            <IndicatiorCircle
              key={index}
              txt={
                <img
                  width={"66%"}
                  height={"57%"}
                  src={icon?.formats?.thumbnail?.url}
                  alt={""}
                />
              }
              backgroundColor={"transparent"}
              color={"#5f5f5f"}
              border={"1px solid #5f5f5f"}
              width={"50px"}
              height={"50px"}
              fontSize={"1.2rem"}
            />
          ) : null
        )}
      </div>
    );
  }

  let CarouselNavigation = {};
  if (!autoSlide) {
    CarouselNavigation.leftArrow = (
      <div
        className={styles.leftArrow}
        onClick={handleSliderLeft}
        style={{
          opacity: leftPosition === 0 ? "0.4" : "1",
          top: getSideArrowPosition(),
        }}
      >
        {leftArrow}
      </div>
    );
    CarouselNavigation.rightArrow = (
      <div
        className={styles.rightArrow}
        style={{
          opacity:
            leftPosition === -100 * (sliderImages.length - 1) ? "0.4" : "1",

          top: getSideArrowPosition(),
        }}
        onClick={handleSliderRight}
      >
        {rightArrow}
      </div>
    );
    CarouselNavigation.dots = (
      <div className={styles.dotsWrapper_manual}>
        {sliderImages.map((image, index) => (
          <span
            key={Math.random()}
            className={styles.dotsWrapper_manual_nav}
            style={{
              backgroundColor: leftPosition === -100 * index ? "white" : null,
            }}
            onClick={() => handleSliderSkip(-100 * index)}
          ></span>
        ))}
      </div>
    );
  }

  if (autoSlide && view === "Login") {
    CarouselNavigation.dots = (
      <div className={styles.dotsWrapper_auto}>
        {sliderImages.map((_, index) => (
          <div
            className={styles.dotsWrapper_auto_nav}
            onClick={() => handleSliderSkip(-100 * index)}
          >
            <div
              key={Math.random()}
              style={{
                backgroundColor:
                  leftPosition === -100 * index ? "#3C3C3C" : "white",
                height: "7px",
                width: "100%",
              }}
            ></div>
            <p
              className={styles.dotsWrapper_auto_nav_index}
              style={{
                color: leftPosition === -100 * index ? "#3C3C3C" : "white",
              }}
            >
              0{index + 1}
            </p>
          </div>
        ))}
      </div>
    );
  }

  // let LoginInfo = null;
  // if (view === "Login" && width > 1300) {
  //   const imageDisplayedIndex = imageDisplayed();

  //   console.log({
  //     sliderImages,
  //     additionalContent,
  //   });
  //   LoginInfo = (
  //     <LoginInfoBox
  //       loginInfoClickHandler={loginInfoClickHandler}
  //       isLoginInfoOpened={isLoginInfoOpened}
  //       leftPosition={leftPosition}
  //       title={additionalContent[`SliderImageTitle${imageDisplayedIndex}`]}
  //       dsc={additionalContent[`SliderImageDescription${imageDisplayedIndex}`]}
  //     />
  //   );
  // }

  let Images = [];

  if (view === "Login") {
    Images = sliderImages.map(({ image, title, dsc }) => (
      <div
        key={Math.random()}
        className={styles.slideImage}
        style={
          image?.formats?.small?.width > image?.formats?.small?.height
            ? customStyle
            : null
        }
      >
        {ImageIcons}
        {image ? (
          <img
            src={`${image.formats[format]?.url}`}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: containImg ? "contain" : "cover",
              position: "absolute"
            }}
          />
        ) : null}
        {width > 1300 ? (
          <LoginInfoBox
            loginInfoClickHandler={loginInfoClickHandler}
            isLoginInfoOpened={isLoginInfoOpened}
            title={title}
            dsc={dsc}
          />
        ) : null}
      </div>
    ));
  } else {
    Images = sliderImages.map(({ Id, formats }) => (
      <div
        key={Math.random()}
        className={styles.slideImage}
        style={
          formats?.small?.width > formats?.small?.height ? customStyle : imageCustomStyle
        }
      >
        {ImageIcons}
        <img
          src={`${formats?.small?.url}`}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: fillImg ? "fill" : containImg ? "contain" : "cover",
            ...newImageStyles
          }}
        />
      </div>
    ));
  }

  return (
    <div
      className={styles.slider}
      style={{
        ...new_styles,
      }}
    >
      {CarouselNavigation.leftArrow}
      <div
        className={styles.imageWrapper}
        style={{ left: `${leftPosition}%` }}
        onClick={onClick}
      >
        {Images}
      </div>
      {CarouselNavigation.rightArrow}
      {CarouselNavigation.dots}
    </div>
  );
};

export default ImageCarousel;

import React from "react";

import styles from "./quantity_button.module.css";

const QuantityButton = ({
  content,
  width,
  backgroundColor,
  color,
  borderTopLeftRadius,
  borderBottomLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  handleClick,
  fontSize,
  cursor = "default",
}) => {
  return (
    <div
      className={styles.QuantityButton}
      style={{
        width,
        backgroundColor,
        color,
        borderTopLeftRadius,
        borderBottomLeftRadius,
        borderTopRightRadius,
        borderBottomRightRadius,
        fontSize,
        cursor,
      }}
      onClick={handleClick}
    >
      {content}
    </div>
  );
};

export default QuantityButton;

import React from "react";

import styles from "./DropDownList.module.css";
import DropDownListLogic from "./DropDownListLogic";

const DropDownList = ({
  isClicked,
  children,
  new_styles,
  new_class,
}) => {
  return (
    <ul
      className={`${styles.DropDownBox__list} ${styles[new_class]}`}
      style={{ display: isClicked ? "block" : "none", ...new_styles }}
    >
      {children}
    </ul>
  );
};

export default DropDownList;

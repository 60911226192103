import React from "react";

const compare = (a, b) => {
  const aValue = a.props.time ? a.props.time : a.props.children;
  const bValue = b.props.time ? b.props.time : b.props.children;
  const atime = Date.parse("9/19/2014 " + aValue);
  const btime = Date.parse("9/19/2014 " + bValue);

  if (atime < btime) {
    return -1;
  }

  if (atime > btime) {
    return 1;
  }

  return 0;
};

const SortJSX = ({ children, by, fromDinning }) => {
  const times = fromDinning
    ? children.props.children
        .map(({ props }) => {
          return props.children;
        })
        .flat()
    : children;

  if (!by) {
    return children;
  }

  return React.Children.toArray(times).sort(compare);
};

export default SortJSX;

export { compare };

import { ActivityTypes } from "../constants/constants";
import { DiningTypes } from "../constants/constants";

const getSpecifiedDate = (data, index) => data[index]?.Date;

const getName = (
  data,
  recordId,
  type,
  facilityBooked,
  action
) => {
  try {
    if (DiningTypes.includes(type)) {
      if (action === "remove") {
        const { Title } = data.find(({ id, objType }) => id === facilityBooked);
        return Title;
      }
    } 

    const { Title } = data.find(({ id, objType }) => id === recordId && objType === type);
    return Title;
  } catch (e) {
    console.error("Error at getName: ", e);
  }
};

const getRestaurantIdByName = (data, title) => {
  try {
    const { id } = data.find(({ Title }) => Title === title);

    return id;
  } catch (e) {
    console.error("Error at getRestaurantIdByName: ", e);
  }
};

const getRestaurantNameByTime = (facilitiesData, data, time, day) => {
  try {
    const { Facilities } = data.find(({ Date }) => Date === day);
    const { FacilityGUID } = Facilities.find(({ Times }) =>
      Times.some(({ Time }) => Time === time)
    );

    const name = getName(facilitiesData, FacilityGUID);

    return name;
  } catch (e) {
    console.error("error at getRestaurantNameByTime: ", e);
  }
};

const selectionIsAvalibale = (data, selection) => {
  try {
    const { Facilities } = data.find(({ Date }) => Date === selection.day);
    const isAvaliable = Facilities.some(
      (d) =>
        d.FacilityGUID === selection.facilityGUID &&
        d.Times.some(({ Time }) => Time === selection.time)
    );

    return isAvaliable;
  } catch (e) {
    console.error("error at selectionIsAvalibale: ", e);
  }
};

const getDayIndex = (data, day) => {
  return data.findIndex((date) => date === day);
};

const findNumberOfStayingDays = (data) => data.length;

const timeSelected = (bookingSelections, date, width, skipedDays = []) => {
  try {
    const isInLargeRange = width > 1324;
    const nonSelectTxt = isInLargeRange ? "not selected" : "__:__";
    const skipedTxt = skipedDays.includes(date) ? "not required" : null;

    const defaultTxt = skipedTxt || nonSelectTxt;
    if (bookingSelections.length === 0) return defaultTxt;

    const selection = bookingSelections.find(({ day }) => day === date);

    if (selection && selection.time) {
      const { time } = selection;
      return time?.substr(0, 5);
    }

    return defaultTxt;
  } catch (e) {
    console.log(
      `${new Date()} Error at timeSelected function in requests.js file ${
        e.message
      }`
    );
  }
};

const treatsSelected = (treatsBookings, date) => {
  const dayTreats = treatsBookings.filter((row) => row.Date === date);
  const bookedAddOns = dayTreats.filter((row) => row.action === "none");
  if (bookedAddOns.length > 0) {
    return "booked";
  } else if (dayTreats.length > 0) {
    return "selected";
  } else {
    return `not selected`;
  }
};

const multipleSelected = (
  date,
  width,
  selectedDate,
  bookingSelections,
  skipedDays,
  view,
  availibility,
  id,
  arrivalDate,
  facilitiesData
) => {

  let hasAvailability = false;
  if (ActivityTypes.includes(view)) {
    let dayAvail = [];
    const isArrivalDate = arrivalDate.substring(0, 10) === date;

    dayAvail = availibility?.find((row) => row.Date === date);
    const availEntity = dayAvail?.Facilities?.find(
      (row) => row.FacilityGUID === id
    );
      
    if (availEntity) {
      let filteredTimes = availEntity?.Times;
      if (isArrivalDate) {
        filteredTimes = availEntity?.Times.filter(
          (row) => row.Time.substring(0, 4) > "12:00"
        );
      }
      hasAvailability = filteredTimes.length > 0;
    }
  }

  if (skipedDays.includes(date))
    return width <= 700 ? "not req." : "not required";
  const booked = id
    ? bookingSelections.filter(
        (row) =>
          row.day === date &&
          (row.action === "none" || row.action === "edit") &&
          row.facilityGUID.includes(id)
      )
    : bookingSelections.filter(
        (row) =>
          row.day === date && (row.action === "none" || row.action === "edit")
      );

  if (booked.length > 0) {
    return width <= 700 && date !== selectedDate
      ? ""
      : `${booked.length} booked`;
  } else {
    const bookSelections = bookingSelections.filter(
      (row) => row.day === date && row.action === "add"
    );

    const isSelected =
      [...ActivityTypes].includes(
        bookSelections[0]?.type
      ) && !bookSelections[0].hasOwnProperty("Quantity");

    return width <= 700 && date !== selectedDate
      ? ""
      : isSelected || bookSelections.length === 0
        ? `not selected`
        : `${bookSelections.length} selected`;
  }
};

const singleSelected = (
  date,
  width,
  selectedDate,
  bookingSelections,
  skipedDays
) => {
  if (skipedDays.includes(date))
    return width <= 700 ? "not req." : "not required";

  const booked = bookingSelections.filter(
    (row) =>
      row.day === date && (row.action === "none" || row.action === "edit")
  );
  if (booked.length > 0) {
    return width <= 700 && date !== selectedDate
      ? ""
      : booked.length === 0
      ? `no bookings`
      : `booked`;
  } else {
    const bookSelections = bookingSelections.filter(
      (row) => row.day === date && row.action === "add"
    );
    return width <= 700 && date !== selectedDate
      ? ""
      : bookSelections.length === 0 ||
        !bookSelections[0].hasOwnProperty("restaurant") ||
        !bookSelections[0].hasOwnProperty("time")
      ? `not selected`
      : `selected`;
  }
};

const getActName = (entertainmentData, facilityId) => {
  const facility = entertainmentData.find(
    (row) => row.Facility.id === facilityId
  );

  if (facility) {
    return facility.Facility.title;
  }

  return "";
};

const getVenueName = (entertainmentData, facilityDetails) => {
  const facility = entertainmentData.find(
    (row) =>
      row.Facility.id === facilityDetails.Id &&
      row.Date === facilityDetails.date &&
      row.Time.substring(0, 5) === facilityDetails.Time.substring(0, 5)
  );

  if (facility) {
    return facility.EntertainmentPublished;
  }

  return "Entertainment";
};

const getEntCombinedName = (entertainmentData, facilityId, day, time) => {
  const facility = entertainmentData.find(
    (row) =>
      row.Facility.id === facilityId &&
      row.Date === day &&
      row.Time.startsWith(time)
  );

  if (facility) {
    return `${facility.Facility.title} - ${facility.EntertainmentPublished}`;
  }

  return "";
};

export {
  getSpecifiedDate,
  getName,
  getRestaurantIdByName,
  selectionIsAvalibale,
  getRestaurantNameByTime,
  findNumberOfStayingDays,
  getDayIndex,
  timeSelected,
  multipleSelected,
  getActName,
  getVenueName,
  getEntCombinedName,
  singleSelected,
  treatsSelected,
};

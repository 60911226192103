import React from "react";
import BlurryWrapperLogic from "./BlurryWrapperLogic";

import styles from "./BlurryWrapper.module.css";

const BlurryWrapper = ({
  children,
  new_class,
  new_styles,
  imgUrl,
  clickFunction = () => {},
  whiteShadowStyle,
  isLoginForm = false
}) => {
  const { reference } = BlurryWrapperLogic(isLoginForm)

  return (
    <div
      className={styles.BlurryWrapper}
      style={{ ...new_styles }}
      onClick={clickFunction}
      ref={reference}
    >
      <span style={{
        width: "100%",
        height: "100%",
        ...whiteShadowStyle
      }}>
      {children}
      </span>
      <div
        style={{
          background: `url(${imgUrl}) center / cover transparent fixed`,
        }}
        className={`${styles.BlurryWrapper__helper} ${styles[new_class]}`}
      />
    </div>
  );
};

export default BlurryWrapper;

import { useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { DiningTypes } from "../constants/constants";

import { DataContext } from "../context/DataContext";
import axiosInstance from "../url/createAxios";

const RequestHook = () => {
  const instance = axiosInstance();
  const {
    setItineraryData,
    setAvailibility,
    setGroupData,
    availibility,
    setPromotionalSpaceContent,
    setPagesData,
    setAddOnsBookings,
  } = useContext(DataContext);

  const getInternalActivityItinerary = async (query) => {
    try {

      const { data } = await instance.get(`/activity-bookings`, { params: query });

      return data.data;
    } catch (error) {
      console.error(
        `${new Date()} Error in getInternalActivityItinerary func in RequestHook.js file ${
          error.message
        }`
      );
    }
  };
  

  const getItinerary = async () => {
    try {
      const strapiItinerary = await getInternalActivityItinerary({
        IsInternalActivity: true,
      });

      const { data: maestroItinerary } = await instance.get(`/Hotel/Booking/Itinerary`);

      setAddOnsBookings(maestroItinerary.AddOns);

      const { ArrivalDate, DepartureDate, Hotel, ReferenceNumber, Dates } =
        maestroItinerary;

      const itineraryDates = Object.keys(Dates).map((key) => {
        return { Data: key, ...Dates[key] };
      });

      const itineraryDatesLength = itineraryDates.length;
      for (let i = 0; i < itineraryDatesLength; i++) {
        const itinerary = itineraryDates[i];
        const currentDateInternalActivities = strapiItinerary ? strapiItinerary.filter(
          (strapiItinerary) =>
            new Date(strapiItinerary.Date).getTime() ===
            new Date(itinerary.Data).getTime()
        ) : [];

        itinerary.Facilities = [
          ...itinerary.Facilities,
          ...itinerary.Spa,
          ...itinerary.Entertainment,
          ...itinerary.Treats,
          ...currentDateInternalActivities,
        ];
      }
      setItineraryData({
        ArrivalDate,
        DepartureDate,
        Hotel,
        ReferenceNumber,
        itineraryDates,
      });

      return itineraryDates;
    } catch (error) {
      console.error(
        `${new Date()} Error in getItinerary in RequestHook.js file ${
          error.message
        }`
      );
    }
  };

  const getAvailability = async (quantity, facility_types) => {
    try {
      if (facility_types?.includes("Treats")) return;

      const { data } = await instance.get(
        `/Hotel/Booking/Facility/Availability?quantity=${quantity}`,
        { params: { facility_types } }
      );

      const validTimes = handlePrevTimes(data);

      const sortedData = validTimes.sort(
        (a, b) => new Date(a.Date) - new Date(b.Date)
      );

      if (facility_types) {
        handleAvailabilityResponse(sortedData, availibility);
      } else {
        setAvailibility(sortedData);
      }
    } catch (e) {
      console.log(
        `${new Date()} Error in getAvailability in RequestHook.js file ${
          e.message
        }`
      );
    }
  };

  const handleAvailabilityRequest = async (reservation, totalGuestsNum) => {
    if (!reservation.Breakfast && !reservation.Dinner) {
      await getAvailability(totalGuestsNum, ["Breakfast", "Dinner"]);
    } else if (!reservation.Breakfast) {
      await getAvailability(totalGuestsNum, ["Breakfast"]);
    } else if (!reservation.Dinner) {
      await getAvailability(totalGuestsNum, ["Dinner"]);
    }
  };

  const handlePrevTimes = (data) => {
    // const currentTime = moment(new Date()).format("2022-02-27");
    const currentTime = moment(new Date()).format("YYYY-MM-DD");

    const validDates = data.map((availability) => {
      const availabilityDateObj = new Date(availability?.Date);
      const currentDateObj = new Date(currentTime);
      if (currentDateObj.getTime() < availabilityDateObj.getTime())
        return availability;

      if (currentDateObj.getTime() === availabilityDateObj.getTime()) {
        const parsedAvailability = JSON.parse(JSON.stringify(availability));

        parsedAvailability.Facilities.map(({ Times, Type }, index) => {
          if (Type !== "Venue") {
            const times = Times.filter((time) => {
              const availableTimeObj = new Date(
                `${availability?.Date} ${time.Time.substring(0, 5)}`
              );
              if (availableTimeObj.getTime() > new Date().getTime())
                return true;
            });
            parsedAvailability.Facilities[index].Times = times;
          }
        });

        return parsedAvailability;
      }

      return {
        Date: availability?.Date,
        Facilities: availability?.Facilities.filter(
          (row) => row.Type === "Venue"
        ),
      };
    });

    return validDates;
  };

  const handleAvailabilityResponse = (data, availability) => {
    try {
      const availabilityLength = availability?.length;
      for (let i = 0; i < availabilityLength; i++) {
        const availabilityItem = availability[i];

        const { Facilities, Date } = availabilityItem;
        const newAvailability = data.find((dataItem) => dataItem.Date === Date);

        const facilitiesLength = Facilities.length;
        for (let j = 0; j < facilitiesLength; j++) {
          const facility = Facilities[j];
          const newFacility = newAvailability.Facilities.find(
            ({ FacilityGUID }) => facility.FacilityGUID === FacilityGUID
          );

          if (newFacility) {
            facility.Times = newFacility.Times;
          }
        }
      }

      setAvailibility([...availibility]);
    } catch (e) {
      console.log(e.message);
    }
  };

  const getGroup = async () => {
    try {

      const { data } = await instance.get(`/group`);

      const itemsLength = data.items.length;
      for (let i = 0; i < itemsLength; i++) {
        const item = data.items[i];
        item.IsInGroup = true;
      }

      data.items.unshift(
        data.items.splice(
          data.items.findIndex((item) => item.IsGroupCreator),
          1
        )[0]
      );

      setGroupData({
        items: data.items,
        groupName: data.groupName,
        groupId: data.groupId,
      });
    } catch (e) {
      console.log(
        `${new Date()} Error in getGroup func at RequestHook.js file: ${
          e.message
        }`
      );
    }
  };

  const cancelRequest = async (cancelSelections) => {
    
    await instance.post(
      `/Hotel/Booking/Facility/Availability`,
      {
        request: cancelSelections,
      },
      { withCredentials: true }
    );
  };

  const constructPageDataObj = (data) => {
    const structuredPageData = DiningTypes.map((type) => {
      return {
        Type: type,
        DesktopTitle: data[`${type}DesktopTitle`],
        MobileTitle: data[`${type}MobileTitle`],
        MobileDescription: data[`${type}_MobileDescription`],
        DesktopDescription_max_length_is_340:
          data[`${type}_DesktopDescription_max_length_is_340`],
        NoBookingNeededInfo: data.NoBookingNeededInfo,
        CheckBoxInfo: data.CheckBoxInfo,
        GroupBoxInfo: data.GroupBoxInfo,
      };
    });

    return structuredPageData;
  };

  const getPagesData = async () => {
    const activity_spa = await instance.get("/pages-data");

    const dining = await instance.get("/dinning-content");

    const preapredList = constructPageDataObj(dining.data);

    preapredList.forEach((listRow) => {
      const index = activity_spa.data.findIndex(
        (row) => row.Type === listRow.Type
      );
      if (index !== -1)
        activity_spa.data[index] = { ...activity_spa.data[index], ...listRow };
    });

    setPagesData([...activity_spa.data, ...preapredList]);
  };

  const getPromotionalSpaceContent = async (Hotel) => {
    const { data } = await instance.get(`/promotional-space-dining-pages/byHotel/${Hotel.Id}`);

    setPromotionalSpaceContent(data);
  };

  return {
    getItinerary,
    getAvailability,
    getGroup,
    cancelRequest,
    getPagesData,
    getPromotionalSpaceContent,
    getInternalActivityItinerary,
    handleAvailabilityRequest,
  };
};

export default RequestHook;

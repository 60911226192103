import React, { useEffect, useState } from "react";
import Button from "../../../shared/Button/Button";
import DaysList from "../../../components/DaysList/DaysList";
import TreatsSelectionForm from "./TreatsSelectionForm";
import TreatsTable from "./TreatsTable";
import InformationalBox from "../../../shared/Containers/InformationalBox";
import { isAddOnBookingCancellable } from "../../../helpingFunctions/utilities";

import styles from "./SelectionPopupBox.module.css";

const SelectionPopupBox = ({
  setShowOpt,
  clickedObject,
  selectedDate,
  showType,
  setView,
  setSelectedDate,
  quantity,
  allDays,
  processSelections,
  totalPriceToRemove,
  totalPriceToAdd,
  handleAddOnSelection,
  addOnsBookings,
  selectionForm,
  setSelectionForm,
  handleAddOnDiscard,
  handleRowOptions,
  hasSelectedAddOnDining,
  reservation,
  checkAddOnDiningBiding,
}) => {
  const [selectedDayAddOns, setSelectedDayAddOns] = useState([]);
  const [hasUnconfirmedChanges, setHasUnconfirmedChanges] = useState(false);
  const [existsInBasket, setExistInBasket] = useState(false);
  const [showInformationalMessage, setShowInformationalMessage] =
    useState(false);

  useEffect(() => {
    const daySelections = [];
    addOnsBookings[clickedObject?.ServiceCode]?.forEach((row) => {
      if (row.Date === selectedDate) {
        daySelections.push(row);
      }

      if (row.action !== "none") {
        setHasUnconfirmedChanges(true);
      }
    });
    setSelectedDayAddOns(daySelections);
  }, [selectedDate, addOnsBookings]);

  useEffect(() => {
    setHasUnconfirmedChanges(false);
    addOnsBookings[clickedObject?.ServiceCode]?.forEach((row) => {
      if (row.action !== "none") {
        setHasUnconfirmedChanges(true);
        return;
      }
    });
  }, [addOnsBookings]);

  useEffect(() => {
    if (selectionForm.Location !== "") {
      const location = selectionForm.Location.includes("In-room")
        ? "Treats"
        : selectionForm.Location.includes("Lunch")
        ? "Lunch"
        : "Dinner";
      setExistInBasket(
        selectedDayAddOns.find((row) => row.Location === location)
      );
    }
  }, [selectionForm]);

  return (
    <>
      <div className={styles.popUpWrapper}>
        <div className={styles.grayOutScreen}></div>
        <div className={styles.alertBox}>
          <h3 style={{ textAlign: "center" }}>{clickedObject?.Title}</h3>

          <div
            style={{
              width: "85%",
              marginBottom: "30px",
              height: "650px",
              overflow: "auto",
            }}
          >
            <DaysList
              data={allDays}
              hideContent={true}
              view={showType}
              setView={setView}
              treatsBookings={addOnsBookings[clickedObject?.ServiceCode]}
            />
            <TreatsSelectionForm
              clickedObject={clickedObject}
              selectedDate={selectedDate}
              handleAddOnSelection={handleAddOnSelection}
              selectionForm={selectionForm}
              setSelectionForm={setSelectionForm}
              setShowOpt={setShowOpt}
              processSelections={processSelections}
              hasUnconfirmedChanges={hasUnconfirmedChanges}
              handleAddOnDiscard={handleAddOnDiscard}
              hasLocationInBasket={existsInBasket}
              reservation={reservation}
              checkAddOnDiningBiding={checkAddOnDiningBiding}
              totalPriceToRemove={totalPriceToRemove}
              totalPriceToAdd={totalPriceToAdd}
              showInformationalMessage={showInformationalMessage}
              setShowInformationalMessage={setShowInformationalMessage}
            />
            {showInformationalMessage &&
              selectionForm.Location.includes("Restaurant") && (
                <InformationalBox
                  showIcon={true}
                  jsxContent={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <p style={{ paddingBottom: 0, fontSize: "16px" }}>
                        You haven't booked{" "}
                        {selectionForm.Location.includes("Lunch")
                          ? "lunch"
                          : "dinner"}{" "}
                        yet. Please click{" "}
                        {
                          <span
                            onClick={() => {
                              setSelectedDate(selectedDate + "I");
                              setView({
                                type: selectionForm.Location.includes("Lunch")
                                  ? "Lunch"
                                  : "Dinner",
                              });
                            }}
                            style={{
                              textDecoration: "underline",
                              padding: "0 5px",
                            }}
                          >
                            {"here"}
                          </span>
                        }{" "}
                        to make the booking.
                      </p>
                    </div>
                  }
                  boxNewStyles={{
                    justifyContent: "center",
                    marginBottom: "15px",
                  }}
                />
              )}
            {selectionForm.Location.includes("In-room") && (
              <>
                {isAddOnBookingCancellable(selectedDate, "In-room") ? (
                  <InformationalBox
                    showIcon={true}
                    content={
                      "Changes to this order are not allowed 48hrs prior to arrival, please contact hotel reception if changes are required."
                    }
                    boxNewStyles={{
                      justifyContent: "center",
                      marginBottom: "15px",
                    }}
                    contentNewStyles={{ fontSize: "16px" }}
                  />
                ) : (
                  <InformationalBox
                    showIcon={true}
                    content={
                      "The selected add on will be placed in your room on your arrival day, time may vary."
                    }
                    boxNewStyles={{
                      justifyContent: "center",
                      marginBottom: "15px",
                    }}
                    contentNewStyles={{ fontSize: "16px" }}
                  />
                )}
              </>
            )}
            <TreatsTable
              selectedDate={selectedDate}
              filteredBookings={selectedDayAddOns}
              handleRowOptions={handleRowOptions}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectionPopupBox;

import React from "react";
import Markdown from "markdown-to-jsx";

import InputCard from "../../components/InputCard/InputCard";
import MainTitle from "../../components/MainTitle/MainTitle";
import Button from "../../shared/Button/Button";
import IndicatiorCircle from "../../shared/IndicatorNumber/IndicatiorCircle";
import LoginLogic from "./LoginLogic";
import Information from "../../components/InformationAlert/Information";
import Heading from "../../shared/Heading/Heading";
import LoadingContainer from "../../shared/LoadingContainer/LoadingContainer";
import BlurryWrapper from "../../components/UI/BulurryWrapper/BlurryWrapper";
import WindowWidth from "../../hooks/WindowWidth";
import PromotionalBanner from "../PromotionalPage/PromotionalBanner";
import AlertBox from "../../components/AlertBox/AlertBox";

import styles from "./login.module.css";
import { HELP_LINK, HOTEL_SELECTION_PACEHOLDER } from "../../constants/constants";
import { MWSTextLogo } from "../../assets/SVGIcons";

const LoginOld = () => {
  const {
    isWaitingToLogIn,
    formData,
    loginPageData,
    isError,
    loading,
    selectedHotel,
    bannerTexts,
    hasSessionExpired,
    manageFormData,
    handleRequest,
    setHasSessionExpired,
    setSelectedHotel,
    wrongCRSAlert,
    setWrongCRSAlert,
  } = LoginLogic();

  const { width } = WindowWidth();

  return (
    <>
      {loading ? (
        <div style={{ minHeight: "100vh" }}>Loading...</div>
      ) : (
        <section
          className={styles.loginWrapper}
          style={{
            background: `url(${loginPageData?.BackgroundImage?.formats?.xxlarge?.url || loginPageData?.BackgroundImage?.url}) center / cover transparent fixed`,
          }}
        >
          <MainTitle height={"130px"} logoHeight={"100%"} warnerLogo={true} />
          <BlurryWrapper
            imgUrl={loginPageData?.BackgroundImage?.formats?.medium?.url}
            new_styles={{
              width: "fit-content",
              height: "fit-content",
              margin: "auto",
              marginTop: width === 1366 ? "0px" : "140px",
              marginBottom: "10px",
            }}
            new_class={"BlurryWrapper__helper_login"}
          >
            <form
              onSubmit={(e) => handleRequest(e, formData)}
              className={styles.formWrapper}
              style={{}}
            >
              <div className={styles.loginFormLogo}>
                {MWSTextLogo}
              </div>
              <div className={styles.headline}>
                <div className={styles.loginTitleWrapper}>
                  <Heading 
                    txt={loginPageData.Title} 
                    fontSize="24px"
                    fontWeight="bold"
                    new_styles={{
                      color: "var(--color-primary)"
                    }}
                  />
                </div>
                {width > 1023 && loginPageData?.DesktopDescription ? (
                  <div className={styles.desktopDescription}>
                    <Markdown>{loginPageData?.DesktopDescription}</Markdown>
                  </div>
                ) : loginPageData?.MobileDescription ? (
                  <div className={styles.mobileDescription}>
                    <Markdown>{loginPageData?.MobileDescription}</Markdown>
                  </div>
                ) : null}
              </div>
              <div className={styles.inputSection}>
                <div className={styles.selectBoxWrapper}>
                  <InputCard
                    label={"Choose your hotel"}
                    selectBox={true}
                    number={1}
                    name={"hotelId"}
                    selectedHotel={selectedHotel}
                    setSelectedHotel={setSelectedHotel}
                    selectBoxContent={loginPageData.Hotels}
                    newLabelStyles={{color: "var(--color-primary)", fontWeight: "bold"}}
                    setFormData={(key, value) => manageFormData(key, value)}
                    placeholder={HOTEL_SELECTION_PACEHOLDER}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <InputCard
                    label={"Enter your surname:"}
                    placeholder={"Please enter surname"}
                    name={"surname"}
                    number={2}
                    newLabelStyles={{color: "var(--color-primary)", fontWeight: "bold"}}
                    setFormData={(key, value) => manageFormData(key, value)}
                  />
                  <InputCard
                    label={"Enter your booking reference number (Starts with 1):"}
                    placeholder={"Booking reference number, begins with 1"}
                    name={"bookingId"}
                    number={3}
                    newLabelStyles={{
                      color: "var(--color-primary)", 
                      fontWeight: "bold",

                    }}
                    value={formData?.bookingId || ""}
                    setFormData={(key, value) => manageFormData(key, value)}
                  />
                </div>
                {isWaitingToLogIn ? <LoadingContainer /> : null}
                {isError && !isWaitingToLogIn &&
                  <article className={styles.errorInfoWrapper}>
                    <Information
                      new_styles={{
                        width: "100%",
                        height: "auto",
                        backgroundColor: "var(--color-error)",
                      }}
                      fullyColoredClass={"fullyColoredClass"}
                      flexDirection={"row"}
                      txt={isError}
                      icon={"exclamation.svg"}
                    />
                  </article>
                }
                <div className={styles.btnWrapper}>
                  <Button
                    backgroundColor={"var(--color-primary)"}
                    txt={"Go to my account"}
                    onClick={(e) => handleRequest(e, formData)}
                  />
                </div>
                <div className={styles.helpWrapper}>
                  <IndicatiorCircle txt={"?"} backgroundColor={"var(--color-error)"} />
                  <a className={styles.helpLink} href={HELP_LINK} target="_blank" rel="noreferrer">
                    Need some help? Click here
                  </a>
                </div>
              </div>
            </form>
          </BlurryWrapper>
          {hasSessionExpired ? (
            <AlertBox
              txt={"Your session expired. Please log in again."}
              button={
                <Button
                  backgroundColor={"var(--color-primary)"}
                  color={"var(--color-secondary)"}
                  txt={"OK"}
                  onClick={() => setHasSessionExpired(false)}
                />
              }
            />
          ) : null}
          {wrongCRSAlert &&
            <AlertBox
            customContent={
              <>
                <Markdown>{loginPageData?.CRSInfoText}</Markdown> 
                {loginPageData?.CRSInfoImage ? (
                  <img
                    className={styles.LoginForm__AlertImg}
                    src={
                      loginPageData.CRSInfoImage.formats?.small?.url ||
                      loginPageData.CRSInfoImage.url
                    }
                    alt={loginPageData?.CRSInfoText}
                  />
                ) : null}
              </>
            }
            button={
              <Button
                backgroundColor={"var(--color-primary)"}
                color={"var(--color-secondary)"}
                txt={"OK"}
                onClick={() => setWrongCRSAlert(false)}
              />
            }
            customClassName="wrongCRSAlert"
          />
          }
        </section>
      )}
      {bannerTexts.length > 0 ? (
        <PromotionalBanner content={bannerTexts} />
      ) : null}
    </>
  );
};

export default LoginOld;

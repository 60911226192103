import React, { useState, useEffect } from "react";
import { formatDate, getDayOfWeek } from "../../../helpingFunctions/utilities";
import styles from "./TreatsTable.module.css";
import Button from "../../../shared/Button/Button";
import AlertBox from "../../../components/AlertBox/AlertBox";
import { editPencil, xSign, undoSign } from "../../../assets/SVGIcons";
import InformationalBox from "../../../shared/Containers/InformationalBox";
import WindowWidth from "../../../hooks/WindowWidth";
import { isAddOnBookingCancellable } from "../../../helpingFunctions/utilities";

const TreatsTable = ({ selectedDate, filteredBookings, handleRowOptions }) => {
  const { width } = WindowWidth();

  return (
    <>
      {filteredBookings.length === 0 ? (
        <InformationalBox
          showIcon={true}
          content={`Currently you haven't made any selections for ${formatDate(
            selectedDate
          )}`}
          boxNewStyles={{
            justifyContent: "center",
          }}
          contentNewStyles={{ fontSize: "16px" }}
        />
      ) : (
        <>
          {width > 800 ? (
            <>
              <div
                style={{
                  width: "100%",
                  background: "white",
                  padding: "20px 0",
                  border: "1px solid rgb(181, 181, 183)",
                  borderRadius: "10px",
                }}
              >
                <p
                  style={{
                    paddingBottom: "20px",
                    paddingLeft: "20px",
                    textAlign: "left",
                  }}
                >
                  <strong>{`Selected treats for ${formatDate(
                    selectedDate
                  )}`}</strong>
                </p>
                <div
                  className={styles.spaBookingsTableRow}
                  style={{ margin: "0 20px" }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                    }}
                    className={styles.spaBookingsTableLocationCell}
                  >
                    {"Location"}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                    }}
                    className={styles.spaBookingsTableQuantityCell}
                  >
                    {"Quantity"}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                    }}
                    className={styles.spaBookingsTableStatusCell}
                  >
                    {"Status"}
                  </p>
                  <div
                    className={`${styles.spaBookingsTableButtonsCell}`}
                  ></div>
                </div>

                <div className={styles.spaBookingsTableWrapper}>
                  {filteredBookings.map(
                    (
                      { Date, Location, Quantity, QuantityBooked, action },
                      index
                    ) => (
                      <>
                        <div
                          key={`${index}_${Date}_${Location}_${Math.random()}`}
                          className={styles.spaBookingsTableRow}
                        >
                          <p
                            style={{
                              textDecoration:
                                action === "remove" ? "line-through" : "",
                            }}
                            className={styles.spaBookingsTableLocationCell}
                          >
                            {Location === "Treats"
                              ? "In-room"
                              : Location === "Lunch"
                              ? "Restaurant - Lunch"
                              : Location === "Dinner"
                              ? "Restaurant - Dinner"
                              : Location}
                          </p>
                          <p
                            style={{
                              textDecoration:
                                action === "remove" ? "line-through" : "",
                            }}
                            className={styles.spaBookingsTableQuantityCell}
                          >
                            {action === "remove" ? QuantityBooked : Quantity}
                          </p>
                          <p
                            style={{
                              color: action === "none" ? "green" : "var(--color-error)",
                            }}
                            className={styles.spaBookingsTableStatusCell}
                          >
                            {action === "none" ? "Confirmed" : "Not Confirmed"}
                          </p>
                          <div
                            className={`${styles.spaBookingsTableButtonsCell}`}
                          >
                            {action !== "remove" &&
                              !isAddOnBookingCancellable(
                                selectedDate,
                                Location === "Treats" ? "In-room" : "Restaurant"
                              ) && (
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    color: "var(--color-info)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleRowOptions("edit", Date, Location)
                                  }
                                >
                                  {"edit"}
                                </span>
                              )}
                            {!isAddOnBookingCancellable(
                              selectedDate,
                              Location === "Treats" ? "In-room" : "Restaurant"
                            ) ? (
                              <span
                                style={{
                                  textDecoration: "underline",
                                  color: "var(--color-info)",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleRowOptions(
                                    action === "add"
                                      ? "remove"
                                      : action === "none"
                                      ? "cancel"
                                      : "undo",
                                    Date,
                                    Location
                                  )
                                }
                              >
                                {action === "add"
                                  ? "remove"
                                  : action === "none"
                                  ? "cancel"
                                  : "undo"}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                <h3 style={{ paddingBottom: "10px", color: "var(--color-primary)" }}>
                  <strong>{`Selected treats for ${selectedDate}`}</strong>
                </h3>
                <div style={{ paddingBottom: "10px" }}>
                  {filteredBookings.map(
                    (
                      { Date, Location, Quantity, QuantityBooked, action },
                      index
                    ) => (
                      <>
                        <div
                          key={`${index}_${Date}_${Location}_${Math.random()}`}
                          style={{
                            backgroundColor: "white",
                            height: "5px",
                            width: "100%",
                          }}
                        />

                        <div
                          style={{
                            backgroundColor: "#f2f2f4",
                            padding: "10px",
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "right",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {action !== "remove" &&
                              !isAddOnBookingCancellable(
                                selectedDate,
                                Location === "Treats" ? "In-room" : "Restaurant"
                              ) && (
                                <span
                                  onClick={() =>
                                    handleRowOptions("edit", Date, Location)
                                  }
                                  className={styles.editIcon}
                                >
                                  {editPencil}
                                </span>
                              )}

                            {!isAddOnBookingCancellable(
                              selectedDate,
                              Location === "Treats" ? "In-room" : "Restaurant"
                            ) && (
                              <span
                                style={{
                                  paddingLeft: "20px",
                                  paddingRight: "10px",
                                }}
                                onClick={() =>
                                  handleRowOptions(
                                    action === "add"
                                      ? "remove"
                                      : action === "none"
                                      ? "cancel"
                                      : "undo",
                                    Date,
                                    Location
                                  )
                                }
                                className={styles.editIcon}
                              >
                                {action === "remove" ? undoSign : xSign}{" "}
                              </span>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "bold",
                                width: "110px",
                                textAlign: "left",
                              }}
                            >
                              {"Location: "}
                            </p>
                            <p
                              style={{
                                textDecoration:
                                  action === "remove" ? "line-through" : "",
                                textAlign: "left",
                              }}
                              className={styles.spaBookingsTableLocationCell}
                            >
                              {Location === "Treats"
                                ? "In-room"
                                : Location === "Lunch"
                                ? "Restaurant - Lunch"
                                : Location === "Dinner"
                                ? "Restaurant - Dinner"
                                : Location}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "bold",
                                width: "110px",
                                textAlign: "left",
                              }}
                            >
                              {"Quantity: "}
                            </p>
                            <p
                              style={{
                                textDecoration:
                                  action === "remove" ? "line-through" : "",
                                textAlign: "left",
                              }}
                              className={styles.spaBookingsTableQuantityCell}
                            >
                              {action === "remove" ? QuantityBooked : Quantity}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "bold",
                                width: "110px",
                                textAlign: "left",
                              }}
                            >
                              {"Status: "}
                            </p>
                            <p
                              style={{
                                color: action === "none" ? "green" : "var(--color-error)",
                                textAlign: "left",
                              }}
                              className={styles.spaBookingsTableStatusCell}
                            >
                              {action === "none"
                                ? "Confirmed"
                                : "Not Confirmed"}
                            </p>
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default TreatsTable;

import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import { getSelectionByDate } from "../../helpingFunctions/selections";

const QuantityBoxLogic = (id, date, limit) => {
  const [quantity, setQuantity] = useState(null);
  const { reservation } = useContext(DataContext);
  const {
    bookingSelections,
    updateQuantity,
    mobileQuantity,
    setMobileQuantity,
  } = useContext(SelectionContext);

  const increment = () => {
    if (
      reservation.Adults === quantity ||
      (limit && limit === quantity) ||
      mobileQuantity[date] === reservation.Adults
    )
      return;

    updateQuantity([id], date, quantity + 1, "Quantity", "increment");
    setQuantity((prevState) => (prevState = mobileQuantity[date]));
  };
  const decrement = () => {
    if (mobileQuantity[date] === 0) return;

    if (mobileQuantity[date] === 1) {
      updateQuantity([id], date, 0, "Quantity", "decrement");
      setQuantity((prevState) => (prevState = mobileQuantity[date]));
      return;
    }

    updateQuantity([id], date, quantity - 1, "Quantity", "decrement");
    setQuantity((prevState) => (prevState = mobileQuantity[date]));
  };

  const getQuantity = () => {
    const selection = getSelectionByDate(bookingSelections, date);

    if (selection?.Quantity) {
      setQuantity(parseInt(selection.Quantity));
      mobileQuantity[date] = parseInt(selection.Quantity)
    } else {
      setQuantity(0);
      mobileQuantity[date] = 0
    }
  };

  useEffect(() => {
    getQuantity();
    // const found = bookingSelections.find(
    //   (row) => row.day === date && row.action !== "add"
    // );
    // console.log({found, date});
    // if (found) {
    //   if (found.action === "remove") {
    //     setQuantity(0);
    //     mobileQuantity[date] = 0;
    //     return;
    //   }

    //   const foundQuantity = found.Quantity ? found.Quantity : found.quantityBooked
    //   setQuantity(parseInt(foundQuantity));
    //   mobileQuantity[date] = foundQuantity;
    // }
  }, [bookingSelections]);

  return { quantity, increment, decrement, mobileQuantity };
};

export default QuantityBoxLogic;

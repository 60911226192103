import React, { useContext } from "react";

import { DataContext } from "../../context/DataContext";
import MainTitle from "../../components/MainTitle/MainTitle";

import styles from "./HotelMapImage.module.css";

const HotelMapImage = ({ view, setView }) => {
  const { reservation } = useContext(DataContext);
  return (
    <>
      <MainTitle
        height={"116px"}
        logoHeight={"130px"}
        logoWidth={"400px"}
        cursor={"pointer"}
        setView={setView}
        enableHomeButton={reservation}
      />
      <div className={styles.HotelMapImage}>
        <picture>
          <source media="(max-width: 640px)" srcset={reservation.Hotel.HotelMapImage?.formats?.small?.url} />
          <source media="(max-width: 900px)" srcset={reservation.Hotel.HotelMapImage?.formats?.medium?.url} />
          <source media="(min-width: 901px)" srcset={reservation.Hotel.HotelMapImage?.formats?.large?.url} />
          <img 
            src={reservation.Hotel.HotelMapImage?.formats?.large?.url} 
            alt={reservation.Hotel.HotelMapImage?.alternativeText || `${reservation.Hotel.Title} Map`}
          />
        </picture>
      </div>
    </>
  )
};

export default HotelMapImage;

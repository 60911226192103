import React, { useContext } from "react";

import GuideItemTV from "../GuideItemTV/GuideItemTV";
import GuideTvCarouselLogic from "./GuideTvCarouselLogic";
import TVFilterAndDay from "../DayTime/DayTime";
import GrayWrapper from "../../../../../components/UI/GrayWrapper/GrayWrapper";
import GuideItemDesktop from "../../GuideItemDesktop/GuideItemDesktop";
import { WhatsOnDataContext } from "../../../../../context/WhatsOnDataContext";

const GuideTvCarousel = () => {
  const { guide, whatsOnGuideAllDayActivities } =
    useContext(WhatsOnDataContext);
  const {
    longDeviceSliderRef,
    activityDayTime,
    isInTVHorizontal,
    isInTVVertical,
  } = GuideTvCarouselLogic(guide);

  let CarouselDisplay = null;
  if (isInTVHorizontal) {
    document.body.style.overflow = "hidden";
    CarouselDisplay = (
      <GrayWrapper
        new_class={"WhatsOnGuideTVHorizontal"}
        new_styles={{
          minWidth: "80%",
          flex: 1,
          minHeight: "50vh",
        }}
        reference={longDeviceSliderRef}
      >
        {whatsOnGuideAllDayActivities
          .concat(guide)
          .map(
            (guideItem) =>
              guideItem && <GuideItemTV key={guideItem.timeId} {...guideItem} />
          )}
      </GrayWrapper>
    );
  }

  if (isInTVVertical) {
    CarouselDisplay = (
      <GrayWrapper
        new_class={"WhatsOnGuideTVVertical"}
        new_styles={{
          minWidth: "80%",
          flex: 1,
          minHeight: "50vh",
        }}
        reference={longDeviceSliderRef}
      >
        {whatsOnGuideAllDayActivities &&
          whatsOnGuideAllDayActivities.map((allDayActivity, index) => (
            <GuideItemDesktop
              key={1}
              guideItem={allDayActivity}
              guide={guide}
              itemIndex={index}
              setSelectedItemId={() => {}}
              setView={() => {}}
              internalActivityItineraryHandler={() => {}}
            />
          ))}
        {guide.map((guideItem, index) => (
          <GuideItemDesktop
            key={guideItem.timeId}
            guideItem={guideItem}
            guide={guide}
            itemIndex={index}
            setSelectedItemId={() => {}}
            setView={() => {}}
            internalActivityItineraryHandler={() => {}}
          />
        ))}
      </GrayWrapper>
    );
  }

  return (
    <>
      <TVFilterAndDay highlightedDayTime={activityDayTime} />
      {CarouselDisplay}
    </>
  );
};

export default GuideTvCarousel;

import React from "react";

import styles from "./VenueBanner.module.css";

const VenueBanner = ({ content, new_styles, enableIcon, new_styles_txt, customIcon }) => {
  return (
    <>
      <div className={styles.VenueBanner} style={{ ...new_styles }}>
        <p className={styles.BannerText} style={{ ...new_styles_txt }}>
          {content}

          {enableIcon ? (
            <img
              className={styles.banner_check_icon}
              src={`/img/info_icons/check_circle.svg`}
              alt=""
            />
          ) : customIcon ? customIcon : null}
        </p>
      </div>
    </>
  );
};

export default VenueBanner;

import React, { useContext } from "react";

import Heading from "../../../../shared/Heading/Heading";
import ImageCarousel from "../../../../shared/ImageCarousel/ImageCarousel";
import InformationText from "../../../../shared/InformationText/InformationText";
import { DataContext } from "../../../../context/DataContext";
import { durationLabel, expand_collapse } from "../shared/helpingFunctions";
import BookActivity from "../BookActivity/BookActivity";
import { handleNullDisplay } from "../../../../helpingFunctions/helpingFunctions";

import styles from "./GuideItemMobile.module.css";
import { ALL_DAY_ACTIVITY } from "../../constants/constants";
import { formatTimeWithoutAmPm } from "../../../../helpingFunctions/utilities";

const GuideItemMobile = ({
  selectedItemId,
  setSelectedItemId,
  itemIndex,
  setView,
  guideItem,
  internalActivityItinerary,
  internalActivityItineraryHandler
}) => {
  const {
    Description,
    Location,
    Price,
    Title,
    Images,
    activityId,
    time,
    duration,
    StartTime,
    EndTime
  } = guideItem;
  const { selectedDate } = useContext(DataContext);
  const isAllDayActivity = ALL_DAY_ACTIVITY === guideItem.Type;
  const id = `${activityId}_${itemIndex}_${selectedDate}`;
  const isClicked = selectedItemId === id;

  return (
    <article
      className={`${styles.GuideItem} ${
        isClicked ? styles.GuideItem_opened : ""
      }`}
      onClick={expand_collapse.bind(this, setSelectedItemId, id)}
    >
      <section
        className={styles.GuideItem__imgWrapper}
        onClick={(e) => e.stopPropagation()}
      >
        <ImageCarousel sliderImages={Images} view={"whatsonguide"} />
      </section>
      <section className={styles.GuideItem__details}>
        <img
          className={styles.GuideItem__dropDown_closed}
          src="/img/drop-down-arrow_1.svg"
        />
        <Heading
          txt={Title}
          new_styles={{ color: "var(--color-primary)", paddingRight: "40px" }}
        />
        <ul
          className={styles.GuideItem__details_list}
          style={{ flexDirection: "column" }}
        >
          <li>
            <strong>Price:</strong> {handleNullDisplay(Price, "£", "Inclusive")}
          </li>
          <li>
            <strong>Start:</strong>{" "}
            {isAllDayActivity ? formatTimeWithoutAmPm(StartTime) : time}
          </li>
          <li>
            <strong>{isAllDayActivity ? "End:" : "Duration:"}</strong>{" "}
            {isAllDayActivity
              ? formatTimeWithoutAmPm(EndTime)
              : durationLabel(duration)}
          </li>
          <li>
            <strong>Location:</strong>{" "}
            {isAllDayActivity ? Location : Location?.locationName}
          </li>
        </ul>
        <div className={styles.expandShow}>
          <InformationText
            content={Description}
            new_styles={{ marginTop: "10px" }}
          />
          <div className={styles.GuideItem__expandEnding}>
            <img
              className={styles.GuideItem__dropDown_opened}
              src="/img/drop-down-arrow_1.svg"
            />
          </div>
          {!isAllDayActivity && (
            <BookActivity
              guideItem={guideItem}
              isInternalActivity={activityId?.includes("internalAct_facility")}
              setView={setView}
              internalActivityItinerary={internalActivityItinerary}
              internalActivityItineraryHandler={
                internalActivityItineraryHandler
              }
            />
          )}
        </div>
      </section>
    </article>
  );
};

export default GuideItemMobile;

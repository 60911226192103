import { useContext, useEffect, useRef, useState } from "react";

import { compare, findOneFromObjList } from "../../helpingFunctions/arrays";
import { DataContext } from "../../context/DataContext";
import WindowWidth from "../../hooks/WindowWidth";

const staticImageLink =
  "https://images.pexels.com/photos/2325446/pexels-photo-2325446.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260";

const CategoriesLogic = (showType) => {
  const [originalData, setOriginalData] = useState([]);
  const [manipulatedData, setManipulatedData] = useState([]);
  const [pageTextData, setPageTextData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [clickedObject, setClickedObject] = useState(null);
  const [gridSettings, setGridSettings] = useState({});
  const categoryBannersRef = useRef([]);

  const {
    facilitiesData,
    availibility,
    pagesData,
    reservation,
    selectedDate,
    setSelectedDate,
  } = useContext(DataContext);
  const { width } = WindowWidth();

  const findAcitivities = () => {
    const allObjects = facilitiesData.filter(
      ({ objType }) => objType === showType
    );
    const structuredObjects = contructCategorisedObject(allObjects);

    setOriginalData(structuredObjects);
    if (showType === "Treats") {
      structuredObjects.sort((a, b) =>
        compare(a.TreatCategoryId, b.TreatCategoryId, "desc")
      );
      structuredObjects.forEach((row) => {
        if (!row.TreatCategoryId) {
          row.CategoryImageLandscape = null;
          row.CategoryImagePortrait = null;
        }
      });
    }
    setManipulatedData(structuredObjects);

    setIsLoading(false);
    return structuredObjects
  };

  const contructCategorisedObject = (allObjects) => {
    const structuredObjects = [];
    const sortedObjects = allObjects.sort((a, b) =>
      compare(a.Title, b.Title, "asc")
    );

    sortedObjects.map((spaObj) => {
      let itemIndex = -1;
      if (showType === "Treats") {
        itemIndex = structuredObjects.findIndex(
          (structuredObj) =>
            structuredObj.TreatCategoryId === spaObj.TreatCategoryId
        );
      } else {
        itemIndex = structuredObjects.findIndex(
          (structuredObj) =>
            structuredObj.ServiceTypeCode === spaObj.ServiceTypeCode
        );
      }

      if (itemIndex >= 0) {
        structuredObjects[itemIndex].objects.push(spaObj);
        if (!structuredObjects[itemIndex].CategoryImagePortrait)
          structuredObjects[itemIndex].CategoryImagePortrait =
            spaObj.CategoryImagePortrait;
        if (!structuredObjects[itemIndex].CategoryImageLandscape)
          structuredObjects[itemIndex].CategoryImageLandscape =
            spaObj.CategoryImageLandscape;
      } else {
        let preparedObj = {
          CategoryImagePortrait: spaObj.CategoryImagePortrait,
          CategoryImageLandscape: spaObj.CategoryImageLandscape,
          objects: [spaObj],
        };

        if (showType === "Treats") {
          preparedObj.TreatCategoryId = spaObj.TreatCategoryId;
          preparedObj.ServiceTypeDescription = spaObj.CategoryTitle;
        } else {
          preparedObj.ServiceTypeCode = spaObj.ServiceTypeCode;
          preparedObj.ServiceTypeDescription = spaObj.ServiceTypeDescription;
        }

        structuredObjects.push(preparedObj);
      }
    });

    const finalObjects = handleUndefinedImages(structuredObjects);

    return finalObjects;
  };

  const handleUndefinedImages = (structuredObjects) => {
    const sortedByImg = structuredObjects.sort(function (a, b) {
      return (
        (a.CategoryImagePortrait === undefined) -
          (b.CategoryImagePortrait === undefined) ||
        (a.CategoryImagePortrait === null) -
          (b.CategoryImagePortrait === null) ||
        +(a.CategoryImagePortrait > b.CategoryImagePortrait) ||
        -(a.CategoryImagePortrait < b.CategoryImagePortrait)
      );
    });

    // sortedByImg.map((spaGroup) => {
    //   if (!spaGroup.CategoryImagePortrait) {
    //     spaGroup.CategoryImagePortrait = staticImageLink;
    //   }
    // });

    return sortedByImg;
  };

  const getPageData = async () => {
    try {
      const data = findOneFromObjList(pagesData, "Type", showType);

      setPageTextData(data);
    } catch (error) {}
  };

  // const checkIfItHasAvailability = (objectId) => {
  //   const hasAvailability = availibility.some(({ Facilities }) =>
  //     Facilities.some(
  //       ({ FacilityGUID, Times }) =>
  //         parseInt(FacilityGUID) === parseInt(objectId) && Times.length > 0
  //     )
  //   );

  //   return hasAvailability;
  // };

  const categorySelectorHandler = (cat) => {
    setClickedObject(null);
    setGridSettings({});
    if (selectedCategory === cat) {
      setSelectedCategory(null);
      return;
    }

    setSelectedCategory(cat);
  };

  const handleCardClick = (clickedObj, index) => {
    if (clickedObject?.id === clickedObj.id) {
      setClickedObject(null);
      setGridSettings({});
      return;
    }
    setClickedObject(clickedObj);
    const settings = getGridElementsPosition(index);
    setGridSettings(settings);
  };

  const getGridElementsPosition = (index) => {
    const isInLargeRange = width > 1875;
    const isInMedimRange = width >= 1325 && width <= 1874;
    let gridColsPerRow = null;
    let sliderWidth = null;
    let sliderHeight = null;

    if (isInLargeRange) {
      gridColsPerRow = 4;
      sliderWidth = "660px";
      sliderHeight = "400px";
    } else if (isInMedimRange) {
      gridColsPerRow = 3;
      sliderWidth = "450px";
      sliderHeight = "400px";
    }

    const rowNumber = Math.floor(index / gridColsPerRow);

    return {
      gridRowNum: rowNumber + 2,
      gridColEnd: gridColsPerRow + 1,
      sliderWidth,
      sliderHeight,
    };
  };

  const updateStateAfterSort = (sortedList) => {
    setClickedObject(null);
    setManipulatedData(sortedList);
  };

  useEffect(() => {
    if (!selectedCategory) return;

    let bannerItem = null;

    if (showType === "Treats") {
      bannerItem = categoryBannersRef.current.find(
        ({ TreatCategoryId }) => TreatCategoryId === selectedCategory
      );
    } else {
      bannerItem = categoryBannersRef.current.find(
        ({ ServiceTypeCode }) => ServiceTypeCode === selectedCategory
      );
    }

    bannerItem.el.scrollIntoView({ behavior: "smooth", alignToTop: true });
  }, [selectedCategory]);

  useEffect(() => {
    if (availibility && availibility.length > 0) findAcitivities();
  }, [availibility]);

  useEffect(() => {
    findAcitivities();
    getPageData();
  }, []);

  return {
    isLoading,
    pageTextData,
    selectedCategory,
    clickedObject,
    gridSettings,
    originalData,
    manipulatedData,
    categoryBannersRef,
    reservation,
    selectedDate,
    setSelectedDate,
    setManipulatedData,
    categorySelectorHandler,
    handleCardClick,
    updateStateAfterSort,
    setClickedObject,
    setSelectedCategory,
    findAcitivities,
  };
};

export default CategoriesLogic;

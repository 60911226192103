import Markdown from "markdown-to-jsx";
import React, { useContext } from "react";

import ActivityCardMobile from "../../../components/ActivityCard/ActivityCardMobile/ActivityCardMobile";
import { ActivitiesTypes } from "../../../constants/constants";
import WindowWidth from "../../../hooks/WindowWidth";
import Heading from "../../../shared/Heading/Heading";
import InformationText from "../../../shared/InformationText/InformationText";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import ActivitiesLogic from "../ActivitiesLogic";

import styles from "./activities.module.css";

const ActivitesMobile = ({ setView, showType }) => {
  const { objects, isLoading, pageTextData } = ActivitiesLogic(showType);
  const { width } = WindowWidth();

  return (
    <div className={styles.activitesMobile}>
      <Heading
        fontSize={"24px"}
        paddingTop={width >= 700 ? "40px" : "20px"}
        paddingBottom={"20px"}
        txt={pageTextData?.MobileTitle}
        fontFamily="Georgia"
        textTransform="uppercase"
      />
      <InformationText
        content={
          pageTextData?.MobileDescription ? (
            <Markdown>{pageTextData?.MobileDescription}</Markdown>
          ) : null
        }
        new_styles={{ paddingBottom: "20px" }}
      />
      {isLoading ? (
        <LoadingContainer />
      ) : (
        <div
          className={styles.activitesMobile__cards}
          style={{
            gridRowGap: ActivitiesTypes.includes(showType) ? "70px" : "0",
            width: "101%",
          }}
        >
          {objects.map((object) => (
            <ActivityCardMobile
              key={object.id}
              {...object}
              onClick={() => setView({ type: object.objType, id: object.id })}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ActivitesMobile;

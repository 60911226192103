import moment from "moment";

import { constructStringWithComma } from "./helpingFunctions";

const formatDate = (date) => {
  if (!date) return;

  return moment(date).format("Do MMMM YYYY");
};

const formatDateBritish = (date) => {
  if (!date) return;

  return moment(date).format("DD/MM/YY");
};

const consturctPopUpString = (daysArray) => {
  let dayString = "";

  daysArray.map((date, index) => {
    const formatedDate = moment(date).format(
      daysArray.length === 1 ? "dddd" : "ddd"
    );
    dayString += constructStringWithComma(
      formatedDate,
      index,
      daysArray.length
    );
  });

  return dayString;
};

const getDayOfWeek = (date) => {
  try {
    return moment(date).format("dddd");
  } catch (error) {
    console.error(
      `${new Date()} error in getDayOfWeek func inside CheckBookingConflicts.js file ${
        error.message
      }`
    );
  }
};

const getDetailedDate = (date) => {
  try {
    return moment(date).format("ddd, Do MMMM YYYY");
  } catch (error) {
    console.error(
      `${new Date()} error in getDetailedDate func inside CheckBookingConflicts.js file ${
        error.message
      }`
    );
  }
};

const calculteDateDifference = (date1, date2) => {
  if (date2 < date1) {
    return;
  }
  const diffInMs = Math.abs(date2 - date1);

  const dayDifference = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  const weeks = Math.floor(dayDifference / 7);

  return {
    weeks,
    days: dayDifference - weeks * 7,
    allDayDifference: dayDifference,
  };
};

const isReservation2WeeksPriorArrDate = (arrivalDate) => {
  const twoWeeksPrior = moment(arrivalDate, "YYYY-MM-DD")
    .subtract(14, "days")
    .format("YYYY-MM-DD");
  const today = new Date().toISOString().substring(0, 10);
  return today >= twoWeeksPrior && today <= arrivalDate.substring(0, 10);
};

const isAddOnBookingCancellable = (requestDate, type) => {
  if (type === "In-room") {
    const twoDaysAhead = moment(new Date(), "YYYY-MM-DD")
      .add(2, "days")
      .format("YYYY-MM-DD");
    return twoDaysAhead >= requestDate;
  }
};

const formatTime = (hours, minutes) => {
  const ampm = hours >= 12 ? "PM" : "AM";

  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  const strTime = `${hours}:${minutes} ${ampm}`;

  return strTime;
};

const getStayingDates = (arrivalDate, departureDate, view = null) => {
  if (!arrivalDate || !departureDate) return;

  const startDate = moment(moment(arrivalDate).format("YYYY-MM-DD"));
  const endDate = moment(moment(departureDate).format("YYYY-MM-DD"));

  const now = startDate.clone();
  const allDates = [];

  while (now.isSameOrBefore(endDate)) {
    allDates.push(now.format("YYYY-MM-DD"));
    now.add(1, "days");
  }

  switch (view) {
    case "Breakfast":
      allDates.shift();
      return allDates;
    case "Dinner":
      allDates.pop();
      return allDates;
    case "Entertainment":
      allDates.pop();
      return allDates;
    default:
      return allDates;
  }
};

const isBookingInThePast = (
  bookingDay,
  bookingTime,
  currentDay,
  currentTime,
  type,
  arrivalDate
) => {
  if (type === "Treats") {
    return currentDay >= arrivalDate;
  }
  return (
    bookingDay < currentDay ||
    (bookingDay === currentDay &&
      bookingTime?.substring(0, 5) <= currentTime?.substring(0, 5))
  );
};

const isReservation3MonthsIn = (arrivalDate) => {
  const after3Months = moment(new Date().toISOString(), "YYYY-MM-DD")
    .add(90, "days")
    .format("YYYY-MM-DD");
  return arrivalDate.substring(0, 10) < after3Months;
};

const getBookedQuantitiesPerDay = (bookings) => {
  try {
    let quantityPerTime = {};
    bookings.forEach((row) => {
      if (row.Time) {
        const time = row.Time.substring(0, 5);
        if (quantityPerTime.hasOwnProperty(time)) {
          quantityPerTime[time] += Number(row.Quantity);
        } else {
          quantityPerTime[time] = Number(row.Quantity);
        }
      }
    });
    return quantityPerTime;
  } catch (error) {
    console.error(
      `${new Date()} error in getBookedQuantitiesPerDay func inside CheckBookingConflicts.js file ${
        error.message
      }`
    );
  }
};

const formatTimeWithoutAmPm = (date) => {
  return moment(date, "HH:mm:ss").format("HH:mm");
};

export {
  calculteDateDifference,
  formatDate,
  getDayOfWeek,
  formatTime,
  getStayingDates,
  consturctPopUpString,
  isBookingInThePast,
  isReservation2WeeksPriorArrDate,
  getBookedQuantitiesPerDay,
  getDetailedDate,
  isReservation3MonthsIn,
  formatDateBritish,
  isAddOnBookingCancellable,
  formatTimeWithoutAmPm
};

import React from "react";

import styles from "./ImageCard.module.css";

const ImageCard = ({ img, handleClick, new_styles, children }) => {

  return (
    <div
      className={styles.ImageCard}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url(${img})`,
        ...new_styles
      }}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default ImageCard;

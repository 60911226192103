import React, { useContext } from "react";
import Heading from "../../shared/Heading/Heading";
import BookingFolioLogic from "./BookingFolioLogic";
import BookingFolioPostingsRow from "./BookingFolioPostingsRow";
import LoadingContainer from "../../shared/LoadingContainer/LoadingContainer";
import { DataContext } from "../../context/DataContext";
import { formatDateBritish } from "../../helpingFunctions/utilities";
import { xSign } from "../../assets/SVGIcons";
import WindowWidth from "../../hooks/WindowWidth";
import Markdown from "markdown-to-jsx";
import InformationBox from "../../shared/Containers/InformationalBox";

import styles from "./booking_folio.module.css";

const BookingFolio = ({ removeModal }) => {
  const { folioDetails, pageData } = BookingFolioLogic();
  const { reservation, setShowFolio } = useContext(DataContext);
  const { width, height } = WindowWidth();

  return (
    <>
      <div className={styles.popUpWrapper}>
        <div className={styles.grayOutScreen}></div>
        {folioDetails ? (
          <>
            {folioDetails.Status === "success" ? (
              <div
                className={styles.alertBox}
              >
                <div
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <div
                    style={{ position: "absolute", top: "10px", right: "15px" }}
                    onClick={removeModal}
                  >
                    {xSign()}
                  </div>
                  <Heading
                    txt={
                      pageData
                        ? width > 1324
                          ? pageData.DesktopTitle
                          : pageData.MobileTitle
                        : ""
                    }
                    fontFamily="Georgia"
                    textTransform="uppercase" 
                    new_styles={{
                      fontSize: "28px",
                      paddingBottom: "20px",
                    }}
                  />
                  <Markdown className={styles.markdown}>
                    {pageData
                      ? width > 1324
                        ? pageData.DesktopDescription_max_length_is_340
                        : pageData.MobileDescription
                      : ""}
                  </Markdown>
                </div>
                <div
                  style={{ width: "100%", paddingTop: "20px"}}
                >
                  <div
                    style={{
                      display: "flex",
                      paddingBottom: "15px",
                      borderBottom: "3px solid black",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ marginLeft: "2%" }}>
                        <p style={{ marginBottom: "10px" }}>
                          <strong>Bill Ref</strong>
                        </p>
                        <p>{folioDetails.FolioNumber}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: width >= 555 ? "35%" : null,
                        }}
                      >
                        <div style={{ paddingLeft: "5%" }}>
                          <p style={{ marginBottom: "10px" }}>
                            <strong>Arrival</strong>
                          </p>
                          <p>
                            {formatDateBritish(reservation.ArrivalDate.split("T")[0])}
                          </p>
                        </div>
                        <div style={{ paddingLeft: "10%" }}>
                          <p style={{ marginBottom: "10px" }}>
                            <strong>Departure</strong>
                          </p>
                          <p>
                            {formatDateBritish(
                              reservation.DepartureDate.split("T")[0]
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "80%",
                  }}
                >
                  <BookingFolioPostingsRow
                    Date={"Trans Date"}
                    PostingCodeDescription={"Description"}
                    Voucher={"Reference ID"}
                    Amount={"Amount (incl VAT)"}
                    newStyles={{ fontWeight: "bold", fontSize: "18px" }}
                  />

                  {folioDetails.FolioDetailInfo.charges.length > 0 ? (
                    <div>
                      <p style={{ margin: "10px 0" }}>
                        <strong>Charges</strong>
                      </p>
                      {folioDetails.FolioDetailInfo.charges.map(
                        (
                          { Date, PostingCodeDescription, Voucher, Amount },
                          index
                        ) => (
                          <BookingFolioPostingsRow
                            key={index}
                            Date={Date}
                            PostingCodeDescription={PostingCodeDescription}
                            Voucher={Voucher}
                            Amount={Amount}
                            totalCharge={folioDetails.totalCharges.toFixed(2)}
                            isLast={
                              index ===
                              folioDetails.FolioDetailInfo.charges.length - 1
                            }
                            title={"Total Charges"}
                          />
                        )
                      )}
                    </div>
                  ) : null}

                  {folioDetails.FolioDetailInfo.payments.length > 0 ? (
                    <div>
                      <p style={{ margin: "10px 0" }}>
                        <strong>Payments</strong>
                      </p>

                      {folioDetails.FolioDetailInfo.payments.map(
                        (
                          { Date, PostingCodeDescription, Voucher, Amount },
                          index
                        ) => (
                          <BookingFolioPostingsRow
                            key={index}
                            Date={Date}
                            PostingCodeDescription={PostingCodeDescription}
                            Voucher={Voucher}
                            Amount={Amount}
                            totalCharge={folioDetails.totalPayments.toFixed(2)}
                            isLast={
                              index ===
                              folioDetails.FolioDetailInfo.payments.length - 1
                            }
                            title={"Total Payments"}
                          />
                        )
                      )}
                    </div>
                  ) : null}

                  <div
                    style={{
                      paddingBottom: "50px",
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "10px",
                      gap: "50px",
                      paddingRight: "20px",
                    }}
                  >
                    <p>
                      <strong>Balance Due:</strong>
                    </p>
                    <p>{`£${folioDetails.FolioBalance}`}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.popUpWrapper}>
                <div className={styles.grayOutScreen}></div>

                <div
                  className={styles.alertBox}
                  style={{
                    width: width < 935 ? "90%" : "60%",
                    // height: "20%",
                    minHeight: "150px",
                    height: "150px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "100%",
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                    onClick={() => {setShowFolio(false)}}
                  >
                    {xSign()}
                  </div>
                  <InformationBox content={folioDetails?.Message} />
                </div>
              </div>
            )}
          </>
        ) : (
          <LoadingContainer />
        )}
      </div>
    </>
  );
};

export default BookingFolio;

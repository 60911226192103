import React from "react";
import TextField from "@mui/material/TextField";
import AdminFiltersLogic from "./AdminFiltersLogic";
import moment from "moment";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";

import styles from "./AdminFilters.module.css";

const AdminFilters = () => {
  const { handleChange, date } = AdminFiltersLogic();
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        inputFormat="DD/MM/YYYY"
        className={styles.DatePicker}
        value={date}
        onChange={handleChange}
        defaultValue={moment(new Date()).format("YYYY-MM-DD")}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: 300, border: "1px solid gray", outline: "none" }}
          />
        )}
        minDate={new Date()}
      />
    </LocalizationProvider>
  );
};

export default AdminFilters;

import React, { useState, useEffect, useContext } from "react";
import styles from "./entertainment-preview.module.css";
import Heading from "../../shared/Heading/Heading";
import EntertainmentCardMobile from "../EntertainmentCard/EntertainmentCardMobile/EntertainmentCardMobile";
import Button from "../../shared/Button/Button";
import { DataContext } from "../../context/DataContext";
import { getDayOfWeek } from "../../helpingFunctions/utilities";

const EntertainmentPreviewSectionMobile = ({
  venueDetails,
  expand,
  setExpand,
  venuesArray,
  selections,
  bookingNotSelected,
  toggleDetailsDialogHandler,
  handleSelection,
  retrieveAvailability,
  availabilityCount,
  checkIsConfirmed,
  width,
  opposingVenueSizeWithoutDefault,
  entertainmentStaticContent,
}) => {
  const [modifiedVenueArray, setModifiedVenueArray] = useState([]);
  const { selectedDate } = useContext(DataContext);

  const adjustVenuesArray = () => {
    setModifiedVenueArray(venuesArray.filter((row) => row.id !== -1));
  };
  
  useEffect(() => {
    if (["2022-10-15", "2022-10-16"].includes(selectedDate)) {
      adjustVenuesArray();
      return;
    }

    const confirmedActsLength = venuesArray.filter(
      (row) => typeof row.id === "number" && row.id !== -1
    ).length;
    const thursdaySaturdaySpecial =
      (confirmedActsLength > 1 && opposingVenueSizeWithoutDefault >= 0) ||
      (confirmedActsLength >= 0 && opposingVenueSizeWithoutDefault > 1);
    if (
      ["Thursday", "Saturday"].includes(getDayOfWeek(selectedDate)) &&
      thursdaySaturdaySpecial
    ) {
      if (venuesArray.length >= 2 && opposingVenueSizeWithoutDefault <= 1) {
        setModifiedVenueArray(
          venuesArray.filter(
            (row) => typeof row.id === "number" && row.id !== -1
          )
        );
      } else if (confirmedActsLength <= 1) {
        setModifiedVenueArray([]);
      } else {
        adjustVenuesArray();
      }
    } else {
      adjustVenuesArray();
    }
  }, [selectedDate]);

  return (
    <>
      {venueDetails ? (
        <section
          className={styles.venueDetailsWrapper}
          style={
            expand
              ? { backgroundColor: "#f2f2f4" }
              : { backgroundColor: "#fff" }
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f2f2f4",
            }}
          >
            <div
              className={`${styles.venueImageWrapper} ${
                expand ? styles.venueImageSelWrapper : null
              }`}
              style={{
                backgroundImage: `url("${venueDetails?.images[0]?.url}")`,
                borderRadius: expand ? "10px" : null,
              }}
            >
              <p className={styles.expandedVenueTitle}>
                {expand ? venueDetails?.title : ""}
              </p>
            </div>
            {expand ? null : (
              <div style={{ width: "98%", backgroundColor: "white" }}>
                <div className={`${styles.titleMobile} ${styles.allign}`}>
                  <Heading
                    txt={venueDetails?.title}
                    paddingTop={"20px"}
                    paddingBottom={"15px"}
                    fontSize={"24px"}
                    icon={"info-information.svg"}
                    details={venueDetails}
                    toggleDetailsDialogHandler={toggleDetailsDialogHandler}
                  />
                </div>
                <div className={styles.descMobile}>
                  <p>{venueDetails?.longDesc}</p>
                </div>
                <div className={styles.descMobile}>
                  {modifiedVenueArray.length > 0 ? (
                    <p>
                      <strong
                        style={{ fontSize: "16px" }}
                      >{`${modifiedVenueArray.length} events `}</strong>{" "}
                      to choose from
                    </p>
                  ) : (
                    <p>
                      <strong>
                        {entertainmentStaticContent?.ThAndSatWhiteBoxText}
                      </strong>
                    </p>
                  )}
                </div>
              </div>
            )}
            {modifiedVenueArray.length > 0 ? (
              <div
                className={styles.expandBtn}
                style={{ backgroundColor: expand ? "#f2f2f4" : "white" }}
              >
                <Button
                  txt={expand ? "Collapse" : `Expand to see events`}
                  onClick={() => setExpand(!expand)}
                  backgroundColor={"var(--color-primary)"}
                />
              </div>
            ) : null}
            {expand
              ? modifiedVenueArray.map((row, i) => {
                  return (
                    <EntertainmentCardMobile
                      key={`${row.Date}${row.Time}${row.id}`}
                      row={row}
                      bookingSelections={selections}
                      bookingNotSelected={bookingNotSelected}
                      toggleDetailsDialogHandler={toggleDetailsDialogHandler}
                      handleSelection={handleSelection}
                      retrieveAvailability={retrieveAvailability}
                      availabilityCount={availabilityCount}
                      checkIsConfirmed={checkIsConfirmed}
                      width={width}
                    />
                  );
                })
              : null}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default EntertainmentPreviewSectionMobile;

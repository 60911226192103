import React from "react";
import Markdown from "markdown-to-jsx";

import styles from "./information-text.module.css";

const InformationText = ({ lineHeight, content, new_styles, markdown }) => {
  return (
    <div
      className={styles.infoWrapper}
      style={{
        lineHeight,
        ...new_styles,
      }}
    >
      {markdown && content ? <Markdown>{content}</Markdown> : content}
    </div>
  );
};

export default InformationText;

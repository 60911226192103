import React from "react";

import styles from "./button.module.css";

const Button = ({
  backgroundColor,
  height,
  width,
  color,
  txt,
  onClick,
  opacity,
  pointerEvents,
  new_styles,
  minHeight,
  newStyles,
  isInThePast,
  href,
}) => {
  return (
    <>
      {href
        ? <a 
          href={href} 
          target="_blank" 
          className={styles.warnerStayButton} 
          rel="noreferrer"
          style={{
            backgroundColor,
            height,
            color,
            opacity,
            pointerEvents,
            minHeight,
            ...new_styles,
            ...newStyles,
            width: "fit-content",
            display: "flex",
            alignItems: "center"
          }}
        >
          {txt}
        </a>
        : <button
          className={`${styles.warnerStayButton} ${isInThePast ? "disableElement" : ""}`}
          style={{
            backgroundColor,
            height,
            color,
            opacity,
            pointerEvents,
            width,
            minHeight,
            ...new_styles,
            ...newStyles,
          }}
          onClick={onClick}
        >
          {txt}
        </button>
      }
    </>
  );
};

export default Button;

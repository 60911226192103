import React, { useEffect, useContext } from "react";

import { DataContext } from "../../../context/DataContext";
import ImageCarousel from "../../../shared/ImageCarousel/ImageCarousel";
import { SelectionContext } from "../../../context/SelectionContext";
import styles from "./entertainment-card-mobile.module.css";
import VenueBanner from "../../../shared/VenueBanner/VenueBanner";

const EntertainmentCardMobile = ({
  row,
  bookingSelections,
  bookingNotSelected,
  toggleDetailsDialogHandler,
  handleSelection,
  retrieveAvailability,
  checkIsConfirmed,
  width,
}) => {
  const {
    selectedDate,
    reservation,
    entertainmentStaticContent,
    cardBannerContent,
  } = useContext(DataContext);
  const { skipedDays } = useContext(SelectionContext);

  const getShowStartsText = () => {
    return entertainmentStaticContent?.OpeningTimeText.includes("$1")
      ? `${entertainmentStaticContent?.OpeningTimeText.replace(
          "$1",
          row.Time.substring(0, 5)
        )}`
      : `${entertainmentStaticContent?.OpeningTimeText} ${row.Time.substring(
          0,
          5
        )}`;
  };

  useEffect(() => {
    if (selectedDate) {
      retrieveAvailability(row);
    }
  }, [selectedDate]);

  const isDisabled =
    bookingNotSelected.includes(row.id) ||
    retrieveAvailability(row) < reservation.Adults ||
    skipedDays.includes(selectedDate) ||
    (selectedDate < new Date().toISOString().split("T")[0] &&
      !bookingSelections.includes(row.id));

  return (
    <>
      <div
        className={styles.selectionBox}
        onClick={() => !isDisabled ? handleSelection(row) : {}}
        style={
          bookingSelections.includes(row.id)
            ? {
                border: "7px solid var(--color-primary)",
                cursor: "pointer",
              }
            : { padding: "7px" }
        }
      >
        <article
          className={`
        ${styles.entertainmentCardMobile}
        ${bookingSelections.includes(row.id) ? styles.highlight : ""}
        ${isDisabled ? styles.disable : ""}
        `}
          style={
            selectedDate < new Date().toISOString().split("T")[0] &&
            bookingSelections.includes(row.id)
              ? { pointerEvents: "none" }
              : null
          }
        >
          <div className={styles.entertainmentImgSliderWrapper}>
            {retrieveAvailability(row) <= 30 &&
            !bookingSelections.includes(row.id) ? (
              <VenueBanner
                new_styles={{
                  width: "50%",
                  height: "13%",
                }}
                content={
                  <>
                    {retrieveAvailability(row) < reservation.Adults
                      ? cardBannerContent.NoAvailDefault
                      : cardBannerContent.Remaining}
                  </>
                }
              />
            ) : null}

            {bookingSelections.includes(row.id) ? (
              <div
                className={styles.banner}
                style={
                  bookingSelections.includes(row.id)
                    ? { top: "-1%", height: "14%" }
                    : null
                }
              >
                <p className={styles.bannerText}>
                  {checkIsConfirmed({
                    day: row.Date,
                    time: row.Time,
                    id: row.Facility.id,
                  })
                    ? cardBannerContent.Booked
                    : cardBannerContent.Selected}
                  <img
                    className={styles.banner_check_icon}
                    src={`/img/info_icons/check_circle.svg`}
                    alt=""
                  />
                </p>
              </div>
            ) : null}

            <ImageCarousel
              sliderImages={
                row.Images.length > 0 ? row.Images : row.Facility.images
              }
              autoSlide={true}
            />
          </div>
          <div className={styles.entertainmentDetails}>
            <div
              className={
                bookingSelections.includes(row.id) ? styles.unselect : null
              }
            >
              <h1
                className={`${styles.title} 
              ${styles.entertainmentTitle}`}
              >
                {row.EntertainmentPublished}
              </h1>

              <div className={styles.time}>
                <h1
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {getShowStartsText()}
                </h1>

                {bookingSelections.includes(row.id) ? (
                  <img
                    className={styles.information_icon}
                    src={`/img/info_icons/check_circle_blue.svg`}
                    alt=""
                  />
                ) : null}
              </div>
              <p className={styles.entertainmentDescription}>
                {row.ActDescription}
              </p>
            </div>
            <p
              className={styles.entertainmentDetailsWrapper}
              onClick={(e) => {
                e.stopPropagation();
                toggleDetailsDialogHandler(true, {
                  EntertainmentPublished: row.EntertainmentPublished,
                  ActDescription: row.ActDescription,
                  BreakDescription: row.BreakDescription,
                  Images:
                    row.Images.length > 0 ? row.Images : row.Facility.images,
                  venueName: row.Facility.title,
                  Details: row.Facility.details,
                })
              }}
            >
              {"Read more >"}
            </p>
          </div>
        </article>
      </div>
    </>
  );
};

export default EntertainmentCardMobile;

import React from "react";
import { makeStyles } from "@material-ui/core";

import { getDayOfWeek } from "../../../../../helpingFunctions/utilities";
import moment from "moment";

const DateDisplay = ({ date }) => {
  const classes = useStyles();

  return (
    <p className={classes.DateDisplay}>
      {getDayOfWeek(date)} - {moment(date).format("DD.MM.YYYY")}
    </p>
  );
};

const useStyles = makeStyles({
  DateDisplay: {
    color: "#3b3b3b",
    fontSize: "30px",
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "20px",
  },
});
export default DateDisplay;

import React from "react";

import styles from "./loading_container.module.css";
import style from "../../components/BookingPreviewItem/booking-preview.module.css";

const LoadingContainer = ({ loaderWrapperStyle = {}, new_styles = {} }) => {
  return (
    <div className={styles.loadingStimulator} style={{...loaderWrapperStyle}}>
      <img
        src={"/img/info_icons/loading_.svg"}
        className={style.loader}
        style={{ ...new_styles }}
      />
    </div>
  );
};

export default LoadingContainer;

import React from "react";

import Dropdown from "../../../components/Dropdown/Dropdown";
import SortingLogic from "./SortingLogic";
import { SORT_OPTIONS } from "../../../assets/StaticObjects";

import styles from "./Sorting.module.css";


const Sorting = ({ setData, initialData }) => {
  const { sortSelected, setSortSelected } = SortingLogic(setData, initialData);
  return (
    <section className={styles.Sorting}>
      <Dropdown
        list={SORT_OPTIONS}
        selectedOption={sortSelected}
        setSelectedOption={setSortSelected}
        title={"Sorting"}
      />
    </section>
  );
};

export default Sorting;

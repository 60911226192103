import React, { useEffect, useContext } from "react";

import KioskLogic from "./KioskLogic";
import Button from "../../shared/Button/Button";
import { useHistory } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import styles from "./Kiosk.module.css";

const Kiosk = () => {
  const history = useHistory();
  const { kioskData } = KioskLogic();
  const { setIsKioskRedirect } = useContext(DataContext);

  useEffect(() => {
    let zendeskTryCount = 0;
    const checkForZendeskInterval = setInterval(() => {
      const zendesk = document.getElementById("launcher");
      if (zendesk) {
        zendesk.style.display = "none";
        clearInterval(checkForZendeskInterval);
      }

      if (zendeskTryCount >= 10) {
        clearInterval(checkForZendeskInterval);
      }
      zendeskTryCount++;
    }, 5000);
  }, []);

  return (
    <>
      <div
        className={styles.Kiosk}
        style={{ backgroundImage: `url(${kioskData?.KioskImage?.url})` }}
      >
        <div className={styles.Login__btn}>
          <Button
            txt={"Login Manually"}
            onClick={() => {
              setIsKioskRedirect(true);
              history.push("/");
            }}
            color={"var(--color-primary)"}
            backgroundColor={"var(--color-secondary)"}
            newStyles={{ minHeight: "calc(100vh - 92vh)" }}
          />
        </div>
      </div>
    </>
  );
};

export default Kiosk;

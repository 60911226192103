import { useEffect, useState } from "react";

const WindowWidth = () => {
  let [width, setWidth] = useState(window.innerWidth);
  let [height, setHeight] = useState(window.outerHeight);

  useEffect(() => {
    const handleWidthResize = () => {
      if (window.innerWidth <= 2) {
        return;
      } else {
        setWidth(window?.innerWidth);
      }
    };
    const handleHeightResize = () => {
      if (window?.innerWidth <= 2) {
        return;
      } else {
        setHeight(window?.outerHeight);
      }
    };
    window.addEventListener("resize", () => {
      handleWidthResize();
      handleHeightResize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        handleWidthResize();
        handleHeightResize();
      });
    };
  }, [window.location.href]);

  return { width, height };
};

export default WindowWidth;

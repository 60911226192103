import moment from "moment";
import { useEffect, useState } from "react";

import { DiningTypes } from "../../constants/constants";
import WindowWidth from "../../hooks/WindowWidth";

const DayButtonLogic = (view, length) => {
  const { width } = WindowWidth();
  const [widthStyle, setWidthStyle] = useState({});
  const [isPreparing, setIsPreparing] = useState(true);

  const handleStyle = () => {
    setIsPreparing(true);
    const isDinning = DiningTypes.includes(view);
    const isInRange = width > 1064 && length >= 6;

    if (isDinning && isInRange) {
      setWidthStyle({
        width: "140px",
      });
    } else if (!isDinning && isInRange) {
      const isLarge = width > 1550;

      if (isLarge) {
        setWidthStyle({
          width: "175px",
        });
      } else {
        setWidthStyle({
          width: "150px",
        });
      }
    }
    setIsPreparing(false);
  };

  const getEntWidth = (width, isSelected) => {
    if (isSelected && width > 700 && width <= 1064)
      return { width: "130px", height: "60px" };

    if (width >= 1150) return { width: "150px" };
    else if (width > 1064 && width < 1150) return { width: "130px" };
    else if (width <= 900) return { width: "120px" };

    return {width: "120px"}
  };

  const whatsOnGuideDay = (date) => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
   
    if (new Date(date).getTime() === new Date(currentDate).getTime())
      return "Today";

    return moment(date).format("ddd");
  };

  useEffect(() => {
    handleStyle();
  }, []);

  return { widthStyle, isPreparing, getEntWidth, whatsOnGuideDay };
};

export default DayButtonLogic;

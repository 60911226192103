import { useEffect, useState } from "react";
import { compare } from "../../../helpingFunctions/arrays";

const SortingLogic = (setData, initialData) => {
  const [sortSelected, setSortSelected] = useState({
    attr: "Title",
    type: "asc",
    value: "Title A to Z",
  });

  const sortHandler = (sortSelected) => {
    const sortedData = initialData.map((item) => {
      return {
        ...item,
        objects: item.objects.sort((a, b) => {
          if (sortSelected.attr === "Price") {
            let priceA = parseInt(a[sortSelected.attr]);
            let priceB = parseInt(b[sortSelected.attr]);
            if (isNaN(priceA)) priceA = "";
            if (isNaN(priceB)) priceB = "";
            return compare(priceA, priceB, sortSelected.type);
          }
          return compare(
            a[sortSelected.attr],
            b[sortSelected.attr],
            sortSelected.type
          );
        }),
      };
    });

    setData(sortedData);
  };

  useEffect(() => {
    if (initialData.length > 0) sortHandler(sortSelected);
  }, [sortSelected]);
  return { sortSelected, setSortSelected };
};

export default SortingLogic;

import React, { useContext } from "react";
import InformationPage from "../../components/InformationPage/InformationPage";
import Heading from "../../shared/Heading/Heading";
import InformationText from "../../shared/InformationText/InformationText";
import Button from "../../shared/Button/Button";
import WindowWidth from "../../hooks/WindowWidth";

import styles from "./Golf.module.css";
import { DataContext } from "../../context/DataContext";

const Golf = ({ setView }) => {
  const { golfPage } = useContext(DataContext);
  const { width } = WindowWidth();
  const isInMobile = width <= 600;

  return (
    <>
      <InformationPage
        image={golfPage.Image?.formats?.large?.url}
        backdropFilter={"blur(5px)"}
        setView={setView}
      >
        {golfPage.Title ? (
          <>
            <Heading
              txt={golfPage.Title}
              fontSize={"40px"}
              fontFamily="Georgia"
              textTransform="uppercase"
              new_styles={{
                color: "var(--color-secondary)",
              }}
            />
            {golfPage.Description &&
              <InformationText
                content={golfPage.Description}
                markdown
                new_styles={{
                  fontSize: "18px",
                  color: "var(--color-secondary)",
                  marginTop: "40px",
                  whiteSpace: "pre-wrap",
                }}
              />
            }
            <div className={styles.Golf__buttons}>
              {golfPage.Link && golfPage.ButtonText ? (
                <Button
                  txt={golfPage.ButtonText}
                  href={golfPage.Link}
                  new_styles={{
                    backgroundColor: "var(--color-primary)",
                    marginRight: "50px",
                    color: "var(--color-secondary)",
                    paddingBlock: "8px",
                  }}
                />
              ) : null}
              {isInMobile ? (
                <Button
                  txt={"Go back"}
                  onClick={setView.bind(this, {})}
                  new_styles={{
                    width: "fit-content",
                    backgroundColor: "var(--color-secondary)",
                    color: "var(--color-primary)",
                    border: "none"
                  }}
                />
              ) : null}
            </div>
          </>
        ) : (
          <></>
        )}
      </InformationPage>
      {isInMobile ? null : (
        <Button
          txt={"Go back"}
          onClick={setView.bind(this, {})}
          new_styles={{
            width: "fit-content",
            height: "fit-content",
            backgroundColor: "var(--color-secondary)",
            color: "var(--color-primary)",
            position: "absolute",
            left: "25px",
            top: "10px",
          }}
        />
      )}
    </>
  );
};

export default Golf;

import React, { useContext } from "react";

import {
  DiningTypes,
  NavBarDropDownTiles,
} from "../../../../constants/constants";
import { DataContext } from "../../../../context/DataContext";

const ConditionalDisplayItems = ["Room Account"]

const NavBarDropDown = ({
  navItemType,
  view,
  setView,
  dropDownItems = [],
  setCrossBurgerDiv,
  showDiningDropdown,
  setShowDiningDropdown,
}) => {
  const { reservation } = useContext(DataContext);
  const SelfServiceTypes = [reservation?.CreditCard ? "View your registered card" : "Register your payment card", "Room Account"];

  if (!NavBarDropDownTiles.includes(navItemType)) {
    return null;
  }

  const isInStay =
    new Date().toISOString().split("T")[0] >=
    reservation.ArrivalDate.split("T")[0];

  if(!isInStay && navItemType === "Self-service") {
    dropDownItems = SelfServiceTypes.filter((item) => !ConditionalDisplayItems.includes(item))
  }  
  const isOn = (type) => view === type;

  const changeView = (newView) => {
    setShowDiningDropdown(false);
    setView(newView);
    setCrossBurgerDiv(false);
  };

  return (
    <div
      style={{
        background: "#f2f2f4",
        height: !showDiningDropdown ? "0" : "fit-content",
        minWidth: "190px",
        position: "absolute",
        left: DiningTypes.includes(view) ? "23.5%" : "0",
        padding: !showDiningDropdown ? "0" : "10px 0",
        fontSize: "16px",
        transition: "all 0.5s ease-in",
        marginTop: "10px",
        opacity: !showDiningDropdown ? "0" : "1",
        zIndex: !showDiningDropdown ? "-2" : "222",
      }}
    >
      {dropDownItems.map((item) => (
        <p
          key={JSON.stringify(item)}
          style={{
            padding: "5px 15px",
            color: isOn(item) ? "var(--color-secondary)" : "var(--color-primary)",
            backgroundColor: isOn(item) ? "var(--color-primary)" : "#f2f2f4",
            position: "relative",
            height: !showDiningDropdown ? "0" : "33px",
            transition: "all 0.5s ease-in",
            opacity: !showDiningDropdown ? "0" : "1",
          }}
          onClick={() =>
            changeView({
              type: DiningTypes.includes(item) ? item : view,
              modalPage: item,
            })
          }
        >
          {item}
        </p>
      ))}
    </div>
  );
};

export default NavBarDropDown;

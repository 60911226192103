import React, { useContext } from "react";
import Markdown from "markdown-to-jsx";

import Heading from "../../shared/Heading/Heading";
import InformationText from "../../shared/InformationText/InformationText";

import styles from "./GiftCards.module.css";
import { DataContext } from "../../context/DataContext";
import Button from "../../shared/Button/Button";

const GiftCards = () => {

  const { giftCardsPage } = useContext(DataContext);

  const giftCardClass = giftCardsPage.GiftCardOptions?.length === 1
    ? "giftCardSingle"
    : "giftCard"
  
  return (
    <>
      <div className={styles.giftCardsWrapper}>
        <div className={styles.Headline}>
          {giftCardsPage.PageTitle &&
            <Heading
              txt={giftCardsPage.PageTitle}
              paddingTop={"40px"}
              paddingBottom={"20px"}
              fontSize={"24px"}
              fontFamily="Georgia"
              textTransform="uppercase"
            />
          }
          {giftCardsPage.PageDescription &&
            <InformationText
              content={
                <Markdown>
                  {giftCardsPage.PageDescription}
                </Markdown>
              }
              lineHeight={"1.5rem"}
            />
          }
        </div>
        <section className={styles[`${giftCardClass}Container`]}>
          {giftCardsPage.GiftCardOptions?.map(card => (
            <div 
              className={styles[giftCardClass]}
              key={card.URL}
            >
              <div 
                className={styles[`${giftCardClass}Image`]}
                style={{
                  backgroundImage: `url(${card.Image?.formats?.medium?.url || card.Image?.url})`,
                }}
              ></div>
              <div className={styles[`${giftCardClass}Content`]}>
                <Heading
                  txt={card.Title}
                  paddingTop={"0px"}
                  paddingBottom={"20px"}
                  fontSize={"24px"}
                  fontFamily="Georgia"
                  textTransform="uppercase"
                />
                {card.ShortDescription &&
                  <Markdown>
                    {card.ShortDescription}
                  </Markdown>
                }
                {card.ButtonText && card.URL &&
                  <div style={{ marginTop: "40px" }}>
                    <Button
                      txt={card.ButtonText}
                      backgroundColor={"var(--color-primary)"}
                      color={"var(--color-secondary)"}
                      minHeight="50px"
                      href={card.URL}
                      newStyles={{
                        paddingBlock: "10px",
                      }}
                    />
                  </div>
                }
              </div>
            </div>
          ))}
        </section>
      </div>
    </>
  );
};

export default GiftCards;

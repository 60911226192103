import React from "react";
import Heading from "../../shared/Heading/Heading";
import InformationText from "../../shared/InformationText/InformationText";
import HelpItemLogic from "./HelpItemLogic";

import styles from "./help_item.module.css";

const HelpItem = ({
  question,
  answer,
  backgroundColor,
  whiteTitle,
  button,
}) => {
  const { isClicked, itemClickHandler } = HelpItemLogic();

  return (
    <article
      className={`${styles.helpItem} ${
        whiteTitle ? styles.helpItem_whiteTitle : styles.helpItem_purpleTitle
      } ${isClicked ? styles.helpItem_expand : null}`}
      style={{ backgroundColor }}
      onClick={!whiteTitle ? itemClickHandler : null}
    >
      <Heading
        txt={question}
        fontSize={"20px"}
        paddingBottom={"15px"}
      />
      <InformationText content={answer} />
      {button}
      {!whiteTitle ? (
        <img
          className={styles.helpItem__icon}
          src={"/img/drop-down-arrow_1.svg"}
          alt={""}
        />
      ) : null}
    </article>
  );
};

export default HelpItem;

import React from "react";
import SelectBox from "../../../shared/SelectBox/SelectBox";
import WindowWidth from "../../../hooks/WindowWidth";
import styles from "./TreatsSelectionForm.module.css";

const TreatsSelectionFormRow = ({
  name,
  label,
  setFormData,
  content,
  selectedItem,
  selectionBoxNewStyles,
}) => {
  const { width } = WindowWidth();
  return (
    <>
      <div
        className={styles.TreatsFormSectionWrapper}
        style={{
          flexDirection: width < 450 ? "column" : "row",
          gap: "15px",
          alignItems: width < 450 ? "flex-start" : "center",
        }}
      >
        <span>
          <strong>{label}</strong>
        </span>
        <div
          style={{
            width: "100px",
            border: "3px solid var(--color-primary)",
            ...selectionBoxNewStyles,
          }}
        >
          <SelectBox
            height={"42px"}
            content={content}
            name={name}
            icon={"drop-down-arrow_1.svg"}
            setFormData={setFormData}
            selectedItem={selectedItem}
          />
        </div>
      </div>
    </>
  );
};

export default TreatsSelectionFormRow;

import React, { useContext, useEffect, useState } from "react";
import { DiningTypes } from "../../constants/constants";
import { DataContext } from "../../context/DataContext";
import ImageCarousel from "../../shared/ImageCarousel/ImageCarousel";
import PromotionalBanner from "../../views/PromotionalPage/PromotionalBanner";

import styles from "./booking-preview-image.module.css";

const BookingPreviewImage = ({ facilityGUID, view, setIsImageFormatSupported }) => {
  const { facilitiesData, entertainmentData, diningProcessingScreenBannerTexts } = useContext(DataContext);

  const [images, setImages] = useState([]);

  const findFacilitiyImages = () => {
    if (!facilityGUID) return;
    if (view === "Entertainment") {
      const { Facility } = entertainmentData.find(
        (id) => id.Facility.id === facilityGUID
      );
      setImages(Facility.images);
    } else {
      const { Image } = facilitiesData.find(
        ({ id, objType }) =>
          id === facilityGUID && objType.toLowerCase() === view.toLowerCase()
      );

      setImages(Image);
    }

  };

  const removeUnsupportedImages = (images) => {
    if (setIsImageFormatSupported) {
      const newImages = images.filter(sliderImage => {
        if (sliderImage.image) {
          return sliderImage.image.formats?.small;
        }
        
        return sliderImage.formats?.small;
      });

      const isSupported = newImages.length !== 0;
      setIsImageFormatSupported(isSupported);
    }
  }

  useEffect(() => {
    if (images.length === 0) {
      findFacilitiyImages();
    } else {
      removeUnsupportedImages(images);
    }
  }, [images]);


  return (
    <div className={styles.imageContainer}>
      {DiningTypes.includes(view) && diningProcessingScreenBannerTexts.length > 0 ? (
        <PromotionalBanner
          content={diningProcessingScreenBannerTexts}
          wrapperNewStyles={{
            position: "absolute",
            zIndex: "22",
            width: "400px",
            top: "170px",
            background: "rgba(89,89,89, 0.7)",
            borderRadius: "0",
            right: "26.2px"
          }}
          rotation={5000}
        />
      ) : null}
      {images.length > 0 ? (
        <ImageCarousel
          sliderImages={images}
          onClick={() => {}}
          autoSlide={true}
        />
      ) : null}
    </div>
  );
};

export default BookingPreviewImage;

import React from "react";
import QuantityButton from "../QuantityButton/QuantityButton";
import QuantityBoxLogic from "./QuantityBoxLogic";

import styles from "./quantity_box.module.css";

const commonStyle = {
  fontSize: '1.5rem',
  width: "50px",
  backgroundColor: "var(--color-primary)",
  color: "#fff"
}

const QuantityBox = ({ id, date, limit }) => {
  const { quantity, increment, decrement } = QuantityBoxLogic(id, date, limit);

  return (
    <div className={styles.QuantityBox}>
      <QuantityButton
        content={'-'}
        {...commonStyle}
        handleClick={decrement}
        cursor="pointer"
      />
      <QuantityButton
        content={quantity}
        width={"59px"}
        backgroundColor={"#fff"}
        color={"#6E6E6F"}
      />
      <QuantityButton
        content={'+'}
        {...commonStyle}
        handleClick={increment}
        cursor="pointer"
      />
    </div>
  );
};


export default QuantityBox;

import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { DataContext } from "../../../../context/DataContext";

import axiosInstance from "../../../../url/createAxios";

const BookActivityLogic = (id, setView, internalActivityItineraryHandler) => {
  const history = useHistory();
  const instance = axiosInstance();
  const { selectedDate, reservation, facilitiesData } = useContext(DataContext);

  const findActivityType = () => {
    try {
      const [strapiId] = id.split("_");
      const parsedStrapiId = Number(strapiId);

      const selectedActivity = facilitiesData.find(
        (facility) => facility.id === parsedStrapiId
      );

      if (selectedActivity) {
        return selectedActivity.objType;
      } else {
        return null;
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in findActivityType func in BookActivityLogic.js ${
          error.message
        }`
      );
    }
  };

  const maestroActivityClicked = (guideItem) => {
    const [originalId] = id.split("_");
    history.push("/");

    let viewType = null;
    if (guideItem.ActivityType === "Venue") {
      viewType = "Entertainment";
    } else if (!guideItem.ActivityType) {
      const selectedActivityType = findActivityType();

      if (!selectedActivityType) {
        return;
      }
      viewType = selectedActivityType;
    } else {
      viewType = guideItem.ActivityType;
    }

    setView({
      type: viewType,
      id: parseInt(originalId),
    });
  };

  const manipulateInternalActivity = async (timeRecord) => {
    try {
      const bookingDetails = {
        HotelId: reservation.Hotel.HotelId,
        ReservationNumber: reservation.ReservationNumber,
        CRSNumber: reservation.CRSNumber,
        GuestName: `${reservation.LastName}, ${reservation.FirstName}`,
        LastName: reservation.LastName,
        FirstName: reservation.FirstName,
        Date: selectedDate,
        Time: `${timeRecord.time}:00`,
        ActivityId: timeRecord.activityId.split("_")[0],
        ActivityTitle: timeRecord.Title,
        InternalActivityTimeId: timeRecord.timeId,
        Type: "Internal Activity",
        Quantity: reservation.Adults,
        RoomNumber: reservation.RoomNumber,
        Comments: reservation.Comments,
        IsInternalActivity: true,
      };

      await instance.post(
        "/activity-bookings/add",
        {
          bookingDetails,
        },
        {
          withCredentials: true,
        }
      );
      internalActivityItineraryHandler({ IsInternalActivity: true });
    } catch (error) {
      console.error(
        `${new Date()} Error in internalActivityClicked func inside BookActivityLogic.js file ${
          error.message
        }`
      );
    }
  };

  return {
    maestroActivityClicked,
    manipulateInternalActivity,
    selectedDate,
  };
};

export default BookActivityLogic;

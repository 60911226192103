import React, { useContext, useState, useEffect } from "react";
import moment from "moment";

import BookingPreviewImage from "../../components/BookingPreviewImage/BookingPreviewImage";
import BookingPreviewItem from "../../components/BookingPreviewItem/BookingPreviewItem";
import { SelectionContext } from "../../context/SelectionContext";
import Button from "../Button/Button";
import { DataContext } from "../../context/DataContext";
import AllSelectionsPopUpLogic from "./AllSelectionsPopUpLogic";

import WindowWidth from "../../hooks/WindowWidth";
import styles from "./all-selections-pop-up.module.css";
import style from "../../css/info_box.module.css";
import { groupBy } from "../../helpingFunctions/arrays";
const itineraryInfoText =
  "*A copy of your itinerary will be sent to the email that was registered with your reservation within 24hrs.";

const AllSelectionsPopUp = ({ setView, view, mainObjectId = null }) => {
  const { width } = WindowWidth();
  const { bookingSelections, bookingStatus, editMode } =
    useContext(SelectionContext);
  const groupedSelections = ["Spa", "Entertainment"].includes(view) ? bookingSelections : groupBy(bookingSelections, [
    "day",
    "LastName",
    "ReservationNumber",
    "time",
  ]);
  const { groupData, reservation } = useContext(DataContext);

  const filteredBookingSelections = groupedSelections.filter(
    (row) =>
      row.action !== "none" &&
      row.LastName === reservation.LastName &&
      row.ReservationNumber === reservation.ReservationNumber &&
      row.FirstName === reservation.FirstName
  );

  const {
    changeView,
    closePopUp,
    errorDate,
    handleErrorRedirect,
    constructPopUpMessage,
    btnDetails,
  } = AllSelectionsPopUpLogic(view, setView, mainObjectId);

  const [isImageFormatSupported, setIsImageFormatSupported] = useState(true);
  const [listWidth, setListWidth] = useState("658px");

  useEffect(() => {
    if (!isImageFormatSupported) {
      setListWidth("100%");
    }
  }, [isImageFormatSupported]);

  return (
    <div className={style.popUpWrapper}>
      <div className={style.grayOutScreen}></div>
      <div className={styles.bookingDetailsPopUp}>
        <div className={styles.successInfo}>{constructPopUpMessage()}</div>
        <div className={styles.bookingDetailsWrapper}>
          <ul
            className={styles.bookingList}
            style={
              width > 1324
                ? { maxHeight: "400px", overflow: "auto", width: listWidth }
                : null
            }
          >
            {filteredBookingSelections?.map(
              (
                { day, facilityGUID, time, action, timeBooked, facilityBooked, Quantity, quantityBooked, firstName, lastName, FirstName, LastName, isParticipant, participantFirstName, participantLastName,
                  type },
                index
              ) => (
                <BookingPreviewItem
                  key={`${facilityGUID}_${time}_${day}_${index}`}
                  index={index}
                  day={day}
                  view={view}
                  time={time ? time.substr(0, 5) : timeBooked.substr(0, 5)}
                  action={action}
                  facilityGUID={facilityGUID[0]}
                  facilityBooked={facilityBooked}
                  quantity={Quantity ? Quantity : parseInt(reservation.Adults)}
                  quantityBooked={quantityBooked}
                  totalElements={filteredBookingSelections.length}
                  firstName={firstName ? firstName : FirstName}
                  lastName={lastName ? lastName : LastName}
                  isParticipant={isParticipant}
                  participantFirstName={participantFirstName}
                  participantLastName={participantLastName}
                  type={type}
                />
              )
            )}
            {bookingStatus.status === "success" ? (
              <p className={styles.itineraryInfoText}>{itineraryInfoText}</p>
            ) : null}
          </ul>
          {isImageFormatSupported ?
            <BookingPreviewImage
              facilityGUID={filteredBookingSelections[0]?.facilityGUID[0]
                ? filteredBookingSelections[0]?.facilityGUID[0]
                : filteredBookingSelections[0]?.facilityBooked}
              view={view}
              setIsImageFormatSupported={setIsImageFormatSupported}
            />
            : null}
        </div>
        <div className={styles.previewButtons}>
          {bookingStatus.status === "success" ||
            bookingStatus.status === "failure" ? (
            <Button
              txt={"Close"}
              border={"2px solid var(--color-primary)"}
              color={"var(--color-primary)"}
              backgroundColor={"var(--color-secondary)"}
              onClick={closePopUp}
            />
          ) : null}

          {bookingStatus.status === "success" ||
            bookingStatus.status === "failure" ? (
            <>
              <Button
                txt={`${
                  errorDate
                  ? `Go back to ${moment(errorDate).format("dddd")} bookings`
                  : btnDetails.label
                  }`}
                backgroundColor={"var(--color-primary)"}
                color={"var(--color-secondary)"}
                onClick={() =>
                  errorDate
                    ? handleErrorRedirect()
                    : changeView(btnDetails.view)
                }
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AllSelectionsPopUp;

import React from "react";

import styles from "./HorisontalCardsGrid.module.css";

const HorisontalCardsGrid = ({ shouldOpen, new_styles, new_class, children}) => {
  return (
    <section
      className={`${styles.HorisontalCardsGrid} ${styles[new_class]}`}
      style={{
        visibility: shouldOpen ? null : "hidden",
        height: shouldOpen ? "auto" : "0",
        paddingBottom: shouldOpen ? "3rem" : "0",
        ...new_styles
      }}
    >
      {children}
    </section>
  );
};

export default HorisontalCardsGrid;

import React, { useState, useEffect, useContext } from "react";
import styles from "./entertainment-preview.module.css";
import Heading from "../../shared/Heading/Heading";
import EntertainmentCardDesktop from "../EntertainmentCard/EntertainmentCardDesktop/EntertainmentCardDesktop";
import { DataContext } from "../../context/DataContext";
import { getDayOfWeek } from "../../helpingFunctions/utilities";

const EntertainmentPreviewSectionDesktop = ({
  venueDetails,
  venuesArray,
  selections,
  bookingNotSelected,
  toggleDetailsDialogHandler,
  handleSelection,
  retrieveAvailability,
  availabilityCount,
  checkIsConfirmed,
  width,
  emptySideSequence,
  opposingVenueSizeWithoutDefault,
}) => {
  const [modifiedVenueArray, setModifiedVenueArray] = useState([]);
  const { selectedDate } = useContext(DataContext);
  
  const adjustVenuesArray = () => {
    for (let i = 0; i < venuesArray.length; i++) {
      if (venuesArray[i].id === -1) {
        let sequence = 0;
        for (let index = i; i < venuesArray.length; index++) {
          const venue = venuesArray[index];
          if (venue?.id === -1) {
            sequence++;
            if (sequence > 1) venuesArray[index].delete = true;
          } else {
            venuesArray[i].time = venue?.Time.substring(0, 5);
            venuesArray[i].sequence = sequence;
            return;
          }
        }
      } else {
        continue;
      }
    }
  };

  useEffect(() => {
    if (["2022-10-15", "2022-10-16"].includes(selectedDate)) {
      displayAll();
      return;
    }

    const confirmedActsLength = venuesArray.filter((row) => typeof row.id === "number" && row.id !== -1).length;
    const thursdaySaturdaySpecial = (confirmedActsLength > 1 && opposingVenueSizeWithoutDefault >= 0) || (confirmedActsLength >= 0 && opposingVenueSizeWithoutDefault > 1)
    if (["Thursday", "Saturday"].includes(getDayOfWeek(selectedDate)) && thursdaySaturdaySpecial) {
      if (venuesArray.length >= 2 && opposingVenueSizeWithoutDefault <= 1) {
        setModifiedVenueArray(venuesArray.filter((row) => typeof row.id === "number" && row.id !== -1));
      } else if (confirmedActsLength <= 1) {
        setModifiedVenueArray([{id: -1, customMark: true, sequence: opposingVenueSizeWithoutDefault}]);
      } else {
        displayAll();
      }
    } else {
      displayAll();
    }
  }, [selectedDate]);

  const displayAll = () => {
    if (venuesArray.length === 0) {
      for (let i = 0; i < emptySideSequence; i++) {
        venuesArray.push({id: -1})
      }
    }
    
    adjustVenuesArray();
    setModifiedVenueArray(venuesArray.filter((row) => !row.hasOwnProperty("delete")));
  };

  return (
    <section className={styles.selectionAllignment}>
      <div className={styles.allign}>
        <Heading
          txt={venueDetails.title}
          fontSize={"24px"}
          icon={"info-information.svg"}
          details={venueDetails}
          toggleDetailsDialogHandler={toggleDetailsDialogHandler}
          view={"Entertainments"}
        />
      </div>
      {modifiedVenueArray.map((row, i) => {
        return (
          <EntertainmentCardDesktop
            key={`${row.Date}${row.Time}${row.id}`}
            index={i}
            venues={venuesArray}
            row={row}
            bookingSelections={selections}
            bookingNotSelected={bookingNotSelected}
            toggleDetailsDialogHandler={toggleDetailsDialogHandler}
            handleSelection={handleSelection}
            retrieveAvailability={retrieveAvailability}
            availabilityCount={availabilityCount}
            checkIsConfirmed={checkIsConfirmed}
            width={width}
          />
        );
      })}
    </section>
  );
};

export default EntertainmentPreviewSectionDesktop;

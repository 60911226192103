import { useState } from "react";

const AddOnsSharedFunc = () => {
  const [totalPriceToAdd, setTotalPriceToAdd] = useState(0);
  const [totalPriceToRemove, setTotalPriceToRemove] = useState(0);

  const handleAddOnsTotalPriceChanges = (source = []) => {
    try {
      let priceToAdd = 0;
      let priceToRemove = 0;
      Object.entries(source).forEach(([key, value]) => {
        value.forEach((toBook) => {
          if (toBook.status === "toBook") {
            const price = Number(toBook.UnitPrice);
            if (toBook.hasOwnProperty("QuantityBooked")) {
              if (toBook.QuantityBooked > toBook.Quantity) {
                priceToRemove +=
                  (toBook.QuantityBooked - toBook.Quantity) * price;
              } else if (toBook.QuantityBooked < toBook.Quantity) {
                priceToAdd += (toBook.Quantity - toBook.QuantityBooked) * price;
              }
            } else {
              priceToAdd += toBook.Quantity * price;
            }
          }
        });
      });

      setTotalPriceToAdd(priceToAdd.toFixed(2));
      setTotalPriceToRemove(priceToRemove.toFixed(2));
    } catch (e) {
      console.error("Error in price management in dining add ons: ", e);
    }
  };

  const hasSelectedAddOnDining = (source, skipedDays, bookingSelections) => {
    const daysNotSelected = new Set();

    Object.entries(source).forEach(([key, value]) => {
      if (value.length > 0 && !skipedDays.includes(key)) {
        const hasBooking = bookingSelections.find((row) => row.day === key);

        if (!hasBooking) daysNotSelected.add(key);
      }
    });

    return daysNotSelected.size === 0;
  };

  const checkAddOnDiningBiding = (location, itineraryData, selectedDate) => {
    if (!["Lunch", "Dinner"].includes(location)) return false;
    const itinerarySelectedDayData = itineraryData.itineraryDates.find(
      (row) => row.Data === selectedDate
    );

    return itinerarySelectedDayData.Facilities.find(
      (row) => row.Date === selectedDate && row.Type === location
    );
  };

  return {
    totalPriceToRemove,
    totalPriceToAdd,
    setTotalPriceToAdd,
    setTotalPriceToRemove,
    handleAddOnsTotalPriceChanges,
    hasSelectedAddOnDining,
    checkAddOnDiningBiding,
  };
};

export default AddOnsSharedFunc;

import React, { useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { isPlatform } from "@ionic/react";
import queryString from "query-string";

import Heading from "../../shared/Heading/Heading";
import InformationText from "../../shared/InformationText/InformationText";
import WhatsOnGuideLogic from "./WhatsOnGuideLogic";
import MainTitle from "../../components/MainTitle/MainTitle";
import DaysList from "../../components/DaysList/DaysList";
import GrayWrapper from "../../components/UI/GrayWrapper/GrayWrapper";
import GuideItemMobile from "./components/GuideItemMobile/GuideItemMobile";
import InformationPage from "../../components/InformationPage/InformationPage";
import Filter from "./components/Filter/Filter";
import GuideItemDesktop from "./components/GuideItemDesktop/GuideItemDesktop";
import ImageCarousel from "../../shared/ImageCarousel/ImageCarousel";
import GuideTvCarousel from "./components/TV/GuideTvCarousel/GuideTvCarousel";
import Information from "../../components/InformationAlert/Information";
import LoadingContainer from "../../shared/LoadingContainer/LoadingContainer";

import { DataContext } from "../../context/DataContext";
import { home_icon } from "../../assets/SVGIcons";
import { WhatsOnDataContext } from "../../context/WhatsOnDataContext";
import { TV_TITLE, DESKTOP_TITLE, MOBILE_TITLE } from "./constants/constants";

import styles from "./WhatsOnGuide.module.css";

const WhatsOnGuide = ({ view, setView }) => {
  const { reservation, selectedDate } = useContext(DataContext);
  const { comingSoonData, ads, guide, whatsOnGuideAllDayActivities, dates, alert, isLoading } =
    useContext(WhatsOnDataContext);
  const {
    selectedItemId,
    internalActivityItinerary,
    setSelectedItemId,
    internalActivityItineraryHandler,
  } = WhatsOnGuideLogic(view);

  const history = useHistory();
  const { hotel } = useParams();
  const { search } = useLocation();
  const { screenView } = queryString.parse(search);

  const isInMobile = screenView === MOBILE_TITLE || isPlatform(MOBILE_TITLE);
  const isInDesktop = screenView === DESKTOP_TITLE || isPlatform(DESKTOP_TITLE);
  const isInTV = screenView === TV_TITLE;

  let GuideItem = null;
  if (isInMobile) {
    GuideItem = GuideItemMobile;
  } else if (isInDesktop) {
    GuideItem = GuideItemDesktop;
  }

  let AdsDisplay = null;
  if (isInDesktop && ads.length > 0) {
    AdsDisplay = (
      <GrayWrapper
        new_styles={{
          width: "302px",
          paddingLeft: "15px",
          paddingRight: "20px",
          paddingTop: "9px",
          paddingBottom: "14px",
        }}
      >
        <ImageCarousel
          onClick={() => {}}
          sliderImages={ads}
          autoSlide={true}
          view={"WhatsOnGuide"}
        />
      </GrayWrapper>
    );
  }

  let ErrorMessage = null;
  if (alert.isError) {
    ErrorMessage = (
      <Information
        new_styles={{
          width: "80%",
          height: "auto",
          backgroundColor: "var(--color-error)",
          margin: "50px auto",
        }}
        fullyColoredClass={"fullyColoredClass"}
        flexDirection={"row"}
        txt={alert.msg}
        marginLeft={"20px"}
        icon={"exclamation.svg"}
      />
    );
  }

  let PageMainSection = null;

  if (isInTV) {
    PageMainSection = <GuideTvCarousel />;
  } else if (isInMobile || isInDesktop) {
    let ManageableScreen = <></>;

    if (isInMobile || isInDesktop) {
      const hotelName = reservation?.Hotel?.Title || hotel;
      const informationText = `Here you can find out all events happening at the ${hotelName} during your stay.`;

      ManageableScreen = (
        <>
          <InformationText
            content={informationText}
            new_styles={{
              textAlign: "center",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          />
          <div
            className={styles.WhatsOnGuide__Filter_wrapper}
            style={{ justifyContent: isInMobile ? "center" : "end" }}
          >
            <Filter isTimeOfDayFiterShown={selectedDate !== null} />
          </div>
          <DaysList data={dates} setView={setView} view={"WhatsOnGuide"} />
        </>
      );
    }

    PageMainSection = (
      <>
        <span className={styles.WhatsOnGuide__dynamicContent}>
          {ManageableScreen}
          <section className={styles.WhatsOnGuide__articles}>
            <GrayWrapper
              new_class={
                isInDesktop
                  ? "WhatsOnGuideDesktop"
                  : isInMobile
                  ? "WhatsOnGuideMobile"
                  : ""
              }
              new_styles={{
                minWidth: "80%",
                flex: 1,
                minHeight: "50vh",
              }}
            >
              {isLoading ? (
                <LoadingContainer
                  new_styles={{
                    width: "120px",
                    height: "120px",
                    marginTop: "120px",
                  }}
                />
              ) : (
                <>
                  {ErrorMessage}
                  { selectedDate && guide && guide.map((guideItem, index) => (
                    <GuideItem
                      key={guideItem.timeId}
                      guideItem={guideItem}
                      guide={guide}
                      itemIndex={index}
                      selectedItemId={selectedItemId}
                      setSelectedItemId={setSelectedItemId}
                      setView={setView}
                      internalActivityItinerary={internalActivityItinerary}
                      internalActivityItineraryHandler={
                        internalActivityItineraryHandler
                      }
                    />
                  ))}
                  {selectedDate === null && whatsOnGuideAllDayActivities && whatsOnGuideAllDayActivities.map((allDayActivity, index) => (
                    <React.Fragment key={index}>
                      <GuideItem
                        key={1}
                        guideItem={allDayActivity}
                        guide={guide}
                        itemIndex={index}
                        selectedItemId={selectedItemId}
                        setSelectedItemId={setSelectedItemId}
                        setView={setView}
                        internalActivityItinerary={internalActivityItinerary}
                        internalActivityItineraryHandler={
                          internalActivityItineraryHandler
                        }
                      />
                    </React.Fragment>
                  ))}
                </>
              )}
            </GrayWrapper>
            {AdsDisplay}
          </section>
        </span>
      </>
    );
  }

  if (Object.keys(comingSoonData).length > 0) {
    return (
      <InformationPage
        image={comingSoonData.ComingSoonImage?.formats?.large?.url}
        backdropFilter={"blur(25px)"}
        enableHomeButton={reservation}
        setView={setView}
      >
        <Heading
          txt={comingSoonData.ComingSoonText}
          fontSize={"40px"}
          new_styles={{
            color: "#fff",
          }}
        />
        <InformationText
          content={comingSoonData.ComingSoonDescription}
          new_styles={{
            fontSize: "20px",
            color: "#fff",
            marginTop: "40px",
            textAlign: "center",
            whiteSpace: "pre-wrap",
          }}
        />
      </InformationPage>
    );
  }

  let HomePageButton = null;
  if (view?.type && (isInDesktop || isInMobile)) {
    HomePageButton = (
      <div
        onClick={() => setView({ type: "" })}
        style={{ position: "absolute", left: "20px", top: "5px" }}
      >
        {home_icon("#fff")}
      </div>
    );
  }

  return (
    <>
      <MainTitle
        height={"50px"}
        logoHeight={"40px"}
        logoWidth={"300px"}
        cursor={"pointer"}
        setView={() => history.go(0)}
      />
      {HomePageButton}
      <div className={styles.WhatsOnGuide}>
        <Heading
          txt={"What's on?"}
          fontFamily="Georgia"
          textTransform="uppercase"
          new_styles={{
            paddingTop: "15px",
            paddingBottom: "15px",
            fontSize: isInTV ? "40px" : "24px",
            width: "100%",
            textAlign: "center",
            color: "#3B3B3B",
          }}
        />
        {PageMainSection}
      </div>
    </>
  );
};

export default WhatsOnGuide;

import React, { useContext } from "react";
import { GROUP_LABELS } from "../../constants/constants";

import { DataContext } from "../../context/DataContext";
import { calcTotalGuests } from "../../helpingFunctions/groups";

import styles from "./GroupBanner.module.css";

const GroupBanner = () => {
  const { groupData } = useContext(DataContext);
  return (
    <div className={styles.GroupBanner}>
      <img
        src="/img/info_icons/info.svg"
        width={"25px"}
        height={"25px"}
        className={styles.GroupBanner__icon}
        alt="no img"
      />
      <h3>
        You are booking for '{groupData.groupName}'. A{" "}
        {GROUP_LABELS.GroupRef.lowerCase} of {calcTotalGuests(groupData.items)}{" "}
        people.
      </h3>
    </div>
  );
};

export default GroupBanner;

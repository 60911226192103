import React, { useContext, useEffect, useState } from "react";
import { SelectionContext } from "../../../context/SelectionContext";
import WindowWidth from "../../../hooks/WindowWidth";

import { getDayOfWeek } from "../../../helpingFunctions/utilities";
import styles from "./TreatsProcessingPopupBox.module.css";

const TreatsProcessingPopupRow = ({
  Date,
  index,
  Quantity,
  title,
  id,
  action,
  ServiceCode,
  Location,
  QuantityBooked,
}) => {
  const { width } = WindowWidth();
  const { bookingStatus, setBookingStatus } = useContext(SelectionContext);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const markCancelled = action === "remove";
  const selectionQtn = markCancelled ? QuantityBooked : Quantity;

  const getTreatLocation = () => {
    return Location === "Treats"
      ? "In-room"
      : Location === "Lunch"
      ? "Lunch at Restaurant"
      : "Dinner at Restaurant";
  };

  const manageLoader = (index) => {
    setTimeout(() => {
      setLoading(false);

      const failureStatus = bookingStatus.results.find(
        (row) => row.Date === Date && row.ServiceCode === ServiceCode
      );

      setStatus(!failureStatus ? "failure" : failureStatus.Status);
      setBookingStatus({
        status: !failureStatus ? "failure" : failureStatus.Status,
      });
    }, index * 1000);
  };

  useEffect(() => {
    if (bookingStatus.status === "after-response") {
      manageLoader(index);
    }
  }, [bookingStatus]);

  return (
    <>
      <div className={styles.bookingPreviewItem}>
        <div style={{ backgroundColor: "var(--color-primary)" }}>
          {action === "remove" ? <div className={styles.cancelLine} /> : null}
          <div style={{ display: "flex", alignItems: "center" }}>
            <li className={styles.singleBooking}>
              <>
                {width <= 700 ? (
                  <>
                    <h3>{getDayOfWeek(Date)}</h3>
                    <div style={{ width: "100%" }}>
                      <h3 style={{ width: "100%" }}>
                        {markCancelled ? (
                          <span style={{ fontWeight: "bold", width: "100%" }}>
                            {"Cancelling: "}
                          </span>
                        ) : null}
                        {`${selectionQtn} x ${title} - ${getTreatLocation()}`}
                      </h3>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <h3>{getDayOfWeek(Date)}</h3>
                    <div style={{ width: "100%" }}>
                      <h3 style={{ width: "100%" }}>
                        {markCancelled ? (
                          <span style={{ fontWeight: "bold", width: "100%" }}>
                            {"Cancelling: "}
                          </span>
                        ) : null}
                        {`${selectionQtn} x ${title} - ${getTreatLocation()}`}
                      </h3>
                    </div>
                  </>
                )}
              </>
            </li>
          </div>
        </div>

        {loading ? (
          <img
            className={styles.loader}
            src={"/img/info_icons/loading_.svg"}
            alt={"no img"}
          />
        ) : (
          <img
            className={styles.progressIndicator}
            src={`${
              status === "success"
                ? "/img/info_icons/done_.svg"
                : "/img/info_icons/error_.svg"
            }`}
            alt={"no img"}
          />
        )}
      </div>
    </>
  );
};

export default TreatsProcessingPopupRow;

import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import ValidateLogInHook from "../../hooks/ValidateLoginInHook";
import { ROUTES } from "../../routes/routeConstants";

const RedirectToLandingPageLogic = () => {
  const history = useHistory();
  const { handleLogIn } = ValidateLogInHook();
  const { maestroId, bookingId, surname } = useParams();

  const handleRequest = async () => {
    const loginData = { maestroId, bookingId, surname };
    const res = await handleLogIn(loginData);

    if (res.success) {
      history.push(ROUTES.Home);
      return;
    }

    history.push(`/`);
  };

  useEffect(() => {
    handleRequest();
  }, []);
};

export default RedirectToLandingPageLogic;

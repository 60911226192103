import { useContext } from "react";

import { DataContext } from "../../context/DataContext";

const ItineraryCardLogic = () => {
  const {
    facilitiesData,
    entertainmentData,
    entertainmentStaticContent,
  } = useContext(DataContext);

  const getBookedDetails = (
    facilityId,
    Type,
    treatsLocation,
    actStrapiId
  ) => {
    let bookedFacilityDetails;

    if (Type === "Spa") {
      bookedFacilityDetails = facilitiesData.find(
        (row) => row.id === facilityId && row.objType === Type
      );
    } else {
      bookedFacilityDetails = facilitiesData.find(
        (row) => row.id === facilityId
      );
    }
    
    if (Type === "Venue") {
      const entDetails = entertainmentData.find(
        (row) => row.id === actStrapiId
      );

      bookedFacilityDetails.entTitle = entDetails?.EntertainmentPublished
        ? entDetails.EntertainmentPublished
        : entertainmentStaticContent?.DefaultCardTitle;
    }

    return JSON.parse(JSON.stringify(bookedFacilityDetails));
  };

  const getSummaryValue = (data, key) => {
    let counts = {};
    data.summary[key].forEach((x) => {
      counts[x] = (counts[x] || 0) + 1;
    });
    const preparedString = [];
    Object.entries(counts).forEach(([key, value]) => {
      if (value === 1) {
        preparedString.push(key);
      } else {
        preparedString.push(`${value} x ${key}`);
      }
    });
    return preparedString.join(", ");
  };

  return {
    getBookedDetails,
    getSummaryValue,
  };
};

export default ItineraryCardLogic;

import React from "react";
import Markdown from "markdown-to-jsx";

import ActivityCardDesktop from "../../../components/ActivityCard/ActivityCardDesktop/ActivityCardDesktop";
import Heading from "../../../shared/Heading/Heading";
import InformationText from "../../../shared/InformationText/InformationText";
import Button from "../../../shared/Button/Button";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import { goToTheTop } from "../../../helpingFunctions/linkChange";
import CategoriesLogic from "../../../shared/Categories/CategoriesLogic";
import CategoryBanner from "../../../shared/Categories/CategoryBanner/CategoryBanner";
import SpaPresentation from "../../../shared/Categories/SpaPresentation/SpaPresentation";
import HorisontalCardsGrid from "../../../shared/Categories/HorisontalCardsGrid/HorisontalCardsGrid";
import ImageCard from "../../../components/ImageCard/ImageCard";
import DetailsBox from "../../../shared/Categories/DetailsBox/DetailsBox";
import Sorting from "../../../shared/Categories/Sorting/Sorting";

import styles from "./SpaDesktop.module.css";

const SpasDesktop = ({ setView, showType }) => {
  const {
    isLoading,
    pageTextData,
    selectedCategory,
    clickedObject,
    gridSettings,
    originalData,
    manipulatedData,
    categoryBannersRef,
    reservation,
    setManipulatedData,
    categorySelectorHandler,
    handleCardClick,
    updateStateAfterSort,
  } = CategoriesLogic(showType);

  return (
    <>
      <div className={styles.activitiesWrapper}>
        {/* <Filters data={originalData} setData={setManipulatedData} /> */}
        {/* <Sorting initialData={originalData} setData={updateStateAfterSort} /> */}
        <div className={styles.activitiesHeadline}>
          <Heading
            txt={reservation.Hotel?.SpaDesktopTitle}
            paddingTop={"40px"}
            paddingBottom={"20px"}
            fontSize={"24px"}
            fontFamily="Georgia"
            textTransform="uppercase"
          />
          <div className={styles.information}>
            <InformationText
              content={
                pageTextData?.DesktopDescription_max_length_is_340 ? (
                  <Markdown>
                    {pageTextData?.DesktopDescription_max_length_is_340}
                  </Markdown>
                ) : null
              }
              new_styles={{ paddingBottom: "20px", lineHeight: "1.5rem", paddingRight: "20px", width: "100%" }}
            />
           <Sorting initialData={originalData} setData={updateStateAfterSort} />
          </div>
          {isLoading ? (
            <LoadingContainer />
          ) : (
            <>
              <SpaPresentation />
              <HorisontalCardsGrid
                shouldOpen={true}
                new_styles={{
                  gridRowGap: "85px",
                }}
              >
                {manipulatedData.map(
                  ({
                    ServiceTypeDescription,
                    CategoryImagePortrait,
                    ServiceTypeCode,
                  }) => (
                    <>
                      {CategoryImagePortrait ? (
                        <ImageCard
                          key={`ImageCard_${ServiceTypeCode}`}
                          img={CategoryImagePortrait}
                          handleClick={categorySelectorHandler.bind(
                            this,
                            ServiceTypeCode
                          )}
                        >
                          <Heading
                            key={`Heading_${ServiceTypeCode}`}
                            txt={ServiceTypeDescription}
                            new_styles={{ color: "#fff", fontSize: "1.4rem" }}
                          />
                        </ImageCard>
                      ) : null}
                    </>
                  )
                )}
              </HorisontalCardsGrid>
              {manipulatedData?.map(
                (
                  {
                    ServiceTypeDescription,
                    CategoryImageLandscape,
                    ServiceTypeCode,
                    objects,
                  },
                  index
                ) => (
                  <>
                    {CategoryImageLandscape ? (
                      <CategoryBanner
                        key={`CategoryBanner_${ServiceTypeCode}`}
                        handleClick={categorySelectorHandler.bind(
                          this,
                          ServiceTypeCode
                        )}
                        isSelected={selectedCategory === ServiceTypeCode}
                        buttonClick={goToTheTop}
                        categoryBannersRef={categoryBannersRef}
                        additionalData={{
                          ServiceTypeCode,
                          index,
                          image: CategoryImageLandscape,
                          content: ServiceTypeDescription,
                        }}
                      />
                    ) : null}
                    <HorisontalCardsGrid
                      shouldOpen={
                        selectedCategory === ServiceTypeCode ||
                        !CategoryImageLandscape
                      }
                      new_styles={{
                        gridRowGap:
                          clickedObject &&
                          clickedObject.ServiceTypeCode === ServiceTypeCode
                            ? null
                            : "85px",
                      }}
                    >
                      {objects?.map((object, index) => (
                        <ActivityCardDesktop
                          key={object.id}
                          {...object}
                          onClick={handleCardClick.bind(this, object, index)}
                          isSelected={clickedObject?.id === object.id}
                        />
                      ))}
                      {clickedObject &&
                      clickedObject.ServiceTypeCode === ServiceTypeCode ? (
                        <DetailsBox
                          dimensionClass={"DetailsBoxDesktop"}
                          gridSettings={gridSettings}
                          clickedObject={clickedObject}
                          unselectCard={handleCardClick.bind(
                            this,
                            clickedObject
                          )}
                          previewFunctionality={setView.bind(this, {
                            type: clickedObject.objType,
                            id: clickedObject.id,
                          })}
                        />
                      ) : null}
                    </HorisontalCardsGrid>
                  </>
                )
              )}
            </>
          )}
        </div>
        <div className={styles.acitivityFooter}>
          <Button
            txt={"Back to the top"}
            backgroundColor={"var(--color-secondary)"}
            color={"var(--color-primary)"}
            border={"2px solid var(--color-primary)"}
            onClick={goToTheTop}
          />
        </div>
      </div>
    </>
  );
};

export default SpasDesktop;

import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { SavedPreSelectionsContext } from "../../../context/SavedPreSelections";
import { SelectionContext } from "../../../context/SelectionContext";
import { isValidDate } from "../../../helpingFunctions/bookingHelp";
import axiosInstance from "../../../url/createAxios";

const SpaPreviewLogic = (
  id,
  selectedDate,
  setAvailableTimes,
  setLoading,
  spaLoading,
  setSpaLoading,
  view,
  setSpaAvailabilityForMobile
) => {
  const instance = axiosInstance();
  const { bookingSelections } = useContext(SelectionContext);
  const { reservation, editMode, itineraryData } = useContext(DataContext);
  const { manage_Activity_Spa_Selections } = useContext(
    SavedPreSelectionsContext
  );

  const [spaAvailability, setSpaAvailability] = useState([]);

  const findSpaAvailibility = async (id) => {
    try {
      const arrivalDate = new Date(reservation.ArrivalDate);
      const nights = reservation.Nights;

      const spaLoading = {};
      for (let i = 0; i <= nights; i++) {
        const searchedDate =
          i === 0
            ? arrivalDate.toISOString().split("T")[0]
            : new Date(arrivalDate.setDate(arrivalDate.getDate() + 1))
                .toISOString()
                .split("T")[0];

        spaLoading[searchedDate] = { isLoading: true };
      }
      setSpaLoading(spaLoading);

      const constructed = [];
      for (const date of Object.keys(spaLoading)) {
        const { data } = await instance.get(
          `/Hotel/Booking/Spa/Availability?service=${id}&searched_date=${date}`,
          { withCredentials: true }
        );

        if (data.msg === "failed" || data[date].length === 0) {
          spaLoading[date] = { isLoading: false };
          setSpaLoading(spaLoading);
          constructed.push({
            Times: [],
            Date: date,
          });
          setSpaAvailability((prevState) => {
            return [
              ...prevState,
              {
                Times: [],
                Date: date,
              },
            ];
          });
          continue;
        }

        const spaTime = { Data: date, ...data[date] };
        const TimesList = [];

        const objects = Object.keys(spaTime)
          .map((_, index) => {
            const { ProviderCode, ServiceDate } = spaTime[`${index}`] || {};
            const Time = ServiceDate?.split("T")[1];

            if (Time) {
              const isValidTime = isValidDate(spaTime.Data, Time)

              if(isValidTime) {
                TimesList.push({
                  Time,
                  Availability: ProviderCode.length,
                  ProviderCode,
                });
              }
              if (index === Object.keys(spaTime).length - 2) {
                return {
                  Times: [...TimesList],
                  Date: spaTime.Data,
                };
              }
            }
          })
          .filter((item) => item);

        manage_Activity_Spa_Selections(
          bookingSelections,
          date,
          objects[0],
          itineraryData,
          "Spa", id
        );

        constructed.push(objects[0]);

        setSpaAvailability((prevState) => [...prevState, objects[0]]);

        spaLoading[date] = { isLoading: false };
        setSpaLoading(spaLoading);
      }

      return constructed;
    } catch (e) {
      console.error("error: ", e);
    }
  };


  useEffect(() => {
    if (view !== "Spa" || !itineraryData) return;
    findSpaAvailibility(id).then((spaAvailability) => {
      setSpaAvailabilityForMobile(spaAvailability);
    });
  }, [itineraryData]);

  return { findSpaAvailibility, spaAvailability };
};

export default SpaPreviewLogic;

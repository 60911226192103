import { useEffect, useContext, useState } from "react";
import { findOneFromObjList } from "../../helpingFunctions/arrays";
import { DataContext } from "../../context/DataContext";
import axiosInstance from "../../url/createAxios";

const BookingFolioLogic = () => {
  const instance = axiosInstance();
  const [folioDetails, setFolioDetails] = useState(null);
  const [pageData, setPageData] = useState(null);
  const { pagesData } = useContext(DataContext);
  
  const fetchData = async () => {
    const { data } = await instance.get("/Hotel/Booking/Folio", {withCredentials: true});

    setFolioDetails(data);
  };

  const getPageData = async () => {
    try {
      const data = findOneFromObjList(pagesData, "Type", "Folio");

      setPageData(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
    getPageData();
  }, []);

  return { folioDetails, pageData };
};

export default BookingFolioLogic;

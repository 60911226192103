import React, { useContext } from "react";
import moment from "moment";

import ItineraryLogic from "./ItineraryLogic";
import Heading from "../../shared/Heading/Heading";
import ItineraryCardMobile from "./ItineraryCardMobile";
import ItineraryCardDesktop from "./ItineraryCardDesktop";
import WindowWidth from "../../hooks/WindowWidth";
import Button from "../../shared/Button/Button";
import { DataContext } from "../../context/DataContext";
import { goToPage } from "../../helpingFunctions/linkChange";
import { isReservation2WeeksPriorArrDate } from "../../helpingFunctions/utilities";
import { calculteDateDifference } from "../../helpingFunctions/utilities";

import styles from "./itinerary.module.css";

const ItineraryNew = ({ setView }) => {
  const { reservation, itineraryData } = useContext(DataContext);
  const {
    handleExpand,
    handleExpandAll,
    expandAll,
    loading,
    preparedData,
    expand,
    dayLineColor,
    promotionalRowSpaceContent,
  } = ItineraryLogic();
  const { width } = WindowWidth();
  const diference = calculteDateDifference(
    new Date(),
    new Date(reservation.ArrivalDate)
  );
  const showCheckinEssentials =
    !!reservation?.Hotel.CheckInEssentials &&
    isReservation2WeeksPriorArrDate(reservation.ArrivalDate);

  return (
    <>
      {loading ? (
        <div style={{ minHeight: "100vh", paddingTop: "20px" }}>Loading...</div>
      ) : (
        <>
          {/* <div
            className={`${styles.itineraryHeadline} ${styles.itineraryContainer}`}
            style={{
              backgroundColor: width > 1324 ? "#FFF" : "#f2f2f4",
            }}
          >
            <Heading
              txt={`Itinerary`}
              fontWeight={"bold"}
              fontSize={"24px"}
              paddingBottom={"20px"}
              paddingTop={"40px"}
              new_styles={{ width: "100%", textAlign: "center" }}
            />
          </div> */}
          <div
            className={`${styles.itinerary}`}
            style={{
              backgroundColor: width > 1324 ? "#FFF" : "#f2f2f4",
              width: width > 1324 ? "80%" : "100%",
              paddingTop: width > 1324 ? "0" : "40px",
            }}
          >
            {width < 1325 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <p style={{ width: "50%", textAlign: "left" }}>
                  <strong>Welcome {reservation?.FirstName}</strong>
                </p>
                <p style={{ width: "50%", textAlign: "right" }}>
                  <strong>Ref: {reservation?.CRSNumber}</strong>
                </p>
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: width > 1324 ? "25px 20px" : "25px 10px",
              }}
            >
              <div
                style={{
                  // paddingTop: "35px",
                  width: "50px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                {diference ? (
                  <>
                    <p style={{ fontSize: "40px" }}>
                      <strong>{diference.allDayDifference}</strong>
                    </p>
                    <p style={{ fontSize: "32px" }}>
                      <strong>Days</strong>
                    </p>
                  </>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  paddingLeft: "15px",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {width > 1324 ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      fontSize: "18px",
                      paddingBottom: "10px",
                    }}
                  >
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <Heading
                        txt={`Welcome ${reservation?.FirstName}`}
                        fontWeight={"bold"}
                        fontSize={"24px"}
                        paddingTop={"10px"}
                        new_styles={{ width: "100%", textAlign: "left" }}
                      />
                    </div>
                    <div style={{ width: "50%", textAlign: "left" }}>
                      <Heading
                        txt={`Ref: ${reservation?.CRSNumber}`}
                        fontWeight={"bold"}
                        fontSize={"24px"}
                        paddingTop={"10px"}
                        new_styles={{ width: "100%", textAlign: "right" }}
                      />
                    </div>
                  </div>
                ) : null}
                <p>{`${diference ? `We look forward to welcoming you to ${
                  reservation.Hotel.Title
                }, on ${moment(new Date(reservation.ArrivalDate)).format(
                  "Do MMMM YYYY"
                )}.` : ""} Please book your dining and book any activities to plan your break to perfection.`}</p>
              </div>
            </div>

            <div
              style={{
                padding:
                  width < 1325 && !showCheckinEssentials ? "0" : "25px 0",
                display: "flex",
              }}
            >
              {width > 1324 ? (
                <div style={{ padding: "25px 0" }}>
                  <Button
                    txt={expandAll ? "Collapse all" : "Expand all"}
                    onClick={() => handleExpandAll()}
                    new_styles={{
                      color: "#fff",
                      backgroundColor: "var(--color-info)",
                      border: "2px solid var(--color-info)",
                      minWidth: "200px",
                    }}
                  />
                </div>
              ) : null}
              {showCheckinEssentials ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    width: "100%",
                    padding: "25px 0"
                  }}
                >
                  <Button
                    txt={"Check-In Essentials"}
                    onClick={() =>
                      goToPage(reservation.Hotel.CheckInEssentials.url)
                    }
                    new_styles={{
                      color: "var(--color-secondary)",
                      backgroundColor: "var(--color-primary)",
                      minWidth: "200px",
                    }}
                  />
                </div>
              ) : null}
            </div>

            <div className={width > 1324 ? styles.contentWrapper : null}>
              {Object.entries(preparedData)?.map(([key, value], index) => (
                <>
                  {width > 1324 ? (
                    <ItineraryCardDesktop
                      key={index}
                      date={key}
                      data={value}
                      row={index + 1}
                      setView={setView}
                      expand={expand}
                      dayLineColor={dayLineColor}
                      handleExpand={handleExpand}
                      promotionalRowSpaceContent={promotionalRowSpaceContent}
                    />
                  ) : (
                    <ItineraryCardMobile
                      key={index}
                      date={key}
                      data={value}
                      row={index + 1}
                      setView={setView}
                      expand={expand}
                      dayLineColor={dayLineColor}
                      handleExpand={handleExpand}
                      promotionalRowSpaceContent={promotionalRowSpaceContent}
                    />
                  )}
                </>
              ))}
            </div>
            {width > 1324 ? (
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  background: "var(--color-secondary)",
                }}
              ></div>
            ) : null}
            <div
              style={{
                width: "100%",
                marginTop: "15px",
                height: "200px",
                display: "flex",
                borderTop: "3px solid #CCC",
                paddingTop: "20px",
              }}
            >
              <div style={{ width: "100%", paddingRight: "25px" }}>
                <Heading
                  txt={"Hotel Address:"}
                  new_styles={{
                    paddingBottom: "10px",
                    fontSize: "20px",
                  }}
                />
                <p>{reservation.Hotel.Title}</p>
                <a
                  href={`https://www.google.com/maps/dir//${itineraryData?.Hotel?.GoogleMapsQuery?.replace(
                    / /g,
                    "+"
                  )}`}
                  target={"_blank"}
                  rel="noreferrer"
                  className={styles.addressLink}
                >
                  {itineraryData?.Hotel?.Address}
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ItineraryNew;

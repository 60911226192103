import React from "react";

import Heading from "../../../../shared/Heading/Heading";
import Logo from "../../../../shared/Logo/Logo";
import { xSign } from "../../../../assets/SVGIcons";

import styles from "./LogoAndTitle.module.css";

const LogoAndTitle = ({removeModal, title}) => {
  return (
    <>
      <div className={styles.LogoAndTitle__imageWrapper}>
        <Logo width={"60%"} height={"55px"} />
        <div
          className={styles.LogoAndTitle__cancel}
          onClick={removeModal}
        >
          {xSign("24px", "var(--color-secondary)", "var(--color-primary)")}
        </div>
      </div>
      <Heading
        txt={title}
        new_styles={{ fontSize: "22px", paddingTop: "35px" }}
      />
    </>
  );
};

export default LogoAndTitle;

import React from "react";

import LogoAndTitle from "../../components/LogoAndTitle/LogoAndTitle";
import Button from "../../../../shared/Button/Button";
import CardConfirmationLogic from "./CardConfirmationLogic";
import InformationText from "../../../../shared/InformationText/InformationText";

import styles from "./CardConfirmation.module.css";

const CardConfirmation = ({ details = {}, removeModal }) => {
  const { pageData } = CardConfirmationLogic();

  const isDayUse = window.location.href.includes("dayexperiences");
  return (
    <>
      <LogoAndTitle
        removeModal={removeModal}
        title={"Registered Credit Card"}
      />
      <div className={styles.CardConfirmation}>
        <ul className={styles.CardConfirmation__items}>
          <li className={styles.CardConfirmation__item}>
            <h4>Date of registration</h4>
            <p>{details.transactionDate}</p>
          </li>
          <li className={styles.CardConfirmation__item}>
            <h4>Description</h4>
            <p>Registration of Credit Card</p>
          </li>
          <li className={styles.CardConfirmation__item}>
            <h4>Card Type</h4>
            <p>{details.cardType}</p>
          </li>
          <li className={styles.CardConfirmation__item}>
            <h4>Card Last 4 Digits</h4>
            <p>{details.cardNums}</p>
          </li>
        </ul>
      </div>
      {!isDayUse && (
        <InformationText
          content={pageData.CC_Confirmation_Text}
          new_styles={{
            marginTop: "25px",
          }}
          markdown
        />
      )}
      <div className={styles.CardConfirmation__button}>
        <Button
          txt={isDayUse ? "Proceed" : "Close"}
          onClick={removeModal}
          new_styles={{ backgroundColor: "var(--color-primary)", color: "var(--color-secondary)" }}
        />
      </div>
    </>
  );
};

export default CardConfirmation;

import { useContext, useEffect, useState } from "react";

import { findOneFromObjList } from "../../helpingFunctions/arrays";
import { getStayingDates } from "../../helpingFunctions/utilities";
import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import RefreshItinerary from "../../shared/RefreshItinerary/RefreshItinerary";
import { assignFirstDate } from "../../helpingFunctions/helpingFunctions";

const EntertainmentsLogic = (showType) => {
  const [pageTextData, setPageTextData] = useState({});
  const [stayDates, setStayDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    availibility,
    pagesData,
    reservation,
    itineraryData,
    selectedDate,
    setSelectedDate,
    entertainmentData,
    setEntertainmentData,
    facilitiesData,
    entertainmentStaticContent,
  } = useContext(DataContext);
  
  const {
    setEditMode,
    setBookingSelections,
    bookingSelections,
    bookingStatus,
    setRefreshEntertainments,
    refreshEntertainments,
    setHasExistingChanges,
  } = useContext(SelectionContext);
  const { resetBookingSelections } = RefreshItinerary(null, showType);

  const getPageData = async () => {
    try {
      const data = findOneFromObjList(pagesData, "Type", showType);

      setPageTextData(data);
    } catch (error) {}
  };

  const findStayDates = async () => {
    const dates = getStayingDates(
      reservation.ArrivalDate,
      reservation.DepartureDate,
      showType
    );

    setStayDates(dates);

    if (selectedDate?.endsWith("I")) {
      setSelectedDate(selectedDate.substr(0, selectedDate.length - 1));
      return;
    } else {
      assignFirstDate(dates, setSelectedDate);
    }
  };

  // const checkIfItineraryExists = () => {
  //   try {
  //     setBookingSelections([]);
  //     setEditMode(null);

  //     itineraryData.itineraryDates.map(({ Data, Entertainment }) => {
  //       Entertainment.map(({ Quantity, Time, Id, Type }) => {
  //         setBookingSelections((prevState) => [
  //           ...prevState,
  //           {
  //             ReservationNumber: reservation.ReservationNumber,
  //             LastName: reservation.LastName,
  //             facilityGUID: [Id],
  //             day: Data,
  //             time: Time,
  //             Quantity,
  //             action: "none",
  //           },
  //         ]);
  //       });
  //     });
  //     setHasExistingChanges(false);
  //     setRefreshEntertainments(false);
  //   } catch (e) {
  //     setRefreshEntertainments(false);
  //     console.log(
  //       `${new Date()} Error in checkIfItineraryExists func ion AcitivityPreviewLogic.js file ${e}`
  //     );
  //   }
  // };

  useEffect(() => {
    if (itineraryData && !bookingStatus) resetBookingSelections();
  }, [itineraryData, bookingStatus]);

  // useEffect(() => {
  //   if (refreshEntertainments) resetBookingSelections();
  // }, [refreshEntertainments]);

  useEffect(() => {
    findStayDates();
    getPageData();
  }, []);

  useEffect(() => {
    if (
      itineraryData &&
      availibility &&
      availibility.length > 0 &&
      entertainmentStaticContent?.hasOwnProperty("DefaultCardTitle")
    ) {
      const venues = facilitiesData.filter((row) => row.objType === "Venue");
      const venuesDetails = {};
      venues.forEach((row) => (venuesDetails[row.id] = row));
      const defaultVenues = [];
      availibility?.forEach((avail) => {
        const entVenues = avail.Facilities.filter(
          (facility) => facility.Type === "Venue"
        );
        entVenues.forEach((venue) => {
          venue.Times.forEach((time) => {
            const existingEnt = entertainmentData.find(
              (data) =>
                data.Date === avail.Date &&
                data.Time.substring(0, 5) === time.Time.substring(0, 5) &&
                data.Facility.id === venue.FacilityGUID
            );
            if (!existingEnt) {
              const venueDetails = venuesDetails[venue.FacilityGUID];
              defaultVenues.push({
                id:
                  Date.now().toString(36) +
                  Math.random().toString(36).substring(2),
                ActID: -1,
                EntertainmentID: -1,
                Facility: {
                  id: venue.FacilityGUID,
                  title: venueDetails.Title,
                  buildingCode: venueDetails.BuildingCode,
                  facilityCode: venueDetails.FacilityCode,
                  facilityTypeCode: venueDetails.FacilityTypeCode,
                  facilityDesc: venueDetails.FacilityDescription,
                  buildDesc: venueDetails.BuildingDescription,
                  longDesc: venueDetails.LongDescription,
                  images: venueDetails.Image,
                  details: venueDetails.Details,
                },
                Date: avail.Date,
                Time: time.Time.substring(0, 5),
                BreakStyle: "",
                BreakDescription: "",
                EntertainmentPublished:
                  entertainmentStaticContent?.DefaultCardTitle,
                ActDescription: "",
                Status: false,
                MainAct: false,
                Images: [],
              });
            }
          });
        });
      });
      const entertainments = [...entertainmentData, ...defaultVenues];
      entertainments.sort((a, b) => {
        return a.Time.localeCompare(b.Time);
      });
      setEntertainmentData(entertainments);
    }

    if (
      itineraryData && availibility &&
      entertainmentStaticContent?.hasOwnProperty("DefaultCardTitle")
    ) {
      setIsLoading(false);
    }
  }, [itineraryData, availibility, entertainmentStaticContent]);

  return { isLoading, pageTextData, stayDates, entertainmentStaticContent };
};

export default EntertainmentsLogic;

import React, { useContext } from "react";
import Markdown from "markdown-to-jsx";

import DaysList from "../../../components/DaysList/DaysList";
import { DataContext } from "../../../context/DataContext";
import Button from "../../../shared/Button/Button";
import Heading from "../../../shared/Heading/Heading";
import {
  formatDate,
  getDayOfWeek,
  isBookingInThePast,
} from "../../../helpingFunctions/utilities";
import { timeSelected } from "../../../assets/requests";
import { SelectionContext } from "../../../context/SelectionContext";
import AllSelectionsPopUp from "../../../shared/AllSelectionPopUp/AllSelectionsPopUp";
import AlertBox from "../../../components/AlertBox/AlertBox";
import ActivityTimeSlotsDesktop from "../../../components/ActivityTimeSlots/ActivityTimeSlotsDesktop/ActivityTimeSlotsDesktop";
import SelectBox from "../../../shared/SelectBox/SelectBox";
import HandleQuantity from "../../../hooks/HandleQuantity";
import Activity_Spa_PreviewLogic from "../Activity_Spa_PreviewLogic";
import Details from "../../../components/ActivityAndSpa_Details/DeatailsDesktop/Details";
import ConflictAlertBox from "../../../shared/validation/ConflictAlertBox";
import InformationalBoxHeavy from "../../../shared/Containers/InformationalBoxHeavy";

import styles from "./activity-preview.module.css";

const dayTimes = ["Morning", "Afternoon"];

const ActivityPreviewDesktop = ({ id, view, setView, goTo }) => {
  const { bookingSelections, editMode, bookingStatus } =
    useContext(SelectionContext);
  const { reservation, itineraryData } = useContext(DataContext);

  const {
    object,
    availableTimes,
    availableDates,
    loading,
    selectedDate,
    alertStatus,
    alertMessage,
    priceTxt,
    isReservationIn3Months,
    activityTimeLimit,
    setActivityTimeLimit,
    manageBooking,
    clearStoredBooking,
    goBack,
    handleSave,
    showConflict,
    closeAlert,
    closePopup,
    getActionButtonLabel,
  } = Activity_Spa_PreviewLogic(id, view, setView, goTo);

  const {
    quantityOptions,
    manageQuantity,
    quantitySelected,
    setQuantitySelected,
  } = HandleQuantity(id, view);

  const currentDaySelection = bookingSelections?.find(
    ({ day }) => day === selectedDate
  );

  const currentDateTime = new Date().toISOString().split("T");

  return (
    <>
      {!object ? (
        <div>Loading...</div>
      ) : (
        <div className={styles.activityPreviewWrapper}>
          <Details
            object={object}
            priceTxt={priceTxt}
            closeFunc={goBack}
            smallerType={true}
          />
          <div className={styles.activityBookingSection}>
            <DaysList data={availableDates} hideContent={true} view={view} id={object.id}/>

            <div className={styles.activityBooking}>
              {availableTimes.length > 0 || loading ? <div className={styles.activitySelectDropDown}>
                <p>
                  Please select the number of guests for {object.Title},{" "}
                  {priceTxt}
                </p>
                <div className={`${styles.activitySelectDropDownWrapper} ${
                  isBookingInThePast(
                    currentDaySelection?.day,
                    currentDaySelection?.time,
                    currentDateTime[0],
                    currentDateTime[1]
                  )
                    ? "fadeElement"
                    : ""
                }`}>
                  {availableTimes.length === 0 ||
                  loading ||
                  !itineraryData ? null : (
                    <SelectBox
                      height={"42px"}
                      content={quantityOptions}
                      name={"Quantity"}
                      icon={"drop-down-arrow_1.svg"}
                      setFormData={(key, value) => manageQuantity(key, value)}
                      selectedItem={
                        currentDaySelection?.Quantity &&
                        currentDaySelection?.action !== "remove"
                          ? `${currentDaySelection?.Quantity} ${
                              currentDaySelection?.Quantity === 1
                                ? " guest"
                                : " guests"
                            }`
                          : quantitySelected
                      }
                      setSelectedItem={setQuantitySelected}
                      activityTimeLimit={activityTimeLimit}
                    />
                  )}
                </div>
              </div> : null}
              <div className={styles.date}>
                {selectedDate ? (
                  <>
                    <strong>{getDayOfWeek(selectedDate)}:</strong>{" "}
                    {formatDate(selectedDate)}
                  </>
                ) : null}
              </div>
              <div className={styles.timeSlotsWrapper}>
                {availableTimes.length > 0 || loading ? (
                  dayTimes.map((dayTime, index) => (
                    <ActivityTimeSlotsDesktop
                      key={dayTime}
                      data={availableTimes}
                      loading={loading}
                      type={dayTime}
                      id={id}
                      paddingLeft={index === 1 ? "2rem" : null}
                      borderLeft={index === 1 ? "1px solid #b5b5b7" : null}
                      view={view}
                      setActivityTimeLimit={setActivityTimeLimit}
                    />
                  ))
                ) : (
                  <InformationalBoxHeavy
                    title={
                      isReservationIn3Months
                        ? null
                        : "Activities for your break available soon..."
                    }
                    content={
                      isReservationIn3Months
                        ? `Sorry, there are no available bookings/times for ${object.Title}, there may be cancellations available, please check with guest services at the ${reservation.Hotel.Title}.`
                        : "Sorry, we haven't released any availability for this date yet, however if you check back closer to your break date, we should have availability."
                    }
                    boxNewStyles={{ marginTop: "20px" }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.activityBookingFooter}>
            {bookingSelections.length === 0 ? null : timeSelected(
                bookingSelections,
                selectedDate
              ) && getActionButtonLabel() ? (
              <Heading
                txt={`You are about to book ${object.Title} at ${timeSelected(
                  bookingSelections,
                  selectedDate
                )} on ${formatDate(selectedDate)}`}
                paddingBottom={"20px"}
              />
            ) : !timeSelected(bookingSelections, selectedDate) &&
              getActionButtonLabel() ? null : editMode &&
              editMode === "edited" ? (
              <Heading
                txt={`You are about to modify/cancel previous selection(s)`}
                paddingBottom={"20px"}
              />
            ) : null}

            <div className={styles.bookingButtons}>
              <Button
                txt={"Cancel"}
                onClick={goBack}
                color={"var(--color-primary)"}
                backgroundColor={"var(--color-secondary)"}
                border={"1px solid var(--color-primary)"}
              />
              {bookingSelections.length === 0 ||
              bookingSelections.filter((row) => row.action !== "none")
                .length === 0 ? null : getActionButtonLabel() ? (
                <Button
                  txt={`Book`}
                  onClick={manageBooking}
                  color={"var(--color-secondary)"}
                  backgroundColor={"var(--color-primary)"}
                  border={"1px solid var(--color-primary)"}
                />
              ) : editMode === "edited" ? (
                <Button
                  txt={`Modify`}
                  onClick={manageBooking}
                  color={"var(--color-secondary)"}
                  backgroundColor={"var(--color-primary)"}
                  border={"1px solid var(--color-primary)"}
                />
              ) : null}
            </div>
          </div>
          {bookingStatus ? (
            <AllSelectionsPopUp
              view={view}
              setView={(v) => setView(v)}
              mainObjectId={id}
            />
          ) : null}
          {alertStatus === "canceling" ? (
            <AlertBox
              txt={
                "We are proccessing your cancel request, this will take some time."
              }
            />
          ) : alertStatus === "non-valid" ? (
            <AlertBox
              txt={alertMessage}
              button={
                <>
                  <Button txt={"Ok"} backgroundColor={"var(--color-primary)"} color={"var(--color-secondary)"} onClick={closeAlert} />
                </>
              }
            />
          ) : ["strong-conflict", "soft-conflict", "average-conflict"].includes(
              alertStatus
            ) ? (
            <ConflictAlertBox
              alertMessage={alertMessage}
              alertStatus={alertStatus}
              showConflict={showConflict}
            />
          ) : alertStatus === "average-conflict" ? (
            <AlertBox
              txt={alertMessage}
              button={
                <>
                  <Button txt={"Return"} backgroundColor={"var(--color-secondary)"} color={"var(--color-primary)"} onClick={closePopup} />
                </>
              }
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default ActivityPreviewDesktop;

import axios from "axios";
import { useHistory } from "react-router-dom";
import util from "util";

import { Http } from '@capacitor-community/http';

const AxiosInstance = () => {
  let lastTimeRedirectedToLoginPage = 0;
  const history = useHistory();

  let instance = null;
  if (process.env.REACT_APP_USE_IONIC) {
    // console.log("USING IONIC VERSION. TODO: remove when we move to fully functional ionic usage");
    instance = {
      get(url, params) {

        const options = {
          url: process.env.REACT_APP_BACK_ENDPOINT + url,
          params: params?.params ? params.params : {},
          webFetchExtra: {
            credentials: "include",
          },
        };

        return Http.get(options);
      },

      post(url, data, params) {
        
        const options = {
          url: process.env.REACT_APP_BACK_ENDPOINT + url,
          headers: {'Content-Type': 'application/json'},
          data: {...data},
          webFetchExtra: {
            credentials: "include",
          },
        };

        return Http.post(options);

      },

      delete(url, params) {
        const options = {
          url: process.env.REACT_APP_BACK_ENDPOINT + url,
          webFetchExtra: {
            credentials: "include",
          },
        };

        return Http.del(options);
      }

    };

  } else {

    //TODO: completely remove this code when we have full portability 
    //for ionic Http compared with current axios implementation 
    instance = axios.create({
      baseURL: process.env.REACT_APP_BACK_ENDPOINT,
      withCredentials: true,
      crossDomain: true,
    });

    instance.interceptors.request.use(x => {
      //console.log(`AX REQ: ${util.inspect(x, {depth: 7})}`);
      return x;
    });

    // Add a response interceptor      
    instance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        //console.log(`AX RESP: ${util.inspect(response, {depth: 7})}`);
        return response;
      },
      function (error) {
        //console.log(`AX ERR: ${util.inspect(error, {depth: 7})}`);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        switch (error?.response?.status) {
          case 403: {
            if (
              document.location.pathname !== "/" &&
              Date.now() - lastTimeRedirectedToLoginPage > 2000
            ) {
              lastTimeRedirectedToLoginPage = Date.now();
              localStorage.removeItem("groupPopUp")
              history.push("/?sessionExpired=true")
            }
            break;
          }
          default:
            break;
        }

        return Promise.reject(error);
      }
    );

  }

  return instance;
};

export default AxiosInstance;

import React from "react";

import Heading from "../../shared/Heading/Heading";
import InformationText from "../../shared/InformationText/InformationText";

import InformationPage from "../../components/InformationPage/InformationPage";

const Maintenance = () => {
  const strMaintenanceData = localStorage.getItem("maintenanceData");

  let maintenanceData = {};
  if (strMaintenanceData) {
    maintenanceData = JSON.parse(strMaintenanceData);
  }

  return (
    <InformationPage
      image={maintenanceData?.Image?.formats?.large?.url}
      new_styles={{ filter: "grayscale(100%)" }}
      backdropFilter={"blur(25px)"}
    >
      <Heading
        txt={maintenanceData.Title}
        fontSize={"40px"}
        new_styles={{
          color: "#fff",
        }}
      />
      <InformationText
        content={maintenanceData.Description}
        new_styles={{
          fontSize: "20px",
          color: "#fff",
          marginTop: "40px",
          textAlign: "center",
          whiteSpace: "pre-wrap",
        }}
      />
    </InformationPage>
  );
};

export default Maintenance;

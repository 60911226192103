const groupNameDisplay = {
    name: "group_name",
    label: "Enter the name for your dining party:",
    alertTxt: "Please enter the name for your dining party!", 
    placeholder: "e.g. Tom's birthday bash"
}
const surname = {
    name: "surname",
    label: "Enter their surname:",
    placeholder: "Please enter surname"
}
const bookingId = {
    name: "bookingId",
    label: "Enter their booking ID:",
    placeholder: "Please enter reference"
}

export {
    groupNameDisplay,
    surname,
    bookingId
}
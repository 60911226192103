import React, { useContext, useEffect } from "react";
import moment from "moment";

import { DataContext } from "../../context/DataContext";
import {
  getDayOfWeek,
  getStayingDates,
} from "../../helpingFunctions/utilities";
import BookingSectionLogic from "./BookingSectionLogic";
import TimeButton from "../../shared/TimeButton/TimeButton";
import RestaurantCardDesktop from "../RestaurantCard/RestaurantDesktop/RestaurantCardDesktop";
import SortJSX from "../../helpingFunctions/SortJSX";
import LoadingContainer from "../../shared/LoadingContainer/LoadingContainer";
import WindowWidth from "../../hooks/WindowWidth";
import InformationText from "../../shared/InformationText/InformationText";
import { SelectionContext } from "../../context/SelectionContext";
import { getSelectionByDate } from "../../helpingFunctions/selections";
import CheckBox from "../../shared/CheckBox/CheckBox";
import RestaurantMobileNew from "../RestaurantCard/RestaurantMobileNew/RestaurantMobileNew";
import InformationalBoxHeavy from "../../shared/Containers/InformationalBoxHeavy";

import styles from "./booking_section.module.css";

const BookingSection = ({
  data,
  facilitiesData,
  showBanner,
  view,
  viewData,
}) => {
  const {
    restaurants,
    allTimes,
    clicked,
    isTimeGrayOut,
    selectedItem,
    clickedResId,
    hasDiningAddOnsOnSkip,
    setHasDiningAddOnsOnSkip,
    setIsTimeGrayOut,
    manageRestaurantMobileClicked,
    handleSkipWhenAddOns,
  } = BookingSectionLogic(data, view);

  const { reservation, selectedDate, itineraryData, availibility, filteredTreatsData } =
    useContext(DataContext);
  const { bookingSelections, skipedDays, skipDayHandler, setSkipedDays } =
    useContext(SelectionContext);
  const { width } = WindowWidth();

  const makeSelectionStatus = (key, value) => {
    if (!selectedItem) return "default";
    if (selectedItem.facilityGUID.length > 1) return "default";

    const [facilityGUID] = selectedItem[key];
    const isSelected = facilityGUID === value;

    return isSelected ? "selected" : "not-selected";
  };

  const checkIfFullyBoooked = (id) => {
    if (allTimes.length === 0) return true;

    return allTimes.some(({ FacilityGUID }) => FacilityGUID.includes(id));
  };

  const getStayDates = () => {
    const stayDates = getStayingDates(
      reservation.ArrivalDate,
      reservation.DepartureDate
    );

    if (view === "Dinner") {
      stayDates.pop();
    }

    return stayDates;
  };

  const findSkipedDays = () => {
    try {
      const allDates = getStayingDates(
        reservation.ArrivalDate,
        reservation.DepartureDate
      );
      const { itineraryDates } = itineraryData;
      allDates.map((date) => {
        const selection = getSelectionByDate(bookingSelections, date);
        const { Facilities } = itineraryDates.find(({ Data }) => Data === date);
        const itineraryItem = Facilities.find(
          ({ Type }) => Type.toLowerCase() === view.toLowerCase()
        );
        const wasSkiped = (!selection && reservation[view] && !itineraryItem) || 
          (!selection && !reservation[view] && !itineraryItem && moment(date).isBefore(moment().subtract(1, 'days')));

        if (wasSkiped && !skipedDays.includes(date)) {
          setSkipedDays((prevState) => [...prevState, date]);
        }
      });
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (itineraryData && availibility) findSkipedDays();
  }, [itineraryData, availibility]);

  const isSkiped = skipedDays.includes(selectedDate);

  return (
    <>
      {restaurants.length > 0 ? (
        <section className={styles.bookingContainer}>
          <div className={styles.bookingSection__heading_desktop}>
            {!showBanner ? (
              <p>
                You are booking for {reservation.Adults} people. <br />
              </p>
            ) : (
              ""
            )}
            <p>
              Select a time of day to view available venues, or select a venue
              to see times available.
            </p>
          </div>
          <div className={styles.bookingSection__heading_mobile}>
            {!showBanner ? (
              <p>
                You are booking for {reservation.Adults} people. <br />
              </p>
            ) : (
              ""
            )}
            <p>Select a time or a restaurant</p>
          </div>
          <div className={styles.bookingSelection}>
            <div className={styles.timeWrapper}>
              <div className={styles.dateNotice}>
                <span className={styles.boldTxt}>
                  {getDayOfWeek(selectedDate?.endsWith('I') ? selectedDate.slice(0, -1) : selectedDate)}:
                </span>
                {`${moment(selectedDate?.endsWith('I') ? selectedDate.slice(0, -1) : selectedDate).format("Do [of] MMMM")} ${moment(
                  selectedDate?.endsWith('I') ? selectedDate.slice(0, -1) : selectedDate
                ).format("yyyy")}`}
              </div>
              <div className={styles.timeSelection}>
                {itineraryData && availibility ? (
                  <SortJSX by="time" fromDinning={true}>
                    <>
                      {allTimes?.map(({ Times, FacilityGUID }) => (
                        <>
                          {Times?.map(({ Time, Availability }) => (
                            <TimeButton
                              key={`${Time}_${FacilityGUID}`}
                              time={Time}
                              facilityGUID={FacilityGUID}
                              available={
                                parseInt(Availability) >= reservation.Adults
                              }
                              view={view}
                              width={width > 1324 ? "145px" : "110px"}
                              height={width > 1324 ? "55px" : "60px"}
                              border={"2px solid var(--color-primary)"}
                              setIsTimeGrayOut={setIsTimeGrayOut}
                              skiped={isSkiped}
                            />
                          ))}
                        </>
                      ))}
                    </>
                  </SortJSX>
                ) : (
                  <LoadingContainer />
                )}
              </div>
            </div>
            <div className={styles.cardsDisplay}>
              {width > 600 ? (
                <>
                  {restaurants.map(
                    (
                      {
                        id,
                        Title,
                        ShortDescription,
                        LongDescription,
                        Image,
                        Type,
                        Menu,
                        Details,
                      },
                      index
                    ) => (
                      <RestaurantCardDesktop
                        key={id}
                        facilitiesData={facilitiesData}
                        availability={allTimes}
                        facilityGUID={[id]}
                        Title={Title}
                        ShortDescription={ShortDescription}
                        Image={Image}
                        Menu={Menu}
                        Details={Details}
                        restaurantsLength={restaurants.length}
                        skiped={isSkiped}
                        isFullyBooked={checkIfFullyBoooked(id)}
                        selectStatus={makeSelectionStatus("facilityGUID", id)}
                        view={view}
                        LongDescription={LongDescription}
                        newClass={
                          width > 1365
                            ? "RestaurantCardDesktop__horisontal"
                            : "RestaurantCardDesktop__vertical"
                        }
                      />
                    )
                  )}{" "}
                </>
              ) : (
                <div className={styles.cardsDisplay__mobile}>
                  {restaurants.map((restaurant, index) => (
                    <RestaurantMobileNew
                      key={restaurant.id}
                      restaurantData={restaurant}
                      selectedState={makeSelectionStatus(
                        "facilityGUID",
                        restaurant.id
                      )}
                      skiped={isSkiped}
                      isFullyBooked={checkIfFullyBoooked(restaurant.id)}
                      view={view}
                      clickedResId={clickedResId}
                      manageRestaurantMobileClicked={
                        manageRestaurantMobileClicked
                      }
                      availability={allTimes}
                    />
                  ))}
                </div>
              )}
              {clicked ? <div id={"addHeigh"} /> : null}
            </div>
          </div>
          {itineraryData && availibility ? (
            <div className={styles.skipButton}>
              {isTimeGrayOut ? (
                <InformationText
                  content={
                    "(*) - This time is not available in this restaurant"
                  }
                  new_styles={{
                    paddingTop: "5px",
                  }}
                />
              ) : null}

              <div className={styles.skipCheck}>
                {/* TODO - Dining add ons related */}
                {/* <CheckBox
                  showCheckIcon={isSkiped}
                  onClick={() => handleSkipWhenAddOns()}
                /> */}
                
                <CheckBox
                  showCheckIcon={isSkiped}
                  onClick={skipDayHandler.bind(this, selectedDate)}
                />
                <InformationText
                  content={viewData.checkBoxInfo}
                  new_styles={{
                    paddingLeft: "15px",
                  }}
                />
              </div>
              {isSkiped && (
                <InformationText content={viewData.noBookingNeededInfo} />
              )}

              {/* TODO - Dining add ons related */}
              {/* {["Lunch", "Dinner"].includes(view) && filteredTreatsData.length > 0 && (
                <AddOnsDiningSection
                  selectedDate={selectedDate}
                  allDates={getStayDates()}
                  view={view}
                  skipedDays={skipedDays}
                  hasDiningAddOnsOnSkip={hasDiningAddOnsOnSkip}
                  setHasDiningAddOnsOnSkip={setHasDiningAddOnsOnSkip}
                  isSkiped={isSkiped}
                />
              )} */}
            </div>
          ) : null}
        </section>
      ) : (
        <div
          style={{
            width: "100%",
            padding: "25px",
            backgroundColor: "var(--color-secondary)",
          }}
        >
          <InformationalBoxHeavy
            title={`${view} reservations coming soon`}
            titleNewStyles={{ marginBottom: "0" }}
          />
        </div>
      )}
    </>
  );
};

export default BookingSection;

import React from "react";
import Markdown from "markdown-to-jsx";

import ActivityCardDesktop from "../../../components/ActivityCard/ActivityCardDesktop/ActivityCardDesktop";
import Heading from "../../../shared/Heading/Heading";
import InformationText from "../../../shared/InformationText/InformationText";
import Button from "../../../shared/Button/Button";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import { goToTheTop } from "../../../helpingFunctions/linkChange";
import CategoriesLogic from "../../../shared/Categories/CategoriesLogic";
import CategoryBanner from "../../../shared/Categories/CategoryBanner/CategoryBanner";
import HorisontalCardsGrid from "../../../shared/Categories/HorisontalCardsGrid/HorisontalCardsGrid";
import DetailsBox from "../../../shared/Categories/DetailsBox/DetailsBox";
import Sorting from "../../../shared/Categories/Sorting/Sorting";
import SelectionPopupBox from "../components/SelectionPopupBox";
import TreatsProcessingPopupBox from "../components/TreatsProcessingPopupBox";
import TreatsLogic from "../logic/TreatsLogic";

import styles from "./TreatsCategoriesDesktop.module.css";

const TreatsCategoriesDesktop = ({ view, setView, showType }) => {
  const {
    isLoading,
    pageTextData,
    selectedCategory,
    clickedObject,
    gridSettings,
    originalData,
    manipulatedData,
    categoryBannersRef,
    selectedDate,
    categorySelectorHandler,
    handleCardClick,
    updateStateAfterSort,
    setSelectedCategory,
    findAcitivities,
  } = CategoriesLogic(showType);

  const {
    toggleOptionsScreen,
    processSelections,
    closePopUp,
    showOpt,
    setShowOpt,
    allDays,
    quantity,
    setQuantity,
    totalPriceToRemove,
    totalPriceToAdd,
    bookingStatus,
    addOnsBookings,
    handleAddOnSelection,
    itineraryData,
    hasSelectedAddOnDining,
    selectionForm,
    setSelectionForm,
    handleAddOnDiscard,
    handleRowOptions,
    setSelectedDate,
    reservation,
    checkAddOnDiningBiding,
  } = TreatsLogic(
    view,
    showType,
    clickedObject,
    setSelectedCategory,
    handleCardClick,
    findAcitivities
  );

  return (
    <>
      <div className={styles.activitiesWrapper}>
        <Sorting initialData={originalData} setData={updateStateAfterSort} />
        <div className={styles.activitiesHeadline}>
          <Heading
            txt={pageTextData?.DesktopTitle}
            paddingTop={"40px"}
            paddingBottom={"20px"}
            fontSize={"24px"}
            fontFamily="Georgia"
            textTransform="uppercase"
          />
          <div className={styles.information}>
            <InformationText
              content={
                pageTextData?.DesktopDescription_max_length_is_340 ? (
                  <Markdown>
                    {pageTextData?.DesktopDescription_max_length_is_340}
                  </Markdown>
                ) : null
              }
              new_styles={{ paddingBottom: "20px", lineHeight: "1.5rem" }}
            />
          </div>
          {isLoading || !itineraryData ? (
            <LoadingContainer />
          ) : (
            <>
              {manipulatedData?.map(
                (
                  {
                    ServiceTypeDescription,
                    CategoryImageLandscape,
                    TreatCategoryId,
                    objects,
                  },
                  index
                ) => (
                  <>
                    {CategoryImageLandscape && (
                      <CategoryBanner
                        key={`CategoryBanner_${TreatCategoryId}`}
                        handleClick={categorySelectorHandler.bind(
                          this,
                          TreatCategoryId
                        )}
                        isSelected={selectedCategory === TreatCategoryId}
                        buttonClick={goToTheTop}
                        categoryBannersRef={categoryBannersRef}
                        additionalData={{
                          TreatCategoryId,
                          index,
                          image: CategoryImageLandscape,
                          content: ServiceTypeDescription,
                        }}
                      />
                    )}
                    <HorisontalCardsGrid
                      shouldOpen={
                        selectedCategory === TreatCategoryId ||
                        !CategoryImageLandscape
                      }
                      new_styles={{
                        gridRowGap:
                          clickedObject &&
                          clickedObject.TreatCategoryId === TreatCategoryId
                            ? null
                            : "85px",
                      }}
                    >
                      {objects?.map((object, index) => (
                        <ActivityCardDesktop
                          key={object.id}
                          {...object}
                          onClick={handleCardClick.bind(this, object, index)}
                          isSelected={clickedObject?.id === object.id}
                          view={view.type}
                        />
                      ))}
                      {clickedObject &&
                      clickedObject.TreatCategoryId === TreatCategoryId && (
                        <DetailsBox
                          dimensionClass={"DetailsBoxDesktop"}
                          gridSettings={gridSettings}
                          clickedObject={clickedObject}
                          unselectCard={handleCardClick.bind(
                            this,
                            clickedObject
                          )}
                          previewFunctionality={() => toggleOptionsScreen()}
                        />
                      )}
                    </HorisontalCardsGrid>
                  </>
                )
              )}
            </>
          )}
        </div>
        <div className={styles.acitivityFooter}>
          <Button
            txt={"Back to the top"}
            backgroundColor={"var(--color-secondary)"}
            color={"var(--color-primary)"}
            border={"2px solid var(--color-primary)"}
            onClick={goToTheTop}
          />
        </div>
      </div>

      {showOpt && (
        <SelectionPopupBox
          setShowOpt={setShowOpt}
          clickedObject={clickedObject}
          selectedDate={selectedDate}
          showType={showType}
          setView={setView}
          quantity={quantity}
          setQuantity={setQuantity}
          allDays={allDays}
          processSelections={processSelections}
          totalPriceToRemove={totalPriceToRemove}
          totalPriceToAdd={totalPriceToAdd}
          addOnsBookings={addOnsBookings}
          handleAddOnSelection={handleAddOnSelection}
          selectionForm={selectionForm}
          hasSelectedAddOnDining={hasSelectedAddOnDining}
          setSelectionForm={setSelectionForm}
          handleAddOnDiscard={handleAddOnDiscard}
          handleRowOptions={handleRowOptions}
          setSelectedDate={setSelectedDate}
          reservation={reservation}
          checkAddOnDiningBiding={checkAddOnDiningBiding}
        />
      )}

      {bookingStatus && (
        <TreatsProcessingPopupBox
          setView={(v) => setView(v)}
          closePopUp={() => closePopUp()}
          view={showType}
          totalPriceToAdd={totalPriceToAdd}
          totalPriceToRemove={totalPriceToRemove}
          clickedObject={clickedObject}
        />
      )}
    </>
  );
};

export default TreatsCategoriesDesktop;

import React, { useContext, useEffect, useState } from "react";

import BookingSection from "../../components/BookingSection/BookingSection";
import DaysList from "../../components/DaysList/DaysList";
import Footer from "../../components/Footer/Footer";
import InfoBox from "../../components/InfoBox/InfoBox";
import InformationText from "../../shared/InformationText/InformationText";
import Heading from "../../shared/Heading/Heading";
import RefreshItinerary from "../../shared/RefreshItinerary/RefreshItinerary";
import WindowWidth from "../../hooks/WindowWidth";
import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import { assignFirstDate } from "../../helpingFunctions/helpingFunctions";
import { getStayingDates } from "../../helpingFunctions/utilities";
import { handleButtonDisplay } from "../../helpingFunctions/groups";
import { findOneFromObjList } from "../../helpingFunctions/arrays";
import GroupBanner from "../../components/GroupBanner/GroupBanner";
import GroupBookingAlert from "../../shared/GroupBookingAlert/GroupBookingAlert";

import styles from "./booking-view.module.css";
import { fontFamily } from "@mui/system";

const BookingView = ({ setView, view }) => {
  const {
    reservation,
    facilitiesData,
    availibility,
    itineraryData,
    selectedDate,
    setSelectedDate,
    groupData,
    isOnValidState,
    setGroupData,
    pagesData,
  } = useContext(DataContext);
  const { bookingStatus } = useContext(SelectionContext);
  const { resetBookingSelections } = RefreshItinerary(null, view);
  const { width } = WindowWidth();
  const [allDates, setAllDates] = useState([]);
  const [viewData, setViewData] = useState({});

  const adjustDates = (arrivalDate, departureDate, view) => {
    const dates = getStayingDates(arrivalDate, departureDate, view);

    setAllDates(dates);
  };
  const manageDateAssign = () => {
    if (selectedDate?.endsWith("I")) {
      setSelectedDate(selectedDate.substr(0, selectedDate.length - 1));
      return;
    }

    assignFirstDate(allDates, setSelectedDate);
  };

  const getViewData = async (viewType) => {
    try {
      if (!viewType) return;

      const data = findOneFromObjList(pagesData, "Type", viewType);

      const desc =
        width > 1324
          ? data.DesktopDescription_max_length_is_340
          : data.MobileDescription;
      const title = width > 1324 ? data.DesktopTitle : data.MobileTitle;

      setViewData({
        title,
        desc,
        noBookingNeededInfo: data.NoBookingNeededInfo,
        checkBoxInfo: data.CheckBoxInfo,
        groupBoxInfo: data.GroupBoxInfo,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    adjustDates(reservation.ArrivalDate, reservation.DepartureDate, view);
  }, []);

  useEffect(() => {
    getViewData(view);
  }, [view]);

  useEffect(() => {
    if (itineraryData && !bookingStatus) resetBookingSelections();
  }, [itineraryData, bookingStatus]);

  useEffect(() => {
    if (allDates.length > 0) manageDateAssign();
  }, [allDates, view]);

  useEffect(() => {
    if (isOnValidState) {
      const allGroupParticipants = groupData.items.filter(({ id }) => id);

      setGroupData((prevState) => {
        return {
          items: allGroupParticipants,
          groupName: prevState.groupName,
          groupId: prevState.groupId,
        };
      });
    }
  }, []);

  let totalGrParticipants = 0;

  if (groupData.items.length > 1)
    groupData.items.forEach(
      (part) => (totalGrParticipants += part.NumberOfGuests)
    );

  const showBanner =
    ["Breakfast", "Dinner"].includes(view) &&
    (reservation.IsGroupCreator ||
      (totalGrParticipants > 1 &&
        reservation.ReservationNumber ===
          groupData.items[0].ReservationNumber)) &&
    !reservation[view];

  return (
    <>
      {!reservation ? (
        <p>Loading...</p>
      ) : (
        <div className={styles.bookingWrapper} id={"bookingView"}>
          <div className={styles.bookingHeadline}>
            <div className={styles.bookingTitle}>
              <Heading
                txt={viewData.title ? viewData.title : view}
                paddingBottom={"13px"}
                paddingTop={"17px"}
                fontSize={"24px"}
                fontFamily="Georgia"
                textTransform="uppercase"
              />
            </div>
            {showBanner ? <GroupBanner info={viewData.groupBoxInfo} /> : null}
            <InformationText content={viewData.desc} />
            {width <= 1324 && itineraryData ? (
              <div style={{ paddingTop: "15px" }}>
                {handleButtonDisplay(
                  reservation,
                  setView,
                  groupData.groupId,
                  view
                )}
              </div>
            ) : null}
          </div>
          <DaysList data={allDates} setView={setView} view={view} />
          <BookingSection
            data={availibility}
            facilitiesData={facilitiesData}
            showBanner={showBanner}
            view={view}
            viewData={viewData}
          />
          {/* Disabled as per #5839 */}
          {view !== "Lunch" && false ? (
            <InfoBox data={availibility} view={view} />
          ) : null}
          <Footer dates={allDates} setView={(v) => setView(v)} view={view} />
        </div>
      )}
      {view !== "Lunch" ? <GroupBookingAlert setView={setView} /> : null}
    </>
  );
};

export default BookingView;

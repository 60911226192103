import React, { useContext, useState, useEffect } from "react";

import FooterLogic from "./FooterLogic";
import Button from "../../shared/Button/Button";
import { SelectionContext } from "../../context/SelectionContext";
import { getDayOfWeek } from "../../helpingFunctions/utilities";
import { DataContext } from "../../context/DataContext";
import WindowWidth from "../../hooks/WindowWidth";
import AlertBox from "../AlertBox/AlertBox";
import ConflictAlertBox from "../../shared/validation/ConflictAlertBox";

import styles from "./footer.module.css";
import { GROUP_LABELS, DiningTypes } from "../../constants/constants";
import AddOnsSharedFunc from "../../hooks/AddOnsSharedFunc";

const Footer = ({ dates, setView, view }) => {
  const { width } = WindowWidth();
  const { totalPriceToRemove, totalPriceToAdd, handleAddOnsTotalPriceChanges } =
    AddOnsSharedFunc();
  const {
    bookingStatus,
    skipedDays,
    bookingSelections,
    diningAddOnsSelections,
  } = useContext(SelectionContext);
  const { setSelectedDate, selectedDate, filteredTreatsData } = useContext(DataContext);

  const {
    handleBackClick,
    manageSave,
    manageAlert,
    saveAfterConflictFound,
    provideBookingStatusAlert,
    isPartOfGroup,
    nextDayIndex,
    valid,
    conflict,
    conflictText,
    chainAlert,
    chainAlerts,
    alertMessage,
    alertStatus,
    prompDiningAddOns,
    setPrompDiningAddOns,
    showConflict,
    saveBookings,
    closePopUp,
    setShowDiningAddOnsPopup,
  } = FooterLogic(dates, setView, view);
  const [actionButtonLabel, setActionButtonLabel] = useState(null);

  const getActionButtonLabel = () => {
    const firstTimeBookings = bookingSelections.filter(
      (row) => row.action === "add" && row.time && row.facilityGUID.length === 1
    );

    // In dining, all days must be selected to proceed, otherwise - continue to next day
    if (["Breakfast", "Dinner"].includes(view)) {
      let selectedDatesCount = 0;
      dates.forEach((date) => {
        const madeSelection = bookingSelections.find((row) => row.day === date && row.action !== "remove");
        if (madeSelection || skipedDays.includes(date)) {
          selectedDatesCount++;
        }
      });
      if (selectedDatesCount !== dates.length) {
        setActionButtonLabel(
          `Continue to ${getDayOfWeek(dates[nextDayIndex + 1])}`
        );
        return;
      }
    }

    if (
      firstTimeBookings.length === bookingSelections.length &&
      bookingSelections.length > 0
    ) {
      setActionButtonLabel(
        `${width > 1324 ? "Confirm all your selections" : "Book"}`
      );
      return;
    }

    const editedBookings = bookingSelections.filter(
      (row) => row.action !== "none"
    );

    if (editedBookings.length > 0) {
      const hasInValid = bookingSelections.filter(
        (row) =>
          (!row.time || row.facilityGUID?.length !== 1) &&
          row.action !== "remove"
      );
      if (hasInValid.length === 0) {
        setActionButtonLabel(`Confirm time changes`);
        return;
      }
    }

    const hasDaySelection = bookingSelections.find((row) => row.day === selectedDate);
    if (
      ["Lunch", "Dinner"].includes(view) &&
      filteredTreatsData.length > 0 &&
      hasDiningAddOns() && hasDaySelection
    ) {
      setActionButtonLabel(
        `${width > 1324 ? "Confirm all your selections" : "Book"}`
      );
      return;
    }

    if (nextDayIndex === dates.length - 1) {
      setActionButtonLabel(null);
      return;
    }
    setActionButtonLabel(
      `Continue to ${getDayOfWeek(dates[nextDayIndex + 1])}`
    );
  };

  const hasDiningAddOns = () => {
    handleAddOnsTotalPriceChanges(diningAddOnsSelections[view]);
    return totalPriceToAdd > 0 || totalPriceToRemove > 0;
  };

  useEffect(() => {
    getActionButtonLabel();
  }, [
    bookingSelections,
    valid,
    nextDayIndex,
    totalPriceToRemove,
    totalPriceToAdd,
    diningAddOnsSelections,
    skipedDays,
  ]);

  return (
    <div className={styles.footer}>
      <div className={styles.footerLeft}>
        {width > 650 ? (
          <Button
            txt={"View itinerary"}
            onClick={setView.bind(this, { type: "itinerary" })}
            color={"var(--color-secondary)"}
            backgroundColor={"var(--color-info)"}
            newStyles={{ border: "2px solid var(--color-info)"}}
          />
        ) : null}
      </div>
      <div className={styles.footerRight}>
        <Button
          txt={`Back`}
          onClick={handleBackClick}
          border={"2px solid var(--color-secondary)"}
          color={"var(--color-primary)"}
          backgroundColor={"var(--color-secondary)"}
        />

        {actionButtonLabel && (
          <Button
            txt={actionButtonLabel}
            onClick={() =>
              actionButtonLabel.includes("Continue")
                ? setSelectedDate(dates[nextDayIndex + 1])
                : manageSave()
            }
            backgroundColor={"var(--color-primary)"}
            color={"var(--color-secondary)"}
          />
        )}
      </div>
      {provideBookingStatusAlert(bookingStatus)}
      {isPartOfGroup && chainAlert ? (
        <AlertBox
          txt={`These changes will only apply to your individual booking, and will not apply to the ${GROUP_LABELS.GroupRef.lowerCase}. ${GROUP_LABELS.GroupRef.regular} bookings can not be modified online, however each guest can modify their individual booking.`}
          button={
            <>
              <Button
                txt={"No"}
                color={"var(--color-primary)"}
                backgroundColor={"var(--color-secondary)"}
                onClick={manageAlert.bind(this, false)}
              />
              <Button
                txt={"Yes"}
                color={"var(--color-secondary)"}
                backgroundColor={"var(--color-primary)"}
                onClick={manageAlert.bind(this, true)}
              />
            </>
          }
        />
      ) : null}
      {["strong-conflict", "soft-conflict", "average-conflict"].includes(
        alertStatus
      ) ? (
        <ConflictAlertBox
          alertMessage={alertMessage}
          alertStatus={alertStatus}
          showConflict={showConflict}
        />
      ) : null}
      {conflictText ? (
        <AlertBox
          txt={conflictText}
          button={
            <>
              <Button
                txt={"Go Back"}
                color={"var(--color-primary)"}
                backgroundColor={"var(--color-secondary)"}
                onClick={
                  isPartOfGroup
                    ? chainAlerts.bind(this, false)
                    : saveAfterConflictFound.bind(this, false)
                }
              />
              <Button
                txt={"Ok"}
                color={"var(--color-secondary)"}
                backgroundColor={"var(--color-primary)"}
                onClick={
                  isPartOfGroup
                    ? chainAlerts.bind(this, true)
                    : saveAfterConflictFound.bind(this, true)
                }
              />
            </>
          }
        />
      ) : null}
      {prompDiningAddOns ? (
        <AlertBox
          customContent={
            <p style={{ fontWeight: "bold" }}>
              Can we interest you in treating yourself?
            </p>
          }
          button={
            <>
              <Button
                txt={"No, thank you"}
                color={"var(--color-primary)"}
                backgroundColor={"var(--color-secondary)"}
                onClick={() => {
                  setPrompDiningAddOns(false);
                  saveBookings();
                }}
              />
              <Button
                txt={"Yes, show me"}
                backgroundColor={"var(--color-primary)"}
                color={"var(--color-secondary)"}
                onClick={() => {
                  setPrompDiningAddOns(false);
                  setShowDiningAddOnsPopup(true);
                }}
              />
            </>
          }
        />
      ) : null}
    </div>
  );
};

export default Footer;

import React, { useContext } from "react";

import { xSign } from "../../assets/SVGIcons";
import { DataContext } from "../../context/DataContext";
import { additionalInfo } from "../../helpingFunctions/groups";
import GroupTableLogic from "./GroupTableLogic";
import WindowWidth from "../../hooks/WindowWidth";
import AlertBox from "../AlertBox/AlertBox";
import Button from "../../shared/Button/Button";
import LoadingContainer from "../../shared/LoadingContainer/LoadingContainer";

import styles from "./group-booking-table.module.css";
import { GROUP_LABELS } from "../../constants/constants";

const GroupTable = ({
  tableData,
  setTableData,
  totalGuests,
  view,
  setView,
}) => {
  const { isDeletingID, alert, handleDeleteRequests } = GroupTableLogic(
    tableData,
    setTableData,
    setView
  );
  const { width } = WindowWidth();
  const { selectedDate } = useContext(DataContext);

  return (
    <div className={styles.groupBookingFormWrapper__group_table}>
      <div className={styles.groupBookingFormWrapper__group_table_header}>
        {width > 1324 ? (
          <>
            <label>Surname on booking</label>
            <label>Booking reservation number</label>
            <label>Number of guests in booking</label>
          </>
        ) : (
          <label>Booking details</label>
        )}
      </div>
      {
        <>
          <div
            className={styles.groupBookingFormWrapper__group_table_body_wrapper}
          >
            {tableData.map(
              ({
                id,
                GuestLastName,
                IsGroupCreator,
                NumberOfGuests,
                ReservationNumber,
                CRSNumber,
                IsInGroup,
                changes,
              }) => (
                <div
                  className={`${
                    styles.groupBookingFormWrapper__group_table_body
                  } ${
                    IsGroupCreator && tableData?.length > 1
                      ? styles.groupBookingFormWrapper__groupCreatorRow
                      : ""
                  }`}
                  key={`${GuestLastName}_${ReservationNumber}`}
                >
                  <h3>
                    {GuestLastName}
                    <p>
                      {additionalInfo(
                        changes,
                        selectedDate,
                        view.itineraryType
                      )}
                    </p>
                  </h3>
                  <h3
                    style={{
                      paddingLeft: !IsGroupCreator ? "23px" : null,
                    }}
                  >
                    {CRSNumber}
                  </h3>
                  <h3
                    style={{
                      paddingLeft: !IsGroupCreator ? "33px" : null,
                    }}
                  >
                    {NumberOfGuests} Guests
                  </h3>
                  {IsGroupCreator ? null : (
                    <div
                      className={
                        styles.groupBookingFormWrapper__group_table_delete
                      }
                      style={{
                        pointerEvents: isDeletingID ? "none" : "auto",
                        cursor: isDeletingID ? "normal" : "pointer",
                      }}
                      onClick={handleDeleteRequests.bind(
                        this,
                        id,
                        ReservationNumber,
                        IsInGroup
                      )}
                    >
                      {isDeletingID === id ? (
                        <LoadingContainer />
                      ) : (
                        <>{xSign()}</>
                      )}
                    </div>
                  )}
                </div>
              )
            )}
          </div>
          <div className={styles.groupBookingFormWrapper__horisontalLine} />
          <div className={styles.groupBookingFormWrapper__info}>
            {width > 1324 ? (
              <>
                <h3>Total guests in your {GROUP_LABELS.GroupRef.lowerCase}:</h3>
                <h3>{totalGuests} Guests</h3>
              </>
            ) : (
              <h3>Total guests in your booking: {totalGuests} Guests</h3>
            )}
          </div>
        </>
      }{" "}
      {alert.status !== "initial" ? (
        <AlertBox
          txt={alert.message}
          button={
            <>
              <Button
                onClick={alert.isYes}
                txt={"Yes"}
                backgroundColor={"var(--color-primary)"}
                color={"var(--color-secondary)"}
              />
              <Button
                onClick={alert.isNo}
                txt={"No"}
                backgroundColor={"var(--color-secondary)"}
                color={"var(--color-primary)"}
              />
            </>
          }
        />
      ) : null}
    </div>
  );
};

export default GroupTable;

import React from "react";
import styles from "./GrayOutBackground.module.css";
const GrayOutBackground = ({ children }) => {
  return (
    <div className={styles.popUpWrapper}>
      <div className={styles.grayOutScreen}></div>
      {children}
    </div>
  );
};
export default GrayOutBackground;

import { useContext, useState } from "react";
import { GROUP_LABELS } from "../../constants/constants";
import { DataContext } from "../../context/DataContext";
import { calcTotalGuests } from "../../helpingFunctions/groups";
import RequestHook from "../../hooks/RequestHook";
import axiosInstance from "../../url/createAxios";

const GroupTableLogic = (tableData, setTableData, setView) => {
  const instance = axiosInstance();
  const { getGroup, handleAvailabilityRequest } = RequestHook();
  const { setReservation, reservation, groupData } = useContext(DataContext);
  const [isDeletingID, setIsDeletingID] = useState(null);
  const [alert, setAlert] = useState({ status: "initial" });

  const prepareDeletion = (id) => {
    const groupParticipantsNum = tableData.length;

    if (groupParticipantsNum === 2) {
      setAlert({
        status: "alert",
        message: (
          <div>
            <p>
              Are you sure to remove this reservation from the{" "}
              {GROUP_LABELS.GroupRef.lowerCase}?
            </p>
            <p>
              By deleting this reservation you will delete the{" "}
              {GROUP_LABELS.GroupRef.lowerCase}. Are you sure to proceed?
            </p>
          </div>
        ),

        isYes: () => {
          deleteGroupParticipant(id).then(() => setView({ type: "Breakfast" }));
        },
        isNo: () => setAlert({ status: "initial" }),
      });
      return;
    }

    setAlert({
      status: "alert",
      message: (
        <div>
          <p>
            Are you sure to remove this reservation from the{" "}
            {GROUP_LABELS.GroupRef.lowerCase}?
          </p>
          <p>
            Please Note: This will not cancel their dining booking, it will just
            remove them from your dining party?
          </p>
        </div>
      ),
      isYes: () => deleteGroupParticipant(id),
      isNo: () => setAlert({ status: "initial" }),
    });
  };
  const deleteGroupParticipant = async (deleteID) => {
    setIsDeletingID(deleteID);
    setAlert({ status: "initial" });

    const { data } = await instance.delete(`/group/${deleteID}`, {
      withCredentials: true,
    });
    const cleanedItems = groupData.items.filter(({ id }) => id !== deleteID);
    const totalGuestsNum = calcTotalGuests(cleanedItems);

    await handleAvailabilityRequest(reservation, totalGuestsNum);
    await getGroup();

    setReservation((prevState) => {
      return {
        ...prevState,
        GroupParticipants: totalGuestsNum,
        PartOfGroup: data.action === "destruct_all" ? false : true,
        IsGroupCreator: data.action === "destruct_all" ? false : true,
      };
    });
    setIsDeletingID(null);
  };

  const removeFromList = (resNum) => {
    const filteredTable = tableData.filter(
      ({ ReservationNumber }) => ReservationNumber !== resNum
    );

    setTableData((prevState) => {
      return {
        ...prevState,
        items: filteredTable,
      };
    });
  };

  const handleDeleteRequests = async (
    recordId,
    ReservationNumber,
    IsInGroup
  ) => {
    if (isDeletingID) {
      return null;
    }

    if (IsInGroup) {
      prepareDeletion(recordId);
    } else {
      removeFromList(ReservationNumber);
    }
  };
  return {
    isDeletingID,
    alert,
    handleDeleteRequests,
  };
};

export default GroupTableLogic;

import React from "react";
import ReactPlayer from "react-player";

import HelpItem from "../../components/HelpItem/HelpItem";
import Heading from "../../shared/Heading/Heading";
import InformationText from "../../shared/InformationText/InformationText";
import HelpPageLogic from "./HelpPageLogic";
import LoadingContainer from "../../shared/LoadingContainer/LoadingContainer";
import FAQSearch from "../../components/FAQSearch/FAQSearch";
import Information from "../../components/InformationAlert/Information";
import Button from "../../shared/Button/Button";

import styles from "./help_page.module.css";
import moment from "moment";

const HelpPage = () => {
  const {
    filteredData,
    videoSectionData,
    isLoading,
    manageSearch,
    openZendeskHandler,
    goBack,
  } = HelpPageLogic();
  const { Title, Description, MeidaLink, HelpImage } = videoSectionData;

  return (
    <div className={styles.helpPage}>
      <div className={styles.helpPage__headline}>
        <div className={styles.helpPage__title}>
          <Heading
            txt={`We’re here to help`}
            paddingBottom={"35px"}
            paddingTop={"35px"}
            fontSize={"24px"}
          />
        </div>
        <div className={styles.helpPage__desc}>
          <InformationText
            content={
              <>
                <p>
                  Your go-to place for any questions on how to make your
                  breakfast and dinner bookings.
                </p>
              </>
            }
          />
        </div>
      </div>
      <div className={styles.helpPage__video_section}>
        {isLoading ? (
          <LoadingContainer />
        ) : (
          <>
            {HelpImage || MeidaLink ? (
              <div className={styles.helpPage__video_title__box}>
                <div className={styles.helpPage__video_title_desc}>
                  <Heading
                    txt={Title}
                    paddingBottom={"20px"}
                    fontSize={"20px"}
                  />
                  <InformationText content={Description} />
                </div>
                <div className={styles.helpPage__media}>
                  {MeidaLink ? (
                    <ReactPlayer
                      width={"100%"}
                      height={"100%"}
                      url={MeidaLink}
                    />
                  ) : (
                    <img
                      src={
                        HelpImage?.formats?.small
                          ? HelpImage.formats.small.url
                          : HelpImage.url
                      }
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  )}
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className={styles.helpPage__highlitedTitle}>
        <Heading txt={"Frequently asked questions"} fontSize={"24px"} />
      </div>
      <div className={styles.helpPage__questions_terms}>
        <div className={styles.helpPage__questions}>
          <FAQSearch manageSearch={manageSearch} />
          {filteredData.length > 0 ? (
            <>
              {
                filteredData.map(({Category, CategoryImage, objects}) => 
                <>
                  <div className={styles.CategoryWrapper} 
                    style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url(${CategoryImage})`}}
                  >
                    <Heading txt={Category ? Category : "Misc"} new_styles={{fontSize: "20px", color: "white", paddingBottom: "15px", paddingLeft:"20px"}}/>
                  </div>
                  {
                    objects.map(({id, Question, Answer}) => 
                    <HelpItem key={id} question={Question} answer={Answer} />
                    )
                  }
                </>
                )
              }
            </>
          ) : (
            <>
              {isLoading ? (
                <LoadingContainer />
              ) : (
                <Information
                  new_styles={{
                    width: "100%",
                    height: "auto",
                    backgroundColor: "#ac1a1b",
                  }}
                  fullyColoredClass={"fullyColoredClass"}
                  flexDirection={"column"}
                  txt={"Sorry we could not find any results!"}
                  icon={"exclamation.svg"}
                />
              )}
            </>
          )}
          <HelpItem
            question={"Need more help?"}
            answer={
              <>
                Our support team would love to help! Click{" "}
                <a
                  style={{
                    color: "var(--color-info)",
                    textDecoration: "underline",
                    fontSize: "1.1em",
                  }}
                  target={"_blank"}
                  rel={"noreferrer"}
                  href={"https://support.warnerleisurehotels.co.uk/hc/en-gb"}
                >
                  here
                </a>{" "}
                to view our contact information.{" "}
              </>
            }
            backgroundColor={"var(--color-primary)"}
            whiteTitle={true}
          />
        </div>

        <div className={styles.helpPage__terms}>
          <InformationText
            content={
              <p>
                Copyright {moment().format("YYYY")} Warner Leisure Hotels. Bourne Holidays Limited
                (trading as Warner Leisure Hotels) is a company registered in
                England and Wales with company number 01854900 whose registered
                office is 1 Park Lane, Hemel Hempstead, Hertfordshire HP2 4YL.
              </p>
            }
          />
          <InformationText
            content={
              <p>
                †Cost of calling Warner: Calls cost no more than calls to
                geographic numbers (01 or 02) and must be included in inclusive
                minutes and discount schemes in the same way. Calls from
                landlines are typically charged up to 9p per minute; calls from
                mobiles typically cost between 8p and 40p per minute. Calls from
                landlines and mobiles are included in many free call packages.
                Please check with your supplier.Check out our step-by-step video
                of how to make your dining bookings.
              </p>
            }
          />
          <div className={styles.HelpPage__goBack}>
            <Button
              txt={"Back"}
              color={"var(--color-primary)"}
              backgroundColor={"var(--color-secondary)"}
              onClick={goBack}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;

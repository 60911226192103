import { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { DataContext } from "../../context/DataContext";
import { NonScrollableTypes } from "../../constants/constants";
import WindowWidth from "../../hooks/WindowWidth";

import axiosInstance from "../../url/createAxios";

const NavBarLogic = (setView, view) => {
  const history = useHistory();
  const location = useLocation();
  const instance = axiosInstance();
  const { clearOnLogOut } = useContext(DataContext);
  const { width, height } = WindowWidth();
  const [navReached, setNavReached] = useState(false);
  const [crossBurgerDiv, setCrossBurgerDiv] = useState(false);
  const [compUnmounted, setCompUnmounted] = useState(false);

  const isInWrongLocation =
    location.pathname === "/help" ||
    location.pathname === "/privacy" ||
    location.pathname === "/cookie" ||
    location.pathname === "/" ||
    location.pathname === "/maintenance" ||
    location.pathname.includes("whatsonguide") ||
    view === "Golf" ||
    view === "WhatsOnGuide" ||
    view === "HotelMapImage"

  const isNonScrollable = height > 1000 && NonScrollableTypes.includes(view);

  const handleBurgerClicked = () => {
    if (width > 1870) return;

    setCrossBurgerDiv(!crossBurgerDiv);
  };

  const trackScrolling = () => {
    const element = document.getElementById("navBar");
    if (isInWrongLocation) return;

    if (
      element?.getBoundingClientRect().top <= 0 &&
      window.scrollY > 153 &&
      !compUnmounted
    ) {
      setNavReached(true);
    } else {
      setNavReached(false);
    }
  };

  const nonScrollableHandler = () => {
    if (isNonScrollable) {
      appHeightHandler("100vh");
      setNavReached(true);
    } else {
      setNavReached(false);
      appHeightHandler(null);
    }
  };

  const appHeightHandler = (value) => {
    const app = document.getElementById("app");
    if (app && width > 1870) app.style.minHeight = value;
  };

  const logOut = async () => {
    await instance.delete(`/login`, { withCredentials: true });
    history.push("/");
    setView({ type: "" });
    clearOnLogOut();
  };

  const homePage = () => {
    setView({ type: "" });
  };

  useEffect(() => {
    if (isNonScrollable) return;

    setCompUnmounted(false);

    window.addEventListener("scroll", trackScrolling);
    return () => {
      window.removeEventListener("scroll", trackScrolling);
      setCompUnmounted(true);
    };
  });

  useEffect(() => {
    nonScrollableHandler(view);
  }, [view, location.pathname, height]);

  useEffect(() => {
    if (!view) setCrossBurgerDiv(false);
  }, [view]);

  return {
    handleBurgerClicked,
    logOut,
    homePage,
    isInWrongLocation,
    crossBurgerDiv,
    navReached,
  };
};

export default NavBarLogic;

import React, { useState, useEffect, useContext } from "react";
import styles from "./entertainment-preview.module.css";
import EntertainmentPreviewSectionMobile from "./EntertainmentPreviewSectionMobile";
import { formatDate, getDayOfWeek } from "../../helpingFunctions/utilities";
import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";

const EntertainmentPreviewMobile = ({
  firstVenueDetails,
  secondVenueDetails,
  prepared,
  selectedDate,
  selections,
  bookingNotSelected,
  toggleDetailsDialogHandler,
  handleSelection,
  retrieveAvailability,
  availabilityCount,
  checkIsConfirmed,
  width,
  entertainmentStaticContent,
}) => {
  const [expandFirst, setExpandFirst] = useState(false);
  const [expandSecond, setExpandSecond] = useState(false);
  const { itineraryData, availibility } = useContext(DataContext);
  const { skipedDays, skipDayHandler } = useContext(SelectionContext);

  const isSkiped = skipedDays.includes(selectedDate);

  useEffect(() => {
    setExpandFirst(false);
    setExpandSecond(false);
  }, [selectedDate]);

  return (
    <div className={styles.selectionWrapper}>
      <div
        style={{
          width: "100%",
          marginBottom: "20px",
          paddingLeft: "1.7rem",
          paddingRight: "1.7rem",
        }}
      >
        {selectedDate ? (
          <div className={styles.date}>
            <p
              className={styles.date}
              style={{ flexDirection: width <= 500 ? "column" : null }}
            >
              {"You are making a selection for "}{" "}
              <p style={{ fontWeight: "bold", marginLeft: "5px" }}>
                {getDayOfWeek(selectedDate)}: {formatDate(selectedDate)}
              </p>
            </p>
          </div>
        ) : null}
        {["Thursday", "Saturday"].includes(getDayOfWeek(selectedDate)) ? (
          <p className={styles.rulesText}>
            {entertainmentStaticContent?.ThAndSatRestriction}
          </p>
        ) : null}
        <p className={styles.rulesText}>
          {entertainmentStaticContent?.EveryDayRestriction}
        </p>
        {!["Thursday", "Saturday"].includes(getDayOfWeek(selectedDate)) ? (
          <p className={styles.rulesText}>
            {entertainmentStaticContent?.VenuesOpeningTimeText}
          </p>
        ) : null}
      </div>
      {Object.keys(prepared).map((row) => {
        return (
          <>
            {row === "first" && Object.keys(firstVenueDetails).length > 0 ? (
              <EntertainmentPreviewSectionMobile
                venueDetails={firstVenueDetails}
                expand={expandFirst}
                setExpand={setExpandFirst}
                venuesArray={prepared[row][selectedDate]}
                selections={selections}
                bookingNotSelected={bookingNotSelected}
                toggleDetailsDialogHandler={toggleDetailsDialogHandler}
                handleSelection={handleSelection}
                retrieveAvailability={retrieveAvailability}
                availabilityCount={availabilityCount}
                checkIsConfirmed={checkIsConfirmed}
                width={width}
                opposingVenueSizeWithoutDefault={
                  prepared.second[selectedDate].filter(
                    (row) => typeof row.id === "number" && row.id !== -1
                  ).length
                }
                entertainmentStaticContent={entertainmentStaticContent}
              />
            ) : null}
            {row !== "first" && Object.keys(secondVenueDetails).length > 0 ? (
              <EntertainmentPreviewSectionMobile
                venueDetails={secondVenueDetails}
                expand={expandSecond}
                setExpand={setExpandSecond}
                venuesArray={prepared[row][selectedDate]}
                selections={selections}
                bookingNotSelected={bookingNotSelected}
                toggleDetailsDialogHandler={toggleDetailsDialogHandler}
                handleSelection={handleSelection}
                retrieveAvailability={retrieveAvailability}
                availabilityCount={availabilityCount}
                checkIsConfirmed={checkIsConfirmed}
                width={width}
                opposingVenueSizeWithoutDefault={
                  prepared.first[selectedDate].filter(
                    (row) => typeof row.id === "number" && row.id !== -1
                  ).length
                }
                entertainmentStaticContent={entertainmentStaticContent}
              />
            ) : null}
          </>
        );
      })}

      {itineraryData && availibility ? (
        <div className={styles.skipButton}>
          <div className={styles.checkBoxWrapper}>
            <div
              className={styles.checkBox}
              onClick={skipDayHandler.bind(this, selectedDate, "Entertainment")}
              style={
                selectedDate < new Date().toISOString().split("T")[0]
                  ? { pointerEvents: "none" }
                  : null
              }
            >
              {isSkiped ? (
                <img src={"/img/info_icons/done_check.svg"} alt={""} />
              ) : null}
            </div>
            <p>{entertainmentStaticContent?.SkipBookingsThickBoxTitle}</p>
          </div>
          {isSkiped ? (
            <p className={styles.skipButton_info}>
              {entertainmentStaticContent?.SkipBookingsThickedText}
            </p>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default EntertainmentPreviewMobile;

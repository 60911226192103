import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { DataProvider } from "./context/DataContext";
import { SavedPreSelectionsProvider } from "./context/SavedPreSelections";

ReactDOM.render(
  <React.StrictMode>
    <SavedPreSelectionsProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </SavedPreSelectionsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

import { useState, useContext, useEffect } from "react";
import moment from "moment";
import { DataContext } from "../../context/DataContext";

import { SelectionContext } from "../../context/SelectionContext";
import { getDayOfWeek } from "../../helpingFunctions/utilities";

const EntertainmentPreviewLogic = (stayDates) => {
  const [prepared, setPrepared] = useState({});
  const [prepareBooked, setPrepareBooked] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [actDetails, setActDetails] = useState({});
  const [firstDetails, setFirstDetails] = useState({});
  const [secondDetails, setSecondDetails] = useState({});
  const [selections, setSelections] = useState([]);
  const [bookingNotSelected, setBookingNotSelected] = useState([]);
  const {
    selectedDate,
    entertainmentData,
    availibility,
    reservation,
    entertainmentStaticContent,
  } = useContext(DataContext);

  const {
    createEntertainmentSelection,
    removeEntertainmentSelection,
    bookingSelections,
    setEditMode,
    skipedDays,
    setSkipedDays,
    setBookingSelections,
  } = useContext(SelectionContext);

  const toggleDetailsDialogHandler = (show, details) => {
    setShowDetails(show);
    setActDetails(details);
  };

  const prepareEntCards = () => {
    let entertainments = {
      first: {},
      second: {},
    };

    const arrivalDate = reservation.ArrivalDate.split("T");
    const departureDate = reservation.DepartureDate.split("T");
    stayDates.forEach((date) => {
      if (date !== departureDate) {
        entertainments.first[date] = [];
        entertainments.second[date] = [];
      }
    });

    // Sort venue columns alphabetically
    const { firstVenueId, secondVenueId, result } = defineVenueOrder();

    for (let row of entertainmentData) {
      if (
        (row.Date === arrivalDate[0] && row.Time.substring(0, 5) <= "15:00") ||
        row.Date === departureDate[0]
      ) {
        continue;
      }

      const isDefault =
        row.EntertainmentPublished === entertainmentStaticContent?.DefaultCardTitle;
      const opposingHourExisting = entertainmentData.find(
        (opposingVenue) =>
          opposingVenue.Date === row.Date &&
          opposingVenue.Time.substring(0, 5) === row.Time.substring(0, 5) &&
          opposingVenue.id !== row.id
      );

      if (
        (result === -1 && row.Facility.id === firstVenueId) ||
        (result === 1 && row.Facility.id === secondVenueId)
      ) {
        if (isDefault) {
          row.ActDescription = entertainmentStaticContent?.DefaultCardFirstDescription;
        }

        if (!opposingHourExisting) {
          entertainments.second[row.Date].push({
            id: -1,
          });
        }

        entertainments.first[row.Date].push({
          ...row,
          order: "first",
          opposingOrder: "second",
        });

        setFirstDetails(row.Facility);
      } else {
        if (isDefault) {
          row.ActDescription = entertainmentStaticContent?.DefaultCardSecondDescription;
        }

        entertainments.second[row.Date].push({
          ...row,
          order: "second",
          opposingOrder: "first",
        });

        if (!opposingHourExisting) {
          entertainments.first[row.Date].push({
            id: -1,
          });
        }

        setSecondDetails(row.Facility);
      }
    }

    stayDates.forEach((date) => {
      if (date !== departureDate) {
        if (reservation.Venue) {
          const hasBooking = bookingSelections.find((row) => row.day === date);
          if (!hasBooking) {
            setSkipedDays((prevState) => [...prevState, date]);
          }
        } else if ((entertainments.first[date].length === 0 && entertainments.second[date].length === 0) || moment(date).isBefore(moment().subtract(1, 'days'))) {
            setSkipedDays((prevState) => [...prevState, date]);
        }
      }
    });

    setPrepared(entertainments);
    setPrepareBooked(entertainments);
  };

  const handleSelection = (selected, skip) => {
    let disabled = new Set(bookingNotSelected);
    const opposingVenue = selected.opposingOrder;
    const venue = selected.order;

    const isSelectedStar =
      selected.BreakDescription?.toLowerCase().includes("headlining");

    let foundStars = [];
    if (isSelectedStar) {
      foundStars = findEntertainment(null, "star", {
        EntertainmentID: selected.EntertainmentID,
        id: selected.id,
      });
    }

    const hasSelectedOpposingHourVenue = findEntertainment(
      opposingVenue,
      "time",
      {
        date: selected.Date,
        time: selected.Time,
      }
    );

    let hasActsSameVenueSameDayThursdaySaturday = [];
    if (
      ["Thursday", "Saturday"].includes(getDayOfWeek(selected.Date)) &&
      !isSelectedStar
    ) {
      const sameDaySameVenues = findEntertainment(venue, "sameVenueSameDay", {
        date: selected.Date,
        EntertainmentID: selected.EntertainmentID,
        id: selected.id,
      });
      sameDaySameVenues.forEach((row) => {
        hasActsSameVenueSameDayThursdaySaturday.push(row.id);
      });
    }

    // Unselect
    if (selections.includes(selected.id)) {
      setSelections((prevState) =>
        prevState.filter((row) => row !== selected.id)
      );

      // If the unselected option is a star act
      if (isSelectedStar) {
        handleUnselectionIfSelectedIsStar(foundStars, opposingVenue);
      }

      // If there is a venue, opposing the hour of the unselected
      if (hasSelectedOpposingHourVenue) {
        handleUnselectionIfSelectedHasOpposingVenue(
          hasSelectedOpposingHourVenue,
          opposingVenue
        );
      }

      // If selection is made on Thrusday or Saturday
      if (hasActsSameVenueSameDayThursdaySaturday.length > 0) {
        handleUnselectionIfSelectedIsOnThursDayOrSaturday(
          hasActsSameVenueSameDayThursdaySaturday,
          venue,
          opposingVenue,
          selected
        );
      }

      if (!skip) {
        removeEntertainmentSelection(
          selected.Facility.id,
          selected.Date,
          getSelectionMaestroTime({
            Time: selected.Time,
            id: selected.Facility.id,
          })
        );
      }
    } else {
      // Select
      setSelections((prevState) => [...prevState, selected.id]);

      if (hasSelectedOpposingHourVenue)
        disabled.add(hasSelectedOpposingHourVenue.id);

      setBookingNotSelected((prevState) => [
        ...prevState,
        ...Array.from(disabled),
        ...foundStars,
        ...hasActsSameVenueSameDayThursdaySaturday,
      ]);

      if (!skip) {
        createEntertainmentSelection({
          ReservationNumber: reservation.ReservationNumber,
          LastName: reservation.LastName,
          FirstName: reservation.FirstName,
          facilityGUID: [selected.Facility.id],
          day: selected.Date,
          time: getSelectionMaestroTime({
            Time: selected.Time,
            id: selected.Facility.id,
          }),
          action: "add",
        });
      }
    }
  };

  const handleUnselectionIfSelectedIsStar = (foundStars, opposingVenue) => {
    const starActToRemainGreydOut = [];
    // For each star act during the stay
    foundStars.forEach((row) => {
      // Get the star act details
      const starActFound = findEntertainment(null, "wholeStayId", {
        id: row,
      });

      // And check if the star act has an opposing venue
      const starActFoundOpposing = findEntertainment(opposingVenue, "time", {
        date: starActFound.Date,
        time: starActFound.Time,
      });

      // If it has opposing venue that is selected already - mark the given star act
      if (selections.includes(starActFoundOpposing?.id)) {
        starActToRemainGreydOut.push(row);
      }
    });

    // Keep the star act greyed out from the selected opposing venue
    const starActsToReEnable = foundStars.filter(
      (row) => !starActToRemainGreydOut.includes(row)
    );

    // Re-enable star acts
    setBookingNotSelected((prevState) =>
      prevState.filter((row) => !starActsToReEnable.includes(row))
    );
  };

  const handleUnselectionIfSelectedHasOpposingVenue = (
    hasSelectedOpposingHourVenue,
    opposingVenue
  ) => {
    // Check if its a star
    const isOpposingVenueStar =
      hasSelectedOpposingHourVenue.BreakDescription?.toLowerCase().includes(
        "headlining"
      );

    let stars = [];
    // If it is, retrieve all stars
    if (isOpposingVenueStar) {
      stars = findEntertainment(null, "star", {
        EntertainmentID: hasSelectedOpposingHourVenue.EntertainmentID,
        id: hasSelectedOpposingHourVenue.id,
      });
      stars.push(hasSelectedOpposingHourVenue.id);

      // If any of the stars has been selected - keep the grey out for the oppossing venue of the unselected
      let skipOpposingHourStarIfAnySelected = false;
      stars.forEach((row) => {
        if (selections.includes(row)) {
          skipOpposingHourStarIfAnySelected = true;
          return;
        }
      });

      // Re-enable the unselected counterpart. Skip if the opposing hour is star act and a star act is selected already
      if (!skipOpposingHourStarIfAnySelected)
        setBookingNotSelected((prevState) =>
          prevState.filter((row) => row !== hasSelectedOpposingHourVenue.id)
        );
    } else if (
      // If the opposing venue hour is on Thursday or Saturday
      ["Thursday", "Saturday"].includes(
        getDayOfWeek(hasSelectedOpposingHourVenue.Date)
      )
    ) {
      // Check if the opposing venue has the same type durring the day
      const sameDayOpposingVenues = findEntertainment(
        opposingVenue,
        "sameVenueSameDay",
        {
          date: hasSelectedOpposingHourVenue.Date,
          EntertainmentID: hasSelectedOpposingHourVenue.EntertainmentID,
          id: hasSelectedOpposingHourVenue.id,
        }
      );

      // If any of the acts has been selected - keep the grey out for the oppossing venue of the unselected
      let skipOpposingHourSameDayOpposingVenueIfAnySelected = false;
      sameDayOpposingVenues.forEach((row) => {
        if (selections.includes(row.id)) {
          skipOpposingHourSameDayOpposingVenueIfAnySelected = true;
          return;
        }
      });

      // Re-enable the unselected counterpart. Skip if the opposing hour is act on Thursday/Saturday and a act is selected already
      if (!skipOpposingHourSameDayOpposingVenueIfAnySelected)
        setBookingNotSelected((prevState) =>
          prevState.filter((row) => row !== hasSelectedOpposingHourVenue.id)
        );
    } else {
      setBookingNotSelected((prevState) =>
        prevState.filter((row) => row !== hasSelectedOpposingHourVenue.id)
      );
    }
  };

  const handleUnselectionIfSelectedIsOnThursDayOrSaturday = (
    hasActsSameVenueSameDayThursdaySaturday,
    venue,
    opposingVenue,
    selected
  ) => {
    const sameDaySameVenueToRemainGreydOut = [];
    // For each same day same venue
    hasActsSameVenueSameDayThursdaySaturday.forEach((row) => {
      // Get the venue details
      const sameVenueSameDayFound = findEntertainment(venue, "id", {
        id: row,
        date: selected.Date,
      });

      // And check if it has opposing hour venue
      const sameVenueSameDayOpposing = findEntertainment(
        opposingVenue,
        "time",
        {
          date: sameVenueSameDayFound.Date,
          time: sameVenueSameDayFound.Time,
        }
      );

      // If it has opposing venue that is selected already - mark the given act
      if (selections.includes(sameVenueSameDayOpposing?.id)) {
        sameDaySameVenueToRemainGreydOut.push(row);
      }
    });

    // Keep the act greyed out from the selected opposing venue
    const sameDaySameVenuesToReEnable =
      hasActsSameVenueSameDayThursdaySaturday.filter(
        (row) => !sameDaySameVenueToRemainGreydOut.includes(row)
      );

    // Re-enable acts
    setBookingNotSelected((prevState) =>
      prevState.filter((row) => !sameDaySameVenuesToReEnable.includes(row))
    );
  };

  const findEntertainment = (venue, by, values) => {
    switch (by) {
      case "time":
        return prepared[venue][values.date].find(
          (row) =>
            row.id !== -1 &&
            row.Time.substring(0, 5) === values.time.substring(0, 5)
        );
      case "id":
        return prepared[venue][values.date].find((row) => row.id === values.id);
      case "star":
        const starShows = new Set();
        const foundStar = entertainmentData.filter(
          (row) =>
            row.id !== -1 &&
            row.EntertainmentID === values.EntertainmentID &&
            row.id !== values.id &&
            row.BreakStyle?.toLowerCase() !== "festival"
        );

        foundStar.forEach((star) => {
          starShows.add(star.id);
        });
        return Array.from(starShows);
      case "wholeStayId":
        return entertainmentData.find((row) => row.id === values.id);
      case "sameVenueSameDay":
        return prepared[venue][values.date].filter(
          (row) =>
            row !== -1 &&
            row.EntertainmentID === values.EntertainmentID &&
            row.id !== values.id
        );
      default:
        break;
    }
  };

  const markBooked = () => {
    const bookingIds = {};
    bookingSelections.forEach((row) => {
      if (!bookingIds.hasOwnProperty(row.facilityGUID[0])) {
        bookingIds[row.facilityGUID[0]] = {};
      }

      if (!bookingIds[row.facilityGUID[0]].hasOwnProperty(row.day)) {
        stayDates.forEach((date) => {
          bookingIds[row.facilityGUID[0]][date] = [];
        });
      }

      bookingIds[row.facilityGUID[0]][row.day].push(row);
    });

    // Sort venue columns alphabetically
    const { firstVenueId, secondVenueId, result } = defineVenueOrder();

    for (let row of entertainmentData) {
      if (row.Date === reservation.DepartureDate.split("T")[0]) continue;

      const facilityId = row.Facility.id.toString();
      if (facilityId) {
        if (Object.keys(bookingIds).includes(facilityId)) {
          const selected = bookingIds[facilityId.toString()][row.Date].find(
            (t) => t.time.substring(0, 5) === row.Time.substring(0, 5)
          );

          if (selected) {
            if (
              (result === -1 && row.Facility.id === firstVenueId) ||
              (result === 1 && row.Facility.id === secondVenueId)
            ) {
              handleSelection(
                {
                  ...row,
                  order: "first",
                  opposingOrder: "second",
                },
                true
              );
            } else {
              handleSelection(
                {
                  ...row,
                  order: "second",
                  opposingOrder: "first",
                },
                true
              );
            }
          }
        }
      }
    }
  };

  const retrieveAvailability = (row) => {
    const availObj = availibility?.find((a) => a.Date === selectedDate);
    if (availObj) {
      const timeObj = availObj.Facilities.find(
        (t) => t.FacilityGUID === row.Facility?.id
      );
      if (timeObj) {
        const obj = timeObj.Times.find(
          (o) => o.Time.substring(0, 5) === row.Time.substring(0, 5)
        );
        if (obj) {
          return Number(obj.Availability);
        } else {
          return 0;
        }
      }
    }
  };

  const getSelectionMaestroTime = (row) => {
    const availObj = availibility?.find((a) => a.Date === selectedDate);

    const timeObj = availObj.Facilities.find((t) => t.FacilityGUID === row.id);

    const obj = timeObj.Times.find(
      (o) => o.Time.substring(0, 5) === row.Time.substring(0, 5)
    );

    return obj.Time;
  };

  const checkIsConfirmed = (details) => {
    return bookingSelections.find(
      (row) =>
        row.day === details.day &&
        row.time.substring(0, 5) === details.time.substring(0, 5) &&
        row.facilityGUID[0] === details.id &&
        row.action === "none"
    );
  };

  const defineVenueOrder = () => {
    let firstVenue = entertainmentData[0];
    let secondVenue = entertainmentData.find(
      (row) => row.Facility.id !== firstVenue.Facility.id
    );
    const result = secondVenue?.Facility.title.localeCompare(
      firstVenue?.Facility.title
    );

    return {
      firstVenueId: firstVenue?.Facility.id,
      secondVenueId: secondVenue?.Facility.id,
      result: result,
    };
  };

  const clearSkippedDaySelections = (venues, order) => {
    const workSelections = [...selections];
    const greyedOutActs = [...bookingNotSelected];
    venues.forEach((row) => {
      const isSelectedStar =
        row.BreakDescription?.toLowerCase().includes("headlining");

      let foundStars = [];
      if (isSelectedStar) {
        foundStars = findEntertainment(null, "star", {
          EntertainmentID: row.EntertainmentID,
          id: row.id,
        });
      }

      if (workSelections.includes(row.id)) {
        setSelections((prevState) => prevState.filter((sel) => sel !== row.id));
        if (isSelectedStar)
          handleUnselectionIfSelectedIsStar(
            foundStars,
            order === "first" ? "second" : "first"
          );
      }

      let keepGreyOut = false;
      foundStars.forEach((star) => {
        if (selections.includes(star)) {
          keepGreyOut = true;
          return;
        }
      });

      if (greyedOutActs.includes(row.id) && !keepGreyOut) {
        setBookingNotSelected((prevState) =>
          prevState.filter((unsel) => unsel !== row.id)
        );
      }
    });
  };

  useEffect(() => {
    if (selectedDate) {
      prepareEntCards();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(prepared).length > 0) {
      clearSkippedDaySelections(prepared.first[selectedDate], "first");
      clearSkippedDaySelections(prepared.second[selectedDate], "second");
      const bookingSels = [...bookingSelections];
      bookingSels.forEach((row) => {
        const isSkiped = skipedDays.includes(row.day);
        if (row.day === selectedDate && isSkiped) {
          if (row.action === "none") {
            row.action = "remove";
          } else if (row.action === "add") {
            row.action = "skip";
          }
        }
      });

      setBookingSelections(bookingSels.filter((row) => row.action !== "skip"));
    }
  }, [skipedDays]);

  useEffect(() => {
    if (prepareBooked) {
      setBookingNotSelected([]);
      setEditMode(null);
      markBooked();
    }
  }, [prepareBooked]);

  return {
    selectedDate,
    prepared,
    showDetails,
    actDetails,
    toggleDetailsDialogHandler,
    firstDetails,
    secondDetails,
    handleSelection,
    selections,
    bookingNotSelected,
    retrieveAvailability,
    checkIsConfirmed,
    entertainmentData,
  };
};

export default EntertainmentPreviewLogic;

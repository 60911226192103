import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getDayIndex } from "../../assets/requests";
import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import SaveBookingHook from "../../hooks/SaveBookingHook";
import Button from "../../shared/Button/Button";
import AlertBox from "../AlertBox/AlertBox";
import LoadingContainer from "../../shared/LoadingContainer/LoadingContainer";
import AllSelectionsPopUp from "../../shared/AllSelectionPopUp/AllSelectionsPopUp";
import CheckBookingConflicts from "../../shared/validation/CheckBookingConflicts";
import HandleBookingConflicts from "../../shared/validation/HandleBookingConflicts";
import {
  findPrice,
  findSpaPrice,
} from "../../helpingFunctions/facilityAndServices";
import { BOOKING_TYPES, DiningTypes } from "../../constants/constants";

const FooterLogic = (dates, setView, view) => {
  const history = useHistory();
  const [pastDateSelection, setPastDateSelection] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState(null);
  const [valid, setValid] = useState(true);
  const [isPartOfGroup, setIsPartOfGroup] = useState(false);
  const [prompDiningAddOns, setPrompDiningAddOns] = useState(false);
  const [nextDayIndex, setNextDayIndex] = useState();
  const [conflict, setConflict] = useState(null);
  const [chainAlert, setChainAlert] = useState(null);

  const { saveBookings } = SaveBookingHook(view, setPastDateSelection);
  const {
    setSelectedDate,
    selectedDate,
    groupData,
    reservation,
    facilitiesData,
    availibility,
    setShowDiningAddOnsPopup,
    filteredTreatsData,
  } = useContext(DataContext);
  const {
    bookingSelections,
    editMode,
    skipedDays,
    setBookingStatus,
    diningAddOnsSelections,
  } = useContext(SelectionContext);
  const { findConflicts, constructAlertMsg } = CheckBookingConflicts();
  const { showConflict, prepareConflictPopups } = HandleBookingConflicts(
    view,
    setAlertStatus,
    setAlertMessage
  );

  const closePopUp = () => {
    setBookingStatus(null);
  };

  const handleBackClick = () => {
    if (nextDayIndex !== 0) {
      const currentDay = getDayIndex(dates, selectedDate);
      if (currentDay < 1) {
        setView({ type: "" });
        return;
      }
      setSelectedDate(dates[currentDay - 1]);
      return;
    }

    setView({ type: "" });
  };

  const handleDayIndex = (nonVaildSelections) => {
    if (nonVaildSelections.length > 0) {
      nonVaildSelections.sort((a, b) => new Date(a) - new Date(b));
      const nextDayIndex = getDayIndex(dates, nonVaildSelections[0]);
      setValid(false);
      setNextDayIndex(nextDayIndex - 1);

      return true;
    }
    return false;
  };

  const manageNextButton = () => {
    const nextDayIndex = getDayIndex(dates, selectedDate);
    if (view === "Entertainment" || DiningTypes.includes(view)) {
      if (nextDayIndex === dates.length - 1) setNextDayIndex(-1);
      else setNextDayIndex(nextDayIndex);

      return;
    }

    if (nextDayIndex === dates.length - 1 || editMode) {
      const nonCompletedSelection = bookingSelections
        .map((selection) => {
          if (!selection.time || selection?.facilityGUID.length !== 1) {
            return selection.day;
          }
        })
        .filter((i) => i);
      const unselectedDays = dates.filter(
        (date) => !bookingSelections.find(({ day }) => day === date)
      );

      const invalidSelections = [
        ...nonCompletedSelection,
        ...unselectedDays,
      ].filter((val) => val);

      const cleanInvalidSelections = invalidSelections.filter(
        (day) => !skipedDays.includes(day)
      );

      if (handleDayIndex(cleanInvalidSelections)) return;

      setValid(true);
    }
    setNextDayIndex(nextDayIndex);
  };

  const manageAlert = (isYes) => {
    if (!isYes) {
      setIsPartOfGroup(false);
      return;
    }
    setIsPartOfGroup(false);
    saveBookings();
  };

  const manageSave = () => {
    const conflicts = findConflicts(view);

    if (groupData.items.length > 1 && reservation[view]) {
      setIsPartOfGroup(true);
      setChainAlert(true);
      return;
    }

    if (conflicts.length > 0) {
      const conflictDetails = [];
      for (let conflict of conflicts) {
        conflictDetails.push({
          ...constructAlertMsg(conflict, view, obtainPrice),
          ...conflict,
          isResolved: false,
        });
      }
      prepareConflictPopups(conflictDetails);
      return;
    }

    
    {/* TODO - Dining add ons related */}
    // let hasAddOnsDiningSelections = false;
    // if (["Lunch", "Dinner"].includes(view) && filteredTreatsData.length > 0) {
    //   let daysSkippedCount = 0;
    //   for (const [key, value] of Object.entries(diningAddOnsSelections[view])) {
    //     if (skipedDays.includes(key)) daysSkippedCount++;
    //     if (value.length > 0) {
    //       hasAddOnsDiningSelections = true;
    //       break;
    //     }
    //   }

    //   if (
    //     !hasAddOnsDiningSelections &&
    //     daysSkippedCount !== Object.keys(diningAddOnsSelections[view]).length
    //   ) {
    //     setPrompDiningAddOns(true);
    //     return;
    //   }
    // }

    // if (groupData.items.length <= 1 && reservation[view]) {
    saveBookings();
    // }
  };

  const obtainPrice = (object_id, type) => {
    if (type === BOOKING_TYPES.InternalActivity) {
      return;
    }

    const price =
      type === "Spa"
        ? findSpaPrice(object_id, facilitiesData)
        : findPrice(object_id, availibility);

    return price;
  };

  const saveAfterConflictFound = (isOk) => {
    setConflict(null);
    if (isOk) saveBookings();
  };

  const chainAlerts = (isOk) => {
    setConflict(null);
    setChainAlert(isOk);
  };

  const handlePastDateSelections = (pastDateSelection) => {
    setConflict({
      txt: "It seems that you selected hours that are not availbale anymore. Please try choosing different options.",
      buttons: (
        <Button
          txt={"Ok"}
          color={"var(--color-secondary)"}
          backgroundColor={"var(--color-primary)"}
          onClick={saveAfterConflictFound.bind(this, false)}
        />
      ),
    });
  };

  const provideBookingStatusAlert = (bookingStatus) => {
    if (!bookingStatus) return null;

    if (bookingStatus.status === "error") {
      return (
        <AlertBox
          txt={`We are sorry, something went wrong please refresh the page!`}
          button={
            <Button
              txt={"OK"}
              color={"var(--color-secondary)"}
              backgroundColor={"var(--color-primary)"}
              onClick={() => history.go(0)}
            />
          }
        />
      );
    } else if (!bookingStatus.skipedAll) {
      return (
        <AllSelectionsPopUp
          setView={(v) => setView(v)}
          closePopUp={closePopUp}
          view={view}
        />
      );
    } else if (bookingStatus.results === "skiped")
      return (
        <AlertBox
          txt={`We skipped your ${view.toLowerCase()}. You can amend anytime if you change your mind.`}
          button={
            <>
              <Button
                txt={"OK"}
                color={"var(--color-secondary)"}
                backgroundColor={"var(--color-primary)"}
                onClick={closePopUp}
              />
              <Button
                txt={"Continue to itinerary"}
                color={"#fff"}
                backgroundColor={"var(--color-info)"}
                newStyles={{ border: "2px solid var(--color-info)"}}
                onClick={setView.bind(this, { type: "itinerary" })}
              />
            </>
          }
        />
      );
    else {
      return (
        <AlertBox
          txt={`We are skiping your ${view.toLowerCase()}.`}
          button={<LoadingContainer />}
        />
      );
    }
  };

  useEffect(() => {
    manageNextButton();
  }, [selectedDate, bookingSelections, skipedDays]);

  useEffect(() => {
    if (pastDateSelection) handlePastDateSelections(pastDateSelection);
  }, [pastDateSelection]);

  return {
    handleBackClick,
    manageSave,
    manageAlert,
    saveBookings,
    setConflict,
    saveAfterConflictFound,
    chainAlerts,
    provideBookingStatusAlert,
    prompDiningAddOns,
    setPrompDiningAddOns,
    isPartOfGroup,
    nextDayIndex,
    valid,
    conflict,
    chainAlert,
    alertMessage,
    alertStatus,
    showConflict,
    closePopUp,
    setShowDiningAddOnsPopup,
  };
};

export default FooterLogic;

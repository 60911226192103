import React from "react";

const TreatsPriceField = ({ totalPriceToRemove, totalPriceToAdd }) => {
  const getMsg = () => {
    const msg = ["A total charge of"];
    if (totalPriceToAdd > 0) {
      msg.push(`£${totalPriceToAdd} will be added to your account`);
    }

    if (totalPriceToAdd > 0 && totalPriceToRemove > 0) {
      msg.push("and a total charge of");
    }

    if (totalPriceToRemove > 0) {
      msg.push(`£${totalPriceToRemove} will be removed from your account`);
    }

    msg.push(". Do you wish to continue?");
    return msg.join(" ");
  };

  return (
    <>
      {totalPriceToAdd > 0 || totalPriceToRemove > 0 ? (
        <div
          style={{
            background: "var(--color-info)",
            color: "var(--color-primary)",
            width: "100%",
            marginTop: "30px",
            borderRadius: "5px",
            padding: "20px 30px",
            justifyContent: "center",
            display: "flex",
            textAlign: "center",
            gap: "10px",
          }}
        >
          <img
            style={{ width: "20px", height: "20px" }}
            src={"/img/info_icons/info.svg"}
            alt={"no img"}
          />
          {getMsg()}
        </div>
      ) : null}
    </>
  );
};

export default TreatsPriceField;

import React from "react";
import MarkDown from "markdown-to-jsx";

import CreateGrouping from "../../components/CreateGrouping/CreateGrouping";
import Heading from "../../shared/Heading/Heading";
import GroupBookingLogic from "./GroupBookingLogic";
import ManageBooking from "../../components/ManageBooking/ManageBooking";
import InformationText from "../../shared/InformationText/InformationText";
import WindowWidth from "../../hooks/WindowWidth";
import ToolTip from "../../shared/Tooltip/ToolTip";

import styles from "./group_booking.module.css";

const GroupBooking = ({ setView, view }) => {
  const { totalGuests, groupData, setGroupData, setTotalGuests, viewData } =
    GroupBookingLogic(view);
  const { width } = WindowWidth();

  return (
    <div
      className={`${styles.groupBookingWrapper} ${
        view.id === 2 ? styles.manageGroup : styles.createGroup
      }`}
    >
      <div className={styles.viewTitle}>
        <Heading
          txt={
            <>
              {width > 1324 ? viewData?.DesktopTitle : viewData?.MobileTitle}{" "}
              <ToolTip
                info={viewData?.TitleTooltip}
                directionClass={width > 900 ? "bottom" : "right"}
                tooltipManipulate={"Tooltip_Groups"}
              />
            </>
          }
          paddingTop={width > 1324 ? "40px" : "28px"}
          paddingBottom={width > 1324 ? "20px" : "14px"}
          fontSize={"24px"}
          fontFamily="Georgia"
          textTransform="uppercase"
        />
      </div>
      <div className={styles.groupBookingInformation}>
        <InformationText
          content={
            <p>
              {width > 1324 ? (
                viewData?.DesktopDescription_max_length_is_340 ? (
                  <MarkDown>
                    {viewData?.DesktopDescription_max_length_is_340}
                  </MarkDown>
                ) : null
              ) : viewData?.MobileDescription ? (
                <MarkDown>{viewData?.MobileDescription}</MarkDown>
              ) : null}
            </p>
          }
        />
      </div>
      {view.id === 1 ? (
        <CreateGrouping
          view={view}
          setView={setView}
          fromManage={view.fromManage}
          tableData={groupData.items}
          setTableData={setGroupData}
          totalGuests={totalGuests}
          viewData={viewData}
          existingGroupName={groupData.groupName}
        />
      ) : (
        <ManageBooking
          view={view}
          setView={setView}
          itineraryType={view.itineraryType}
          tableData={groupData.items}
          groupName={groupData.groupName}
          setTotalGuests={setTotalGuests}
          totalGuests={totalGuests}
        />
      )}
    </div>
  );
};

export default GroupBooking;

import { useEffect, useState, useRef } from "react";
import style from "./promotional-banner.module.css";
import WindowWidth from "../../hooks/WindowWidth";
import { LinkItUrl } from "react-linkify-it";

const PromotionalBanner = ({ content, wrapperNewStyles, rotation = 7000 }) => {
  const [leftPosition, setLeftPosition] = useState(0);
  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setDivHeight(ref.current.clientHeight);
    const carouselInterval = setInterval(() => {
      setLeftPosition((prevState) => {
        if (prevState > -100 * (content.length - 1)) {
          return prevState - 100;
        } else if (prevState === -100 * (content.length - 1)) {
          return 0;
        } else if (prevState < 0) {
          return prevState + 100;
        } else {
          return 0;
        }
      });
      setDivHeight(ref.current.clientHeight);
    }, rotation);

    return () => {
      clearInterval(carouselInterval);
    };
  }, []);

  return (
    <>
      <section
        style={{
          width: "100%",
          background: "var(--color-primary)",
          height: divHeight,
          ...wrapperNewStyles,
        }}
      >
        <div id={style.slider} style={{ height: divHeight }}>
          <ul
            style={{
              left: `${leftPosition}%`,
              width: `${content.length * 100}%`,
            }}
          >
            {content.map(({ Message }, index) => (
              <li ref={ref} key={index}>
                <div
                  className={style.sliderContainer}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <LinkItUrl className={style.link}>
                    <p style={{ textAlign: "center", color: "white", zIndex: "23" }}>{Message}</p>
                  </LinkItUrl>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default PromotionalBanner;

import React, { useContext } from "react";

import Heading from "../../shared/Heading/Heading";
import Button from "../../shared/Button/Button";
import WindowWidth from "../../hooks/WindowWidth";
import IndicatiorCircle from "../../shared/IndicatorNumber/IndicatiorCircle";
import ManageBookingLogic from "./ManageBookingLogic";
import { DataContext } from "../../context/DataContext";
import { getDayOfWeek } from "../../helpingFunctions/utilities";
import { additionalInfo } from "../../helpingFunctions/groups";

import styles from "../../views/GroupBooking/group_booking.module.css";
import style from "./manage_booking.module.css";
import { GROUP_LABELS, HELP_LINK } from "../../constants/constants";

const ManageBooking = ({
  view,
  setView,
  tableData,
  totalGuests,
  itineraryType,
  setTotalGuests,
  groupName,
}) => {
  const { width } = WindowWidth();
  const { selectedDate } = useContext(DataContext);
  const { isLoading } = ManageBookingLogic(itineraryType, setTotalGuests);

  return (
    <>
      <div className={styles.groupBookingFormWrapper}>
        <div className={style.ManageBooking__headline}>
          <Heading
            txt={`${view.itineraryType} ${
              GROUP_LABELS.GroupRef.uppercase
            } Summary for ${groupName} for ${getDayOfWeek(selectedDate)}`}
            fontSize={"24px"}
            paddingBottom={"20px"}
          />
        </div>
        <div className={style.ManageBooking__previewTable}>
          <div className={style.previewTable_header}>
            {width > 1324 ? (
              <>
                <h3>Name on a {GROUP_LABELS.GroupRef.lowerCase} booking</h3>
                <h3>Number of guests in booking</h3>
              </>
            ) : (
              <h3>Booking Preview</h3>
            )}
          </div>
          <>
            {!isLoading ? (
              <>
                {tableData.map((groupParticipant, index) => (
                  <>
                    <div
                      className={`${style.previewTable_item} ${
                        groupParticipant.IsGroupCreator
                          ? styles.previewTable_item_groupLeader
                          : null
                      }`}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#FFFFFF" : null,
                      }}
                      key={groupParticipant.id}
                    >
                      <h3>
                        {groupParticipant.GuestFirstName.substr(0, 1)}{" "}
                        {groupParticipant.GuestLastName}
                        <br />
                        {additionalInfo(
                          groupParticipant.changes,
                          selectedDate,
                          view.itineraryType
                        )}
                      </h3>
                      <h3>{groupParticipant.NumberOfGuests} Guests</h3>
                    </div>
                  </>
                ))}
              </>
            ) : null}
          </>
        </div>
        <div className={style.total}>
          <h3>Total:</h3>
          <h3 className={style.totalNumber}>{totalGuests} Guests</h3>
        </div>
      </div>

      <div className={style.ManageBooking__buttons}>
        <Button
          onClick={() => setView({ type: "" })}
          txt={"Return to homepage"}
          backgroundColor={"var(--color-secondary)"}
          color={"var(--color-primary)"}
        />
      </div>
      <div className={style.helpWrapper}>
        <IndicatiorCircle txt={"?"} backgroundColor={"#000"} />
        <span>
          Need some help?{" "}
          <a className={style.helpLink} href={HELP_LINK} target="_blank" rel="noreferrer">
            {" "}
            Click here{" "}
          </a>
        </span>
      </div>
    </>
  );
};

export default ManageBooking;
import React from "react";

import MainTitleLogic from "./MainTitleLogic";
import Logo from "../../shared/Logo/Logo";
import { home_icon } from "../../assets/SVGIcons";

import styles from "./main-title.module.css";

const MainTitle = ({
  height,
  logoHeight,
  setView,
  warnerLogo,
  logoWidth,
  cursor,
  hotelTitle,
  enableHomeButton,
}) => {
  const { handleClick } = MainTitleLogic(setView);

  
  return (
    <div
      className={styles.MainTitle}
      style={{
        height,
      }}
      onClick={handleClick}
    >
      {enableHomeButton && (
        <div
          onClick={() => setView({ type: "" })}
          style={{ position: "absolute", left: "20px", cursor: "pointer" }}
        >
          {home_icon("var(--color-secondary)")}
        </div>
      )}
      <Logo
        width={logoWidth}
        height={logoHeight}
        cursor={cursor}
        warnerLogo={warnerLogo}
        hotelTitle={hotelTitle}
      />
    </div>
  );
};

export default MainTitle;

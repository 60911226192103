import React from "react";

import styles from "./SelectCard.module.css";

const SelectCard = ({ new_style, children, onClick }) => {
  return (
    <div
      className={styles.SelectCard}
      style={{ ...new_style }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default SelectCard;

import React from "react";

const InformationalBox = ({
  content,
  jsxContent,
  boxNewStyles,
  contentNewStyles,
  showIcon,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          background: "var(--color-info)",
          borderColor: "var(--color-info)",
          border: "1px solid transparent",
          ...boxNewStyles,
        }}
      >
        {showIcon ? (
          <img
            style={{
              width: "25px",
              height: "2.5rem",
              marginLeft: "15px",
            }}
            src={`/img/info_icons/info-information.svg`}
            alt=""
          />
        ) : null}
        {jsxContent ? (
          jsxContent
        ) : (
          <p
            style={{
              fontSize: "12px",
              color: "var(--color-primary)",
              padding: ".5rem",
              ...contentNewStyles,
            }}
          >
            {content}
          </p>
        )}
      </div>
    </>
  );
};

export default InformationalBox;

import { useContext } from "react";
import { DiningTypes, GroupTypes } from "../constants/constants";
import { groupBy } from "../helpingFunctions/arrays";
import { DataContext } from "../context/DataContext";
import { SelectionContext } from "../context/SelectionContext";
import { returnBookType } from "../helpingFunctions/bookingHelp";
import axiosInstance from "../url/createAxios";
import { isValidDate } from "../helpingFunctions/bookingHelp";
import RequestHook from "./RequestHook";
import { delay } from "../helpingFunctions/helpingFunctions";
import { checkAvailabilityBeforeLinking } from "../helpingFunctions/groups";

const SaveBookingHook = (view) => {
  const {
    bookingSelections,
    setBookingStatus,
    setBookingSelections,
    skipedDays,
    setSkipedDays,
    setHasExistingChanges,
    setDiningAddOnsSelections,
    diningAddOnsSelections,
  } = useContext(SelectionContext);
  const {
    reservation,
    availibility,
    groupData,
    setReservation,
    addOnsBookings,
    filteredTreatsData,
  } = useContext(DataContext);
  const groupedSelections = ["Spa", "Entertainment"].includes(view)
    ? bookingSelections
    : groupBy(bookingSelections, [
        "day",
        "LastName",
        "ReservationNumber",
        "time",
      ]);
  const hasSkipedAll = groupedSelections.length === 0 && skipedDays.length > 0;
  const instance = axiosInstance();
  const { getItinerary, getAvailability, getGroup } = RequestHook();

  const prepareGroupBookings = (
    cleanedBookingSelections,
    toRemove,
    reservationType
  ) => {
    const specifiedSelections = [];

    const hasManyReservations =
      groupData.items.length > 1 &&
      GroupTypes.includes(reservationType) &&
      !reservation[reservationType];
    const isLinkedReservation = view === "Group_Linking";

    let allSelections = cleanedBookingSelections;

    if (hasManyReservations) {
      const selectionsLength = cleanedBookingSelections.length;
      const groupLength = groupData.items.length;
      for (let i = 0; i < selectionsLength; i++) {
        const selection = cleanedBookingSelections[i];
        for (let j = 0; j < groupLength; j++) {
          const groupItem = groupData.items[j];

          if (groupItem.ReservationNumber === selection.ReservationNumber) {
            selection.groupId = groupData.groupId;
            selection.groupName = groupData.groupName;
            continue;
          }

          specifiedSelections.push({
            LastName: groupItem.GuestLastName,
            FirstName: groupItem.GuestFirstName,
            ReservationNumber: groupItem.ReservationNumber,
            day: selection.day,
            facilityGUID: selection.facilityGUID,
            restaurant: selection.restaurant,
            time: selection.time,
            groupId: groupData.groupId,
            groupName: groupData.groupName,
            Quantity: groupItem.NumberOfGuests,
            action: "add",
          });
        }
      }
      allSelections = [...cleanedBookingSelections, ...specifiedSelections];
    } else if (isLinkedReservation) {
      const selectionsLength = cleanedBookingSelections.length;

      for (let i = 0; i < selectionsLength; i++) {
        const selection = cleanedBookingSelections[i];
        specifiedSelections.push({
          LastName: selection.LastName,
          FirstName: selection.FirstName,
          ReservationNumber: selection.ReservationNumber,
          day: selection.day,
          facilityGUID: selection.facilityGUID,
          restaurant: selection.restaurant,
          time: selection.time,
          groupId: groupData.groupId,
          groupName: groupData.groupName,
          Quantity: selection.Quantity,
          action: "add",
        });
      }
      allSelections = [...specifiedSelections];
    }
    let isGroupRequest = specifiedSelections.length > 0;

    return { isGroupRequest, allSelections };
  };

  const cleanBookingSelections = (
    filteredBookingSelections,
    reservationType
  ) => {
    const cleanedSelections = filteredBookingSelections.filter(({ day }) => {
      const selectionDay = new Date(day);
      if (reservationType === "Breakfast") {
        const [arrivalDay] = reservation.ArrivalDate.split("T");
        return new Date(arrivalDay).getTime() < selectionDay.getTime();
      } else if (reservationType === "Dinner") {
        const [departureDay] = reservation.DepartureDate.split("T");

        return new Date(departureDay).getTime() > selectionDay.getTime();
      } else {
        return true;
      }
    });

    setBookingSelections(cleanedSelections);
    return cleanedSelections;
  };

  const structureRequests = (
    filteredBookingSelections,
    toRemove,
    reservationType
  ) => {
    try {
      const cleanedBookingSelections = cleanBookingSelections(
        filteredBookingSelections,
        reservationType
      );

      const cancelArray = [];
      const bookArray = [];

      const { isGroupRequest, allSelections } = prepareGroupBookings(
        cleanedBookingSelections,
        toRemove,
        reservationType
      );

      const reworkedBookingSelections = [...allSelections];

      if (reservationType === "Treats") {
        reworkedBookingSelections.forEach((row) => {
          if (row.action === "remove" || row.Quantity > 0) bookArray.push(row);
        });
        return { cancelArray, bookArray, isGroupRequest };
      }

      allSelections.forEach((selection) => {
        const preparedObj = {
          id: selection.facilityGUID[0],
          ReservationNumber: selection.ReservationNumber,
          LastName: selection.LastName,
          FirstName: selection.FirstName,
          type: returnBookType(reservationType),
        };

        if (isGroupRequest) {
          preparedObj.groupId = selection.groupId;
          preparedObj.groupName = selection.groupName;
        }

        if (reservationType === "Spa") {
          preparedObj.ProviderCode = selection.providerCode;
          preparedObj.isParticipant = selection.isParticipant;

          if (preparedObj.isParticipant) {
            preparedObj.participantFirstName = selection.participantFirstName;
            preparedObj.participantLastName = selection.participantLastName;
          }
        }

        if (
          selection.action === "remove" &&
          isValidDate(selection.day, selection.timeBooked)
        ) {
          preparedObj.type = returnBookType(selection.type);
          preparedObj.Date = `${selection.day}T${selection.timeBooked}`;
          preparedObj.Quantity = selection.Quantity;
          if (selection.hasOwnProperty("facilityBooked"))
            preparedObj.id = selection.facilityBooked;
          preparedObj.action = selection.action;

          if (reservationType === "Lunch") {
            preparedObj.Quantity = selection.quantityBooked;
          }

          if (DiningTypes.includes(reservationType)) {
            setSkipedDays([...skipedDays, selection.day]);
          }

          cancelArray.push(preparedObj);
        } else if (selection.action === "add") {
          preparedObj.Date = `${selection.day}T${selection.time}`;
          preparedObj.Quantity = selection.Quantity
            ? selection.Quantity
            : reservation.Adults;
          preparedObj.type = returnBookType(reservationType);

          preparedObj.action = "add";
          if (reservationType === "Spa") {
            preparedObj.ProviderCode = selection.providerCode;
          }
          if (!DiningTypes.includes(reservationType)) {
            preparedObj.action = selection.action;
          }

          bookArray.push(preparedObj);
        } else if (selection.action === "edit") {
          const cancelObj = {
            ...preparedObj,
            Date: `${selection.day}T${selection.timeBooked}`,
            Quantity: selection.quantityBooked,
          };
          const bookObj = {
            ...preparedObj,
            Date: `${selection.day}T${selection.time}`,
            Quantity: selection.Quantity,
          };

          if (!DiningTypes.includes(reservationType)) {
            cancelObj.action = "remove";
            bookObj.action = "add";
          }

          if (["Breakfast", "Dinner"].includes(reservationType)) {
            cancelObj.id = selection.facilityBooked;
            bookObj.id = selection.facilityGUID[0];
            bookObj.Quantity = reservation.Adults;
          }

          if (reservationType === "Spa") {
            bookObj.ProviderCode = selection.ProviderCode.pop();
          }

          if (
            !DiningTypes.includes(reservationType) &&
            isValidDate(selection.day, selection.timeBooked)
          )
            cancelArray.push(cancelObj);
          bookArray.push(bookObj);

          const newObj = { ...selection };
          newObj.action = "remove";
          delete newObj.time;
          if (isValidDate(selection.day, selection.timeBooked))
            reworkedBookingSelections.push(newObj);
        } else if (selection.action === "skip") {
          preparedObj.isDiningAddOn = true;
          preparedObj.Date = `${selection.day}T${selection.time}`;
          preparedObj.Quantity = selection.Quantity
            ? selection.Quantity
            : reservation.Adults;
          preparedObj.type = returnBookType(reservationType);
          bookArray.push(preparedObj);
        }
        bookingSelections.forEach((row) => {
          if (row.action === "none") {
            reworkedBookingSelections.push(row);
          }
        });
      });

      const diningAddOns = [];

      {
        /* TODO - Dining add ons related */
      }

      if (["Lunch", "Dinner"].includes(view) && filteredTreatsData.length > 0) {
        const books = [...cancelArray, ...bookArray];
        for (const [key, value] of Object.entries(addOnsBookings)) {
          value.forEach((row) => {
            if (row.Location === view) {
              const selectedDining = books.find((booked) =>
                booked.Date.startsWith(row.Date)
              );

              if (selectedDining) {
                const preparedDiningAddOnObj = {
                  Date: row.Date,
                  Quantity: row.Quantity,
                  UnitPrice: row.UnitPrice,
                  ServiceCode: row.ServiceCode,
                  CurrencyCode: row.CurrencyCode,
                  Comment: row.Comment,
                  id: row.id,
                  title: row.title,
                  action: skipedDays.includes(row.Date) ? "remove" : row.action,
                  Location: row.Location,
                };
                const dateHour = selectedDining.Date.split("T");
                const now = new Date().toISOString().split("T");

                const location =
                  view === "Dinner"
                    ? "Restaurant - Dinner"
                    : view === "Lunch"
                    ? "Restaurant - Lunch"
                    : "In-room";

                const requestFor = ["Dinner", "Lunch"].includes(view)
                  ? `${row.Date} - ${dateHour[1].substring(0, 5)}`
                  : row.Date;

                preparedDiningAddOnObj.Comment = `Add-on | Location | ${location} | Request for | ${requestFor} | Qty | ${
                  row.Quantity
                } | Type - Service code | ${row.title} - ${
                  row.ServiceCode
                } | Booked on | ${now[0]} - ${now[1].substring(0, 5)} |><`;

                preparedDiningAddOnObj.Quantity = skipedDays.includes(row.Date) ? 0 : row.Quantity;
                diningAddOns.push(preparedDiningAddOnObj);
              }
            }
          });
        }

        Object.entries(addOnsBookings).forEach(([key, value]) => {
          value.forEach((val) => {
            if (val.Location !== view) {
              diningAddOns.push(val);
            }
          });
        });
      }

      //       if (["Lunch", "Dinner"].includes(view) && filteredTreatsData.length > 0) {
      //         for (const [key, value] of Object.entries(
      //           diningAddOnsSelections[view]
      //         )) {
      //           value.forEach((row) => {
      //             const selectedDining = books.find((row) =>
      //               row.Date.startsWith(key)
      //             );

      //             const preparedDiningAddOnObj = {
      //               Date: key,
      //               Quantity: row.Quantity,
      //               UnitPrice: row.UnitPrice,
      //               ServiceCode: row.ServiceCode,
      //               CurrencyCode: row.CurrencyCode,
      //               Comment: row.Comment,
      //               id: row.id,
      //               title: row.title,
      //               action: row.action,
      //               Location: row.Location,
      //             };

      //             if (row.status !== "pending") {
      //               if (selectedDining) {
      //                 const dateHour = selectedDining.Date.split("T");
      //                 if (preparedDiningAddOnObj.Comment.includes("timeHere")) {
      //                   preparedDiningAddOnObj.Comment =
      //                     preparedDiningAddOnObj.Comment.replace(
      //                       "timeHere",
      //                       dateHour[1].substring(0, 5)
      //                     );
      //                 } else {
      //                   const now = new Date().toISOString().split("T");
      //                   const location =
      //                     view === "Dinner"
      //                       ? "Restaurant - Dinner"
      //                       : view === "Lunch"
      //                       ? "Restaurant - Lunch"
      //                       : "In-room";
      //                   const requestFor = ["Dinner", "Lunch"].includes(view)
      //                     ? `${row.Date} - ${dateHour[1].substring(0, 5)}`
      //                     : row.Date;
      //                   preparedDiningAddOnObj.Comment = `Add-on | Location | ${location} | Request for | ${requestFor} | Qty | ${
      //                     row.Quantity
      //                   } | Type - Service code | ${row.title} - ${
      //                     row.ServiceCode
      //                   } | Booked on | ${now[0]} - ${now[1].substring(0, 5)} |><`;
      //                 }
      //               }

      //               preparedDiningAddOnObj.Quantity = row.Quantity;
      //               diningAddOns.push(preparedDiningAddOnObj);
      //             } else {
      //               if (row.action !== "add") {
      //                 preparedDiningAddOnObj.Quantity = row.QuantityBooked;
      //                 preparedDiningAddOnObj.action = "none";
      //                 diningAddOns.push(preparedDiningAddOnObj);
      //               }
      //             }
      //           });
      //         }

      //         Object.entries(addOnsBookings).forEach(([key, value]) => {
      //           value.forEach((val) => {
      //             if (val.Location !== view) {
      //               diningAddOns.push(val);
      //             }
      //           });
      //         });
      //       }

      setBookingSelections(reworkedBookingSelections);
      return { cancelArray, bookArray, diningAddOns, isGroupRequest };
    } catch (error) {
      console.error(
        `${new Date()} Error in structureRequests file ${error.message}`
      );
    }
  };

  const regulateBookingSelections = (skip, toRemove) => {
    const toRemoveBooked = toRemove ? toRemove : [];

    {
      /* TODO - Dining add ons related */
    }
    // if (["Lunch", "Dinner"].includes(view) && filteredTreatsData.length > 0) {
    //   bundleDiningAddOns();
    // }

    let filteredBookingSelections = groupedSelections.filter(
      (row) => row.action !== "none"
    );

    filteredBookingSelections = [
      ...filteredBookingSelections,
      ...toRemoveBooked,
    ];

    if (
      skip ||
      (filteredBookingSelections.length === 0 && skipedDays.length === 0)
    )
      return;

    filteredBookingSelections.sort((a, b) => new Date(a.day) - new Date(b.day));
    setBookingStatus({
      status: "started",
      skipedAll: hasSkipedAll,
    });

    return {
      filteredBookingSelections,
      toRemoveBooked,
    };
  };

  const bundleDiningAddOns = () => {
    const diningAddOnsSelectionsCopy = JSON.parse(
      JSON.stringify(diningAddOnsSelections)
    );
    for (const [key, value] of Object.entries(
      diningAddOnsSelectionsCopy[view]
    )) {
      const bookingIndex = groupedSelections.findIndex(
        (row) => row.day === key
      );

      if (bookingIndex > -1) {
        if (groupedSelections[bookingIndex].action === "none") {
          // Adjusting dining if only dining add on has changed so that its send for processing without making changes in maestro
          const newAddOnBookings = value.filter((row) => row.action !== "none");
          if (newAddOnBookings.length > 0) {
            groupedSelections[bookingIndex].action = "skip";
          }
        } else if (groupedSelections[bookingIndex].action === "remove") {
          // Cancelling all dining add ons for a given day if the dining is cancelled
          value.forEach((row) => {
            row.action = "remove";
            row.status = "toBook";
            row.Quantity = 0;
          });
        }
      }
    }

    setDiningAddOnsSelections(diningAddOnsSelectionsCopy);
  };

  const sendBookingRequest = async (
    cancelArray,
    bookArray,
    isGroupRequest,
    reservationType,
    diningAddOns = []
  ) => {
    return await instance.post(
      `/Hotel/Booking/Facility/Availability`,
      {
        request: [...bookArray, ...cancelArray],
        diningAddOns: diningAddOns,
        isGroupRequest: isGroupRequest,
        reservationType:
          reservationType === "Entertainment" ? "Venue" : reservationType,
      },
      { withCredentials: true }
    );
  };

  const afterSaveSync = async (
    res,
    reservationType,
    bookArray = [],
    cancelArray = [],
    diningAddOns = []
  ) => {
    try {
      const { bookings } = res.data;
      if (!bookings) {
        setBookingStatus({
          status: "error",
        });
        return;
      }

      if (reservationType === "Treats") {
        await handleTreatsUpdateStatus(
          res,
          bookArray,
          cancelArray,
          reservationType
        );
      } else {
        let bookedDiningAddOns;
        if (
          ["Lunch", "Dinner"].includes(reservationType) &&
          res.data.hasOwnProperty("overallStatus")
        ) {
          bookedDiningAddOns = await handleTreatsUpdateStatus(
            res,
            diningAddOns,
            [],
            reservationType
          );
        }

        getItinerary().then(async (res) => {
          if (reservationType !== "Spa") {
            await getAvailability(reservation.Adults, [reservationType]);
          }
          await getGroup();
          setHasExistingChanges(false);
          if (hasSkipedAll) {
            setBookingStatus({
              results: "skiped",
              skipedAll: hasSkipedAll,
            });
          } else {
            setBookingStatus({
              status: "after-response",
              results: bookings,
              diningAddOnsResults: bookedDiningAddOns,
            });
          }
        });

        if (
          DiningTypes.includes(reservationType) ||
          reservationType === "Entertainment"
        ) {
          const vue =
            reservationType === "Entertainment" ? "Venue" : reservationType;
          setReservation((prevState) => {
            return {
              ...prevState,
              [vue]: true,
            };
          });
        }
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in afterSaveSync func inside SaveBookingHook.js file ${
          error.message
        }`
      );
    }
  };

  const handleTreatsUpdateStatus = async (
    res,
    bookArray,
    cancelArray,
    source
  ) => {
    let result;
    if (res.data.overallStatus === "processing") {
      const progressId =
        source === "Treats"
          ? res.data.bookings[0].CorrelationID[0]
          : res.data.CorrelationID[0];
      let taskStatus = "processing";
      while (!["success", "Failed", "Error", "Warning"].includes(taskStatus)) {
        result = await instance.post(
          `/Hotel/Booking/TreatsStatus`,
          {
            request: [...bookArray, ...cancelArray],
            corellationId: progressId,
          },
          { withCredentials: true }
        );
        taskStatus = result.data.overallStatus;
        delay(4000);
      }

      if (!result) {
        setBookingStatus({
          status: "error",
        });
        return;
      }

      if (source === "Treats") {
        getItinerary().then(async (res) => {
          setBookingStatus({
            status: "after-response",
            results: result.data.bookings,
          });
        });
      } else {
        return result.data.bookings;
      }
    } else if (
      res.data.overallStatus.toLowerCase() === "error" ||
      res.data.overallStatus.toLowerCase() === "warning"
    ) {
      if (source === "Treats") {
        getItinerary().then(async (res) => {
          setBookingStatus({
            status: "after-response",
            results: result.data.bookings,
          });
        });
      } else {
        return result.data.bookings;
      }
    }
  };

  const saveBookings = async (skip, toRemove) => {
    try {
      const { filteredBookingSelections, toRemoveBooked } =
        regulateBookingSelections(skip, toRemove);

      if (view === "Group_Linking") {
        const isAvailable = checkAvailabilityBeforeLinking(
          availibility,
          filteredBookingSelections
        );

        if (!isAvailable) {
          return false;
        }

        for (let index = 0; index < GroupTypes.length; index++) {
          const type = GroupTypes[index];
          const singularTypeReservations = filteredBookingSelections.filter(
            (bookItem) => bookItem.type === type
          );

          if (singularTypeReservations.length <= 0) {
            continue;
          }
          const { cancelArray, bookArray, isGroupRequest } = structureRequests(
            singularTypeReservations,
            toRemoveBooked,
            type
          );

          await sendBookingRequest(
            cancelArray,
            bookArray,
            isGroupRequest,
            type,
            toRemoveBooked
          );
        }
        return true;
      } else {
        const { cancelArray, bookArray, diningAddOns, isGroupRequest } =
          structureRequests(filteredBookingSelections, toRemoveBooked, view);

        const res = await sendBookingRequest(
          cancelArray,
          bookArray,
          isGroupRequest,
          view,
          diningAddOns
        );
        await afterSaveSync(res, view, bookArray, cancelArray, diningAddOns);
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in saveBookings func inside SaveBookingHook file ${
          error.message
        }`
      );
    }
  };

  const autoSelectData = async (selections, avaliableDays, editMode) => {
    const { action } = selections[0];
    if (action !== "add") {
      const editedSelection = selections.find(
        ({ action }) => action === "edit"
      );
      const { facilityGUID, action, time, restaurant } = editedSelection;

      for (let index = 0; index < selections.length; index++) {
        const element = selections[index];
        if (element.action === "remove") {
          selections[index].facilityGUID = facilityGUID;
          selections[index].action = action;
          selections[index].time = time;
          selections[index].restaurant = restaurant;
          selections[index].title = restaurant;
        }
      }

      return selections;
    } else {
      const { facilityGUID, time, restaurant, type } = selections[0];
      for await (let avaliableDay of avaliableDays) {
        if (
          !selections.includes({
            day: avaliableDay.date,
            facilityGUID,
            restaurant,
            time,
            ReservationNumber: reservation.ReservationNumber,
            LastName: reservation.LastName,
            FirstName: reservation.FirstName,
            action: "add",
            type,
            title: undefined,
          })
        ) {
          selections.push({
            day: avaliableDay.date,
            facilityGUID,
            restaurant,
            time,
            ReservationNumber: reservation.ReservationNumber,
            LastName: reservation.LastName,
            FirstName: reservation.FirstName,
            action: "add",
            type,
            title: undefined,
          });
        }
      }
      return selections;
    }
  };

  return { saveBookings, autoSelectData };
};
export default SaveBookingHook;

import React from "react";
import style from "./promotional-page.module.css";

const PromotionalPage = () => {
  return (
    <>
      <div
        style={{
          height: "100vh",
          flex: "1 1",
          width: "100%",
          backgroundImage:
            "url(https://strapi-admin-images.s3.eu-central-1.amazonaws.com/Serve_App_Background_0cf55e204d.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className={style.logoWrapper}>
          <img
            className={style.logo}
            src={"/img/PromotionalPage/My_Warner_Serve_Logo.jpg"}
            alt="no logo"
          />
        </div>
        <div className={style.contentWrapper}>
          <div className={style.headerStyle}>
            <h1 style={{ fontFamily: "Cabrito Norm ExBold" }}>Download</h1>
            <h1 style={{ fontFamily: "Cabrito Norm ExBold" }}>our app!</h1>
          </div>
          <div className={style.phoneImgWrapper}>
            <div className={style.textStyle}>
              <p style={{ fontFamily: "Cabrito Norm Book" }}>
                Order and pay for
              </p>
              <p style={{ fontFamily: "Cabrito Norm Book" }}>drinks from the</p>
              <p style={{ fontFamily: "Cabrito Norm Book" }}>
                comfort of your table
              </p>
            </div>

            <div style={{ paddingTop: "20px" }}>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.mywarnerstay.iorder&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    className={style.iconStyle}
                    alt="Get it on Google Play"
                    src="https://lh3.googleusercontent.com/cjsqrWQKJQp9RFO7-hJ9AfpKzbUb_Y84vXfjlP0iRHBvladwAfXih984olktDhPnFqyZ0nu9A5jvFwOEQPXzv7hr3ce3QVsLN8kQ2Ao=s0"
                  />
                </a>
              </div>
              <div style={{ paddingTop: "10px" }}>
                <a href="https://itunes.apple.com/app/id1593420690">
                  <img
                    className={style.iconStyle}
                    src={
                      "/img/PromotionalPage/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                    }
                    alt={"Get it on App Store"}
                  />
                </a>
              </div>

              <div
                className={style.phoneImg}
                style={{
                  backgroundImage:
                    "url(/img/PromotionalPage/My_Warner_Serve_Mobile-removebg-preview.png)",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionalPage;

import React from "react";
import DayButtonLogic from "./DayButtonLogic";

import styles from "./day_button.module.css";

const AllDayButton = ({
  handleClick,
  isSelected,
  beforeSelected,
  length,
  view,
}) => {
  const { widthStyle, isPreparing } =
    DayButtonLogic(view, length);

  const isInWhatsOnGuide = view === "WhatsOnGuide";

  return (
    <>
      {isPreparing ? (
        <></>
      ) : (
        <div
          className={`${styles.dayBtn} ${isSelected ? styles.focused : ""} ${
            beforeSelected ? styles.beforeSelect : null
          }`}
          onClick={handleClick}
          style={{
            width: widthStyle.width,
            height: isInWhatsOnGuide || isSelected ? "75px" : null,
          }}
        >
          <div className={styles.day}>
            <span
              className={`${styles.dayWrapper} ${styles.dayWrapper_desktop}`}
            >
              All Day Activities
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default AllDayButton;

import { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";

import { goToTheTop } from "../helpingFunctions/linkChange";

const ScrollToTop = ({ view }) => {
  const location = useLocation()

  useEffect(() => {
    goToTheTop()
  }, [view, location]);

  return null;
};

export default withRouter(ScrollToTop);

import { useState, useEffect, useContext } from "react";

import { DataContext } from "../../context/DataContext";
import { findOneFromObjList } from "../../helpingFunctions/arrays";
import { calcTotalGuests } from "../../helpingFunctions/groups";
import axiosInstance from "../../url/createAxios";

const settings = {
  Group_Table: {
    enpoint: "/group-table-page",
  },
  Group_Create: {
    enpoint: "/create-group-page",
  },
};

const GroupBookingLogic = (view) => {
  const instance = axiosInstance();
  const [totalGuests, setTotalGuests] = useState(0);
  const [viewData, setViewData] = useState({});
  const { groupData, setGroupData } = useContext(DataContext);

  const getGroupCreatePageData = async (type) => {
    const { data } = await instance.get(settings[type].enpoint, {withCredentials: true});

    setViewData({
      DesktopDescription_max_length_is_340:
        data.DesktopDescription_max_length_is_500,
      DesktopTitle: data.DesktopTitle,
      MobileDescription: data.MobileDescription,
      MobileTitle: data.MobileTitle,
      TitleTooltip: data.TitleTooltip,
      GroupNameTooltip: data.GroupNameTooltip,
      SurnameTooltip: data.SurnameTooltip,
      BookingIdTooltip: data.BookingIdTooltip
    });
  };

  useEffect(() => {
    getGroupCreatePageData(view.id === 1 ? "Group_Create" : "Group_Table");
  }, [view]);

  useEffect(() => {
    if (groupData.items.length > 0)
      setTotalGuests(calcTotalGuests(groupData.items));
  }, [groupData.items]);

  return { totalGuests, groupData, setGroupData, viewData, setTotalGuests };
};

export default GroupBookingLogic;

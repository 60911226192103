import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { DataContext } from "../../context/DataContext";
import {
  calculteDateDifference,
  formatDate,
} from "../../helpingFunctions/utilities";
import WindowWidth from "../../hooks/WindowWidth";
import axiosInstance from "../../url/createAxios";

import styles from "./welcome.module.css";

const WelcomingModalLogic = (setView) => {
  const history = useHistory();
  const instance = axiosInstance();
  const { reservation, clearOnLogOut } = useContext(DataContext);
  const { width } = WindowWidth();

  const [welcomeHeadingString, setWelcomeHeadingString] = useState("");
  const [arrivalDate, setArrivalDate] = useState([]);
  const [homePageDesc, setHomePageDesc] = useState("");

  const highlightContent = (content) => (
    <span className={styles.WelcomeModal__highlights}>{content}</span>
  );

  const constructArrivalTime = (reservation) => {
    const { ArrivalDate } = reservation;
    const beginText = "Your next stay with us is in ";
    const difference = calculteDateDifference(
      new Date(),
      new Date(ArrivalDate)
    );

    if (!difference) {
      setWelcomeHeadingString(`Welcome`);
      return;
    }

    if (difference.weeks > 0) {
      setWelcomeHeadingString(
        <>
          {width > 1324 ? beginText : null}
          {highlightContent(
            <>
              {difference.weeks} weeks and {difference.days} days
            </>
          )}
        </>
      );
    } else {
      setWelcomeHeadingString(
        <>
          {beginText}
          {highlightContent(<>{difference.days} days</>)}
        </>
      );
    }
  };

  const findArrivalDate = (data) => {
    const { ArrivalDate } = data;
    const formatedDate = formatDate(ArrivalDate);

    setArrivalDate(formatedDate);
  };

  const getHomePageDesc = async () => {
    try {

      const { data } = await instance.get("/home-page/desc");

      setHomePageDesc(
        width > 1324 ? data.DesktopDescription : data.MobileDescription
      );
    } catch (e) {
      console.log(
        `${new Date()} error in getHomePageDesc func in WelcomingModalLogic.js ${e}`
      );
    }
  };

  const logOut = async () => {

    await instance.delete(`/login`);
    history.push("/");
    clearOnLogOut(setView)
  };

  useEffect(() => {
    if (reservation) {
      findArrivalDate(reservation);
      constructArrivalTime(reservation);
    }
  }, [reservation]);

  useEffect(() => {
    getHomePageDesc();
  }, []);
  return {
    welcomeHeadingString,
    arrivalDate,
    reservation,
    homePageDesc,
    highlightContent,
    logOut
  };
};

export default WelcomingModalLogic;

import { BOOKING_TYPES } from "../constants/constants";

const findSpaPrice = (Id, facilities) => {
  try {
    const { Price } = facilities.find(
      ({ id, objType }) => id === Id && objType.toLowerCase() === "spa"
    );

    return Price;
  } catch (error) {
    console.log(
      `${new Date()} Error in findSpaPrice func in helpingFunctions.js file: ${error}`
    );
  }
};

const findTreatsPrice = (Id, facilities) => {
  try {
    const { Price } = facilities.find(
      ({ id, objType }) => id === Id && objType.toLowerCase() === "treats"
    );

    return Price;
  } catch (error) {
    console.log(
      `${new Date()} Error in findSpaPrice func in helpingFunctions.js file: ${error}`
    );
  }
};

const findPrice = (id, availibility) => {
  const facilities = availibility
    .map(({ Facilities }) => {
      const facility = Facilities.find(
        ({ FacilityGUID }) => FacilityGUID === id
      );
      if (facility) {
        return facility;
      }
    })
    .filter((x) => x);

  const times = facilities.map(({ Times }) => Times);

  let prices = times
    .map((time) => {
      return time.map(({ Price }) => Price);
    })
    .flat();

  if (prices.length <= 0) return null;
  prices = prices.filter((row) => !!row)
  const min = Math.min(...prices);

  return min;
};

const isInclusive = (price, type) => {
  return price === 0.0
    ? "Inclusive"
    : price === "" || price === null || price === "null" || isNaN(price)
    ? "Sold out"
    : type === "Treats" ? `£${parseFloat(price).toFixed(2)}` : `£${parseFloat(price).toFixed(2)} (per person)`;
};

const obtainPrice = (object_id, type, facilitiesData, availibility) => {
  if(!availibility || type === BOOKING_TYPES.InternalActivity) {
    return;
  }
  const price =
    type === "Spa"
      ? findSpaPrice(object_id, facilitiesData)
      : type === "Treats"
      ? findTreatsPrice(object_id, facilitiesData)
      : findPrice(object_id, availibility);

  return price;
};
const managePriceTxt = (object, facilitiesData, availibility) => {
  const price = obtainPrice(object.id, object.objType, facilitiesData, availibility);

  return isInclusive(price, object.objType)
};

const checkAvailability = (availibility, id, setHasAvailability) => {
  availibility.forEach((row) => {
    const foundAvailability = row.Facilities.find((facility) => facility.FacilityGUID === id);
    
    if (foundAvailability) {
      setHasAvailability(true);
      return;
    }
  })
};

export { isInclusive, findPrice, findSpaPrice, obtainPrice, managePriceTxt, checkAvailability };

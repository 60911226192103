import { useEffect, useState } from "react";

const GuideItemDesktopLogic = (desc, isSelected, guide) => {
  const [formattedDesc, setFormattedDesc] = useState({});

  useEffect(() => {
    descriptionLengthHandler();
  }, [isSelected,guide]);

  const descriptionLengthHandler = () => {
    if (isSelected || !desc || desc.length < 80) {
      setFormattedDesc({
        desc,
        hasMoreContent: false
      });
      return;
    }
    const lastIndex = desc.length;
    const shortageIndex = desc.indexOf(".", 80) + 1;
  
    if(lastIndex === shortageIndex) {
        setFormattedDesc({
            desc,
            hasMoreContent: false
          });
          return
    }

    setTimeout(() => {
      setFormattedDesc({
        desc: desc.substr(0, shortageIndex),
        hasMoreContent: true
      });
    }, 500);
  };

  return { descriptionLengthHandler, formattedDesc };
};

export default GuideItemDesktopLogic;

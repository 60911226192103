import React from "react";
import Markdown from "markdown-to-jsx";

import DaysList from "../../../components/DaysList/DaysList";
import Button from "../../../shared/Button/Button";
import Heading from "../../../shared/Heading/Heading";
import IndicatiorCircle from "../../../shared/IndicatorNumber/IndicatiorCircle";
import { formatDate, getDayOfWeek } from "../../../helpingFunctions/utilities";
import ImageCarousel from "../../../shared/ImageCarousel/ImageCarousel";
import AllSelectionsPopUp from "../../../shared/AllSelectionPopUp/AllSelectionsPopUp";
import AlertBox from "../../../components/AlertBox/AlertBox";
import ActivityTimeSlotsDesktop from "../../../components/ActivityTimeSlots/ActivityTimeSlotsDesktop/ActivityTimeSlotsDesktop";
import { xSign } from "../../../assets/SVGIcons";
import SpaExtraSection from "../../../components/SpaExtra/SpaExtraSection";
import SpaExtraTable from "../../../components/SpaExtra/SpaExtraTable";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import ConflictAlertBox from "../../../shared/validation/ConflictAlertBox";
import SpaPreviewMultGuestsLogic from "../ActivityAndSpaLogic/SpaPreviewMultGuestsLogic";
import WindowWidth from "../../../hooks/WindowWidth";
import InformationalBoxHeavy from "../../../shared/Containers/InformationalBoxHeavy";

import styles from "./activity-preview.module.css";

const dayTimes = ["Morning", "Afternoon"];

const SpaPreviewDesktop = ({ id, view, setView, goTo }) => {
  const { width } = WindowWidth();

  const {
    isOnEditModePerSelectedDate,
    spaSelection,
    unfinishedSelection,
    showSpaExtraSection,
    filteredBookings,
    reservation,
    termsChecked,
    showBookConfirmation,
    isEditMode,
    actionAlertObj,
    hasChanges,
    bookingStatus,
    showBookAnotherTreatmentDialog,
    selectedDate,
    object,
    availableDates,
    spaLoading,
    alertStatus,
    alertMessage,
    priceTxt,
    spaFormData,
    setSpaSelection,
    setUnfinishedSelection,
    setTermsChecked,
    setShowSpaExtraSection,
    setActionAlertObj,
    setShowBookConfirmation,
    setIsEditMode,
    handleShowSpaExtraSection,
    prompSpaConfirmation,
    closeActionAlert,
    checkUnconfirmChanges,
    setShowBookAnotherTreatmentDialog,
    setSpaFormData,
    setActivityTimeLimit,
    manageBooking,
    goBack,
    showConflict,
    closeAlert,
    manageSpaFormData,
    isReservationIn3Months,
  } = SpaPreviewMultGuestsLogic(id, view, setView, goTo);


  return (
    <>
      {!object || !selectedDate ? (
        <LoadingContainer />
      ) : (
        <div className={styles.activityPreviewWrapper}>
          <div className={styles.activityDetails}>
            <div className={styles.activityHeader}>
              <div className={styles.activityTitle}>
                <Heading
                  txt={object?.Title}
                  paddingBottom={"20px"}
                  paddingTop={"40px"}
                  fontSize={"24px"}
                />
              </div>
              <div className={styles.closeBTn} onClick={goBack}>
                {xSign()}
              </div>
            </div>
            <div className={styles.activityOverview}>
              <div className={styles.activityImages}>
                <div className={styles.sliderWrapper}>
                  <ImageCarousel
                    onClick={() => {}}
                    sliderImages={object.Image}
                  />
                </div>
              </div>
              <div className={styles.activityDescription}>
                <Heading txt={priceTxt} fontSize={"1.3rem"} />
                <div className={styles.activitiesTextWrapper}>
                  {object.LongDescription ? (
                    <Markdown>{object.LongDescription}</Markdown>
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.activitiesIconWrapper}>
                  {object.Details.map(({ icon }, index) =>
                    icon ? (
                      <IndicatiorCircle
                        key={index}
                        txt={
                          <img
                            width={"66%"}
                            height={"57%"}
                            src={icon?.formats?.thumbnail?.url}
                            alt={""}
                          />
                        }
                        backgroundColor={"transparent"}
                        color={"#5f5f5f"}
                        border={"1px solid #5f5f5f"}
                        width={"50px"}
                        height={"50px"}
                        fontSize={"1.2rem"}
                      />
                    ) : null
                  )}
                </div>
                <div className={styles.spaTermsWrapper}>
                  <a
                    className={styles.termsAndConditions}
                    href={`${object.PolicyLink}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Cancellation Terms & Conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.activityBookingSection}>
            <DaysList data={availableDates} hideContent={true} view={view} />

            <div
              className={styles.activityBooking}
              style={{ paddingRight: "4rem" }}
            >
              {spaSelection[selectedDate]?.length > 0 ? <div style={{ padding: "20px 30px 30px 0" }}>
                <p>
                  {`You are booking `}
                  <strong>{object.Title}</strong>
                  {" treatment for "}
                  <strong>
                    {getDayOfWeek(selectedDate)}: {formatDate(selectedDate)}
                  </strong>
                </p>
                <p>
                  {"Please select the desired time below for your treatment."}
                </p>
              </div> : null}
              {spaSelection.hasOwnProperty(selectedDate) ? (
                <div
                  className={styles.timeSlotsWrapper}
                  style={{
                    borderBottom: "1px solid rgb(181, 181, 183)",
                    paddingBottom: "30px",
                  }}
                >
                  {spaSelection[selectedDate].length > 0 ? (
                    dayTimes.map((dayTime, index) => (
                      <ActivityTimeSlotsDesktop
                        key={dayTime}
                        data={spaSelection[selectedDate]}
                        spaLoading={spaLoading}
                        type={dayTime}
                        id={id}
                        paddingLeft={index === 1 ? "2rem" : null}
                        borderLeft={index === 1 ? "1px solid #b5b5b7" : null}
                        view={view}
                        setActivityTimeLimit={setActivityTimeLimit}
                        setSpaSelection={setSpaSelection}
                        spaSelection={spaSelection}
                        setUnfinishedSelection={setUnfinishedSelection}
                        unfinishedSelection={unfinishedSelection}
                      />
                    ))
                  ) : (
                    <InformationalBoxHeavy
                      title={
                        isReservationIn3Months
                          ? null
                          : "Spa treatments for your break available soon..."
                      }
                      content={
                        isReservationIn3Months
                          ? `Sorry, there are no available bookings/times for ${object.Title}, there may be cancellations available, please check with guest services at the ${reservation.Hotel.Title}.`
                          : "Sorry, we haven't released any availability for this date yet, however if you check back closer to your break date, we should have availability."
                      }
                      boxNewStyles={{ marginTop: "20px" }}
                    />
                  )}
                </div>
              ) : (
                <LoadingContainer />
              )}

              {!spaLoading[selectedDate]?.isLoading &&
              spaSelection.hasOwnProperty(selectedDate) &&
              spaSelection[selectedDate].length > 0 ? (
                <>
                  {showSpaExtraSection ||
                  (!showSpaExtraSection && filteredBookings.length === 0) ? (
                    <SpaExtraSection
                      reservation={reservation}
                      termsChecked={termsChecked}
                      manageSpaFormData={manageSpaFormData}
                      spaFormData={spaFormData}
                      setSpaFormData={setSpaFormData}
                      spaSelection={spaSelection}
                      setSpaSelection={setSpaSelection}
                      setTermsChecked={setTermsChecked}
                      unfinishedSelection={unfinishedSelection}
                      setUnfinishedSelection={setUnfinishedSelection}
                      optionDetails={object}
                      setShowSpaExtraSection={setShowSpaExtraSection}
                      setActionAlertObj={setActionAlertObj}
                      setShowBookConfirmation={setShowBookConfirmation}
                      showBookConfirmation={showBookConfirmation}
                      isEditMode={isEditMode}
                      setIsEditMode={setIsEditMode}
                      selectedDate={selectedDate}
                      policyLink={object.PolicyLink}
                      width={width}
                    />
                  ) : null}

                  {hasChanges ? (
                    <div
                      className={styles.bookingButtons}
                      style={{ paddingTop: "15px", paddingBottom: "15px", display: "flex" }}
                    >
                      <Button
                        txt={"Discard changes"}
                        onClick={goBack}
                        color={"var(--color-primary)"}
                        backgroundColor={"var(--color-secondary)"}
                        newStyles={{ marginLeft: "0", width: "220px" }}
                      />
                      <Button
                        txt={`${isOnEditModePerSelectedDate ? "Confirm Change" : "Confirm Booking"}`}
                        onClick={manageBooking}
                        color={"var(--color-secondary)"}
                        backgroundColor={"var(--color-primary)"}
                        newStyles={{ width: "260px" }}
                      />
                    </div>
                  ) : null}

                  {filteredBookings.length > 0 &&
                  !showSpaExtraSection &&
                  spaSelection.hasOwnProperty(selectedDate) ? (
                    <>
                      <div
                        style={{
                          padding: "20px 0 20px 0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          txt={"Book another guest"}
                          onClick={handleShowSpaExtraSection}
                          color={"var(--color-primary)"}
                          backgroundColor={"var(--color-secondary)"}
                        />
                        <div style={{ paddingLeft: "25px" }}>
                          <Button
                            txt={"Book another treatment"}
                            onClick={() => checkUnconfirmChanges()}
                            color={"var(--color-primary)"}
                            backgroundColor={"var(--color-secondary)"}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  {filteredBookings.length > 0 &&
                  !spaLoading[selectedDate]?.isLoading &&
                  spaSelection.hasOwnProperty(selectedDate) ? (
                    <SpaExtraTable
                      filteredBookings={filteredBookings}
                      policyLink={object.PolicyLink}
                      servicePrice={object.Price}
                      selectedDate={selectedDate}
                      setShowSpaExtraSection={setShowSpaExtraSection}
                      setUnfinishedSelection={setUnfinishedSelection}
                      setTermsChecked={setTermsChecked}
                      termsChecked={termsChecked}
                      setSpaFormData={setSpaFormData}
                      unfinishedSelection={unfinishedSelection}
                      spaSelection={spaSelection}
                      setSpaSelection={setSpaSelection}
                      isEditMode={isEditMode}
                      setIsEditMode={setIsEditMode}
                      width={width}
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
          <div className={styles.activityBookingFooter}>
            {/* {timeSelected(bookingSelections, selectedDate) && !editMode ? (
              <Heading
                txt={`You are about to book ${object.Title} at ${timeSelected(
                  bookingSelections,
                  selectedDate
                )} on ${formatDate(selectedDate)}`}
                paddingBottom={"20px"}
              />
            ) : editMode && editMode === "edited" ? (
              <Heading
                txt={`You are about to modify/cancel previous selection(s)`}
                paddingBottom={"20px"}
              />
            ) : null} */}
          </div>

          {bookingStatus ? (
            <AllSelectionsPopUp
              view={view}
              setView={(v) => setView(v)}
              mainObjectId={id}
            />
          ) : null}

          {actionAlertObj?.status === "missing-atr" ? (
            <AlertBox
              txt={actionAlertObj.alertMessage}
              button={
                <>
                  <Button
                    txt={"Ok"}
                    color={"var(--color-secondary)"}
                    onClick={closeActionAlert}
                    backgroundColor={"var(--color-primary)"}
                  />
                </>
              }
            />
          ) : null}

          {showBookConfirmation === "show" ? (
            <AlertBox
              customContent={
                <div>
                  <p>
                    {`You have selected `}
                    <strong>{object.Title}</strong>
                    {` at ${unfinishedSelection.time.substring(
                      0,
                      5
                    )} on ${getDayOfWeek(
                      unfinishedSelection.date
                    )}: ${formatDate(unfinishedSelection.date)}.`}
                  </p>
                  <p>
                    {`This will add `} <strong>£{object.Price}</strong>{" "}
                    {` to your bill.`}
                  </p>
                </div>
              }
              button={
                <>
                  <Button
                    txt={"Cancel"}
                    backgroundColor={"var(--color-secondary)"}
                    color={"var(--color-primary)"}
                    border={"2px solid var(--color-primary)"}
                    onClick={() => prompSpaConfirmation("No")}
                  />
                  <Button
                    txt={"I agree"}
                    backgroundColor={"var(--color-primary)"}
                    color={"var(--color-secondary)"}
                    onClick={() => prompSpaConfirmation("Yes")}
                  />
                </>
              }
              boxNewStyles={{ width: "640px", minWidth: "640px" }}
            />
          ) : null}

          {alertStatus === "canceling" ? (
            <AlertBox
              txt={
                "We are proccessing your cancel request, this will take some time."
              }
            />
          ) : alertStatus === "non-valid" ? (
            <AlertBox
              txt={alertMessage}
              button={
                <>
                  <Button txt={"Ok"} backgroundColor={"var(--color-primary)"} color={"var(--color-secondary)"} onClick={closeAlert} />
                </>
              }
            />
          ) : ["strong-conflict", "soft-conflict", "average-conflict"].includes(
              alertStatus
            ) ? (
            <ConflictAlertBox
              alertMessage={alertMessage}
              alertStatus={alertStatus}
              showConflict={showConflict}
              spaSelection={spaSelection}
              setSpaSelection={setSpaSelection}
            />
          ) : showBookAnotherTreatmentDialog ? (
            <AlertBox
              customContent={
                <div>
                  <p>
                    By continuing with this action, you will dismiss all
                    unconfirmed selections. Are you sure?
                  </p>
                </div>
              }
              button={
                <>
                  <Button
                    txt={"Cancel"}
                    backgroundColor={"var(--color-secondary)"}
                    color={"var(--color-primary)"}
                    border={"2px solid var(--color-primary)"}
                    onClick={() => setShowBookAnotherTreatmentDialog(false)}
                  />
                  <Button
                    txt={"Ok"}
                    backgroundColor={"var(--color-primary)"}
                    color={"var(--color-secondary)"}
                    onClick={() => goBack()}
                  />
                </>
              }
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default SpaPreviewDesktop;

import { useContext, useState } from "react";
import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import SaveBookingHook from "../../hooks/SaveBookingHook";

const HandleBookingConflicts = (view, setAlertStatus, setAlertMessage) => {
  const [conflicts, setConflicts] = useState([]);
  const [test, setTest] = useState([]);
  const { saveBookings } = SaveBookingHook(view);
  const { reservation, availibility } = useContext(DataContext);
  const { bookingSelections, setBookingSelections } =
    useContext(SelectionContext);
  let toRemove = [];
  const handleStrongConflict = (conflictObj, spaSelection, setSpaSelection) => {
    reverseAction(conflictObj, spaSelection, setSpaSelection);
  };
  let skip = false;
  const handleSoftConflict = () => {
    setAlertStatus(null);
    setAlertMessage(null);
  };

  const handleAverageConflict = (
    action,
    conflictObj,
    totalAvrgConflicts,
    totalAvrgConflictsUnresolved
  ) => {
    if (action === "cancel") {
      if (conflictObj.isInclusiveActivity) {
        const bookedIndex = bookingSelections.findIndex(
          (row) => row.action !== "add" && row.action !== "remove"
        );

        if (bookedIndex !== -1) {
          bookingSelections[bookedIndex].action = "none";
          bookingSelections[bookedIndex].time =
            bookingSelections[bookedIndex].timeBooked;
        }

        setBookingSelections((prevState) =>
          prevState.filter(
            (row) =>
              row.facilityGUID[0] === conflictObj.booked.Id &&
              (row.action === "none" || row.action === "remove")
          )
        );

        setAlertStatus(null);
        setAlertMessage(null);
        skip = true;
        return;
      }

      // Trigger prev state restoration if we have only 1 avrg conflict or if
      // we have multiple avrg confclits, we just resolved the last one and
      // replace was not press for any of them
      const hasReplacement = bookingSelections.find(
        (row) => row.day === conflictObj.booked.Date && row.action === "remove"
      );
      if (
        totalAvrgConflicts === 1 ||
        (totalAvrgConflicts > 1 &&
          totalAvrgConflictsUnresolved === 1 &&
          !hasReplacement)
      ) {
        // Filter out all records different from the currently operated facility/service
        let differentFacilityGUIDItems = bookingSelections.filter(
          (row) =>
            row.facilityGUID[0] === conflictObj.newlySelected.facilityGUID[0]
        );

        const indexesToRemove = [];
        // if same day - reverse if action is remove or edit
        // mark for delete if its add
        differentFacilityGUIDItems.forEach((row, index) => {
          if (row.day === conflictObj.booked.Date) {
            if (row.action === "remove" || row.action === "edit") {
              row.action = "none";
              row.time = row.timeBooked;
            } else if (row.action === "add") {
              indexesToRemove.push(index);
            }
          }
        });

        // Remove same day add cases
        differentFacilityGUIDItems = differentFacilityGUIDItems.filter(
          (row, index) => !indexesToRemove.includes(index)
        );

        setBookingSelections(differentFacilityGUIDItems);
        skip = true;
      }
      setAlertStatus(null);
      setAlertMessage(null);
    } else if (action === "replace") {
      const preparedForRemoveal = bookingSelections.findIndex(
        (row) =>
          row.day === conflictObj.booked.Date &&
          ["remove", "edit"].includes(row.action) && conflictObj.newlySelected.facilityGUID.includes(conflictObj.booked.Id)
      );
      
      if (preparedForRemoveal === -1) {
        const obj = {
          FirstName: reservation.FirstName,
          LastName: reservation.LastName,
          Quantity: Number(conflictObj.booked.Quantity),
          ReservationNumber: reservation.ReservationNumber,
          action: "remove",
          day: conflictObj.booked.Date,
          facilityGUID: [conflictObj.booked.Id],
          quantityBooked: Number(conflictObj.booked.Quantity),
          timeBooked: conflictObj.booked.Time,
          title: conflictObj.booked.Title,
          type: conflictObj.booked.Type,
        };
        toRemove.push(obj)
        setTest(prevState => [...prevState, ...toRemove])
        // toRemove = test;

        // setBookingSelections((prevState) => {
        //   return [...prevState, obj];
        // });
      }

      setAlertStatus(null);
      setAlertMessage(null);
    }
  };

  const showConflict = (actionType, spaSelection, setSpaSelection) => {
    const action = actionType.split("|");

    const currentUnresolvedIndex = conflicts.findIndex(
      (row) => row.conflictType === action[1] && !row.isResolved
    );

    if (action[1] === "soft-conflict") {
      handleSoftConflict();
    } else if (action[1] === "strong-conflict") {
      handleStrongConflict(conflicts[currentUnresolvedIndex], spaSelection, setSpaSelection);
    } else if (action[1] === "average-conflict") {
      const totalAvrgConflicts = conflicts.filter(
        (row) => row.conflictType === "average-conflict"
      ).length;
      const totalAvrgConflictsUnresolved = conflicts.filter(
        (row) => row.conflictType === "average-conflict" && !row.isResolved
      ).length;

      handleAverageConflict(
        action[0],
        conflicts[currentUnresolvedIndex],
        totalAvrgConflicts,
        totalAvrgConflictsUnresolved
      );
    }
    conflicts[currentUnresolvedIndex].isResolved = true;
    setConflicts(conflicts);

    const nextUnresolvedIndex = conflicts.findIndex((row) => !row.isResolved);
    if (nextUnresolvedIndex !== -1) {
      setAlertStatus(conflicts[nextUnresolvedIndex].conflictType);
      setAlertMessage(conflicts[nextUnresolvedIndex].message);
    } else {
      const hasStrongConflictsIndex = conflicts.findIndex(
        (row) => row.conflictType === "strong-conflict"
      );

      if (hasStrongConflictsIndex === -1) {
        // if (toRemove.length === 0 && test.length === 0) {
        //   setConflicts([]);
        //   return;
        // };
        saveBookings(skip, [...toRemove, ...test]);
      }
      setConflicts([]);
    }
  };

  const prepareConflictPopups = (conflictDetails) => {
    const hasStrongConflictsIndex = conflictDetails.findIndex(
      (row) => row.conflictType === "strong-conflict"
    );
    let conflictDetailsReworked = conflictDetails;

    if (hasStrongConflictsIndex !== -1) {
      // If we have multiple conflicts and at least 1 is strong (spa) -> show just that one
      conflictDetailsReworked = conflictDetailsReworked.filter(
        (row, index) => index === hasStrongConflictsIndex
      );
    }

    setTest([]);
    conflictDetails.sort((a, b) => a.conflictType - b.conflictType);
    setConflicts(conflictDetailsReworked);
    setAlertStatus(conflictDetailsReworked[0].conflictType);
    setAlertMessage(conflictDetailsReworked[0].message);
  };

  const reverseAction = (conflictObj, spaSelection, setSpaSelection) => {
    setAlertStatus(null);
    setAlertMessage(null);

    const removeFromSelectionsIndex = bookingSelections.findIndex(
      (row) => row.day === conflictObj.booked.Date && row.action === "add"
    );

    if (view === "Spa") {
      const unsel = bookingSelections[removeFromSelectionsIndex]
      const spaOpt = {...spaSelection};
      const foundAvailndex = spaOpt[unsel.day].findIndex((row) => row.Time === unsel.time);

      if (foundAvailndex > -1) {
        const availObj = spaOpt[unsel.day][foundAvailndex];
        availObj.Availability++;
        availObj.ProviderCode.push(unsel.providerCode);
        spaOpt[unsel.day][foundAvailndex] = availObj;
        setSpaSelection(spaOpt);
      }
    }

    if (removeFromSelectionsIndex !== -1) {
      setBookingSelections((prevState) =>
        prevState.filter((row, index) => index !== removeFromSelectionsIndex)
      );
    }

    const reverseEditFromSelectionsIndex = bookingSelections.findIndex(
      (row) => row.day === conflictObj.booked.Date && row.action === "edit"
    );

    if (reverseEditFromSelectionsIndex !== -1) {
      bookingSelections[reverseEditFromSelectionsIndex].time =
        bookingSelections[reverseEditFromSelectionsIndex].timeBooked;
      bookingSelections[reverseEditFromSelectionsIndex].action = "none";
      setBookingSelections((prevState) => (prevState = bookingSelections));
    }
  };

  return {
    handleStrongConflict,
    handleSoftConflict,
    handleAverageConflict,
    showConflict,
    prepareConflictPopups,
  };
};

export default HandleBookingConflicts;

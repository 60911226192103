import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";
import { obtainPrice } from "../../helpingFunctions/facilityAndServices";
import { getVenueName } from "../../assets/requests";
import WindowWidth from "../../hooks/WindowWidth";
import { DiningTypes, BOOKING_TYPES, ActivitiesTypes } from "../../constants/constants";

const ItineraryLogic = () => {
  const [activities, setActivities] = useState([]);
  const [dinning, setDinning] = useState([]);
  const [loading, setLoading] = useState(true);

  const [expandAll, setExpandAll] = useState(false);
  const [preparedData, setPreparedData] = useState({});
  const [expand, setExpand] = useState({});
  const [dayLineColor, setDayLineColor] = useState({});
  const { width } = WindowWidth();
  const [hasHotelLunchOpt, setHasHotelLunchOpt] = useState(false);
  const [promotionalRowSpaceContent, setPromotionalRowSpaceContent] =
    useState(null);

  const {
    itineraryData,
    reservation,
    facilitiesData,
    availibility,
    entertainmentData,
    promotionalSpaceItineraryExpandedRowsDetails,
  } = useContext(DataContext);

  const prepareData = () => {
    try {
      const obj = {};
      const expandObj = {};
      const lineColorObj = {};
      itineraryData?.itineraryDates?.forEach((row) => {
        obj[row.Data] = {
          summary: {
            Arrival: [],
            Breakfast: [],
            Lunch: [],
            Dinner: [],
            Departure: [],
            Activity: [],
            Spa: [],
            Venue: [],
            Extras: [],
            "Internal Activity": []
          },
          details: [],
        };

        const bookings = [...row.Facilities];
        expandObj[row.Data] = false;

        if (row.Data === reservation.ArrivalDate.split("T")[0]) {
          const arrObj = {
            Title: "Arrival",
            Time: "15:00",
            Date: row.Data,
            Type: "Arrival",
          };
          bookings.push(arrObj);

          const hasDinner = hasDinningBooking(bookings, ["Dinner"]);
          if (!hasDinner) {
            bookings.push({
              Title: "Dinner",
              Time: "9999",
              Date: row.Data,
              Type: "Dinner",
            });
          }
        } else if (row.Data === reservation.DepartureDate.split("T")[0]) {
          const depObj = {
            Title: "Departure",
            Time: "10:00",
            Date: row.Data,
            Type: "Departure",
          };
          bookings.push(depObj);
          const hasBfst = hasDinningBooking(bookings, ["Breakfast"]);
          if (!hasBfst) {
            bookings.push({
              Title: "Breakfast",
              Time: "1",
              Date: row.Data,
              Type: "Breakfast",
            });
          }
        } else {
          const hasBfst = hasDinningBooking(bookings, ["Breakfast"]);
          if (!hasBfst) {
            bookings.push({
              Title: "Breakfast",
              Time: "1",
              Date: row.Data,
              Type: "Breakfast",
            });
          }
          const hasDinner = hasDinningBooking(bookings, ["Dinner"]);
          if (!hasDinner) {
            bookings.push({
              Title: "Dinner",
              Time: "9999",
              Date: row.Data,
              Type: "Dinner",
            });
          }
        }

        if (reservation.Hotel.TwoLetterCode === "HP") {
          const hasEnt = hasDinningBooking(bookings, ["Venue"]);
          if (!hasEnt && row.Data !== reservation.DepartureDate.split("T")[0]) {
            bookings.push({
              Title: "Showtime",
              Time: "9998",
              Date: row.Data,
              Type: "Venue",
            });
          }
        }

        bookings.forEach((row) => {
          if (["Breakfast", "Lunch", "Dinner"].includes(row.Type)) {
            row.priority = "A";
          } else if ([...ActivitiesTypes, "Spa"].includes(row.Type)) {
            if (
              ActivitiesTypes.includes(row.Type) &&
              obtainPrice(row.Id, row.Type, facilitiesData, availibility) === 0
            ) {
              row.priority = "C";
            } else {
              row.priority = "B";
            }
          } else {
            if (row.Type === "Treats") {
              row.priority = "E";
            } else row.priority = "D";
          }
        });

        bookings.sort((a, b) => {
          if (a.Type === "Treats" || b.Type === "Treats") {
            return a.priority.localeCompare(b.priority); // a.priority > b.priority ? -1 : 1;
          }

          if (a.Time === b.Time) {
            return a.priority.localeCompare(b.priority); //a.priority < b.priority ? -1 : 1;
          }

          return a.Time.localeCompare(b.Time); //a.Time > b.Time;
        });

        obj[row.Data].details = bookings.filter(
          (row) =>
            !["Arrival", "Departure"].includes(row.Type) &&
            !["1", "9998", "9999"].includes(row.Time)
        );

        bookings.forEach((booking) => {
          if (["Golf"].includes(booking.Type)) {
            obj[row.Data].summary.Extras.push(booking.Time.substring(0, 5));
          } else if (["Treats"].includes(booking.Type)) {
            obj[row.Data].summary.Extras.push("1 Treat");
          } else if (booking.Type === BOOKING_TYPES.InternalActivity) {
            obj[row.Data].summary["Activity"].push(
              booking.Time.substring(0, 5)
            );
          } else {
            obj[row.Data].summary[booking.Type].push(
              booking.Time.substring(0, 5)
            );
          }
        });

        lineColorObj[row.Data] = "var(--color-info)";
      });

      setPreparedData(obj);
      setExpand(expandObj);
      setDayLineColor(lineColorObj);
    } catch (error) {
      console.error(
        `${new Date()} Error in prepareData func in ItineraryLogic file ${
          error.message
        }`
      );
    }
  };

  const hasDinningBooking = (bookings, type) => {
    return bookings.find((row) => type.includes(row.Type));
  };

  const handleExpand = (date) => {
    let expandObjCopy = { ...expand };

    if (width < 1325) {
      Object.entries(expandObjCopy).forEach(([key, value]) => {
        if (value && key !== date) {
          expandObjCopy[key] = false;
        }
      });
    }

    expandObjCopy[date] = !expandObjCopy[date];
    setExpand(expandObjCopy);
  };

  const handleExpandAll = () => {
    let expandObjCopy = { ...expand };

    Object.entries(expandObjCopy).forEach(([key, value]) => {
      expandObjCopy[key] = !expandAll;
    });

    setExpand(expandObjCopy);
    setExpandAll(!expandAll);
  };

  const structureData = (typeList, type3) => {
    const bookedFacilities = itineraryData.itineraryDates.map(
      ({ Data, Facilities, Entertainment }) => {
        let bookedEnt = [];
        const bookedFacility = Facilities.filter(({ Type }) =>
          typeList.includes(Type)
        );

        if (type3) {
          bookedEnt = Entertainment.filter(({ Type }) => Type === "Venue");

          bookedEnt.forEach((row) => {
            row.Title = getVenueName(entertainmentData, { ...row, date: Data });
          });

          bookedFacility.forEach((booked) => {
            if (booked.Type === "Treats") {
              let code;
              let place;
              if (booked.ServiceCode.startsWith("l") || booked.ServiceCode.startsWith("d")) {
                code = booked.ServiceCode.substring(1);
                place = booked.ServiceCode[0] === "l" ? "Lunch" : "Dinner";
              } else {
                code = booked.ServiceCode;
                place = "In-room"
              }
              const detailsTreats = facilitiesData.find(
                (row) =>
                  row.ServiceCode === code &&
                  row.objType === "Treats"
              );

              booked.Title = detailsTreats?.Title;
              booked.Location = place;
              booked.Id = detailsTreats?.id;
              booked.Time = "9995";
            }
          });
        }
        const booked = [...bookedFacility, ...bookedEnt];

        booked.sort((a, b) => {
          return a.Time === "9999" && b.Time === "9999"
            ? a.Title.localeCompare(b.Title)
            : a.Time.localeCompare(b.Time);
        });

        if (bookedFacility || bookedEnt) {
          return {
            Data,
            bookedFacility: booked,
          };
        }
      }
    );

    return bookedFacilities;
  };

  const manageData = () => {
    const bookedActivities = structureData(
      [...ActivitiesTypes, "Spa", "Treats"],
      "Treats"
    );
    const bookedDinning = structureData(DiningTypes);

    setActivities(bookedActivities);
    setDinning(bookedDinning);
    setLoading(false);
  };

  const checkLunchOpt = () => {
    const restaurantsByType = facilitiesData.filter(
      ({ Type }) => Type === "Lunch"
    );
    setHasHotelLunchOpt(restaurantsByType.length > 0);

    if (promotionalSpaceItineraryExpandedRowsDetails.length > 0) {
      const promObj = promotionalSpaceItineraryExpandedRowsDetails.find(
        (row) => row.ButtonRedirect === "Lunch"
      );
      if (promObj) {
        setPromotionalRowSpaceContent(promObj);
      } else {
        setPromotionalRowSpaceContent(
          promotionalSpaceItineraryExpandedRowsDetails[0]
        );
      }
    }
  };

  useEffect(() => {
    if (itineraryData) {
      prepareData();
      manageData();
      checkLunchOpt();
    }
  }, [itineraryData]);

  return {
    handleExpand,
    handleExpandAll,
    expandAll,
    loading,
    preparedData,
    expand,
    dayLineColor,
    hasHotelLunchOpt,
    promotionalRowSpaceContent,
  };
};

export default ItineraryLogic;

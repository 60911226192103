import React from "react";

import RedirectToLandingPageLogic from "./RedirectToLandingPageLogic";

import styles from "./redirect.module.css";

const RedirectToLandingPage = () => {
  RedirectToLandingPageLogic();

  return (
    <div className={styles.redirectPageWrapper}>
      <p>We are redirecting you to another page</p>
    </div>
  );
};

export default RedirectToLandingPage;

import React, { useContext } from "react";
import Button from "../../../shared/Button/Button";
import Heading from "../../../shared/Heading/Heading";

import { DataContext } from "../../../context/DataContext";
import { SelectionContext } from "../../../context/SelectionContext";
import WindowWidth from "../../../hooks/WindowWidth";

import BookingPreviewImage from "../../../components/BookingPreviewImage/BookingPreviewImage";
import TreatsProcessingPopupRow from "./TreatsProcessingPopupRow";
import styles from "./TreatsProcessingPopupBox.module.css";

const TreatsProcessingPopupBox = ({
  setView,
  closePopUp,
  view,
  totalPriceToAdd,
  totalPriceToRemove,
  clickedObject,
}) => {
  const { width } = WindowWidth();
  const { bookingSelections, bookingStatus } = useContext(SelectionContext);
  const { reservation } = useContext(DataContext);
  const constructPopUpMessage = () => {
    const currentStatus = bookingStatus.status;
    let DisplayElement = null;

    if (currentStatus === "started" || currentStatus === "after-response") {
      DisplayElement = (
        <Heading
          txt={`We are verifying your bookings`}
          new_styles={{
            fontSize: "20px",
          }}
        />
      );
    } else if (currentStatus === "success") {
      DisplayElement = (
        <Heading
          txt={`
              You have successfully ${
                bookingSelections.filter(
                  (row) =>
                    ["remove", "edit", "none"].includes(row.action) &&
                    clickedObject.id === row.id
                ).length > 0
                  ? "changed"
                  : "booked"
              } your ${clickedObject.Title} options for your stay.
            `}
          new_styles={{
            fontSize: "20px",
            lineHeight: "24px",
          }}
        />
      );
    } else {
      DisplayElement = (
        <Heading
          txt={`Unfortunately we failed to book some of your selections. Please try again!`}
          new_styles={{
            fontSize: "20px",
          }}
        />
      );
    }

    return DisplayElement;
  };

  const getMsg = () => {
    const msg = ["A total charge of"];
    if (totalPriceToRemove > 0) {
      msg.push(`£${totalPriceToRemove} has been removed from your account`);
    }

    if (totalPriceToAdd > 0 && totalPriceToRemove > 0) {
      msg.push("and a total charge of");
    }

    if (totalPriceToAdd > 0) {
      msg.push(
        `£${totalPriceToAdd} has been added to your account for settlement at ${reservation.Hotel.Title}`
      );
    }

    msg.push(".");
    return msg.join(" ");
  };

  return (
    <>
      <div className={styles.popUpWrapper}>
        <div className={styles.grayOutScreen}></div>
        <div className={styles.bookingDetailsPopUp}>
          <div className={styles.successInfo}>{constructPopUpMessage()}</div>
          <div className={styles.bookingDetailsWrapper}>
            <ul
              className={styles.bookingList}
              style={
                width > 1324
                  ? { maxHeight: "400px", overflow: "auto", width: "658px" }
                  : null
              }
            >
              {bookingSelections
                ?.filter(
                  (row) => row.QuantityBooked !== 0 && row.action !== "none"
                )
                .map(
                  (
                    { Date, id, Quantity, title, action, QuantityBooked, ServiceCode, Location },
                    index
                  ) => (
                    <TreatsProcessingPopupRow
                      index={index}
                      Date={Date}
                      id={id}
                      Quantity={Quantity}
                      title={title}
                      action={action}
                      ServiceCode={ServiceCode}
                      QuantityBooked={QuantityBooked}
                      Location={Location}
                    />
                  )
                )}
            </ul>
            <BookingPreviewImage facilityGUID={clickedObject.id} view={view} />
          </div>
          {bookingStatus.status === "success" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "25px 20px 15px 20px",
              }}
            >
              {getMsg()}
            </div>
          ) : null}

          <div
            className={styles.previewButtons}
            style={{ justifyContent: "center", gap: "30px" }}
          >
            {bookingStatus.status === "success" ||
            bookingStatus.status === "failed" ? (
              <>
                <Button
                  txt={"Close"}
                  color={"var(--color-primary)"}
                  backgroundColor={"var(--color-secondary)"}
                  onClick={closePopUp}
                />

                <Button
                  txt={"Continue to itinerary"}
                  color={"var(--color-secondary)"}
                  backgroundColor={"var(--color-info)"}
                  newStyles={{ border: "2px solid var(--color-info)"}}
                  onClick={() => setView({ type: "itinerary" })}
                />
              </>
            ) : (
              <Button
                txt={"Close"}
                color={"var(--color-primary)"}
                backgroundColor={"var(--color-secondary)"}
                onClick={closePopUp}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TreatsProcessingPopupBox;

import { GROUP_LABELS } from "../constants/constants";
import Button from "../shared/Button/Button";
import { groupBy } from "./arrays";
import { sumNumbers } from "./helpingFunctions";

const handleButtonDisplay = (reservation, setView, isCreated = false, view) => {
  if (view !== "Breakfast" && view !== "Dinner") return;
  const objectProps = {};

  if (!isCreated) {
    objectProps.labelText = GROUP_LABELS.CreateGroup;
    objectProps.viewGr = false;
  } else if (reservation.IsGroupCreator) {
    objectProps.labelText = GROUP_LABELS.ManageGroup;
    objectProps.viewGr = false;
    objectProps.fromManage = true;
  } else if (!reservation.isGroupCreator) {
    objectProps.labelText = GROUP_LABELS.ViewGroup;
    objectProps.viewGr = true;
  }

  return (
    <Button
      txt={objectProps.labelText}
      onClick={() =>
        objectProps.viewGr
          ? setView({ type: "group-booking", id: 2, itineraryType: view })
          : setView({
              type: "group-booking",
              id: 1,
              itineraryType: view,
              fromManage: objectProps.fromManage,
            })
      }
      color={"var(--color-secondary)"}
      backgroundColor={"var(--color-primary)"}
    />
  );
};

const findParticipants = (selectedDate, itineraryData, itineraryType) => {
  try {
    if (!selectedDate || !itineraryData) return;

    const cleanedDate = selectedDate.endsWith("I")
      ? selectedDate.substr(0, selectedDate.length - 1)
      : selectedDate;
    const { itineraryDates } = itineraryData;

    const { Facilities } = itineraryDates.find(
      ({ Data }) => Data === cleanedDate
    );

    const { allParticipants } = Facilities.find(
      ({ Type }) => Type === itineraryType
    );
    return allParticipants || [];
  } catch (e) {
    console.error(
      `${new Date()} Error in findParticipants func in groups.js file ${e}`
    );
  }
};

const calcTotalGuests = (tableData) => {
  const numbers = tableData.map(({ NumberOfGuests }) => Number(NumberOfGuests));

  return numbers.reduce((a, b) => a + b, 0);
};

const additionalInfo = (changes, selectedDate, itineraryType) => {
  if (!changes) return null;

  const currentDateSelection = changes[selectedDate];

  if (
    !currentDateSelection ||
    !currentDateSelection.hasOwnProperty(itineraryType)
  )
    return null;

  if (currentDateSelection[itineraryType]) return null;
  else return "This reservation chose a different time";
};

const checkAvailabilityBeforeLinking = (availability, toBeLinkedSelections) => {
  try {
    const groupedReservations = groupBy(toBeLinkedSelections, [
      "LastName",
      "ReservationNumber",
    ]);

    const totalQtn = sumNumbers(groupedReservations, "Quantity");

    let hasAvailability = true;
    for (let index = 0; index < toBeLinkedSelections.length; index++) {
      const { facilityGUID, day, time, type } = toBeLinkedSelections[index];

      const currentDayAvailability = availability.find(
        ({ Date }) => Date === day
      );
      if (currentDayAvailability) {
        const FacilityByType = currentDayAvailability.Facilities.find(
          ({ Type, FacilityGUID }) =>
            Type === type && facilityGUID[0] === FacilityGUID
        );
        if (FacilityByType) {
          const selectedTime = FacilityByType.Times.find(
            ({ Time }) => Time === time
          );
          if (parseInt(selectedTime.Availability) < totalQtn) {
            hasAvailability = false;
          }
        } else {
          hasAvailability = false;
        }
      } else {
        hasAvailability = false;
      }
    }

    return hasAvailability
  } catch (error) {
    console.error(
      `${new Date()} Error in checkAvailabilityBeforeLinking func inside groups.js helper file ${
        error.message
      }`
    );
  }
};

const getTotalQtnForAddedReservations = (allGroupItems) => {
  try {
    const notAddedToTheGroupReservations = allGroupItems.filter(({id}) => !id);

    const totalQtn = sumNumbers(notAddedToTheGroupReservations, "NumberOfGuests");

    return totalQtn
  } catch (error) {
    console.error(
      `${new Date()} Error in getTotalQtnForAddedReservations func inside groups.js helper file ${
        error.message
      }`
    );
  }
}

export {
  handleButtonDisplay,
  findParticipants,
  calcTotalGuests,
  additionalInfo,
  checkAvailabilityBeforeLinking,
  getTotalQtnForAddedReservations
};

import React, { useEffect, useState } from "react";
import Button from "../../shared/Button/Button";
import InputCard from "../../components/InputCard/InputCard";
import SpaExtraSectionLogic from "./SpaExtraSectionLogic";
import AlertBox from "../../components/AlertBox/AlertBox";

import styles from "./spa-extra-section.module.css";

const SpaExtraSection = ({
  reservation,
  termsChecked,
  manageSpaFormData,
  spaFormData,
  setSpaFormData,
  spaSelection,
  setSpaSelection,
  setTermsChecked,
  unfinishedSelection,
  setUnfinishedSelection,
  optionDetails,
  setShowSpaExtraSection,
  setActionAlertObj,
  setShowBookConfirmation,
  showBookConfirmation,
  setIsEditMode,
  isEditMode,
  selectedDate,
  policyLink,
  width,
}) => {
  const {
    validateSpaForm,
    handleCheckBox,
    handleAddAction,
    handleModification,
  } = SpaExtraSectionLogic(
    unfinishedSelection,
    setActionAlertObj,
    spaFormData,
    optionDetails,
    termsChecked,
    setTermsChecked,
    setSpaFormData,
    setShowSpaExtraSection,
    setUnfinishedSelection,
    setSpaSelection,
    spaSelection,
    setIsEditMode
  );
  const [showCnxDialog, setShowCnxDialog] = useState(false);

  const cancelEditMode = () => {
    const spaOptions = { ...spaSelection };

    if (unfinishedSelection) {
      const previousSelectionIndex = spaOptions[selectedDate].findIndex(
        (row) => row.Time === unfinishedSelection.time
      );
      spaOptions[selectedDate][previousSelectionIndex].selected = false;
    }

    setIsEditMode(null);
    setTermsChecked(false);
    setUnfinishedSelection(null);
    setShowSpaExtraSection(false);
    setSpaSelection(spaOptions);

    setSpaFormData({
      firstName: "",
      lastName: "",
    });
  };

  const prompSpaEditConfirmation = () => {
    if (isEditMode?.action === "none")
      setShowCnxDialog(true);
    else 
     handleModification(isEditMode)
  };

  const prompSpaConfirmation = () => {
    const isValid = validateSpaForm();

    if (!isValid) return;

    if (showBookConfirmation === "Yes") {
      setShowBookConfirmation("hide");
      handleAddAction();
    } else if (showBookConfirmation === "No") {
      setShowBookConfirmation("hide");
    } else {
      setShowBookConfirmation("show");
    }
  };

  useEffect(() => {
    if (showBookConfirmation !== "hide") {
      prompSpaConfirmation();
    }
  }, [showBookConfirmation]);

  return (
    <>
      <div className={styles.activityBooking}>
        <>
          <p>
            {`Is the treatment for you `}{" "}
            <strong>
              {reservation.Salutation ? reservation.Salutation : ""}{" "}
              {reservation.FirstName} {reservation.LastName}?
            </strong>
          </p>

          <div
            style={{
              alignItems: width < 510 ? "start" : "center",
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            <div className={styles.checkBoxWrapper}>
              <div className={styles.checkBox} onClick={handleCheckBox}>
                {termsChecked ? (
                  <img src={"/img/info_icons/done_check.svg"} alt={""} />
                ) : null}
              </div>
              <p>Yes, the treatment is for me.</p>
            </div>

            {termsChecked ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: width > 510 ? "start" : "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  // width: "100%",
                  gap: "20px"
                }}
              >
                <Button
                  txt={isEditMode ? "Modify" : "Add to basket"}
                  onClick={() =>
                    isEditMode
                      ? handleModification(isEditMode)
                      : prompSpaConfirmation()
                  }
                  backgroundColor={"var(--color-primary)"}
                  color={"var(--color-secondary)"}
                  height={"50px"}
                  minHeight={"50px"}
                  newStyles={{ paddingLeft: "15px", paddingRight: "15px" }}
                />
                {isEditMode ? (
                  <Button
                    txt={"Cancel"}
                    onClick={() => cancelEditMode()}
                    backgroundColor={"var(--color-secondary)"}
                    color={"var(--color-primary)"}
                    height={"50px"}
                    minHeight={"50px"}
                    newStyles={{ paddingLeft: "15px", paddingRight: "15px" }}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </>

        {!termsChecked ? (
          <>
            <div style={{ paddingTop: "15px", paddingBottom: "25px" }}>
              <p>If not, please enter the full names below:</p>
            </div>

            <div
              style={{
                alignItems: "end",
                display: "flex",
                paddingBottom: width > 1324 ? "40px" : "0",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              <InputCard
                newBoxStyles={{
                  height: "50px",
                  border: "1px solid var(--color-primary)",
                }}
                newLabelStyles={{ color: "var(--color-primary)" }}
                label={"First name"}
                name={"firstName"}
                setFormData={(key, value) => manageSpaFormData(key, value)}
                value={spaFormData.firstName}
              />
              <InputCard
                newBoxStyles={{
                  height: "50px",
                  border: "1px solid var(--color-primary)",
                }}
                newLabelStyles={{ color: "var(--color-primary)" }}
                label={"Last name"}
                name={"lastName"}
                setFormData={(key, value) => manageSpaFormData(key, value)}
                value={spaFormData.lastName}
              />

              <div style={{ width: "35%", height: width < 525 ? "fit-content" : "50px", display: "flex", flexWrap: width > 525 ? "nowrap" : "wrap" }}>
                <Button
                  txt={isEditMode ? "Modify" : "Add to basket"}
                  onClick={() =>
                    isEditMode
                      ? prompSpaEditConfirmation()
                      : prompSpaConfirmation()
                  }
                  backgroundColor={"var(--color-primary)"}
                  color={"var(--color-secondary)"}
                  height={"50px"}
                  minHeight={"50px"}
                  newStyles={{ paddingLeft: "15px", paddingRight: "15px" }}
                />
                {isEditMode ? (
                  <Button
                    txt={"Cancel"}
                    onClick={() => cancelEditMode()}
                    backgroundColor={"var(--color-secondary)"}
                    color={"var(--color-primary)"}
                    height={"50px"}
                    minHeight={"50px"}
                    newStyles={{ marginLeft: width < 525 ? "0" : "20px", paddingLeft: "15px", paddingRight: "15px", marginTop: width < 525 ? "10px" : "0" }}
                  />
                ) : null}
              </div>
            </div>
          </>
        ) : null}
      </div>
      {showCnxDialog ? (
        <AlertBox
          customContent={
            <div>
              <p>{`Are you sure?`}</p>
              <p>
                {`Have you read our `}{" "}
                <strong>
                  <a
                    href={`${policyLink}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline", color: "var(--color-info)" }}
                  >
                    Cancellation policy
                  </a>
                </strong>
                {"?"}
              </p>
            </div>
          }
          button={
            <>
              <Button
                txt={"Cancel"}
                backgroundColor={"var(--color-secondary)"}
                color={"var(--color-primary)"}
                onClick={() => setShowCnxDialog(false)}
              />
              <Button
                txt={"Confirm"}
                backgroundColor={"var(--color-primary)"}
                color={"var(--color-secondary)"}
                onClick={() => handleModification(isEditMode)}
              />
            </>
          }
          boxNewStyles={{ width: "640px", minWidth: "640px" }}
        />
      ) : null}
    </>
  );
};

export default SpaExtraSection;

import React, { useContext, useEffect, useState, useRef } from "react";

import { getDetailedDate } from "../../helpingFunctions/utilities";
import { DataContext } from "../../context/DataContext";
import { dropDownArrowIcon } from "../../assets/SVGIcons";
import {
  BOOKING_TYPES,
  GROUP_LABELS,
  ActivitiesTypes,
} from "../../constants/constants";
import ItineraryCardExpandRow from "./ItineraryCardExpandRow";
import CheckBookingConflicts from "../../shared/validation/CheckBookingConflicts";
import Popover from "../../shared/Popover/Popover";
import WindowWidth from "../../hooks/WindowWidth";
import InformationalBox from "../../shared/Containers/InformationalBox";
import PromotionalSpaceItineraryRow from "../../shared/Containers/PromotionalSpaceItineraryRow";
import ItineraryCardLogic from "./ItineraryCardLogic";

import styles from "./itinerary_desktop_card.module.css";

const ItineraryCardDesktop = ({
  date,
  data,
  row,
  setView,
  expand,
  handleExpand,
  dayLineColor,
  promotionalRowSpaceContent,
}) => {
  const { setSelectedDate, reservation, groupData } = useContext(DataContext);
  const { getBookedDetails, getSummaryValue } = ItineraryCardLogic();
  const { checkItineraryConflicts } = CheckBookingConflicts();
  const [togglePopover, setTogglePopover] = useState(false);
  const [bookingConflicts, setBookingConflicts] = useState({});
  const [divHeight, setDivHeight] = useState(120);
  const ref = useRef(null);
  const { width } = WindowWidth();
  const InternalActivityLabel = BOOKING_TYPES.InternalActivity;

  const altNames = {
    Activity: "Activities booked",
    Spa: "Spa treatments booked",
    Extras: "Extras",
    [InternalActivityLabel]: "Activities booked",
  };

  const partOfGroupText = (diningType) => {
    if (!["Breakfast", "Dinner"].includes(diningType)) return null;

    const groupItem = groupData.items.find(
      ({ CRSNumber }) => CRSNumber === reservation.CRSNumber
    );
    if (!groupItem || !groupItem.changes || !groupItem.changes[date])
      return null;

    const isPartOfGroup = groupItem.changes[date][diningType];

    if (isPartOfGroup)
      return `  - You are part of "${groupData.groupName}" ${GROUP_LABELS.GroupRef.lowerCase}`;
    else return null;
  };
  const handleClick = (viewObj) => {
    if (!["Spa", ...ActivitiesTypes, "Treats"].includes(viewObj.type)) {
      delete viewObj.id;
    }

    setSelectedDate(date + "I");
    setView(viewObj);
  };
  useEffect(() => {
    setBookingConflicts(checkItineraryConflicts(date));
  }, []);

  useEffect(() => {
    if (expand[date]) {
      setDivHeight(ref.current.clientHeight);
    }
  }, [expand]);

  return (
    <>
      <div
        className={styles.cardWrapper}
        style={{
          height: expand[date] ? `${divHeight + 100}px` : "280px",
          transition: "all 0.5s",
          position: "relative",
          marginTop: row === 1 || row === 2 ? "0" : "35px",
        }}
        onClick={() => handleExpand(date)}
      >
        <div
          style={{
            background: dayLineColor[date],
            color: "white",
            padding: "10px 20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2 style={{ width: "100%" }}>{`Day ${row}: ${getDetailedDate(
            date
          )}`}</h2>
          <span
            className={`${styles.dropArrow} ${
              expand[date] ? styles.rotate180deg : styles.rotate0deg
            }`}
          >
            {dropDownArrowIcon}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            overflow: "auto",
            overflowX: "hidden",
            height: expand[date] ? "auto" : "210px",
            opacity: expand[date] ? 0 : 1,
            transition: "all 0.5s ease-in-out",
          }}
          onClick={() => (!expand[date] ? handleExpand(date) : null)}
        >
          {!expand[date] ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "15px",
              }}
            >
              <div
                className={styles.leftSummaryContainer}
                style={{ width: "50%" }}
              >
                {Object.entries(data.summary).map(([key, value], index) => (
                  <>
                    {[
                      "Breakfast",
                      "Lunch",
                      "Dinner",
                      "Arrival",
                      "Departure",
                      "Venue",
                    ].includes(key) && value.length > 0 ? (
                      <>
                        {["1", "9999", "9998"].includes(value[0]) &&
                        ["Breakfast", "Lunch", "Dinner", "Venue"].includes(
                          key
                        ) ? (
                          <div
                            style={{
                              paddingTop: key === "Breakfast" ? "0" : "15px",
                              fontSize: "16px",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <strong>{`${
                                key === "Venue" ? "Show" : key
                              }: `}</strong>
                              <div>
                                <p
                                  style={{
                                    textDecoration: "underline",
                                    color: "var(--color-info)",
                                    paddingLeft: "5px",
                                    paddingBottom: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleClick({
                                      type:
                                        key === "Venue" ? "Entertainment" : key,
                                    })
                                  }
                                >
                                  {"book now"}
                                </p>
                              </div>
                            </div>

                            {(key === "Venue" && reservation.Venue) ||
                            (key === "Breakfast" && reservation.Breakfast) ||
                            (key === "Dinner" && reservation.Dinner) ? (
                              <InformationalBox
                                content={`(Opted for "Not Required")`}
                                boxNewStyles={{ width: "90%" }}
                              />
                            ) : null}
                          </div>
                        ) : (
                          <p style={{ paddingTop: "15px", fontSize: "16px" }}>
                            <strong>{`${
                              key === "Venue" ? "Show" : key
                            }: `}</strong>{" "}
                            {getSummaryValue(data, key)} {partOfGroupText(key)}
                            {/* {data.details[0] &&
                            ["Breakfast", "Dinner"].includes(key) &&
                            data.details[0].isPartOfAGroup
                              ? `  - You are part of "${groupData.groupName}" group`
                              : null} */}
                          </p>
                        )}
                      </>
                    ) : null}
                  </>
                ))}
              </div>
              <div
                className={styles.rightSummaryContainer}
                style={{ width: "50%" }}
              >
                {Object.entries(data.summary).map(([key, value], index) => (
                  <>
                    {![
                      "Breakfast",
                      "Lunch",
                      "Dinner",
                      "Arrival",
                      "Departure",
                      "Venue",
                    ].includes(key) && value.length > 0 ? (
                      <>
                        <p style={{ paddingTop: "10px", fontSize: "16px" }}>
                          <strong>{`${altNames[key]}: `}</strong>
                        </p>
                        <p style={{ fontSize: "16px" }}>
                          {getSummaryValue(data, key)}
                        </p>
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  paddingTop: "5px",
                  paddingLeft: "10px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ width: "100%", display: "flex", height: "30px" }}>
                  <p
                    style={{ fontSize: "20px" }}
                    onClick={() => setTogglePopover(!togglePopover)}
                  ></p>
                  {togglePopover ? (
                    <Popover content={date} newStyles={{ width: "100px" }} />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>

        <div
          style={{
            width: "100%",
            opacity: expand[date] ? 1 : 0,
            transition: "all 0.5s ease-in-out",
          }}
          ref={ref}
        >
          <div style={{ display: expand[date] ? "block" : "none" }}>
            {data.details.map(
              (
                {
                  Id,
                  actStrapiId,
                  Time,
                  Title,
                  Type,
                  Quantity,
                  isParticipant,
                  FirstName,
                  LastName,
                  Location,
                  ParticipantFirstName,
                  ParticipantLastName,
                },
                index
              ) => (
                <>
                  {!["Arrival, Departure"].includes(Type) ? (
                    <ItineraryCardExpandRow
                      key={`${index}_${date}_${Time}_${Type}`}
                      date={date}
                      facilityId={Id}
                      bookedTime={Time}
                      Type={Type}
                      handleClick={handleClick}
                      Quantity={Quantity}
                      bookingConflicts={bookingConflicts}
                      isParticipant={isParticipant}
                      FirstName={FirstName}
                      LastName={LastName}
                      ParticipantFirstName={ParticipantFirstName}
                      ParticipantLastName={ParticipantLastName}
                      bookedDetails={
                        Type !== InternalActivityLabel
                          ? Type === "Treats"
                            ? { Title: Title, Location: Location }
                            : getBookedDetails(Id, Type, Location, actStrapiId)
                          : { Title }
                      }
                    />
                  ) : (
                    <div>{Title}</div>
                  )}
                </>
              )
            )}
            {promotionalRowSpaceContent ? (
              <>
                {promotionalRowSpaceContent.ButtonRedirect === "Lunch" &&
                data.details.filter((row) => row.Type === "Lunch").length ===
                  0 &&
                reservation.Breakfast ? (
                  <PromotionalSpaceItineraryRow
                    content={promotionalRowSpaceContent}
                    setView={setView}
                    redirectionDate={date}
                    setSelectedDate={setSelectedDate}
                  />
                ) : (
                  <div style={{ height: "50px" }}></div>
                )}
              </>
            ) : (
              <div style={{ height: "50px" }} />
            )}
          </div>
          {width < 1325 ? (
            <div
              style={{
                height: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "rotate(180deg)",
                opacity: expand[date] ? 1 : 0,
                transition: "all 1s ease-in",
              }}
            >
              <span
                style={{
                  paddingTop: "22px",
                  display: expand[date] ? "block" : "none",
                }}
              >
                {dropDownArrowIcon}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ItineraryCardDesktop;

import React from "react";

import styles from "./PXPResult.module.css";

const PXPResult = ({ callbackStatus }) => {
  if (callbackStatus === "success") {
    return null;
  }

  return (
    <div className={styles.PXPResult}>
      <h3>
        There was an issue with the transaction please try again later!
      </h3>
    </div>
  );
};

export default PXPResult;

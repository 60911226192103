import React, { useContext } from "react";
import ReactPlayer from "react-player";

import { DataContext } from "../../../context/DataContext";
import WindowWidth from "../../../hooks/WindowWidth";

import styles from "./SpaPresentation.module.css";

const SpaPresentation = () => {
  const { reservation } = useContext(DataContext);
  const { width } = WindowWidth();

  const spaPreviewImage = reservation.Hotel.SpaPreviewImage;
  const spaPreviewVideoLink = reservation.Hotel.SpaPreviewVideoLink;

  if (!spaPreviewImage?.medium && !spaPreviewVideoLink) return null;

  let Content = null;
  if (spaPreviewVideoLink) {
    Content = (
      <ReactPlayer
        width={width <= 500 ? "400px" : "620px"}
        height={width <= 500 ? "300px" : "349px"}
        url={spaPreviewVideoLink}
        style={{
          padding: "0",
        }}
      />
    );
  } else {
    Content = (
      <img
        className={styles.SpaPresentation__image}
        width={"100%"}
        height={"100%"}
        src={`${spaPreviewImage.medium.url}`}
        alt="no img"
      />
    );
  }

  return <div className={styles.SpaPresentation}>{Content}</div>;
};

export default SpaPresentation;

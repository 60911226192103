import moment from "moment";
import { constructStringWithComma } from "./helpingFunctions";
import { DiningTypes } from "../constants/constants";

const returnBookType = (type) =>
  DiningTypes.includes(type) ||
  type === "Activity" ||
  type === "Entertainment" ||
  type === "Treat" ||
  type === "Venue" || 
  type === "Dining"
    ? "activity"
    : "spa";

const contructSoftConflictText = (list, attribute) => {
  let str = "";

  list.map((item, index) => {
    const value = item[attribute];

    if (!str.includes(value)) {
      str += constructStringWithComma(value, index, list.length);
    }
  });

  const trimedStr = str.trim();
  const trimedStrLn = trimedStr.length;

  if (trimedStr.charAt(trimedStrLn - 1) === ",") {
    str = trimedStr.substring(0, trimedStrLn - 1);
  }
  return str;
};

const isValidDate = (
  selectedDate = moment(new Date()).format("YYYY-MM-DD"),
  selectedTime = "00:00:00-00:00"
) => {
  try {
    const selectedTimeObj = new Date(
      `${selectedDate}T${selectedTime.substring(0, 5)}`
    );

    const isValid = selectedTimeObj.getTime() >= new Date().getTime();

    return isValid;
  } catch (error) {
    console.error(
      `${new Date()} Error in isValidDate func in bookingHelp.js file ${
        error.message
      }`
    );
  }
};

const removeObject = (reservation, data) => {
  return {
    FirstName: reservation.FirstName,
    LastName: reservation.LastName,
    ReservationNumber: reservation.ReservationNumber,
    Quantity: Number(data.Quantity),
    day: data.Date,
    facilityGUID: [data.Id],
    facilityBooked: data.Id,
    quantityBooked: Number(data.Quantity),
    timeBooked: data.Time,
    title: data.Title,
    type: data.Type,
    action: "remove",
  };
};

const isInItinerary = (preSelection, itinerary, date, type) => {
  if (!preSelection) return false;
  const { itineraryDates } = itinerary;
  const preSelectionTime = preSelection.time
    ? preSelection.time
    : preSelection.timeBooked;

  const currentDate = itineraryDates.find(({ Data }) => Data === date);

  const relatedData = currentDate[type];

  const isIn = relatedData.some(
    ({ Id, Time }) =>
      preSelection.facilityGUID[0] === Id && preSelectionTime === Time
  );

  return isIn;
};

export {
  returnBookType,
  contructSoftConflictText,
  isValidDate,
  removeObject,
  isInItinerary,
};

import React from "react";

import styles from "./heading.module.css";

const Heading = ({
  txt,
  fontSize,
  fontWeight,
  fontFamily,
  textTransform = "none",
  paddingTop,
  paddingBottom,
  paddingRight,
  icon,
  details,
  toggleDetailsDialogHandler,
  new_styles,
  view,
  customHeadingClass,
}) => {
  return (
    <>
      <p
        className={`${customHeadingClass}`}
        style={{
          fontSize,
          fontWeight,
          paddingTop,
          paddingBottom,
          paddingRight,
          fontFamily,
          textTransform,
          ...new_styles,
        }}
      >
        {txt}
      </p>

      {icon && txt ? (
        <img
          className={styles.information_icon}
          src={`/img/info_icons/${icon}`}
          style={{cursor: toggleDetailsDialogHandler ? "pointer" : "default"}}
          onClick={() =>
            toggleDetailsDialogHandler(true, {
              EntertainmentPublished: details.title,
              ActDescription: details.longDesc,
              Images: details.images,
              Details: details.details,
            })
          }
          alt=""
        />
      ) : (
        <>
          {view === "Entertainments" ? (
            <div style={{ height: "55px" }}></div>
          ) : null}
        </>
      )}
    </>
  );
};

export default Heading;

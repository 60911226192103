import React from "react";

import styles from "./GrayWrapper.module.css";

const GrayWrapper = ({ new_styles, new_class, children, reference }) => {
  return (
    <div
      className={`${styles.GrayWrapper} ${styles[new_class]}`}
      style={{ ...new_styles }}
      ref={reference}
    >
      {children}
    </div>
  );
};

export default GrayWrapper;

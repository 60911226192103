import { useState, useContext, useEffect } from "react";

import { checkCurrentTime } from "../../helpingFunctions/helpingFunctions";

import { DataContext } from "../../context/DataContext";
import { goToPage } from "../../helpingFunctions/linkChange";
import RequestHook from "../../hooks/RequestHook";
import axiosInstance from "../../url/createAxios";

import styles from "./landing-page.module.css";

const LandingPageLogic = (view, setView) => {
  const instance = axiosInstance();
  const {
    setReservation,
    setFacilitiesData,
    setLate,
    setTreatsData,
    setEntertainmentData,
    setEntertainmentStaticContent,
    setCardBannerContent,
    setLandingPage,
    setDiningProcessingScreenBannerTexts,
    setPromotionalSpaceItineraryExpandedRowsDetails,
    setBooked,
    setGiftCardsPage,
    setGolfPage,
  } = useContext(DataContext);
  const {
    getItinerary,
    getAvailability,
    getGroup,
    getPagesData,
    getPromotionalSpaceContent,
  } = RequestHook();

  const [loading, setLoading] = useState(view.stopRequests ? false : true);

  const checkBookStatus = (
    breakfast,
    lunch,
    dinner,
    hasSpa,
    hasEntertainment,
    hasActivitiest,
    hasTreat,
    is4by4,
    is3by3,
    spaLabel
  ) => {
    if (!breakfast && !dinner && (is4by4 ? !hasEntertainment : true)) {
      const unbookedOptions = [];
      unbookedOptions.push(
        <>
          <span
            className={styles.infoLinks}
            onClick={() => handleLinkClick("Breakfast")}
          >
            Breakfast
          </span>
        </>
      );
      if (!lunch) {
        unbookedOptions.push(
          <>
            <span
              className={styles.infoLinks}
              onClick={() => handleLinkClick("Lunch")}
            >
              Lunch
            </span>
          </>
        );
      }
      unbookedOptions.push(
        <>
          <span
            className={styles.infoLinks}
            onClick={() => handleLinkClick("Dinner")}
          >
            Dinner
          </span>
        </>
      );
      if (is4by4 && !hasEntertainment) {
        unbookedOptions.push(
          <>
            <span
              className={styles.infoLinks}
              onClick={() => handleLinkClick("Entertainment")}
            >
              Entertainment
            </span>
          </>
        );
      }
      if ((is4by4 || is3by3) && !hasSpa) {
        unbookedOptions.push(
          <>
            <span
              className={styles.infoLinks}
              onClick={() => handleLinkClick("Spas")}
            >
              {spaLabel}
            </span>
          </>
        );
      }
      if (is4by4) {
        unbookedOptions.push(
          <>
            <span
              className={styles.infoLinks}
              onClick={() => handleLinkClick("Golf")}
            >
              Golf
            </span>
          </>
        );
      }
      if (!hasActivitiest) {
        unbookedOptions.push(
          <>
            <span
              className={styles.infoLinks}
              onClick={() => handleLinkClick("Activities")}
            >
              Activities
            </span>
          </>
        );
      }
      if (!hasTreat) {
        unbookedOptions.push(
          <>
            <span
              className={styles.infoLinks}
              onClick={() =>
                goToPage(
                  "https://www.warnerleisurehotels.co.uk/discover/celebrations"
                )
              }
            >
              Treats
            </span>
          </>
        );
      }

      const concatenatedUnbookedOpts = [];
      let unbookedOptsLength = unbookedOptions.length;
      for (let i = 0; i < unbookedOptsLength; i++) {
        if (i === unbookedOptsLength - 1) {
          concatenatedUnbookedOpts.push(" and ");
          concatenatedUnbookedOpts.push(unbookedOptions[i]);
        } else {
          concatenatedUnbookedOpts.push(unbookedOptions[i]);
          if (i !== unbookedOptsLength - 2) concatenatedUnbookedOpts.push(", ");
        }
      }

      return {
        txt: (
          <>
            You have not confirmed any selections for your stay.{" "}
            {concatenatedUnbookedOpts}
          </>
        ),
        backgroundColor: "#595858",
        lineBackgroundColor: "var(--color-error)",
        sizes: {
          Information: "20.491803278688526%",
          Dinning: "36.47540983606557%",
        },
        icon: "exclamation.svg",
      };
    } else if (breakfast && dinner && (is4by4 ? hasEntertainment : true)) {
      return {
        txt: (
          <>
            You have confirmed all your selections. If you would like to review
            your selections please visit the{" "}
            <span
              className={styles.infoLinks}
              onClick={() => handleLinkClick("itinerary")}
            >
              itinerary
            </span>
            .
          </>
        ),
        backgroundColor: "#595858",
        lineBackgroundColor: "#119900",
        sizes: {
          Information: "59.016393%",
          Dinning: "17.21311475409836%",
        },
        icon: "check_circle.svg",
      };
    } else {
      let bookedTxt = "Thank you for booking your ";
      let notBookedTxt = "We would like to remind you to book your ";

      const booked = [];
      const notBooked = [];

      breakfast ? booked.push("Breakfast") : notBooked.push("Breakfast");
      dinner ? booked.push("Dinner") : notBooked.push("Dinner");
      // hasActivitiest ? booked.push("Activities") : notBooked.push("Activities");
      // (is4by4 || is3by3) && hasSpa ? booked.push("Spa") : notBooked.push("Spa");
      if (is4by4) {
        hasEntertainment
          ? booked.push("Entertainment")
          : notBooked.push("Entertainment");
      }
      for (let i in booked) {
        if (booked.length === 1) {
          bookedTxt += booked[i] + " options.";
          break;
        } else if (booked.length - 1 === Number(i)) {
          bookedTxt = bookedTxt.substring(0, bookedTxt.length - 1);
          bookedTxt += ` and ${booked[i]} options.`;
          break;
        }
        bookedTxt += ` ${booked[i]},`;
      }

      for (let i in notBooked) {
        if (notBooked.length === 1) {
          notBookedTxt += notBooked[i] + " as well.";
          break;
        } else if (notBooked.length - 1 === Number(i)) {
          notBookedTxt = notBookedTxt.substring(0, notBookedTxt.length - 1);
          notBookedTxt += ` and ${notBooked[i]} as well.`;
          break;
        }

        notBookedTxt += ` ${notBooked[i]},`;
      }

      return {
        txt: (
          <>
            {bookedTxt} <br />
            {notBookedTxt}
          </>
        ),
        backgroundColor: "#595858",
        lineBackgroundColor: "var(--color-info)",
        sizes: {
          Information: "31.147540983606557%",
          Dinning: "31.147540983606557%",
        },
        icon: "exclamation.svg",
      };
    }
  };

  const getReservation = async () => {
    const { data } = await instance.get(`/Hotel`);

    const {
      id,
      ArrivalDate,
      DepartureDate,
      Hotel,
      Nights,
      Adults,
      ReservationNumber,
      CRSNumber,
      LastName,
      FirstName,
      RateType,
      Facilities,
      Treats,
      Spa,
      Breakfast,
      CreditCard,
      DiningProcessingScreenBannerTexts,
      PromotionalSpaceItineraryExpandedRowsDetails,
      Lunch,
      Dinner,
      PartOfGroup,
      GroupParticipants,
      IsGroupCreator,
      Entertainment,
      hasActivitiest,
      hasSpa,
      hasEntertainment,
      hasTreat,
      Salutation,
    } = data;

    const is4by4 = Hotel.TwoLetterCode === "HP";
    const is3by3 = Spa.length > 0;

    const dinningBookInfo = checkBookStatus(
      Breakfast,
      Lunch,
      Dinner,
      hasSpa,
      hasEntertainment,
      hasActivitiest,
      hasTreat,
      is4by4,
      is3by3,
      Hotel.SpaLabel
    );

    setBooked(dinningBookInfo);
    setDiningProcessingScreenBannerTexts(DiningProcessingScreenBannerTexts);
    setPromotionalSpaceItineraryExpandedRowsDetails(
      PromotionalSpaceItineraryExpandedRowsDetails
    );
    const isLate = checkCurrentTime(ArrivalDate);

    setLate({
      isLate,
    });

    setReservation({
      id,
      ArrivalDate,
      CreditCard,
      DepartureDate,
      Hotel,
      Nights,
      Adults,
      ReservationNumber,
      CRSNumber,
      LastName,
      FirstName,
      RateType,
      Breakfast,
      Lunch,
      Dinner,
      Venue: hasEntertainment,
      PartOfGroup,
      GroupParticipants,
      IsGroupCreator,
      Salutation,
      IsWithSpa: Spa.length > 0,
    });
    getAvailability(GroupParticipants === 0 ? Adults : GroupParticipants);
    contructMaestroObjects(Facilities, Spa, Entertainment, Treats);
    
    getPromotionalSpaceContent(Hotel);
    setLoading(false);
  };

  const contructMaestroObjects = (Facilities, Spa, Entertainment, Treats) => {
    const facilities = Facilities.map((facility) => ({
      ...facility,
      objType: facility.Type,
    }));
    const spas = Spa.map((spa) => ({ ...spa, objType: "Spa" }));
    const entertainments = Entertainment.map((ent) => ({
      ...ent,
      objType: "Entertainment",
    }));
    const treats = Treats?.map((treat) => ({ ...treat, objType: "Treats" }));

    setEntertainmentData([...entertainments]);
    setTreatsData(treats);
    setFacilitiesData([...facilities, ...spas, ...entertainments, ...treats]);
  };

  const getLandingPageData = async () => {
    const { data } = await instance.get("/home-page");
    if(!data) {
      return;
    }
    
    const [destructedData] = data;

    setLandingPage(destructedData);
  };

  const getGiftCardsPageData = async () => {
    try {
      const { data } = await instance.get("/gift-cards-page");

      if(!data) {
        return;
      }
      
      if (data.published_at) {
        setGiftCardsPage(data);
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in getGiftCardsPageData func in LandingPageLogic.js file ${
          error.message
        }`
      );
    }
  };

  const getGolfPageData = async () => {
    try {
      const { data } = await instance.get("/golf-page");

      if(!data) {
        return;
      }
      
      if (data.published_at) {
        setGolfPage(data);
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in getGolfPageData func in LandingPageLogic.js file ${
          error.message
        }`
      );
    }
  };

  const handleLinkClick = (type) => setView({ type });

  const getEntertainmentStaticContent = async () => {
    try {
      const { data } = await instance.get("/entertainment-static-texts");

      setEntertainmentStaticContent(data);
    } catch (error) {}
  };

  const getCardBannersContent = async () => {
    try {
      const { data } = await instance.get("/card-banner-texts");

      setCardBannerContent(data);
    } catch (error) {}
  };

  const handleTilesWidth = (is4by4, is3by3, is3by2, isDesktop, type) => {
    if (is4by4) {
      if (isDesktop) {
        if (type === "Dining") {
          return "49.36%";
        }
      } else {
        if (type === "Dining") {
          return "49%";
        }

        if (type === "Entertainment") {
          return "49%";
        }
      }

      return "23.36%";
    } else if (is3by3) {
      if (isDesktop) {
        if (type === "Info") {
          return "20.412%";
        } else if (type === "Dining") {
          return "76.412%";
        }
      } else {
        if (type === "Dining") {
          return "100%";
        }
      }

      return "31.411962616822427%";
    } else if (is3by2) {
      if (isDesktop) {
        if (type === "Dining") {
          return "65.412%";
        } else if (type === "Info") {
          return "31.5607%";
        }
      } else {
        if (type === "Dining") {
          return "100%";
        }
      }

      return "48.5607476635514%";
    }
  };

  const groupAlertHandler = () => {
    try {
      const groupPopUp = localStorage.getItem("groupPopUp");
      if (!groupPopUp || groupPopUp === "null" || groupPopUp === "undefined") {
        localStorage.setItem("groupPopUp", "initial");
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in groupAlertHandler func in LandingPageLogic.js file ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    const isUnderMaintenance = document.location.pathname.includes("maintenance")
    if (isUnderMaintenance) {
      return;
    }
    if (!view.stopRequests) {
      groupAlertHandler();
      getPagesData();
      getEntertainmentStaticContent();
      getCardBannersContent();
      getLandingPageData();
      getReservation();
      getGroup();
      getItinerary();
      getGiftCardsPageData();
      getGolfPageData();
    }
  }, []);

  return {
    loading,
    handleLinkClick,
    handleTilesWidth,
  };
};

export default LandingPageLogic;

import { useContext } from "react";

import { DiningTypes } from "../../constants/constants";
import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import {
  isBookingInThePast,
  getBookedQuantitiesPerDay,
} from "../../helpingFunctions/utilities";

const CheckBookingConflicts = () => {
  const { itineraryData, facilitiesData, reservation, availibility } =
    useContext(DataContext);
  const { bookingSelections } = useContext(SelectionContext);

  const findConflicts = (type, isFromItinerary, obtainPrice) => {
    try {
      const { itineraryDates } = itineraryData;

      const foundConflicts = [];
      let activityConflictFound = false;

      // Check for already booked inclusive activity
      if (type === "Activity") {
        activityConflictFound = findInclusiveActivityConflict(
          type,
          obtainPrice,
          itineraryDates,
          foundConflicts
        );
      }

      if (activityConflictFound) {
        return foundConflicts;
      }

      const currentDate = new Date();
      const currentDateTime = currentDate.toISOString().split("T");
      for (let selection of bookingSelections) {
        if (selection.action === "remove") continue;

        const foundDate = itineraryDates.find(
          (row) => row.Data === selection.day
        );

        const bookings = [...foundDate.Facilities];

        let quantityPerTime = getBookedQuantitiesPerDay(bookings);

        for (let booking of bookings) {
          const isInThePast = isBookingInThePast(
            booking.day,
            booking.time,
            currentDateTime[0],
            currentDateTime[1]
          );
          if (isInThePast) continue;

          const foundConflict = findSameDaySameTimeConflict(
            booking,
            isFromItinerary,
            type,
            selection,
            quantityPerTime
          );

          if (foundConflict) {
            if (booking.Title === selection.title) {
              continue;
            }

            if (selection.facilityGUID[0] !== booking.Id) {
              // Skip conflict validation if a replacement has already happened
              const replacementAlreadyAddedIndex = bookingSelections.findIndex(
                (row) => row.action === "remove" && row.day === selection.day
              );
              // booking.Type === selection.type -> if dining is conflicting with dining - skip
              if (replacementAlreadyAddedIndex !== -1 || (DiningTypes.includes(type) && booking.Type === selection.type)) {
                continue;
              }
            }

            foundConflicts.push({
              booked: { ...booking },
              newlySelected: { ...selection },
            });
          }
        }
      }

      return foundConflicts;
    } catch (error) {
      console.log(
        `${new Date()} error in findConflicts func inside CheckBookingConflicts.js file ${
          error.message
        }`
      );
    }
  };

  const findSpaEndTime = (selection) => {
    const isSelectionSpaIndex = facilitiesData.findIndex(
      (row) => row.id === selection.facilityGUID[0]
    );
    const spaDetails = facilitiesData[isSelectionSpaIndex];

    const bookingServiceStartDate = new Date(
      `${selection.day}T${selection.time.substring(0, 5)}`
    );
    bookingServiceStartDate.setMinutes(
      bookingServiceStartDate.getMinutes() + Number(spaDetails.Duration)
    );
    const endTime = `${("0" + bookingServiceStartDate.getHours()).slice(-2)}:${(
      "0" + bookingServiceStartDate.getMinutes()
    ).slice(-2)}`;
    return `${endTime}${selection.time.substring(5)}`;
  };

  const findInclusiveActivityConflict = (
    type,
    obtainPrice,
    itineraryDates,
    foundConflicts
  ) => {
    try {
      let activityConflictFound = false;

      const bookingSelLength = bookingSelections.filter(
        (row) => row.action !== "remove"
      ).length;
      const currentDate = new Date();
      const currentDateTime = currentDate.toISOString().split("T");

      // If we have only 1 active selection
      if (bookingSelLength === 1) {
        const bookingSelection = bookingSelections[0];
        const price = obtainPrice(
          bookingSelection.facilityGUID[0],
          type,
          facilitiesData,
          availibility
        );

        if (price === 0) {
          if (bookingSelection.action === "edit") {
            const bookingDay = itineraryDates.find(
              (row) => row.Data === bookingSelection.day
            );
            const bookedObj = bookingDay.Facilities.find(
              (row) => row.Id === bookingSelection.facilityGUID[0]
            );

            const bookedPrice = obtainPrice(
              bookedObj.Id,
              type,
              facilitiesData,
              availibility
            );

            if (bookedPrice !== 0.0) {
              findSameDaySameTimeInclusiveActivityConflicts(
                itineraryDates,
                foundConflicts
              );
            } else {
              foundConflicts.push({
                booked: { ...bookedObj },
                newlySelected: { ...bookingSelection },
                forbid: isBookingInThePast(
                  bookingDay.Data,
                  bookedObj.Time,
                  currentDateTime[0],
                  currentDateTime[1]
                ),
              });
            }

            activityConflictFound = true;
          }
        }
      } else if (bookingSelLength > 1) {
        // If we have more then 1 selection
        const price = obtainPrice(
          bookingSelections[0].facilityGUID[0],
          type,
          facilitiesData,
          availibility
        );

        if (price === 0) {
          findSameDaySameTimeInclusiveActivityConflicts(
            itineraryDates,
            foundConflicts
          );

          if (bookingSelLength > 2) {
            foundConflicts.push({
              booked: {
                Type: "Activity",
                Id: bookingSelections[0].facilityGUID[0],
              },
              newlySelected: { ...bookingSelections[1] },
              forbid: true,
            });
            return true;
          }

          const bookedIndex = bookingSelections.findIndex(
            (row) => row.action === "none" || row.action === "edit"
          );

          if (bookedIndex !== -1) {
            const bookedSelection = bookingSelections[bookedIndex];
            const bookingDay = itineraryDates.find(
              (row) => row.Data === bookedSelection.day
            );
            const bookedObj = bookingDay.Facilities.find(
              (row) => row.Id === bookedSelection.facilityGUID[0]
            );

            foundConflicts.push({
              booked: { ...bookedObj },
              newlySelected: { ...bookedSelection },
              forbid: isBookingInThePast(
                bookingDay.Data,
                bookedObj.Time,
                currentDateTime[0],
                currentDateTime[1]
              ),
            });
            activityConflictFound = true;
          } else {
            foundConflicts.push({
              booked: {
                Type: "Activity",
                Id: bookingSelections[0].facilityGUID[0],
              },
              newlySelected: { ...bookingSelections[1] },
              forbid: true,
            });
            activityConflictFound = true;
          }
        }
      }

      return activityConflictFound;
    } catch (error) {
      console.log(
        `${new Date()} error in findInclusiveActivityConflict func inside CheckBookingConflicts.js file ${
          error.message
        }`
      );
    }
  };

  const findSameDaySameTimeInclusiveActivityConflicts = (
    itineraryDates,
    foundConflicts
  ) => {
    bookingSelections.forEach((selection) => {
      if (["add", "edit"].includes(selection.action)) {
        const itineraryDate = itineraryDates.find(
          (date) => date.Data === selection.day
        );
        const booked = [
          ...itineraryDate.Facilities,
          ...itineraryDate.Entertainment,
        ];

        let quantityPerTime = getBookedQuantitiesPerDay(booked);

        booked.forEach((booking) => {
          const hasConf = findSameDaySameTimeConflict(
            booking,
            false,
            "Activity",
            selection,
            quantityPerTime
          );

          if (hasConf) {
            foundConflicts.push({
              booked: { ...booking },
              newlySelected: { ...selection },
              sameTimeSkipper: true,
            });
          }
        });
      }
    });
  };

  const findSameDaySameTimeConflict = (
    booking,
    isFromItinerary,
    type,
    selection,
    quantityPerTime
  ) => {
    try {
      if (!isFromItinerary) {
        const attemptingToAddQuantity = [
          "Entertainment",
          ...DiningTypes,
        ].includes(type)
          ? reservation.Adults
          : Number(selection.Quantity);

        const time = selection.time.substring(0, 5);
        let bookedQuan = 0;
        if (type === "Spa") {
          const durConf = checkSpaDurationConf(booking, selection, type);
          if (durConf.isConflicting) {
            Object.entries(quantityPerTime).forEach(([key, value]) => {
              if (key >= durConf.start && key <= durConf.end) {
                bookedQuan += value;
              }
            });
          }
        } else if (quantityPerTime.hasOwnProperty(time)) {
          bookedQuan = quantityPerTime[time];
        } else if (booking.Type === "Spa") {
          const durConf = checkSpaDurationConf(booking, selection, type);
          if (durConf.isConflicting) {
            Object.entries(quantityPerTime).forEach(([key, value]) => {
              if (key >= durConf.start && key <= durConf.end) {
                bookedQuan += value;
              }
            });
          }
        }
        const totalQuantityAfterBooking =
          bookedQuan + Number(attemptingToAddQuantity);

        // If the conflict occurs in Spa (trying to book spa)
        if (type === "Spa") {
          const checkSpaConf = checkSpaDurationConf(booking, selection, type);
          if (
            booking.Date === selection.day &&
            checkSpaConf.isConflicting &&
            totalQuantityAfterBooking > reservation.Adults &&
            selection.action !== "none"
          ) {
            return true;
          }

          if (
            booking.Date === selection.day &&
            checkSpaConf.isSameNameConflict &&
            checkSpaConf.isConflicting &&
            selection.action !== "none"
          ) {
            return true;
          }
        } else if (booking.Type === "Spa") {
          // If the conflicted element is a spa (already has spa booked)
          const earliestStartTime = booking.Time;
          const latestEndTime = booking.ServiceEndTime;
          const hasConflictDuringDuration =
            selection.time >= earliestStartTime &&
            selection.time <= latestEndTime;

          if (
            booking.Date === selection.day &&
            hasConflictDuringDuration &&
            totalQuantityAfterBooking > reservation.Adults &&
            selection.action !== "none"
          ) {
            return true;
          }
        }

        if (
          booking.Date === selection.day &&
          booking.Time === selection.time &&
          totalQuantityAfterBooking > reservation.Adults &&
          selection.action !== "none"
        ) {
          return true;
        }
      }

      return false;
    } catch (error) {
      console.log(
        `${new Date()} Error in findSameDaySameTimeConflict func in CheckBookingConflicts file ${
          error.message
        }`
      );
    }
  };

  const constructAlertMsg = (conflict, conflictOccuranceView, obtainPrice) => {
    const conflictType = conflict.booked.Type;

    switch (conflictOccuranceView) {
      // If the conflict occurs in Activity
      case "Activity":
      case "Lunch":
      case "Treat":
        // If a booking for the same time is Spa - cancel
        if (conflictType === "Spa") {
          return {
            conflictType: "strong-conflict",
            message: retrieveAlertMsg(conflictType, null, conflict),
          };
        }

        // If a booking for the same time is Bfst, Dinner or Venue (included) - remind and proceed
        if (["Breakfast", "Dinner", "Venue"].includes(conflictType)) {
          return {
            conflictType: "soft-conflict",
            message: retrieveAlertMsg(
              conflictType,
              null,
              conflict,
              conflictOccuranceView
            ),
          };
        }

        const price = obtainPrice(
          conflict.booked.Id,
          conflict.booked.Type,
          facilitiesData,
          availibility
        );
        if (
          ["Activity"].includes(conflictType) &&
          price === 0.0 &&
          !conflict.sameTimeSkipper &&
          conflict.newlySelected.facilityGUID.includes(conflict.booked.Id)
        ) {
          if (conflict.forbid) {
            return {
              conflictType: "strong-conflict",
              message: retrieveAlertMsg(
                "inclusiveActivityForbid",
                null,
                conflict
              ),
              isInclusiveActivity: true,
            };
          }

          return {
            conflictType: "average-conflict",
            message: retrieveAlertMsg(
              "inclusiveActivityReplace",
              null,
              conflict
            ),
            isInclusiveActivity: true,
          };
        } else {
          const newSelPrice = obtainPrice(
            conflict.newlySelected.facilityGUID[0],
            conflict.newlySelected.type,
            facilitiesData,
            availibility
          );
          return {
            conflictType: "average-conflict",
            message: retrieveAlertMsg(
              "replaceWithPrice",
              newSelPrice,
              conflict
            ),
          };
        }
      case "Spa":
        if (conflictType === "Spa") {
          return {
            conflictType: "strong-conflict",
            message: retrieveAlertMsg(conflictType, null, conflict),
          };
        }

        if (["Breakfast", "Dinner", "Venue"].includes(conflictType)) {
          return {
            conflictType: "soft-conflict",
            message: retrieveAlertMsg(conflictType, null, conflict),
          };
        }

        if (
          conflictType === "Activity" ||
          conflictType === "Treat"
        ) {
          const price = obtainPrice(
            conflict.newlySelected.facilityGUID[0],
            conflict.newlySelected.type,
            facilitiesData,
            availibility
          );
          return {
            conflictType: "average-conflict",
            message: retrieveAlertMsg("replaceWithPrice", price, conflict),
          };
        }
        break;
      case "Breakfast":
      case "Dinner":
      case "Venue":
      case "Entertainment":
        return {
          conflictType: "soft-conflict",
          message: retrieveAlertMsg(
            conflictType,
            null,
            conflict,
            conflictOccuranceView
          ),
        };

      default:
        console.error("Unhandled type: " + conflictType);
        break;
    }
  };

  const retrieveAlertMsg = (
    conflictType,
    price,
    conflict,
    conflictOccuranceView
  ) => {
    switch (conflictType) {
      case "Spa":
        if (
          ["Entertainment", "Breakfast", "Dinner"].includes(
            conflictOccuranceView
          )
        ) {
          return `You have ${conflictType.toLowerCase()} booked at ${conflict.booked.Time.substring(
            0,
            5
          )} on ${
            conflict.booked.Date
          }. Please remember to update your ${conflictType.toLowerCase()} time.`;
        }

        return `You have confirmed ${conflictType.toLowerCase()} booking at ${conflict?.booked.Time.substring(
          0,
          5
        )} on ${conflict.booked.Date} for ${
          conflict.booked.Duration
        } minutes. You can go back and select a new time/day, 
            or you can cancel/modify your previous selections individually from the itinerary 'Edit' option. Please remember that cancellation fees may apply.`;
      case "Breakfast":
      case "Dinner":
      case "Venue":
      case "Activity":
      case "Lunch":
        return `You have ${conflictType.toLowerCase()} booked at ${conflict.booked.Time.substring(
          0,
          5
        )} on ${
          conflict.booked.Date
        }. Please remember to update your ${conflictType.toLowerCase()} time.`;
      case "inclusiveActivityReplace":
        return `You already have booked ${conflict.booked.Title} on ${conflict.booked.Date}. Please remember that you are allowed to book only one inclusive activity of the same kind during your stay. Press 'Replace' to modify your booking or press 'Cancel' to go back.`;
      case "inclusiveActivityForbid":
        return `Please remember that you are allowed to book only one inclusive activity of the same kind during your stay.`;
      case "replaceWithPrice":
        return `You have ${
          conflict.booked.Title
        } booked at ${conflict?.booked.Time.substring(0, 5)} on ${
          conflict.booked.Date
        }. Press 'Replace' to continue booking ${conflict.newlySelected.title}${
          price ? ", price: £" + price + " per person," : ""
        } for this time or press 'Cancel' to go back and select a new time/day, or
            you can cancel/modify your previous selections individually from the itinerary 'Edit' option.`;

      default:
        console.error("Unhandled type: " + conflictType);
    }
  };

  const checkSpaDurationConf = (booking, selection, type) => {
    let isConflicting = false;
    let start = "";
    let end = "";
    let selected = "";
    let isSameNameConflict = false;

    if (type === "Spa") {
      const selectionStartTime = selection.time.substring(0, 5);
      const selectionEndTime = findSpaEndTime(selection).substring(0, 5);
      const bookedStartTime = booking.Time.substring(0, 5);

      if (booking.Type.toLowerCase() === "spa") {
        const bookedEndTime = booking.ServiceEndTime.substring(0, 5);
        if (
          bookedStartTime <= selectionStartTime &&
          selectionStartTime <= bookedEndTime
        ) {
          start = bookedStartTime;
          selected = selectionStartTime;
          end = bookedEndTime;
          isConflicting = true;
        }

        if (
          bookedStartTime <= selectionEndTime &&
          selectionEndTime <= bookedEndTime
        ) {
          start = bookedStartTime;
          selected = selectionStartTime;
          end = selectionEndTime;
          isConflicting = true;
        }

        if (
          (booking.isParticipant && selection.isParticipant) ||
          (!booking.isParticipant && !selection.isParticipant)
        ) {
          if (booking.isParticipant) {
            if (
              booking.ParticipantFirstName === selection.participantFirstName &&
              booking.ParticipantLastName === selection.participantLastName
            ) {
              start = bookedStartTime;
              selected = selectionStartTime;
              end = selectionEndTime;
              isSameNameConflict = true;
            }
          } else {
            if (
              booking.FirstName === selection.FirstName &&
              booking.LastName === selection.LastName
            ) {
              start = bookedStartTime;
              selected = selectionStartTime;
              end = selectionEndTime;
              isSameNameConflict = true;
            }
          }
        }
      } else {
        if (
          selectionStartTime <= bookedStartTime &&
          bookedStartTime <= selectionEndTime
        ) {
          start = bookedStartTime;
          selected = selectionStartTime;
          end = selectionEndTime;
          isConflicting = true;
        }
      }
    } else {
      const selectionStartTime = selection.time.substring(0, 5);
      const bookedStartTime = booking.Time.substring(0, 5);
      const bookedEndTime = booking.ServiceEndTime.substring(0, 5);

      if (
        selectionStartTime >= bookedStartTime &&
        selectionStartTime <= bookedEndTime
      ) {
        start = bookedStartTime;
        selected = selectionStartTime;
        end = bookedEndTime;
        isConflicting = true;
      }
    }

    return {
      isConflicting,
      start,
      end,
      selected,
      isSameNameConflict,
    };
  };

  const checkItineraryConflicts = (date) => {
    const { itineraryDates } = itineraryData;

    const bookingDay = itineraryDates.find((row) => row.Data === date);

    const sortedBookings = {};

    bookingDay.Facilities.forEach((row) => {
      const time = row.Time.substring(0, 5);
      if (sortedBookings.hasOwnProperty(time)) {
        sortedBookings[time].push({
          type: row.Type,
          id: row.Id,
          title: row.Title,
          quantity: row.Quantity,
        });
      } else {
        sortedBookings[time] = [
          {
            type: row.Type,
            id: row.Id,
            title: row.Title,
            quantity: row.Quantity,
          },
        ];
      }
    });

    const preparedConflictMsgs = {};

    Object.entries(sortedBookings).forEach(([key, value]) => {
      if (value.length > 1) {
        let quantity = 0;
        value.forEach((row) => {
          quantity += Number(row.quantity);
        });

        if (quantity > reservation.Adults) {
          value.forEach((row) => {
            const conflictEntities = value.filter(
              (val) => val.type !== row.type
            );
            if (conflictEntities.length > 0)
              preparedConflictMsgs[`${key}|${row.type}`] = conflictEntities;
          });
        }
      }
    });

    return preparedConflictMsgs;
  };

  const getSummaryValue = (facilities) => {
    let counts = {};
    facilities.forEach((x) => {
      counts[x] = (counts[x] || 0) + 1;
    });
    const preparedString = [];
    Object.entries(counts).forEach(([key, value]) => {
      if (value === 1) {
        preparedString.push(key);
      } else {
        preparedString.push(`${value} x ${key}`);
      }
    });
    return preparedString.join(", ");
  };

  return {
    findConflicts,
    constructAlertMsg,
    checkItineraryConflicts,
  };
};

export default CheckBookingConflicts;

import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../../context/DataContext";
import {
  isInclusive,
  obtainPrice,
  checkAvailability
} from "../../../helpingFunctions/facilityAndServices";
import { ActivitiesTypes } from "../../../constants/constants";
import Heading from "../../../shared/Heading/Heading";
import IndicatiorCircle from "../../../shared/IndicatorNumber/IndicatiorCircle";
import ImageCard from "../../ImageCard/ImageCard";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import VenueBanner from "../../../shared/VenueBanner/VenueBanner";

import styles from "./activity-card.module.css";

const ActivityCardDesktop = ({
  id,
  Title,
  ShortDescription,
  Image,
  Details,
  Price,
  onClick,
  objType,
  isSelected,
  view,
}) => {
  const { availibility, facilitiesData } = useContext(DataContext);
  const [hasAvailability, setHasAvailability] = useState(false);

  useEffect(() => {
    if (availibility && ActivitiesTypes.includes(objType)) {
      checkAvailability(availibility, id, setHasAvailability);
    } 
  }, [availibility]);

  return (
    <article
      className={styles.activityCard}
      style={{
        border: isSelected ? "2px solid var(--color-secondary)" : null,
        background: isSelected ? "var(--color-secondary)" : null,
        padding: isSelected ? "6px" : null,
        paddingBottom: isSelected ? "30px" : null,
        position: "relative",
      }}
    >
      {view !== "Treats"  && objType !== "Spa" && objType !== "Activity" && <VenueBanner
        new_styles={{
          width: "100%",
          height: "13%",
          left: 0,
          top: 0,
        }}
        content={
          <>
            {hasAvailability === "" ? (
              <LoadingContainer />
            ) : (
              <>
                {hasAvailability
                  ? "Slots available"
                  : "Currently no availability"}
              </>
            )}
          </>
        }
      />}
      <ImageCard img={Image[0]?.formats?.small?.url} handleClick={onClick}>
        <div className={styles.shade} />
        <div className={styles.activityIcons}>
          {Details?.map(({ icon }, index) =>
            icon ? (
              <IndicatiorCircle
                key={index}
                txt={
                  <img
                    width={"66%"}
                    height={"57%"}
                    src={icon?.formats?.thumbnail?.url}
                    alt={""}
                  />
                }
                backgroundColor={"#fff"}
                color={"#5f5f5f"}
                border={"1px solid #5f5f5f"}
                width={"50px"}
                height={"50px"}
                fontSize={"1.2rem"}
              />
            ) : null
          )}
        </div>
        <Heading
          txt={Title}
          new_styles={{ color: "#fff", fontSize: "1.4rem" }}
        />
      </ImageCard>

      <div className={styles.activityDesc}>
        <Heading
          txt={
            objType === "Spa"
              ? isInclusive(Price) 
              : isInclusive(obtainPrice(id, objType, facilitiesData, availibility), objType)
          }
          fontSize={"22px"}
          paddingTop={"1rem"}
          paddingBottom={"1rem"}
        />
        <p className={styles.desc}>{ShortDescription}</p>
      </div>
    </article>
  );
};

export default ActivityCardDesktop;

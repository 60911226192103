import React from "react";

import DropDownBoxLogic from "./DropDownBoxLogic";
import SelectCard from "../SelectCard/SelectCard";
import DropDownList from "./components/DropDownList/DropDownList";

import styles from "../DropdownBox/DropDownBox.module.css";
import { useEffect } from "react";

const DropDownBox = ({
  children,
  selectedItem,
  label,
  placeHolder,
  dropdownNewStyles,
  shouldCloseDropDown = false,
  dropdownIconSource,
  closeSubDropDowns = () => {},
  setShouldCloseDropDown = () => {},
}) => {
  const { manageDropDownClick, clickedItem, dropDownRef } = DropDownBoxLogic(
    closeSubDropDowns,
    setShouldCloseDropDown
  );

  useEffect(() => {
    if (shouldCloseDropDown) {
      manageDropDownClick(label);
    }
  }, [shouldCloseDropDown]);

  const isClicked = ["Dining Type", "Activity type"].includes(label)
    ? clickedItem === label && !shouldCloseDropDown
    : clickedItem === label;

  return (
    <div style={{ ...dropdownNewStyles?.wrapper }} ref={dropDownRef}>
      <p
        className={styles.DropDownBox__label}
        style={{ ...dropdownNewStyles?.label }}
      >
        {label}
      </p>
      <SelectCard
        onClick={manageDropDownClick.bind(this, label)}
        new_style={{
          display: "flex",
          height: "60px",
          flexDirection: "column",
          position: "relative",
          ...dropdownNewStyles?.field,
        }}
      >
        <div className={styles.DropDownBox__Top}>
          <span
            className={styles.DropDownBox__Top_selectedItem}
            style={{ textTransform: selectedItem ? "capitalize" : "" }}
          >
            {selectedItem ? selectedItem : placeHolder}
          </span>
          <img
            src={dropdownIconSource}
            alt="no img"
            className={`${styles.DropDownBox_icon} ${
              isClicked ? styles.DropDownBox_icon_rotate : ""
            }`}
          />
        </div>
        <DropDownList
          isClicked={isClicked}
          closeDropDowns={manageDropDownClick}
        >
          {children}{" "}
        </DropDownList>
      </SelectCard>
    </div>
  );
};

export default DropDownBox;

import React from "react";

import "./PXPForm.module.css";

const PXPForm = () => {
  return (
    <div style={{ margin: "100px", width: "1000px", height: "1000px" }}>
      <div className={"PXPForm"}>
        <header className="PXPForm__header">
          <p className="PXPForm__title">Add New Card</p>
        </header>
        <form className="PXPForm__form">
          <div className="PXPForm__input_label">
            <label className="PXPForm__label">Holder Name</label>
            <input className="PXPForm__input" type={"text"} />
          </div>
          <div className="PXPForm__input_label">
            <label className="PXPForm__label">Card Number</label>
            <input className="PXPForm__input" type={"number"} />
          </div>
          <div className="PXPForm__input_label expiry">
            <div className="PXPForm__input_label_expiry">
              <label className="PXPForm__label">Expiry Month</label>
              <input className="PXPForm__input" type={"text"} />
            </div>
            <div className="PXPForm__input_label_expiry">
              <label className="PXPForm__label">Expiry Year</label>
              <input className="PXPForm__input" type={"text"} />
            </div>
          </div>

          <div className="PXPForm__input_label">
            <label className="PXPForm__label">Security Code</label>
            <input className="PXPForm__input" type={"password"} />
          </div>
          <input
            className="PXPForm__submit"
            value={"Submit Card Details"}
            type={"submit"}
          />
        </form>
      </div>
    </div>
  );
};

export default PXPForm;

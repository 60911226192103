import React from "react";
import { check_circle } from "../../assets/SVGIcons";

import styles from "./CheckBox.module.css";

const CheckBox = ({ showCheckIcon, onClick }) => {
  return (
    <span className={styles.CheckBox} onClick={onClick}>
      {showCheckIcon ? check_circle() : null}
    </span>
  );
};

export default CheckBox;

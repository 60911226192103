import React from 'react'

import { WarnerLogoText } from '../../../constants/constants';

import Heading from "../../../shared/Heading/Heading";

const TextLogo = () => {
  return (
    <Heading 
        txt={WarnerLogoText}
        new_styles={{
            fontSize: "1.3rem",
            color: "var(--color-primary)"
        }}
    />
  )
}

export default TextLogo
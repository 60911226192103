import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useMaintenance = (setView) => {
  const location = useLocation();
  const history = useHistory();

  const isInMaintenance = location.pathname.includes("maintenance");

  let sse = null;
  const checkMaintenanceStatus = async () => {
    try {
      sse = new EventSource(
        `${process.env.REACT_APP_BACK_ENDPOINT}maintenance`,
        { withCredentials: true }
      );
      sse.onerror = function (error) {
        console.error(
          `${new Date()} Error in the EventSource ${JSON.stringify(error)}`
        );
      };
      sse.addEventListener("underMaintenance", (e) => {
        const parsedData = JSON.parse(e.data);

        if (parsedData.Enabled) {
          if (isInMaintenance) {
            return;
          }
          localStorage.setItem("maintenanceData", e.data);

          history.push("/maintenance");
        } else {
          if (!isInMaintenance) {
            return;
          }
          localStorage.setItem("maintenanceData", "{}");
          setView({ type: "" });
          history.push("/");
        }
      });
    } catch (error) {
      console.error(
        `${new Date()} Error in checkMaintenanceStatus func in RouteLogic.js file ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    //TODO: remove when we reslove the problem with that code when using IONIC
    if (!process.env.REACT_APP_USE_IONIC) {
      checkMaintenanceStatus();
      return () => {
        sse.close();
      };
    }
  }, [location.pathname]);

  return {  };
};

export default useMaintenance;

import Markdown from "markdown-to-jsx";
import React from "react";

import ActivityCardMobile from "../../../components/ActivityCard/ActivityCardMobile/ActivityCardMobile";
import ImageCard from "../../../components/ImageCard/ImageCard";
import { goToTheTop } from "../../../helpingFunctions/linkChange";
import WindowWidth from "../../../hooks/WindowWidth";
import Heading from "../../../shared/Heading/Heading";
import InformationText from "../../../shared/InformationText/InformationText";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import CategoryBanner from "../../../shared/Categories/CategoryBanner/CategoryBanner";
import SpaPresentation from "../../../shared/Categories/SpaPresentation/SpaPresentation";
import HorisontalCardsGrid from "../../../shared/Categories/HorisontalCardsGrid/HorisontalCardsGrid";
import DetailsBox from "../../../shared/Categories/DetailsBox/DetailsBox";
import Sorting from "../../../shared/Categories/Sorting/Sorting";
import CategoriesLogic from "../../../shared/Categories/CategoriesLogic";

import styles from "./SpaMobile.module.css";

const SpaMobile = ({ showType, setView }) => {
  const {
    isLoading,
    pageTextData,
    selectedCategory,
    clickedObject,
    originalData,
    manipulatedData,
    categoryBannersRef,
    reservation,
    setManipulatedData,
    categorySelectorHandler,
    handleCardClick,
    updateStateAfterSort,
  } = CategoriesLogic(showType);
  const { width } = WindowWidth();

  return (
    <div className={styles.activitesMobile}>
      <Heading
        fontSize={"24px"}
        paddingTop={width >= 700 ? "40px" : "20px"}
        paddingBottom={"20px"}
        txt={reservation.Hotel?.SpaMobileTitle}
        fontFamily="Georgia"
        textTransform="uppercase"
      />
      <InformationText
        content={
          pageTextData?.MobileDescription ? (
            <Markdown>{pageTextData?.MobileDescription}</Markdown>
          ) : null
        }
        new_styles={{ paddingBottom: "20px" }}
      />
      <Sorting initialData={originalData} setData={updateStateAfterSort} />
      {isLoading ? (
        <LoadingContainer />
      ) : (
        <>
          <SpaPresentation />
          <HorisontalCardsGrid
            key={2}
            shouldOpen={true}
            new_styles={{
              gridRowGap: "85px",
              gridTemplateColumns:
                width >= 700 ? "repeat(auto-fill, 200px)" : "repeat(auto-fill, 120px)",
              gridGap: "10px",
            }}
          >
            {manipulatedData.map(
              ({
                ServiceTypeDescription,
                CategoryImagePortrait,
                ServiceTypeCode,
              }) => (
                <>
                  {CategoryImagePortrait ? (
                    <ImageCard
                      key={`ImageCard_${ServiceTypeCode}`}
                      img={CategoryImagePortrait}
                      new_styles={{
                        height: width >= 700 ? "200px" : "111px",
                        padding: "10px",
                        paddingBottom: "15px",
                      }}
                      handleClick={categorySelectorHandler.bind(
                        this,
                        ServiceTypeCode
                      )}
                    >
                      <Heading
                        key={`Heading_${ServiceTypeCode}`}
                        txt={ServiceTypeDescription}
                        new_styles={{ color: "#fff", fontSize: width >= 700 ? "20px" : "18px"}}
                      />
                    </ImageCard>
                  ) : null}
                </>
              )
            )}
          </HorisontalCardsGrid>
          {manipulatedData?.map(
            (
              {
                ServiceTypeDescription,
                ServiceTypeCode,
                CategoryImageLandscape,
                objects,
              },
              index
            ) => (
              <span
                key={`Wrapper_${ServiceTypeCode}`}
                style={{ width: "100%" }}
              >
                {CategoryImageLandscape ? (
                  <CategoryBanner
                    key={`CategoryBanner_${ServiceTypeCode}`}
                    handleClick={categorySelectorHandler.bind(
                      this,
                      ServiceTypeCode
                    )}
                    isSelected={selectedCategory === ServiceTypeCode}
                    buttonClick={goToTheTop}
                    categoryBannersRef={categoryBannersRef}
                    additionalData={{
                      ServiceTypeCode,
                      index,
                      image: CategoryImageLandscape,
                      content: ServiceTypeDescription,
                    }}
                  />
                ) : null}
                <HorisontalCardsGrid
                  key={`HorisontalCardsGrid_${ServiceTypeCode}`}
                  shouldOpen={
                    selectedCategory === ServiceTypeCode ||
                    !CategoryImageLandscape
                  }
                  new_styles={{
                    gridGap: "10px",
                    paddingTop: "25px",
                    paddingBottom: "25px",
                    gridTemplateColumns:
                      width >= 700 ? "repeat(auto-fill, 200px)" : "1fr 1fr",
                    position: "relative",
                  }}
                >
                  {objects.map((object) => (
                    <ActivityCardMobile
                      key={object.id}
                      {...object}
                      onClick={handleCardClick.bind(this, object, index)}
                      isSelected={clickedObject?.id === object.id}
                    />
                  ))}
                </HorisontalCardsGrid>
                {clickedObject &&
                      clickedObject.ServiceTypeCode === ServiceTypeCode ? (
                  <DetailsBox
                    dimensionClass={"DetailsBoxMobile"}
                    clickedObject={clickedObject}
                    unselectCard={handleCardClick.bind(this, clickedObject)}
                    previewFunctionality={setView.bind(this, {
                      type: clickedObject.objType,
                      id: clickedObject.id,
                    })}
                  />
                ) : null}
              </span>
            )
          )}
        </>
      )}
    </div>
  );
};

export default SpaMobile;

import Markdown from "markdown-to-jsx";
import React, { useEffect, useState } from "react";
import { xSign } from "../../../assets/SVGIcons";
import Button from "../../../shared/Button/Button";
import Heading from "../../../shared/Heading/Heading";
import ImageCarousel from "../../../shared/ImageCarousel/ImageCarousel";
import IndicatiorCircle from "../../../shared/IndicatorNumber/IndicatiorCircle";

import styles from "./Details.module.css";

const Details = ({
  object,
  priceTxt,
  closeFunc,
  previewFunctionality,
  sliderWrapperSize = { width: "585px", height: "400px" },
  smallerType = false,
}) => {
  const [isContraindicationsAccepted, setIsContraindicationsAccepted] =
    useState(false);

  const showContraindications =
    !isContraindicationsAccepted && object.Contraindications;

  let NavigateButton = null;
  let SpaCancelLink = null;

  if (previewFunctionality) {
    NavigateButton = (
      <>
        {object.Contraindications ? (
          <div className={styles.checkBoxWrapper}>
            <Heading
              txt={"Contraindications"}
              new_styles={{
                paddingBottom: "20px",
                paddingTop: "20px",
                fontSize: "20px",
                width: "100%",
              }}
            />
            <Markdown>{object.Contraindications}</Markdown>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "right",
                paddingTop: "15px",
                alignItems: "center",
              }}
            >
              <div
                className={styles.checkBox}
                onClick={() =>
                  setIsContraindicationsAccepted(!isContraindicationsAccepted)
                }
                style={{
                  marginRight: "10px",
                }}
              >
                {isContraindicationsAccepted ? (
                  <img src={"/img/info_icons/done_check.svg"} alt={""} />
                ) : null}
              </div>
              <p>{"I Accept"}</p>
            </div>
          </div>
        ) : null}
        <div className={styles.Details__PrecedeBtn}>
          <Button
            txt={
              object.objType === "Treats"
                ? "Book now / Add to stay"
                : "Check availability"
            }
            onClick={previewFunctionality}
            new_styles={{
              color: "var(--color-secondary)",
              backgroundColor: "var(--color-primary)",
              border: "2px solid var(--color-primary)",
              minWidth: "200px",
              opacity: object.objType === "Treats" ? "1" : showContraindications ? "0.4" : "1",
              pointerEvents: object.objType === "Treats" ? "auto" : showContraindications ? "none" : "auto",
            }}
          />
        </div>
      </>
    );
  } else if (object.objType === "Spa") {
    SpaCancelLink = (
      <div className={styles.spaTermsWrapper} style={{marginTop: "20px"}}>
        <a
          className={styles.termsAndConditions}
          href={`${object.PolicyLink}`}
          target="_blank"
          rel="noreferrer"
        >
          View Cancellation Terms & Conditions
        </a>
      </div>
    );
  }

  const [carousel, setCarousel] = useState(null);
  useEffect(() => {
    setIsContraindicationsAccepted(false);
    setCarousel(null);

    setTimeout(() => {
      setCarousel(
        <ImageCarousel onClick={() => {}} sliderImages={object.Image} />
      );
    }, 50);
  }, [object]);

  return (
    <section className={styles.Details}>
      <div
        className={`${styles.Details__Header} ${
          smallerType
            ? styles.Details__Header_leftAligned
            : styles.Details__Header_centered
        }`}
      >
        <div className={styles.Details__Title}>
          <Heading
            txt={object?.Title}
            fontFamily="Georgia"
            textTransform="uppercase" 
            new_styles={{
              paddingBottom: "20px",
              paddingTop: "40px",
              fontSize: "24px",
              paddingRight: "10px",
            }}
          />
          {smallerType ? (
            <Heading
              txt={priceTxt}
              new_styles={{
                color: "var(--color-primary)",
                fontSize: "1.3rem",
                paddingBottom: "17px",
                paddingTop: "40px",
              }}
            />
          ) : null}
        </div>
        <div className={styles.Details__closeBTn} onClick={closeFunc}>
          {xSign()}
        </div>
      </div>
      <div className={styles.Details__activityOverview}>
        <div className={styles.Details__activityImages}>
          <div
            className={styles.Details__sliderWrapper}
            style={{
              ...sliderWrapperSize,
            }}
          >
            {carousel}
          </div>
        </div>
        <div
          className={styles.Details__activityDescription}
        >
          {!smallerType ? (
            <Heading
              txt={priceTxt}
              new_styles={{
                fontSize: "1.3rem",
              }}
            />
          ) : null}
          <div className={styles.Details__activitiesTextWrapper}>
            {object.LongDescription ? (
              <Markdown>{object.LongDescription}</Markdown>
            ) : (
              ""
            )}
          </div>
          <div className={styles.Details__activitiesIconWrapper}>
            {object.Details?.map(({ icon }, index) =>
              icon ? (
                <IndicatiorCircle
                  key={index}
                  txt={
                    <img
                      width={"66%"}
                      height={"57%"}
                      src={icon?.formats?.thumbnail?.url}
                      alt={""}
                    />
                  }
                  backgroundColor={"transparent"}
                  color={"#5f5f5f"}
                  border={"1px solid #5f5f5f"}
                  width={"50px"}
                  height={"50px"}
                  fontSize={"1.2rem"}
                />
              ) : null
            )}
          </div>
          {SpaCancelLink}
        </div>
      </div>
      {NavigateButton}
    </section>
  );
};

export default Details;

import { useState, useContext, useEffect } from "react";
import {
  cloneObject,
  sumNumbers,
  validateForm,
} from "../../helpingFunctions/helpingFunctions";
import { DataContext } from "../../context/DataContext";
import RequestHook from "../../hooks/RequestHook";
import axiosInstance from "../../url/createAxios";
import { SelectionContext } from "../../context/SelectionContext";
import SaveBookingHook from "../../hooks/SaveBookingHook";
import { compare, groupBy } from "../../helpingFunctions/arrays";
import { DiningTypes, GROUP_LABELS } from "../../constants/constants";
import { getTotalQtnForAddedReservations } from "../../helpingFunctions/groups";

const formObj = {
  surname: "",
  bookingId: "",
};

const CreateGroupingLogic = (totalGuests, existingGroupName) => {
  const instance = axiosInstance();

  const [formData, setFormData] = useState(formObj);
  const [informationObj, setInformationObj] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [groupStatus, setGroupStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [groupName, setGroupName] = useState(existingGroupName);
  const [nonBookedRes, setNonBookedRes] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(null);
  const { saveBookings } = SaveBookingHook("Group_Linking");

  const { setBookingSelections, bookingStatus } = useContext(SelectionContext);
  const {
    isOnValidState,
    setIsOnValidState,
    reservation,
    setReservation,
    itineraryData,
    groupData,
    setGroupData,
  } = useContext(DataContext);
  const { getAvailability, getGroup,  handleAvailabilityRequest} = RequestHook();
  const manageFormData = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const manageGroupNameValue = (key, value) => {
    setGroupName(value);
  };

  const findIfReservationExits = (reservation) => {
    const reservationInGroup = groupData.items.some(
      ({ ReservationNumber, GuestLastName }) =>
        ReservationNumber === reservation.ReservationNumber &&
        GuestLastName === reservation.LastName
    );

    return reservationInGroup;
  };

  const validateReservation = async (e) => {
    e.preventDefault();
    try {
      if (loading) return;
      setLoading(true);
      const { bookingId, surname } = formData || {};
      const undefinedValues = validateForm({ bookingId, surname });
      const undefinedKeys = Object.keys(undefinedValues);

      if (undefinedKeys.length > 0) {
        setLoading(false);
        if (undefinedKeys.length === 2) {
          setInformationObj({
            txt: "Please enter surname and booking ID to perform validation.",
            backgroundColor: "var(--color-error)",
            icon: "exclamation.svg",
          });
          return;
        }
        setInformationObj({
          txt: `Please enter ${
            undefinedKeys[0] === "surname" ? "your surname" : "booking ID"
          } to perform validation.`,
          backgroundColor: "var(--color-error)",
          icon: "exclamation.svg",
        });
        return;
      }

      const { data } = await instance.post(
        `/group/validate`,
        {
          GuestLastName: surname,
          CRSNumber: bookingId,
        },
        { withCredentials: true }
      );
      setLoading(false);
      setIsOnValidState(true);

      if (data.validReservation) {
        const { validReservation } = data;

        const isAdded = findIfReservationExits(validReservation);

        if (isAdded) {
          setInformationObj({
            txt: `Reservation with ReservationNumber: ${validReservation.CRSNumber} and surname: ${validReservation.LastName} is already a part of your ${GROUP_LABELS.GroupRef.lowerCase}.`,
            backgroundColor: "var(--color-error)",
            icon: "exclamation.svg",
          });
          return;
        } else {
          if (
            (reservation.Breakfast || reservation.Dinner) &&
            !validReservation.HasBookings
          ) {
            setNonBookedRes((prevState) => {
              return [
                ...prevState,
                {
                  ReservationNumber: validReservation.ReservationNumber,
                  CRSNumber: validReservation.CRSNumber,
                  GuestLastName: validReservation.LastName,
                  GuestFirstName: validReservation.FirstName,
                  NumberOfGuests: validReservation.Adults,
                  LinkHash: validReservation.LinkHash,
                },
              ];
            });
          }

          setGroupData((prevState) => {
            return {
              items: [
                ...prevState.items,
                {
                  ReservationNumber: validReservation.ReservationNumber,
                  CRSNumber: validReservation.CRSNumber,
                  GuestLastName: validReservation.LastName,
                  GuestFirstName: validReservation.FirstName,
                  NumberOfGuests: validReservation.Adults,
                  LinkHash: validReservation.LinkHash,
                  IsGroupCreator: false,
                  IsInGroup: false,
                },
              ],
              groupName: prevState?.groupName ? prevState?.groupName : null,
              groupId: prevState?.groupId ? prevState?.groupId : null,
            };
          });
        }
      }

      const isSuccess = data.status === 200;
      setInformationObj({
        txt: data.msg,
        backgroundColor: isSuccess ? "#2F5C27" : "var(--color-error)",
        icon: isSuccess ? "check_circle.svg" : "exclamation.svg",
      });
      setFormData(formObj);
    } catch (e) {
      console.log(e.messages);
    }
  };

  const createHashList = () => {
    const hashList = groupData.items
      .map(({ LinkHash }) => {
        return { hash: LinkHash };
      })
      .filter((x) => x.hash);

    return hashList;
  };

  const hasNonBookedRes = () => {
    if (nonBookedRes.length > 0) {
      const requestArr = [];
      for (let index = 0; index < nonBookedRes.length; index++) {
        const element = nonBookedRes[index];
        const { itineraryDates } = itineraryData;
        itineraryDates.map(({ Facilities }) => {
          Facilities.map((facility) => {
            if (["Breakfast", "Dinner"].includes(facility.Type)) {
              requestArr.push({
                FirstName: element.GuestFirstName,
                LastName: element.GuestLastName,
                ReservationNumber: element.ReservationNumber,
                action: "add",
                Quantity: element.NumberOfGuests,
                day: facility.Date,
                facilityGUID: [facility.Id],
                restaurant: facility.Title,
                time: facility.Time,
                type: facility.Type,
                groupId: groupData.groupId,
                groupName,
              });
            }
          });
        });
        setBookingSelections(requestArr);
        setGroupStatus("book-for-new-res");
      }
    }

    return nonBookedRes.length > 0;
  };

  const createGroupReq = async () => {
    setLoadingStatus("createGroup");
    const hash = createHashList();

    const { data } = await instance.post(
      `/group`,
      {
        hash,
        GroupName: groupName,
      },
      { withCredentials: true }
    );

    if (data.status === 200) {
      setIsOnValidState(false);
      groupData.items.map((x) => {
        x.IsInGroup = true;
      });

      await getGroup();
      await handleAvailabilityRequest(reservation, totalGuests);

      setGroupStatus("finished");
      setInformationObj({
        txt: data.msg,
        backgroundColor: "#2F5C27",
        icon: "check_circle.svg",
      });
      setNonBookedRes([]);
      setReservation((prevState) => {
        return {
          ...prevState,
          IsGroupCreator: true,
          PartOfGroup: true,
        };
      });
    } else {
      setInformationObj({
        txt: data.msg,
        backgroundColor: "var(--color-error)",
        icon: "exclamation.svg",
      });
    }
    setLoadingStatus(null);
  };

  const prepareGroupCreation = async () => {
    try {
      setGroupStatus("started");
      if (!groupName || groupName.trim().length === 0) {
        setGroupStatus("non-valid");
        return;
      }

      if (hasNonBookedRes()) return;

      await createGroupReq();
    } catch (e) {
      console.log(
        `${new Date()} Error in createGroup func in CreateGroupingLogic.js file: ${e}`
      );
    }
  };

  const handleAddingBookings = async () => {
    setLoadingStatus("saveBookings");
    const totalQtn = sumNumbers(nonBookedRes, "NumberOfGuests");
    await getAvailability(totalQtn, ["Breakfast", "Dinner"]);

    const hasAvailability = await saveBookings();
    if (hasAvailability) {
      await createGroupReq();
    } else {
      setInformationObj({
        txt: `Unfortunately there was not availability to book for the new reservations`,
        backgroundColor: "var(--color-error)",
        icon: "exclamation.svg",
      });
      await getGroup();
      setGroupStatus("finished");
    }
  };

  useEffect(() => {
    if (bookingStatus && bookingStatus.results) {
      const hasFailure = bookingStatus.results.some(
        ({ Status }) => Status === "failure"
      );

      if (hasFailure) {
        setInformationObj({
          txt: `Unfortunately we could not add this guest to your ${GROUP_LABELS.GroupRef.lowerCase} as there was no availability for the timeslot you were looking for!`,
          backgroundColor: "var(--color-error)",
          icon: "exclamation.svg",
        });
        setGroupStatus(null);
        setLoadingStatus(null);
      } else {
        createGroupReq().then(() => {
          setGroupStatus("finished");
          setLoadingStatus(null);
        });
      }
    }
  }, [bookingStatus]);

  const manageCheck = () => {
    setTermsChecked(!termsChecked);
  };

  return {
    manageFormData,
    validateReservation,
    prepareGroupCreation,
    manageCheck,
    manageGroupNameValue,
    setGroupStatus,
    handleAddingBookings,
    loadingStatus,
    informationObj,
    termsChecked,
    groupStatus,
    loading,
    formData,
    isOnValidState,
    groupName,
    nonBookedRes,
  };
};

export default CreateGroupingLogic;

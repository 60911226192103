import React, { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { navBarIcons, editPencil } from "../../assets/SVGIcons";
import { isBookingInThePast } from "../../helpingFunctions/utilities";
import InformationalBox from "../../shared/Containers/InformationalBox";
import { ActivitiesTypes, BOOKING_TYPES, DiningTypes } from "../../constants/constants";

import styles from "./itinerary_mobile_card.module.css";

const ItineraryCardExpandRow = ({
  date,
  facilityId,
  bookedTime,
  Type,
  handleClick,
  bookingConflicts,
  Quantity,
  isParticipant,
  FirstName,
  LastName,
  ParticipantFirstName,
  ParticipantLastName,
  bookedDetails,
}) => {

  const { reservation } = useContext(DataContext);

  const getRedirectToPage = (type) => {
    let redirectTo = "";
    switch (type) {
      case "Venue":
        redirectTo = "Entertainment";
        break;
      case BOOKING_TYPES.InternalActivity:
        redirectTo = "WhatsOnGuide";
        break;
      default:
        redirectTo = type;
        break;
    }

    return redirectTo;
  };

  const getDayConflicts = () => {
    const dayConflicts = ["You have "];
    bookingConflicts[`${bookedTime.substring(0, 5)}|${Type}`].forEach(
      (row, index) => {
        dayConflicts.push(
          <>
            <span>
              {[...ActivitiesTypes, "Venue", BOOKING_TYPES.InternalActivity].includes(
                row.type
              )
                ? row.title
                : row.type}
            </span>
            {index !==
            bookingConflicts[`${bookedTime.substring(0, 5)}|${Type}`].length - 1
              ? ", "
              : ""}
          </>
        );
      }
    );
    dayConflicts.push(
      " booked for the same time today. Please remember to update "
    );

    bookingConflicts[`${bookedTime.substring(0, 5)}|${Type}`].forEach(
      (row, index) => {
        dayConflicts.push(
          <>
            <span
              className={styles.editIcon}
              style={{
                fontStyle: "italic",
                textDecoration: "underline",
              }}
              onClick={() =>
                handleClick({
                  type: getRedirectToPage(row.type),
                  id: row.id,
                })
              }
            >
              {`your ${
                [...ActivitiesTypes, "Venue", BOOKING_TYPES.InternalActivity].includes(
                  row.type
                )
                  ? row.title
                  : row.type.toLowerCase()
              } booking`}
            </span>
            {index !==
            bookingConflicts[`${bookedTime.substring(0, 5)}|${Type}`].length - 1
              ? ", "
              : "."}
          </>
        );
      }
    );
    return dayConflicts;
  };

  const currentDate = new Date();
  const currentDateTime = currentDate.toISOString().split("T");
  const isInThePast = isBookingInThePast(
    date,
    bookedTime,
    currentDateTime[0],
    currentDateTime[1],
    Type,
    reservation.ArrivalDate.split("T")[0]
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          paddingLeft: "25px",
          paddingRight: "25px",
          marginBottom: "10px",
        }}
      >
        <span
          className={isInThePast ? styles.iconFillInPast : ""}
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: "20px",
            paddingBottom: "10px",
          }}
        >
          {DiningTypes.includes(Type)
            ? navBarIcons.Dinner.iconPurple
            : ActivitiesTypes.includes(Type) || Type === BOOKING_TYPES.InternalActivity
            ? navBarIcons.Activities.iconPurple
            : Type === "Spa"
            ? navBarIcons.Spas.iconPurple
            : Type === "Venue"
            ? navBarIcons.Entertainment.iconPurple
            : Type === "Treat"
            ? navBarIcons.Treats.iconPurple
            : Type === "Golf"
            ? navBarIcons.Golf.iconPurple
            : Type === "Treats"
            ? navBarIcons.Treats.iconPurple
            : navBarIcons.Breakfast.iconPurple}
        </span>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "16px",
              borderBottom: "1px solid #CCC",
              width: "85%",
              paddingBottom: "10px",
              color: !isInThePast ? "var(--color-primary)" : "#949494",
            }}
          >
            <p>
              <strong>{`${
                Type === "Spa"
                  ? "Spa treatment"
                  : Type === "Venue"
                  ? "Showtime"
                  : Type === BOOKING_TYPES.InternalActivity
                  ? "Activity"
                  : Type
              }: `}</strong>
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: !["Venue", "Spa"].includes(Type) ? "5px" : "0",
              }}
            >
              {Type === "Venue" ? (
                <>
                  <p style={{ paddingRight: "5px" }}>
                    <strong
                      style={{ fontSize: "18px" }}
                    >{`${bookedTime.substring(0, 5)} - `}</strong>
                    <strong>{bookedDetails.entTitle}</strong>
                  </p>
                </>
              ) : Type === "Treats" ? (
                <>
                  <p style={{ paddingRight: "5px" }}>
                    <strong
                      style={{ fontSize: "18px" }}
                    >{`${Quantity} x `}</strong>
                    {bookedDetails?.Title + " - " + bookedDetails?.Location}
                  </p>
                </>
              ) : Type === "Spa" ? (
                <>
                  <p
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    <strong
                      style={{ fontSize: "18px" }}
                    >{`${bookedTime.substring(0, 5)} - `}</strong>
                    {`${bookedDetails?.Title} - (${
                      isParticipant ? ParticipantFirstName : FirstName
                    } ${isParticipant ? ParticipantLastName : LastName})`}
                  </p>
                </>
              ) : (
                <p>
                  <strong style={{ fontSize: "18px" }}>{`${bookedTime.substring(
                    0,
                    5
                  )} - `}</strong>
                  {bookedDetails?.Title}
                  {ActivitiesTypes.includes(Type) ? (
                    <span>{` for ${Quantity} ${
                      Quantity === "1" ? "guest" : "guests"
                    }`}</span>
                  ) : null}
                </p>
              )}
            </div>

            {Type === "Venue" ? (
              <div style={{ paddingBottom: "5px" }}>
                <p>
                  Venue: <strong>{bookedDetails.Title}</strong>
                </p>
              </div>
            ) : null}

            {bookingConflicts[`${bookedTime.substring(0, 5)}|${Type}`] &&
            !isInThePast ? (
              <>
                <InformationalBox content={getDayConflicts()} showIcon={true} />
              </>
            ) : null}
          </div>

          {!isInThePast ? (
            <span
              className={styles.editIcon}
              style={{
                position: "absolute",
                right: "30px",
                padding: "1px",
              }}
              onClick={() =>
                handleClick({
                  type: getRedirectToPage(Type),
                  id: facilityId,
                })
              }
            >
              {editPencil}
            </span>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ItineraryCardExpandRow;

import React from "react";

import Heading from "../../../../../shared/Heading/Heading";
import InformationText from "../../../../../shared/InformationText/InformationText";

import { durationLabel } from "../../shared/helpingFunctions";

import styles from "./GuideItemTV.module.css";
import { ALL_DAY_ACTIVITY } from "../../../constants/constants";
import { formatTimeWithoutAmPm } from "../../../../../helpingFunctions/utilities";

const GuideItemTV = ({
  Description,
  Location,
  Price,
  Title,
  Images,
  duration,
  time,
  timeId,
  Type,
  StartTime,
  EndTime
}) => {
  const imageUrl = Images[0]?.formats.medium
    ? Images[0].formats.medium.url
    : Images[0]?.formats.small
      ? Images[0].formats.small.url
      : Images[0]?.url
  const isAllDayActivity = ALL_DAY_ACTIVITY === Type;

  return (
    <article className={styles.GuideItemTV} id={isAllDayActivity ? "All Day Activities" : timeId}>
      <div
        className={styles.GuideItemTV__slider}
        style={{
          background: `url(${imageUrl}) center / cover transparent`,
        }}
      ></div>
      <div className={styles.GuideItemTV__details}>
        <Heading
          txt={Title}
          new_styles={{
            fontSize: "34px",
            color: "var(--color-primary)",
            fontWeight: "bold",
          }}
        />
        <ul className={styles.GuideItemTV__details_txt}>
          <li>
            <strong>Price:</strong>{" "}
            {`${Price ? `£${Price}` : `Inclusive`}`}
          </li>
          <li>
            <strong>Start:</strong> {isAllDayActivity ? formatTimeWithoutAmPm(StartTime) : time}
          </li>
          <li>
            <strong>Location:</strong> {isAllDayActivity ? Location : Location?.locationName}
          </li>
          <li>
            <strong>{isAllDayActivity ? "End:" : "Duration:"}</strong> {isAllDayActivity
                ? formatTimeWithoutAmPm(EndTime)
                : durationLabel(duration)}
          </li>
        </ul>
        <InformationText
          content={Description}
          new_styles={{
            fontSize: "20px",
          }}
        />
      </div>
    </article>
  );
};

export default GuideItemTV;

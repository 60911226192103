import { useContext, useEffect } from "react";

import { DataContext } from "../../../context/DataContext";
import { SavedPreSelectionsContext } from "../../../context/SavedPreSelections";
import { SelectionContext } from "../../../context/SelectionContext";
import { filterTimes } from "../../../helpingFunctions/helpingFunctions";
import { ActivitiesTypes } from "../../../constants/constants";

const ActivityPreviewLogic = (id, selectedDate, setAvailableTimes, setLoading, view) => {
    const { bookingSelections, editMode, setBookingSelections } = useContext(SelectionContext)
    const { availibility, reservation, bookingStatus, itineraryData,  } = useContext(DataContext)
    const { manage_Activity_Spa_Selections } = useContext(SavedPreSelectionsContext);

    const findAvailibility = (activityId, date) => {
        try {
          const { Facilities } = availibility?.find(({ Date }) => Date === date);
          const { Times } = Facilities.find(
            ({ FacilityGUID }) => FacilityGUID === activityId
          ) || { Times: [] };

          manage_Activity_Spa_Selections(bookingSelections, date, Times,  itineraryData, "Facilities", id)
          
          if (availibility[0].Date === date) {
            const filterOnlyAvailable = filterTimes(Times)

            setAvailableTimes([...filterOnlyAvailable]);
            setLoading(false);
            return filterOnlyAvailable;
          }
  
          setAvailableTimes([...Times]);
          setLoading(false);
          return Times;
        } catch (e) {
          console.log(
            `${new Date()} Error in findAvailibility function in ActivityPreviewLogic file: `,
            e
          );
        }
      };

    useEffect(() => {
        if (selectedDate && !selectedDate.endsWith("I") && ([...ActivitiesTypes, "Treat"].includes(view)) && !bookingStatus) {
          findAvailibility(id, selectedDate);
        } 
    }, [selectedDate,itineraryData, editMode, availibility]);

    return {findAvailibility}
}

export default ActivityPreviewLogic

import React from "react";
import { useState } from "react";
import WindowWidth from "../../hooks/WindowWidth";

import styles from "./ToolTip.module.css";

const ToolTip = ({ info, directionClass, tooltipManipulate, toolTipBoxStyles }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { width } = WindowWidth();

  return (
    <span
      className={`${styles.ToolTip} ${styles[tooltipManipulate]}`}
      onClick={setIsHovered.bind(this, !isHovered)}
      onMouseEnter={width > 900 ? setIsHovered.bind(this, true) : null}
      onMouseLeave={setIsHovered.bind(this, false)}
    >
      <img
        className={styles.ToolTip__icon}
        src="/img/info_icons/info.svg"
        alt="no img"
      />
      <div
        className={`${styles.tooltipAction} ${styles[directionClass]}`}
        style={{ display: isHovered ? "block" : "none", ...toolTipBoxStyles }}
      >
        <div className={styles.tooltipArrow} />
        <div
          className={styles.tooltipLabel}
          onMouseEnter={setIsHovered.bind(this, true)}
        >
          {info}
        </div>
      </div>
    </span>
  );
};

export default ToolTip;

import React from "react";
import { useLocation } from "react-router-dom";

import {
  INTERNAL_REMOVE_BOOKED_TXT,
} from "../../constants/constants";
import { isBookingInThePast } from "../../../../helpingFunctions/utilities";

import BookActivityLogic from "./BookActivityLogic";
import Button from "../../../../shared/Button/Button";

const BookActivity = ({
  setView,
  isInternalActivity,
  guideItem,
  internalActivityItinerary,
  internalActivityItineraryHandler,
}) => {
  const { pathname } = useLocation();
  const { maestroActivityClicked, manipulateInternalActivity, selectedDate } =
    BookActivityLogic(
      guideItem.activityId,
      setView,
      internalActivityItineraryHandler
    );

  const isNotPrivate = pathname.includes("public") || pathname.includes("team");
  const today = new Date();
  const userTimezoneOffset = today.getTimezoneOffset() * 60000;
  const todayUpdated = new Date(today.getTime() - userTimezoneOffset).toISOString().split("T");
  const isInThePast = isBookingInThePast(
    selectedDate,
    guideItem.time,
    todayUpdated[0],
    todayUpdated[1]
  )

  if (isNotPrivate) {
    return null;
  }

  if (isInternalActivity) {
    const isBooked = internalActivityItinerary.some(
      ({ InternalActivityTimeId }) =>
        InternalActivityTimeId === guideItem.timeId
    );

    if (isBooked) {
      return (
        <Button
          txt={INTERNAL_REMOVE_BOOKED_TXT}
          onClick={async (e) => {
            e.stopPropagation();
            await manipulateInternalActivity(guideItem);
          }}
          new_styles={{
            maxHeight: "64px",
            backgroundColor: "var(--color-primary)",
            color: "var(--color-secondary)",
          }}
          isInThePast={isInThePast}
        />
      );
    }
  } else {
    return (
      <Button
        onClick={maestroActivityClicked.bind(this, guideItem)}
        txt={"Book"}
        new_styles={{
          maxHeight: "64px",
          backgroundColor: "var(--color-primary)",
          color: "var(--color-secondary)",
        }}
        isInThePast={isInThePast}
      />
    );
  }

  return null;
};
export default BookActivity;

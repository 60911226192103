import { useContext, useEffect } from "react";

import { initialDate } from "../../../../constants/constants";
import { DataContext } from "../../../../context/DataContext";
import { WhatsOnDataContext } from "../../../../context/WhatsOnDataContext";
import { cloneObject } from "../../../../helpingFunctions/helpingFunctions";
import { FILTER_ERROR, NOTHING_CREATED_ERROR } from "../../constants/constants";

const FilterLogic = () => {
  const {
    updateWhatsOnGuideDisplay,
    manageFilters,
    whatsOnGuideDataStatic,
    selectedFilters,
    whatsOnGuideAllDayActivitiesStatic,
    setWhatsOnGuideAllDayActivities,
    setAlert
  } = useContext(WhatsOnDataContext);
  const { selectedDate } = useContext(DataContext);

  const manageFilteration = (selection, filterType) => {
    if (selection.value === "All") {
      const clonedFilter = cloneObject(selectedFilters);
      delete clonedFilter[filterType];

      if (Object.keys(clonedFilter).length > 0) {
        manageFilters({ ...clonedFilter });
      } else {
        manageFilters({});
        if(selectedDate) {
          updateWhatsOnGuideDisplay([...whatsOnGuideDataStatic]);
        } else {
          setWhatsOnGuideAllDayActivities(whatsOnGuideAllDayActivitiesStatic);
          setAlert({
            isError: false,
          });
        }
      }
      return;
    }

    manageFilters((prevState) => {
      if (prevState[filterType]) {
        prevState[filterType] = selection;
        return { ...prevState };
      }
      return { ...prevState, [filterType]: selection };
    });
  };

  const filterAction = (selectedOption) => {
    let filteredData = null;
    if(selectedDate === null) {
      if (selectedOption["location"] === undefined || selectedOption["location"]?.value === "All") {
        setWhatsOnGuideAllDayActivities(whatsOnGuideAllDayActivitiesStatic);
      } else {
        filteredData = whatsOnGuideAllDayActivitiesStatic.filter(
          (allDayActivity) => 
            allDayActivity.Location === selectedOption["location"]?.value
        );
        if (filteredData.length === 0) {
          setAlert({
            isError: true,
            msg: selectedOption["location"]?.value ? FILTER_ERROR : NOTHING_CREATED_ERROR,
          });
        } else {
          setAlert({
            isError: false,
          });
        }
        setWhatsOnGuideAllDayActivities(filteredData);
      }
    } else {
      filteredData = applyFilter(whatsOnGuideDataStatic, selectedOption);
      updateWhatsOnGuideDisplay([...filteredData]);
    }
  };

  const applyFilter = (data, selectedOption) => data.map((guideItem) => {
      const AvailabletimesFilered = guideItem.Availabletimes.availability?.map(
        (availableTime) => {
          const availabilityFiltered = availableTime.availability.map(
            (availabilityItem) => {
              const filteredTimes = availabilityItem.times.filter((timeObj) => {
                const selectedKeys = Object.keys(selectedOption);
                const selectedKeysLn = selectedKeys.length;

                if (!timeObj.recordId) {
                  return;
                }
                const matchItems = selectedKeys.filter((key) => {
                  switch (key) {
                    case "location": {
                      return timeObj.Location.id === selectedOption[key].id;
                    }
                    case "time": {
                      const availableTime = new Date(
                        `${initialDate}T${timeObj.time}:00`
                      ).getTime();
                      const startTime = new Date(
                        `${initialDate}T${selectedOption[key].startHour}:00`
                      ).getTime();
                      const endTime = new Date(
                        `${initialDate}T${selectedOption[key].endHour}:00`
                      ).getTime();
                      return (
                        availableTime >= startTime && availableTime <= endTime
                      );
                    }
                    default: {
                      return true;
                    }
                  }
                });

                return matchItems.length === selectedKeysLn;
              });

              return {
                ...availabilityItem,
                times: filteredTimes,
              };
            }
          );
          return {
            ...availableTime,
            availability: availabilityFiltered,
          };
        }
      );
      return {
        ...guideItem,
        Availabletimes: { availability: AvailabletimesFilered },
      };
    });

  useEffect(() => {
    if (Object.keys(selectedFilters).length > 0) {
      filterAction(selectedFilters);
    }
  }, [selectedFilters]);

  return { manageFilteration };
};

export default FilterLogic;

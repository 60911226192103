import React from "react";

import Heading from "../../../shared/Heading/Heading";
import InformationText from "../../../shared/InformationText/InformationText";
import EntertainmentsLogic from "../EntertainmentsLogic";
import EntertainmentPreview from "../../../components/EntertainmentPreview/EntertainmentPreview";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import DaysList from "../../../components/DaysList/DaysList";
import EntertainmentFooter from "../../../components/Footer/EntertainmentFooter";

import styles from "./entertainments.module.css";
import Markdown from "markdown-to-jsx";
import WindowWidth from "../../../hooks/WindowWidth";

const EntertainmentsDesktop = ({ setView, showType }) => {
  const { isLoading, pageTextData, stayDates, entertainmentStaticContent } = EntertainmentsLogic(showType);
  const { width } = WindowWidth();
  
  return (
    <>
      {isLoading ? (
        <LoadingContainer />
      ) : (
        <div className={styles.entertainmentWrapper}>
          <div className={styles.entertainmentHeadline}>
            <div className={styles.entertainmentTitle}>
              <Heading
                txt={pageTextData?.DesktopTitle}
                paddingTop={"40px"}
                paddingBottom={"20px"}
                fontSize={"24px"}
                fontFamily="Georgia"
                textTransform="uppercase"
              />
              <div className={styles.information}>
                <InformationText
                  content={
                    pageTextData?.DesktopDescription_max_length_is_340 ? (
                      <Markdown>
                        {pageTextData?.DesktopDescription_max_length_is_340}
                      </Markdown>
                    ) : null
                  }
                  lineHeight={"1.5rem"}
                />
              </div>
            </div>
          </div>
          <DaysList data={stayDates} setView={setView} view={showType} />
          <EntertainmentPreview stayDates={stayDates} width={width} entertainmentStaticContent={entertainmentStaticContent}/>

          <EntertainmentFooter
            dates={stayDates}
            setView={(v) => setView(v)}
            view={showType}
          />
        </div>
      )}
    </>
  );
};

export default EntertainmentsDesktop;

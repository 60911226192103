import React from "react";

import { TIME_FILTER_OPTIONS } from "../../../../../../assets/StaticObjects";
import { DEFAULT_FILTER_PLACEHOLDER } from "../../../../constants/constants";

import Dropdown from "../../../../../../components/Dropdown/Dropdown";

import UserFiltersLogic from "./UserFiltersLogic";

const UserFilters = ({manageFilteration, selectedFilters, isTimeOfDayFilterShown}) => {
  const { storedLocations } = UserFiltersLogic();
  const filterSettings = [
    {
      type: "time",
      title: "Filter by time of day",
      list: TIME_FILTER_OPTIONS,
      isShown: isTimeOfDayFilterShown
    },
    {
      type: "location",
      title: "Filter by location",
      list: storedLocations,
      isShown: true
    },
  ];
  
  return (
    <>
      {filterSettings.map(
        ({ type, title, list, isShown }) =>
          isShown && (
            <Dropdown
              key={type}
              list={list}
              title={title}
              selectedOption={
                selectedFilters[type]
                  ? selectedFilters[type].value
                  : DEFAULT_FILTER_PLACEHOLDER
              }
              setSelectedOption={manageFilteration}
              filterType={type}
              view={"WhatsOnGuide"}
            />
          )
      )}
    </>
  );
};

export default UserFilters;

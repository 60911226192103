import React, { useContext, useEffect } from "react";

import { DiningTypes } from "../../constants/constants";
import DayButton from "../../shared/DayButton/DayButton";
import { SelectionContext } from "../../context/SelectionContext";
import DaysListLogic from "./DaysListLogic";
import { multipleSelected, singleSelected, treatsSelected } from "../../assets/requests";
import { DataContext } from "../../context/DataContext";
import WindowWidth from "../../hooks/WindowWidth";
import { leftArrow, rightArrow } from "../../assets/SVGIcons";
import { handleButtonDisplay } from "../../helpingFunctions/groups";

import styles from "./days_list.module.css";
import "../../css/regularCss/action_button.css";
import { assignFirstDate } from "../../helpingFunctions/helpingFunctions";
import AllDayButton from "../../shared/DayButton/AllDayButton";
import WhatsOnGuideLogic from "../../views/WhatsOnGuide/WhatsOnGuideLogic";
import { ALL_DAY_ACTIVITY } from "../../views/WhatsOnGuide/constants/constants";
import { WhatsOnDataContext } from "../../context/WhatsOnDataContext";

const DaysList = ({ data, setView, hideContent, view, id, treatsBookings = [] }) => {
  const { bookingSelections, skipedDays } = useContext(SelectionContext);
  const {
    selectedDate,
    groupData,
    reservation,
    itineraryData,
    setSelectedDate,
    availibility,
    facilitiesData
  } = useContext(DataContext);
  const { width } = WindowWidth();
  const {
    selectedItemId,
    setSelectedItemId,
  } = WhatsOnGuideLogic(view);
  const { handleClick, slideLeft, slideRight, leftPosition } = DaysListLogic(
    data.length,
    view,
    width
  );
  const { setAlert } = useContext(WhatsOnDataContext);

  useEffect(() => {
    if (view === "Spa" || view === "Activity") {
      assignFirstDate(data, setSelectedDate);
    }
  }, []);

  useEffect(() => {
    if (view === "WhatsOnGuide") {
      setAlert({ isError: false, })
    }
  }, [selectedDate]);

  const allDayActivitiesTab = () => (
    <AllDayButton
      key={`${ALL_DAY_ACTIVITY}Button`}
      handleClick={() => {
        setSelectedItemId(ALL_DAY_ACTIVITY);
        setSelectedDate(null);
      }}
      isSelected={selectedItemId === ALL_DAY_ACTIVITY && selectedDate === null}
      length={1}
      view={view}
    />
  );

  return (
    <>
      {(data.length >= 3 && width < 750) ||
      (data.length >= 6 && width < 1065)||
      (data.length >= 7 && width < 1121) ? (
        <div className={styles.dayList_mobile}>
          <div
            className={`${styles.arrow} ${styles.dayList_mobile__leftArrow}`}
            onClick={slideRight}
          >
            {leftArrow}
          </div>
          <div className={styles.dayList_mobile__slider_container}>
            <div
              className={styles.slider_container_show}
              style={{ left: leftPosition }}
            >
              {data.map((date, index) => (
                <DayButton
                  key={date}
                  date={date}
                  timeSelected={
                    DiningTypes.includes(view)
                      ? singleSelected(
                          date,
                          width,
                          selectedDate,
                          bookingSelections,
                          skipedDays
                        )
                      : view === "Treats" 
                      ? treatsSelected(treatsBookings, date) 
                      : multipleSelected(
                          date,
                          width,
                          selectedDate,
                          bookingSelections,
                          skipedDays, 
                          view, 
                          availibility, 
                          id, 
                          reservation.ArrivalDate,
                          facilitiesData
                        )
                  }
                  handleClick={() => handleClick(date)}
                  isSelected={selectedDate === date}
                  beforeSelected={
                    index < data.length && selectedDate === data[index + 1]
                  }
                  length={data.length}
                  view={view}
                />
              ))}
              {view === "WhatsOnGuide" && allDayActivitiesTab()}
            </div>
          </div>
          <div
            className={`${styles.arrow} ${styles.dayList_mobile__rightArrow}`}
            onClick={slideLeft}
          >
            {rightArrow}
          </div>
        </div>
      ) : (
        <div className={styles.daysContainer}>
          <div className={styles.daysWrapper}>
            {data.map((date, index) => (
              <DayButton
                key={date}
                date={date}
                timeSelected={
                  DiningTypes.includes(view)
                    ? singleSelected(
                        date,
                        width,
                        selectedDate,
                        bookingSelections,
                        skipedDays
                      )
                    : view === "Treats" 
                    ? treatsSelected(treatsBookings, date)
                    : multipleSelected(
                        date,
                        width,
                        selectedDate,
                        bookingSelections,
                        skipedDays, 
                        view, 
                        availibility, 
                        id, 
                        reservation?.ArrivalDate,
                        facilitiesData
                      )
                }
                handleClick={() => handleClick(date)}
                isSelected={selectedDate === date}
                beforeSelected={
                  index < data.length && selectedDate === data[index + 1]
                }
                length={data.length}
                view={view}
              />
            ))}
            {view === "WhatsOnGuide" && allDayActivitiesTab()}
          </div>
          <div className={styles.btnWrapper}>
            {hideContent || width <= 1324 || !itineraryData || view === "Lunch"
              ? null
              : handleButtonDisplay(
                  reservation,
                  setView,
                  groupData.groupId,
                  view,
                )}
          </div>
        </div>
      )}
    </>
  );
};

export default DaysList;

import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import UserFilters from "./components/UserFilters/UserFilters";
import FilterLogic from "./FilterLogic";
import AdminFilters from "./components/AdminFilters/AdminFilters";

import { WhatsOnDataContext } from "../../../../context/WhatsOnDataContext";

import styles from "./Filter.module.css";

const Filter = (isTimeOfDayFilterShown) => {
  const { selectedFilters } = useContext(WhatsOnDataContext);
  const { pathname } = useLocation();
  const isAdminPage = pathname.includes("team");
  const { manageFilteration } = FilterLogic();
  let PageFilters = null;
  if (isAdminPage) {
    PageFilters = <AdminFilters />;
  } else {
    PageFilters = (
      <UserFilters
        isTimeOfDayFilterShown={isTimeOfDayFilterShown.isTimeOfDayFiterShown}
        selectedFilters={selectedFilters}
        manageFilteration={manageFilteration}
      />
    );
  }

  return <div className={styles.Filter}>{PageFilters}</div>;
};

export default Filter;

import React from "react";
import Markdown from "markdown-to-jsx";
import Heading from "../../../shared/Heading/Heading";

import ImageCarousel from "../../../shared/ImageCarousel/ImageCarousel";

import styles from "../../../css/info_box.module.css";
import style from "./RestaurantDetailsContainer.module.css";

const RestaurantDetailsContainer = ({
  details,
  toggleDetailsDialogHandler,
  width,
  source,
}) => {
  return (
    <div className={styles.popUpWrapper}>
      <div className={styles.grayOutScreen}></div>
      {width >= 750 ? (
        <div
          className={style.infoDetailsBox}
          style={{ backgroundColor: source === "addOns" ? "white" : "#f2f2f4" }}
        >
          <img
            className={style.closeButtons}
            src={`/img/info_icons/error_.svg`}
            onClick={() => toggleDetailsDialogHandler(false, {})}
            alt=""
          />

          <div className={style.contentWrapper}>
            <div className={style.imgSliderWrapper}>
              <ImageCarousel
                sliderImages={details.Images}
                containImg={true}
                imageCustomStyle={{ borderRadius: "1px" }}
                fillImg={true}
              />
            </div>

            <div className={style.infoWrapper}>
              <Heading
                txt={details.Title}
                fontSize={"20px"}
                new_styles={{
                  color: "var(--color-primary)",
                  paddingBottom: "30px",
                  paddingRight: "50px",
                }}
              />

              {details.Price ? (
                <p className={style.shortDesc}>
                  <Markdown>{`£ ${details.Price}`}</Markdown>
                </p>
              ) : null}

              <div
                style={{
                  overflow: "auto",
                  height: "230px",
                  paddingRight: "20px",
                }}
              >
                {details.ShortDescription ? (
                  <p className={style.shortDesc}>
                    <Markdown>{details.ShortDescription}</Markdown>
                  </p>
                ) : null}
                {details.LongDescription ? (
                  <p className={style.longDesc}>
                    <Markdown>{details.LongDescription}</Markdown>
                  </p>
                ) : null}
              </div>

              {source === "addOns" ? (
                <div
                  style={{
                    padding: "20px",
                    marginTop: "15px",
                    display: "flex",
                    alignItems: "center",
                    background: "#f2f2f4",
                  }}
                >
                  <img
                    className={style.closeButtons}
                    src={`/img/info_icons/exclamation_blue.svg`}
                    onClick={() => toggleDetailsDialogHandler(false, {})}
                    alt=""
                  />
                  <p style={{ paddingLeft: "10px" }}>
                    Please note that by clicking add on the main screen you are
                    adding this treat to that particular day.
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={style.infoDetailsBox}
          style={{ backgroundColor: source === "addOns" ? "white" : "#f2f2f4" }}
        >
          <div
            className={style.imgSliderWrapper}
            style={{ height: "350px", position: "relative" }}
          >
            <div
              className={style.title}
              style={{
                display: "flex",
                alignItems: "center",
                background: "white",
                justifyContent: "space-between",
                width: "100%",
                zIndex: "7",
                position: "absolute",
                padding: "10px 0",
              }}
            >
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Heading
                  txt={details.Title}
                  fontSize={"20px"}
                  new_styles={{
                    color: "var(--color-primary)",
                  }}
                />
              </div>
              <img
                className={style.closeButtons}
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
                src={`/img/info_icons/error_.svg`}
                onClick={() => toggleDetailsDialogHandler(false, {})}
                alt=""
              />
            </div>
            <ImageCarousel
              sliderImages={details.Images}
              imageCustomStyle={{ borderRadius: "5px" }}
              fillImg={true}
              newSliderStyles={{ left: "0" }}
              newImageStyles={{ borderRadius: "0" }}
            />
          </div>

          {details.Price ? (
            <p className={style.shortDesc} style={{ marginTop: "20px" }}>
              <Markdown>{`£ ${details.Price}`}</Markdown>
            </p>
          ) : null}

          <div
            style={{
              overflow: "auto",
              height: "230px",
              width: "100%",
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            {details.ShortDescription ? (
              <p className={style.shortDesc}>{details.ShortDescription}</p>
            ) : null}
            {details.LongDescription ? (
              <p className={style.longDesc}>
                <Markdown>{details.LongDescription}</Markdown>
              </p>
            ) : null}
          </div>

          {source === "addOns" ? (
            <div
              style={{
                padding: "20px",
                margin: "15px",
                display: "flex",
                alignItems: "center",
                background: "#f2f2f4",
              }}
            >
              <img
                className={style.closeButtons}
                src={`/img/info_icons/exclamation_blue.svg`}
                onClick={() => toggleDetailsDialogHandler(false, {})}
                alt=""
              />
              <p style={{ paddingLeft: "10px" }}>
                Please note that by clicking add on the main screen you are
                adding this treat to that particular day.
              </p>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default RestaurantDetailsContainer;

import React, { useContext, useState } from "react";

import SpaTimeSlotsMobile from "../../../components/ActivityTimeSlots/AcitivityTimeSlotsMobile/SpaTimeSlotsMobile";
import Heading from "../../../shared/Heading/Heading";
import ImageCarousel from "../../../shared/ImageCarousel/ImageCarousel";
import InformationText from "../../../shared/InformationText/InformationText";
import Button from "../../../shared/Button/Button";
import WindowWidth from "../../../hooks/WindowWidth";
import AllSelectionsPopUp from "../../../shared/AllSelectionPopUp/AllSelectionsPopUp";
import Markdown from "markdown-to-jsx";
import AlertBox from "../../../components/AlertBox/AlertBox";
import { xSign } from "../../../assets/SVGIcons";
import DaysList from "../../../components/DaysList/DaysList";
import SpaPreviewMultGuestsLogic from "../ActivityAndSpaLogic/SpaPreviewMultGuestsLogic";
import SpaExtraSection from "../../../components/SpaExtra/SpaExtraSection";
import SpaExtraTable from "../../../components/SpaExtra/SpaExtraTable";
import { formatDate, getDayOfWeek } from "../../../helpingFunctions/utilities";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import ConflictAlertBox from "../../../shared/validation/ConflictAlertBox";
import InformationalBoxHeavy from "../../../shared/Containers/InformationalBoxHeavy";

import styles from "./spa-preview.module.css";

const SpaPreviewMobile = ({ id, view, setView, goTo }) => {
  const { width } = WindowWidth();
  const {
    spaSelection,
    unfinishedSelection,
    showSpaExtraSection,
    filteredBookings,
    reservation,
    termsChecked,
    showBookConfirmation,
    isEditMode,
    actionAlertObj,
    hasChanges,
    bookingStatus,
    showBookAnotherTreatmentDialog,
    selectedDate,
    object,
    availableDates,
    spaLoading,
    alertStatus,
    alertMessage,
    priceTxt,
    spaFormData,
    isOnEditModePerSelectedDate,
    setTermsChecked,
    setShowSpaExtraSection,
    setActionAlertObj,
    setShowBookConfirmation,
    setSpaSelection,
    setUnfinishedSelection,
    setIsEditMode,
    handleShowSpaExtraSection,
    prompSpaConfirmation,
    closeActionAlert,
    checkUnconfirmChanges,
    setShowBookAnotherTreatmentDialog,
    setSpaFormData,
    manageBooking,
    goBack,
    showConflict,
    closeAlert,
    manageSpaFormData,
    isReservationIn3Months,
  } = SpaPreviewMultGuestsLogic(id, view, setView, goTo);

  return (
    <>
      {!object ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className={styles.activityPreviewMobile}>
            <div className={styles.activityPreviewMobile__title}>
              <Heading
                txt={object?.Title}
                paddingBottom={"20px"}
                paddingTop={width >= 700 ? "40px" : "20px"}
                fontSize={"24px"}
                fontFamily="Georgia"
                textTransform="uppercase"
              />
              <div
                className={styles.activityPreviewMobile__close}
                onClick={goBack}
              >
                {xSign()}
              </div>
            </div>
            <div className={styles.activityPreviewMobile__slider}>
              <ImageCarousel onClick={() => {}} sliderImages={object?.Image} />
            </div>
            <div className={styles.activityPreviewMobile__details_selections}>
              <div className={styles.activityPreviewMobile__details}>
                <Heading
                  txt={priceTxt}
                  paddingBottom={"20px"}
                  paddingTop={"20px"}
                  fontSize={"22px"}
                />
                <InformationText
                  content={
                    object?.LongDescription ? (
                      <Markdown>{object?.LongDescription}</Markdown>
                    ) : (
                      ""
                    )
                  }
                />
              </div>
              <>
                {spaSelection.hasOwnProperty(selectedDate) ? (
                  <div>
                    {filteredBookings.length > 0 ? (
                      <SpaExtraTable
                        filteredBookings={filteredBookings}
                        policyLink={object.PolicyLink}
                        servicePrice={object.Price}
                        selectedDate={selectedDate}
                        setShowSpaExtraSection={setShowSpaExtraSection}
                        setUnfinishedSelection={setUnfinishedSelection}
                        setTermsChecked={setTermsChecked}
                        termsChecked={termsChecked}
                        setSpaFormData={setSpaFormData}
                        unfinishedSelection={unfinishedSelection}
                        spaSelection={spaSelection}
                        setSpaSelection={setSpaSelection}
                        isEditMode={isEditMode}
                        setIsEditMode={setIsEditMode}
                        width={width}
                      />
                    ) : null}
                  </div>
                ) : null}

                {hasChanges ? (
                  <div className={styles.bookingButtons}>
                    <Button
                      txt={"Discard changes"}
                      onClick={goBack}
                      color={"var(--color-primary)"}
                      backgroundColor={"var(--color-secondary)"}
                    />
                    <Button
                      txt={`${
                        isOnEditModePerSelectedDate
                          ? "Confirm Change"
                          : "Confirm Booking"
                      }`}
                      onClick={manageBooking}
                      color={"var(--color-secondary)"}
                      backgroundColor={"var(--color-primary)"}
                    />
                  </div>
                ) : null}

                {filteredBookings.length > 0 && !showSpaExtraSection ? (
                  <>
                    <div
                      style={{
                        padding: "20px 0 20px 0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        txt={"Book another guest"}
                        onClick={handleShowSpaExtraSection}
                        color={"var(--color-primary)"}
                        backgroundColor={"var(--color-secondary)"}
                      />
                      <div style={{ paddingLeft: "25px" }}>
                        <Button
                          txt={"Book another treatment"}
                          onClick={() => checkUnconfirmChanges()}
                          color={"var(--color-primary)"}
                          backgroundColor={"var(--color-secondary)"}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles.activityPreviewMobile__selections}>
                    <DaysList
                      data={availableDates}
                      hideContent={true}
                      view={view}
                    />
                    <div
                      style={{
                        backgroundColor: "var(--color-secondary)",
                        padding: "25px",
                        marginBottom: "30px",
                      }}
                    >
                      {spaSelection[selectedDate]?.length > 0 ? (
                        <>
                          <div
                            style={{
                              paddingTop: "15px",
                            }}
                          >
                            {`You are booking `}
                            <strong>{object?.Title}</strong>
                            {` for `}
                            <strong>{getDayOfWeek(selectedDate) + ", "}</strong>
                            <strong>{formatDate(selectedDate)}</strong>
                            {". "}
                          </div>
                          <div>
                            Please select the desired time below for your
                            treatment.
                          </div>
                        </>
                      ) : null}

                      {spaSelection.hasOwnProperty(selectedDate) &&
                      !spaLoading[selectedDate]?.isLoading ? (
                        <>
                          {spaSelection[selectedDate].length > 0 ? (
                            <>
                              <SpaTimeSlotsMobile
                                id={id}
                                key={selectedDate}
                                date={selectedDate}
                                activity={object}
                                view={view}
                                spaAvailabilityForMobile={
                                  spaSelection[selectedDate]
                                }
                                setSpaSelection={setSpaSelection}
                                spaSelection={spaSelection}
                                setUnfinishedSelection={setUnfinishedSelection}
                                unfinishedSelection={unfinishedSelection}
                                isEditMode={isEditMode}
                              />

                              {!spaLoading[selectedDate]?.isLoading ? (
                                <>
                                  {showSpaExtraSection ||
                                  (!showSpaExtraSection &&
                                    filteredBookings.length === 0) ? (
                                    <div>
                                      <SpaExtraSection
                                        reservation={reservation}
                                        termsChecked={termsChecked}
                                        manageSpaFormData={manageSpaFormData}
                                        spaFormData={spaFormData}
                                        setSpaFormData={setSpaFormData}
                                        spaSelection={spaSelection}
                                        setSpaSelection={setSpaSelection}
                                        setTermsChecked={setTermsChecked}
                                        unfinishedSelection={
                                          unfinishedSelection
                                        }
                                        setUnfinishedSelection={
                                          setUnfinishedSelection
                                        }
                                        optionDetails={object}
                                        setShowSpaExtraSection={
                                          setShowSpaExtraSection
                                        }
                                        setActionAlertObj={setActionAlertObj}
                                        setShowBookConfirmation={
                                          setShowBookConfirmation
                                        }
                                        showBookConfirmation={
                                          showBookConfirmation
                                        }
                                        isEditMode={isEditMode}
                                        setIsEditMode={setIsEditMode}
                                        selectedDate={selectedDate}
                                        policyLink={object.PolicyLink}
                                        width={width}
                                      />
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                            </>
                          ) : (
                            <InformationalBoxHeavy
                              title={
                                isReservationIn3Months
                                  ? null
                                  : "Spa treatments for your break available soon..."
                              }
                              content={
                                isReservationIn3Months
                                  ? `Sorry, there are no available bookings/times for ${object.Title}, there may be cancellations available, please check with guest services at the ${reservation.Hotel.Title}.`
                                  : "Sorry, we haven't released any availability for this date yet, however if you check back closer to your break date, we should have availability."
                              }
                              boxNewStyles={{ marginTop: "20px" }}
                            />
                          )}
                        </>
                      ) : (
                        <LoadingContainer />
                      )}
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>

          {bookingStatus ? (
            <AllSelectionsPopUp
              view={view}
              setView={(v) => setView(v)}
              mainObjectId={id}
            />
          ) : null}

          {actionAlertObj?.status === "missing-atr" ? (
            <AlertBox
              txt={actionAlertObj.alertMessage}
              button={
                <>
                  <Button
                    txt={"Ok"}
                    color={"var(--color-secondary)"}
                    backgroundColor={"var(--color-primary)"}
                    onClick={closeActionAlert}
                  />
                </>
              }
            />
          ) : null}

          {showBookConfirmation === "show" ? (
            <AlertBox
              customContent={
                <div>
                  <p>
                    {`You have selected `}
                    <strong>{object.Title}</strong>
                    {` at ${unfinishedSelection.time.substring(
                      0,
                      5
                    )} on ${getDayOfWeek(
                      unfinishedSelection.date
                    )}: ${formatDate(unfinishedSelection.date)}.`}
                  </p>
                  <p>
                    {`This will add `} <strong>£{object.Price}</strong>{" "}
                    {` to your bill.`}
                  </p>
                </div>
              }
              button={
                <>
                  <Button
                    txt={"Cancel"}
                    backgroundColor={"var(--color-secondary)"}
                    color={"var(--color-primary)"}
                    border={"2px solid var(--color-primary)"}
                    onClick={() => prompSpaConfirmation("No")}
                  />
                  <Button
                    txt={"I agree"}
                    backgroundColor={"var(--color-primary)"}
                    color={"var(--color-secondary)"}
                    onClick={() => prompSpaConfirmation("Yes")}
                  />
                </>
              }
            />
          ) : null}

          {alertStatus === "canceling" ? (
            <AlertBox
              txt={
                "We are proccessing your cancel request, this will take some time."
              }
            />
          ) : alertStatus === "non-valid" ? (
            <AlertBox
              txt={alertMessage}
              button={
                <>
                  <Button txt={"Ok"} backgroundColor={"var(--color-primary)"} color={"var(--color-secondary)"} onClick={closeAlert} />
                </>
              }
            />
          ) : ["strong-conflict", "soft-conflict", "average-conflict"].includes(
              alertStatus
            ) ? (
            <ConflictAlertBox
              alertMessage={alertMessage}
              alertStatus={alertStatus}
              showConflict={showConflict}
              spaSelection={spaSelection}
              setSpaSelection={setSpaSelection}
            />
          ) : showBookAnotherTreatmentDialog ? (
            <AlertBox
              customContent={
                <div>
                  <p>
                    By continuing with this action, you will dismiss all
                    unconfirmed selections. Are you sure?
                  </p>
                </div>
              }
              button={
                <>
                  <Button
                    txt={"Cancel"}
                    backgroundColor={"var(--color-secondary)"}
                    color={"var(--color-primary)"}
                    border={"2px solid var(--color-primary)"}
                    onClick={() => setShowBookAnotherTreatmentDialog(false)}
                  />
                  <Button
                    txt={"Ok"}
                    backgroundColor={"var(--color-primary)"}
                    color={"var(--color-secondary)"}
                    onClick={() => goBack()}
                  />
                </>
              }
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default SpaPreviewMobile;

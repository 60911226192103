import React from "react";

import AlertBox from "../../components/AlertBox/AlertBox";
import Button from "../../shared/Button/Button";

const ConflictAlertBox = ({ alertStatus, alertMessage, showConflict, spaSelection, setSpaSelection }) => {
  return (
    <>
      {alertStatus === "strong-conflict" ? (
        <AlertBox
          txt={alertMessage}
          button={
            <>
              <Button
                txt={"Ok"}
                color={"var(--color-secondary)"}
                backgroundColor={"var(--color-primary)"}
                onClick={() => showConflict("ok|strong-conflict", spaSelection, setSpaSelection)}
              />
            </>
          }
        />
      ) : alertStatus === "soft-conflict" ? (
        <AlertBox
          txt={alertMessage}
          button={
            <>
              <Button
                txt={"Ok"}
                color={"var(--color-secondary)"}
                backgroundColor={"var(--color-primary)"}
                onClick={() => showConflict("ok|soft-conflict")}
              />
            </>
          }
        />
      ) : alertStatus === "average-conflict" ? (
        <AlertBox
          txt={alertMessage}
          button={
            <>
              <Button
                txt={"Cancel"}
                backgroundColor={"var(--color-secondary)"}
                color={"var(--color-primary)"}
                onClick={() => showConflict("cancel|average-conflict")}
              />
              <Button
                txt={"Replace"}
                backgroundColor={"var(--color-primary)"}
                color={"var(--color-secondary)"}
                onClick={() => showConflict("replace|average-conflict")}
              />
            </>
          }
        />
      ) : null}
    </>
  );
};

export default ConflictAlertBox;

import React from "react";

import styles from "./indicator_number.module.css";

const IndicatiorCircle = ({
  txt,
  backgroundColor,
  width,
  height,
  color,
  border,
  fontSize,
}) => {
  return (
    <div
      className={styles.numberCircle}
      style={{
        backgroundColor,
        width,
        height,
        color,
        border,
        fontSize,
      }}
    >
      {txt}
    </div>
  );
};

export default IndicatiorCircle;

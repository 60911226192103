import React, { useContext, useState } from "react";

import Button from "../../shared/Button/Button";
import CreateGroupingLogic from "./CreateGroupingLogic";
import Information from "../InformationAlert/Information";
import InputCard from "../InputCard/InputCard";
import GroupTable from "../GroupTable/GroupTable";
import WindowWidth from "../../hooks/WindowWidth";
import LoadingContainer from "../../shared/LoadingContainer/LoadingContainer";
import ToolTip from "../../shared/Tooltip/ToolTip";
import AlertBox from "../AlertBox/AlertBox";
import { DataContext } from "../../context/DataContext";
import { groupNameDisplay, surname, bookingId } from "./constants/constants";

import styles from "../../views/GroupBooking/group_booking.module.css";
import style from "./create_grouping.module.css";
import { GROUP_LABELS } from "../../constants/constants";

const CreateGrouping = ({
  view,
  setView,
  totalGuests,
  viewData,
  fromManage = false,
  existingGroupName = "",
}) => {
  const { groupData, setGroupData } = useContext(DataContext);
  const handleCancel = () => {
    const allGroupParticipants = groupData.items.filter(({ id }) => id);

    setGroupData((prevState) => {
      return {
        items: allGroupParticipants,
        groupName: prevState.groupName,
        groupId: prevState.groupId,
      };
    });
    setView({ type: "Breakfast" });
  };
  const handleReturnToHomepage = () => {
    setView({ type: "" });
  };
  const handleReturnToBfstBookingPage = () => {
    setView({ type: "Breakfast" });
  };
  const {
    manageFormData,
    validateReservation,
    setGroupStatus,
    prepareGroupCreation,
    manageCheck,
    manageGroupNameValue,
    handleAddingBookings,
    informationObj,
    loadingStatus,
    termsChecked,
    groupStatus,
    loading,
    formData,
    groupName,
  } = CreateGroupingLogic(totalGuests, existingGroupName);

  const hasNewMembers = groupData.items.some(({ id }) => !id);

  const { width } = WindowWidth();
  const directionClass = width > 900 ? "bottom" : "right";
  return (
    <>
      <div className={styles.groupBookingFormWrapper}>
        <div className={style.CreateGrouping__Title}>
          <InputCard
            label={
              <>
                {groupNameDisplay.label}
                <ToolTip
                  info={viewData.GroupNameTooltip}
                  directionClass={directionClass}
                  tooltipManipulate={"Tooltip_Groups"}
                />
              </>
            }
            placeholder={groupNameDisplay.placeholder}
            name={groupNameDisplay.name}
            setFormData={(key, value) => manageGroupNameValue(key, value)}
            value={groupName}
            readOnly={fromManage}
            newBoxStyles={{
              color: fromManage ? "gray" : null,
              fontStyle: fromManage ? "italic" : null,
            }}
          />
        </div>
        {
          <form
            className={`${styles.groupBookingForm} ${
              groupStatus === "finished" ? styles.groupFormed : ""
            }`}
            onSubmit={validateReservation}
          >
            <div className={styles.createGroupInputWrapper}>
              <InputCard
                label={
                  <>
                    {surname.label}
                    <ToolTip
                      info={viewData.SurnameTooltip}
                      directionClass={directionClass}
                      tooltipManipulate={"Tooltip_Groups"}
                    />
                  </>
                }
                placeholder={surname.placeholder}
                name={surname.name}
                setFormData={(key, value) => manageFormData(key, value)}
                value={formData?.surname}
              />
              <InputCard
                label={
                  <>
                    {bookingId.label}
                    <ToolTip
                      info={viewData.BookingIdTooltip}
                      directionClass={directionClass}
                      tooltipManipulate={"Tooltip_Groups"}
                    />
                  </>
                }
                placeholder={bookingId.placeholder}
                name={bookingId.name}
                setFormData={(key, value) => manageFormData(key, value)}
                value={formData?.bookingId}
              />
            </div>
            <Button
              txt={"Add"}
              pointerEvents={loading ? "none" : "auto"}
              onClick={validateReservation}
              backgroundColor={"var(--color-primary)"}
              color={"var(--color-secondary)"}
            />
            {loading ? (
              <div className={style.CreateGrouping__loader}>
                <LoadingContainer />
              </div>
            ) : null}
            {informationObj ? (
              <div className={styles.msgWrapper}>
                {
                  <Information
                    txt={informationObj.txt}
                    new_styles={{
                      width: "100%",
                      backgroundColor: informationObj.backgroundColor,
                    }}
                    fullyColoredClass={"fullyColoredClass"}
                    flexDirection={width >= 1150 ? "row" : "column"}
                    marginLeft={width >= 1324 ? "35px" : "0px"}
                    icon={informationObj.icon}
                  />
                }
              </div>
            ) : null}
          </form>
        }
        {groupData.items ? (
          <GroupTable
            tableData={groupData.items}
            setTableData={setGroupData}
            totalGuests={totalGuests}
            view={view}
            setView={setView}
          />
        ) : null}
        {hasNewMembers && groupStatus !== "finished" ? (
          <div className={style.confiramtion_modal}>
            <p>
              By proceeding to book for {totalGuests} guests, you agree that you
              have read and accepted our{" "}
              <a
                href={
                  "https://www.warnerleisurehotels.co.uk/company/terms-and-conditions"
                }
                className={style.termsLink}
                target={"_blank"}
              >
                Terms and Conditions
              </a>{" "}
              and booking will be made on behalf of the whole{" "}
              {GROUP_LABELS.GroupRef.lowerCase} you have created.
            </p>
            <div className={style.checkBoxWrapper}>
              <div className={style.checkBox} onClick={manageCheck}>
                {termsChecked ? (
                  <img src={"/img/info_icons/done_check.svg"} alt={""} />
                ) : null}
              </div>
              <p style={{ width: "150px" }}>Yes, I agree</p>
            </div>
          </div>
        ) : null}
        <div
          className={`${styles.groupBookingButtonsWrapper} ${
            groupData.items.length > 1 ? style.groupBookingButtonsValid : ""
          }`}
        >
          <Button
            txt={groupStatus === "finished" ? "Return to homepage" : "Cancel"}
            onClick={
              groupStatus === "finished" ? handleReturnToHomepage : handleCancel
            }
            backgroundColor={"var(--color-secondary)"}
            color={"var(--color-primary)"}
          />
          <Button
            txt={
              groupStatus === "finished"
                ? "Return to breakfast booking page"
                : groupStatus === "started"
                ? "Saving"
                : groupData.groupId
                ? "Save"
                : GROUP_LABELS.CreateGroup
            }
            opacity={(termsChecked && hasNewMembers) || groupStatus ? "1" : "0.5"}
            pointerEvents={
              termsChecked &&
              groupData.items.length > 1 &&
              groupStatus !== "started"
                ? "auto"
                : "none"
            }
            onClick={
              groupStatus === "finished"
                ? handleReturnToBfstBookingPage
                : prepareGroupCreation
            }
            backgroundColor={"var(--color-primary)"}
            color={"var(--color-secondary)"}
          />
          {loadingStatus === "createGroup" ? (
            <div style={{ width: "55px", height: "70px", marginLeft: "20px" }}>
              <LoadingContainer
                new_styles={{ width: "40px", height: "40px" }}
              />
            </div>
          ) : null}
        </div>
        {groupStatus === "non-valid" ? (
          <AlertBox
            txt={groupNameDisplay.alertTxt}
            button={
              <>
                <Button
                  txt={"Ok"}
                  color={"var(--color-secondary)"}
                  backgroundColor={"var(--color-primary)"}
                  onClick={setGroupStatus.bind(this, null)}
                />
              </>
            }
          />
        ) : null}
        {groupStatus === "book-for-new-res" ? (
          <AlertBox
            txt={
              "This will create breakfast and dinner booking for the guest you are trying to link, would you like to proceed?"
            }
            button={
              <>
                {loadingStatus === "saveBookings" ||
                loadingStatus === "createGroup" ? (
                  <LoadingContainer
                    new_styles={{ width: "50px", height: "50px" }}
                  />
                ) : (
                  <>
                    <Button
                      txt={"Yes"}
                      color={"var(--color-secondary)"}
                      backgroundColor={"var(--color-primary)"}
                      onClick={handleAddingBookings}
                    />
                    <Button
                      txt={"No"}
                      color={"var(--color-primary)"}
                      backgroundColor={"var(--color-secondary)"}
                      onClick={setGroupStatus.bind(this, null)}
                    />
                  </>
                )}
              </>
            }
          />
        ) : null}
      </div>
    </>
  );
};

export default CreateGrouping;

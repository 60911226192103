import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Index from "./routes";

import "./css/theme.css"
import styles from "./css/app.module.css";

import useThemeDetector from "./hooks/useThemeDetector";
const lightSchemeIcon = document.querySelector("link#light-scheme-icon");
const darkSchemeIcon = document.querySelector("link#dark-scheme-icon");

const App = () => {
  const isDarkTheme = useThemeDetector();

  useEffect(() => {
    if (isDarkTheme) {
      lightSchemeIcon?.remove();
      document.head.append(darkSchemeIcon);
    } else {
      document.head.append(lightSchemeIcon);
      darkSchemeIcon?.remove();
    }
  }, [isDarkTheme]);

  useEffect(() => {
    setTimeout(() => {
      const doc = document.querySelector(".wcv-panel");
      if (doc) doc.style.display = "none";
    }, 100);
  }, []);

  return (
    <Router>
      <div className={styles.app} id={"app"}>
        <Index />
      </div>
    </Router>
  );
};

export default App;

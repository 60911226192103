import React, { useEffect, useState } from 'react'

import axiosInstance from "../../../../url/createAxios";

const AddCardLogic = () => {
    const instance = axiosInstance()
    const [pageData, setPageData] = useState({});

    const getData = async () => {
        const { data } = await instance.get("/pxp-modal-texts");
        setPageData(data)
    }

    useEffect(() => {
        getData()
    },[])
  return {
    pageData
  }
}

export default AddCardLogic
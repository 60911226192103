import React, { useState, useEffect } from "react";

import VerticalLine from "../VerticalLine/VerticaLine";
import BookingTypeLogic from "./BookingTypeLogic";
import NavBarDropDown from "./components/NavBarDropDown/NavBarDropDown";
import { DiningTypes, NavBarDropDownTiles } from "../../constants/constants";
import { navBarIcons } from "../../assets/SVGIcons";

import styles from "./booking_type.module.css";

const BookingType = ({
  txt,
  type,
  setCrossBurgerDiv,
  setView,
  navReached,
  view,
  iconColor,
  hideItem = false,
  dropDownItems = [],
  new_styles = {},
}) => {
  const { handleClick } = BookingTypeLogic(setView, view);
  const [showDiningDropdown, setShowDiningDropdown] = useState(false);
  const isDining = type === "Dining";
  useEffect(() => {
    setShowDiningDropdown(false);
    if (showDiningDropdown) setCrossBurgerDiv(false);
  }, [view]);

  if (hideItem) return null;
  return (
    <>
      <div
        className={`${styles.bookingType} ${
          view === type ? styles.highlight : ""
        }`}
        onClick={() =>
          NavBarDropDownTiles.includes(type)
            ? null
            : handleClick(type, setCrossBurgerDiv)
        }
        style={{ ...new_styles }}
      >
        <li
          className={`${styles.navItemReached}`}
          style={{ ...new_styles }}
        >
          {navBarIcons[type]?.[iconColor]}
          <p
            className={styles.navItemText}
            onClick={() =>
              isDining && !showDiningDropdown
                ? handleClick(type, setCrossBurgerDiv)
                : null
            }
          >
            {txt}
            <NavBarDropDown
              navItemType={type}
              view={view}
              setView={setView}
              dropDownItems={dropDownItems}
              setCrossBurgerDiv={setCrossBurgerDiv}
              showDiningDropdown={showDiningDropdown}
              setShowDiningDropdown={setShowDiningDropdown}
            />
          </p>
        </li>

        {NavBarDropDownTiles.includes(type) ? (
          <div
            style={{
              paddingLeft: "5px",
              paddingBottom: "10px",
              transform: "rotate(90deg)",
            }}
            onClick={() => setShowDiningDropdown(!showDiningDropdown)}
          >
            {navBarIcons["rightNavArrow"]?.[iconColor]}
          </div>
        ) : null}
        {type === "Folio" ? null : (
          <VerticalLine
            height={"33%"}
            marginRight={"30px"}
            marginLeft={"30px"}
            bgColor={iconColor === "iconPurple" ? "var(--color-primary)" : "var(--color-secondary)"}
          />
        )}
      </div>
    </>
  );
};

export default BookingType;

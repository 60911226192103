import moment from "moment";
import { useState, useContext, useEffect } from "react";

import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import { isValidDate } from "../../helpingFunctions/bookingHelp";
import { cloneObject } from "../../helpingFunctions/helpingFunctions";
import WindowWidth from "../../hooks/WindowWidth";
import VenueBanner from "../../shared/VenueBanner/VenueBanner";

const RestaurantCardLogic = (
  facilityGUID,
  isFullyBooked,
  selectedState,
  view,
  availability
) => {
  const {
    getSelection,
    bookingSelections,
    createBookingSelectionNew,
    removeBookingSelectionNew,
  } = useContext(SelectionContext);
  const { selectedDate, cardBannerContent, itineraryData, availibility } =
    useContext(DataContext);

  const [openIframe, setOpenIframe] = useState(false);
  const [showRestDetails, setShowRestDetails] = useState(false);
  const [selection, setSelection] = useState([]);
  const [fadeElement, setFadeElement] = useState(null);
  const [isBooked, setIsBooked] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);

  const getThisSelection = () => {
    const currentSelection = getSelection(facilityGUID);

    setSelection(currentSelection);
  };

  const managefadeOfElement = () => {
    try {
      const elementMatched = bookingSelections.find(
        (booking) =>
          booking?.facilityGUID?.find((x) => x !== facilityGUID[0]) &&
          booking.day === selectedDate
      );

      if (elementMatched) {
        setFadeElement(elementMatched);
      } else {
        setFadeElement(null);
      }
    } catch (e) {
      console.log(
        `${new Date()} Error at managefadeOfElement function in RestaurantCardLogic file: `,
        e
      );
    }
  };

  const isDinningBooked = () => {
    const isBooked = bookingSelections.findIndex(
      (row) => row.action === "none" && row.day === selectedDate
    );
    isBooked === -1 ? setIsBooked(false) : setIsBooked(true);
  };

  const hasValidSelection = () => {
    let hasValidSel = false;
    const selection = bookingSelections.find((row) => row.day === selectedDate);
    if (selection) {
      hasValidSel = selection.hasOwnProperty("time");
    }

    setHasSelection(hasValidSel);
  };

  const handleClick = (facilityGUID, selectedDate, value, key) => {
    try {
      const prevObject = cloneObject(selection);
      const selectedRestaurant = getSelection(facilityGUID);

      if (selectedRestaurant && prevObject.facilityGUID.length === 1) {
        removeBookingSelectionNew([], selectedDate, "", key);
        return;
      }

      createBookingSelectionNew(
        facilityGUID,
        selectedDate,
        value,
        key,
        "add",
        view
      );
    } catch (e) {
      console.log(
        `${new Date()} Error at handleClick function in RestaurantCardLogic file: `,
        e
      );
    }
  };

  const getBannerContent = () => {
    const date = selectedDate?.endsWith('I') ? selectedDate.slice(0, -1) : selectedDate;

    if (date?.endsWith("-12-31") && view === "Dinner") {
      return cardBannerContent.DinnerNoAvail31stDecember;
    } else if (date?.endsWith("-12-25") && view === "Dinner") {
      return cardBannerContent.DinnerNoAvail25thDecember;
    } else if (moment(date).diff(moment().format('YYYY-MM-DD'), 'days') > 119) {
      return cardBannerContent.DiningFutures;
    }

    return cardBannerContent.NoAvailDefault;
  }

  useEffect(() => {
    if (selectedDate) {
      managefadeOfElement();
      getThisSelection();
    }
  }, [bookingSelections, selectedDate]);

  const isGrayedOut =
    (fadeElement && !fadeElement.facilityGUID.includes(facilityGUID[0])) ||
    (isFullyBooked && availability?.length === 0) ||
    !isFullyBooked;

  const fullyBookedBanner = (
    height = "50px",
    fontSize = "18px",
    paddingLeft = "10px"
  ) => {
    if (
      itineraryData &&
      availibility &&
      availibility.length > 0 &&
      (!isFullyBooked || (isFullyBooked && availability?.length === 0))
    ) {
      return (
        <VenueBanner
          content={getBannerContent()}
          new_styles={{
            backgroundColor: "var(--color-primary)",
            height,
            justifyContent: "center",
            fontSize,
            paddingLeft,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            left: "0",
          }}
        />
      );
    } else if (itineraryData && availibility && availibility.length === 0) {
      return (
        <VenueBanner
          content={cardBannerContent.DiningFutures}
          new_styles={{
            backgroundColor: "var(--color-primary)",
            height,
            justifyContent: "center",
            fontSize,
            paddingLeft,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            left: "0",
          }}
        />
      );
    }

    return <></>;
  };

  const handleIframe = () => {
    setOpenIframe(true)
  }

  useEffect(() => {
    if (selectedDate) {
      isDinningBooked();
      hasValidSelection();
    }
  }, [bookingSelections, selectedDate]);

  return {
    handleClick,
    selectedDate,
    isGrayedOut,
    openIframe,
    handleIframe,
    FullyBookedBanner: fullyBookedBanner,
    isBooked,
    hasSelection,
    cardBannerContent,
    setShowRestDetails,
    showRestDetails
  };
};

export default RestaurantCardLogic;

import React from "react";

const Logo = ({
  width,
  height,
  handleClick,
  cursor,
  warnerLogo,
}) => {
  return (
    <>
      {warnerLogo ? (
        <img
          src={"/img/logos/Warner_Logo_Blue.svg"}
          style={{ width, height, cursor }}
          onClick={handleClick}
          alt="Warner Hotels"
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={"/img/logos/Warner_Logo_Blue.svg"}
            style={{ width, height, cursor }}
            onClick={handleClick}
            alt="Warner Hotels"
          />
        </div>
      )}
    </>
  );
};

export default Logo;

import React from "react";

import TimeButtonLogic from "./TimeButtonLogic";

import styles from "../../css/time_button.module.css";
import "../../css/regularCss/selection_indications.css";
import { useEffect } from "react";

const TimeButton = ({
  time,
  Availability,
  facilityGUID,
  icon,
  available,
  width,
  border,
  height,
  borderRadius,
  setActivityTimeLimit,
  setIsTimeGrayOut,
  skiped,
  view,
  setSpaSelection,
  spaSelection,
  setUnfinishedSelection,
  unfinishedSelection,
}) => {
  const { handleClick, selection, selectedDate, fadeElement } = TimeButtonLogic(
    time,
    facilityGUID,
    view,
    setActivityTimeLimit,
    Availability,
  );
  const isGrayOut = fadeElement || !available;

  const checkSpaSelection = () => {
    const test = spaSelection[selectedDate]?.find((row) => row.Time === time);
    return test?.selected;
  };

  useEffect(() => {
    if (setIsTimeGrayOut) setIsTimeGrayOut(isGrayOut);
  }, [fadeElement, available]);

  if (!time) return null;

  return (
    <>
      <div
        onClick={handleClick.bind(
          this,
          facilityGUID,
          selectedDate,
          time,
          "time",
          view,
          setSpaSelection,
          spaSelection,
          setUnfinishedSelection,
          unfinishedSelection,
          Availability
        )}
        className={`
                ${styles.timeButton}
                ${
                  (
                    view === "Spa"
                      ? checkSpaSelection()
                      : selection?.time === time
                  )
                    ? styles.focusedTime
                    : ""
                }
                ${isGrayOut || skiped ? "fadeElement" : ""}
            `}
        style={{
          width,
          height,
          borderRadius,
          border,
        }}
      >
        <p>
          {time?.substr(0, 5)} {isGrayOut ? "*" : null}
        </p>
        {icon}
      </div>
    </>
  );
};

export default TimeButton;

import React, { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";
import { filterTimes } from "../../../helpingFunctions/helpingFunctions";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import Information from "../../InformationAlert/Information";
import TimeButton from "../../../shared/TimeButton/TimeButton";
import styles from "./spa-time-slots.module.css";

const SpaTimeSlotsMobile = ({
  date,
  activity,
  view,
  spaAvailabilityForMobile,
  id,
  setSpaSelection,
  spaSelection,
  setUnfinishedSelection,
  unfinishedSelection,
  isEditMode,
}) => {
  const [timesAvailable, setTimesAvailable] = useState(null);

  const { reservation, itineraryData, availibility, selectedDate } =
    useContext(DataContext);

  const manageTimeDisplaySpa = () => {
    try {
      if (spaAvailabilityForMobile.length > 0) {
        if (
          new Date(date).getTime() ===
          new Date(reservation.ArrivalDate.substr(0, 10)).getTime()
        ) {
          const filterOnlyAvailable = filterTimes(spaAvailabilityForMobile);

          setTimesAvailable([
            { type: "Afternoon", times: filterOnlyAvailable },
          ]);
          return;
        }
        const morningTimes = { type: "Morning", times: [] };
        const afternoonTimes = { type: "Afternoon", times: [] };
        const sortedSpaAvailabilityForMobile = spaAvailabilityForMobile.sort((a, b) => a.Time.localeCompare(b.Time));
        sortedSpaAvailabilityForMobile.forEach((row) => {
          if (parseInt(row.Time?.substr(0, 3)) >= 12) {
            afternoonTimes.times.push(row);
          } else {
            morningTimes.times.push(row);
          }
        });

        setTimesAvailable([morningTimes, afternoonTimes]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    manageTimeDisplaySpa();
  }, [spaAvailabilityForMobile, availibility]);

  useEffect(() => {
    if (unfinishedSelection && !isEditMode) {
      const index = spaSelection[unfinishedSelection.date].findIndex(
        (row) => row.Time === unfinishedSelection.time
      );

      if (index !== -1) {
        const spaOptions = { ...spaSelection };

        spaOptions[unfinishedSelection.date][index].selected = false;
        setSpaSelection(spaOptions);
      }
      setUnfinishedSelection(null);
    }
  }, [selectedDate]);

  return (
    <div>
      <>
        {!timesAvailable || timesAvailable?.length > 0 ? (
          <div className={styles.activityTimeSlotsMobile__hours}>
            {itineraryData && timesAvailable ? (
              <>
                {timesAvailable.map(({ type, times }, index) => (
                  <>
                    <div>
                      <div
                        style={{ paddingTop: "25px", paddingBottom: "20px" }}
                      >
                        <strong>{type}</strong>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gridGap: "20px",
                          flexWrap: "wrap",
                          paddingLeft: "25px",
                        }}
                      >
                        {times.map(({ Availability, Time }, index) => (
                          <TimeButton
                            key={Time}
                            time={Time}
                            Availability={Availability}
                            facilityGUID={[id]}
                            available={parseInt(Availability) >= 1}
                            width={"100px"}
                            height={"45px"}
                            border={"2px solid var(--color-primary)"}
                            view={view}
                            setSpaSelection={setSpaSelection}
                            spaSelection={spaSelection}
                            setUnfinishedSelection={setUnfinishedSelection}
                            unfinishedSelection={unfinishedSelection}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <LoadingContainer />
            )}
          </div>
        ) : (
          <div className={styles.activityTimeSlotsMobile__error}>
            <Information
              new_styles={{
                width: "100%",
                backgroundColor: "var(--color-error)",
              }}
              fullyColoredClass={"fullyColoredClass"}
              flexDirection={"row"}
              marginLeft={"10px"}
              txt={`Sorry there are no available bookings/times for ${activity.Title}, there may be cancellations available, please check with guest services at the ${reservation.Hotel.Title}.`}
              icon={"exclamation.svg"}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default SpaTimeSlotsMobile;

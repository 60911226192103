import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import styles from "./booking-preview.module.css";

const BookingPreviewItemLogic = (day, totalElements, index) => {
  const { bookingStatus, setBookingStatus, diningAddOnsSelections } =
    useContext(SelectionContext);

  const { facilitiesData, setSelectedDate, entertainmentData, filteredTreatsData } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");

  const manageLoader = (index) => {
    setTimeout(() => {
      setStatus(
        bookingStatus?.results?.find((val) => val.Date.split("T")[0] === day)
          ?.Status
      );
      setLoading(false);
      if (totalElements === index + 1) {
        const failureStatus = bookingStatus.results.some(
          ({ Status }) => Status === "failure"
        );

        setBookingStatus({
          status: failureStatus ? "failure" : "success",
        });
      }
    }, index * 1000);
  };

  const handleFailureIconClick = () => {
    setSelectedDate(day);
    setBookingStatus(null);
  };

  const getDiningAddOns = (view, date, diningAction) => {
    const options = diningAddOnsSelections[view][date].filter((row) => row.status !== "pending");

    const prepAddOns = [];
    for (const row of options) {
      const resultStatus = getDiningAddOnsStatus(row.Date, row.ServiceCode);
      if (
        (diningAction === "remove" && row.action === "none") ||
        resultStatus === "no-change"
      )
        continue;

      prepAddOns.push(
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "10px",
            paddingTop: "10px",
          }}
        >
          <img
            style={{ width: "20px", height: "20px" }}
            src={"/img/info_icons/Icon_Add-on.svg"}
            alt={"no img"}
          />
          <p
            style={{
              width: "100%",
              textAlign: "left",
              textDecoration: row.action === "remove" ? "line-through" : "",
            }}
          >
            <strong>Add on:</strong>
            {` ${
              row.action === "remove" ? row.QuantityBooked : row.Quantity
            } x ${row.title} - ${row.Location}`}
          </p>
          {resultStatus !== "no-change" && (
            <>
              {loading ? (
                <img
                  className={styles.loader}
                  style={{
                    width: "40px",
                    marginLeft: "15px",
                    marginRight: "10px",
                  }}
                  src={"/img/info_icons/loading_white.svg"}
                  alt={"no img"}
                />
              ) : (
                <div style={{ marginRight: "35px", width: "40px" }}>
                  {resultStatus === "failed" ? (
                    <img
                      onClick={
                        status === "success" || status === "no-change"
                          ? null
                          : handleFailureIconClick
                      }
                      style={{width: "25px", height: "25px"}}
                      className={styles.progressIndicator}
                      src={`/img/info_icons/error_.svg`}
                      alt={"no img"}
                    />
                  ) : (
                    <p style={{ color: "white" }}>
                      {row.Status === "failed" ? "Failed" : row.action === "remove" ? "Cancelled" : "Added"}
                    </p>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      );
    }

    return prepAddOns;
  };

  const getDiningAddOnsStatus = (date, serviceCode) => {
    if (bookingStatus.status === "after-response") {
      const bookedDiningAddOn = bookingStatus.diningAddOnsResults.find(
        (row) => row.Date === date && row.ServiceCode === serviceCode
      );
      if (bookedDiningAddOn) return bookedDiningAddOn.Status;
      return "failed";
    }
  };

  useEffect(() => {
    if (bookingStatus.status === "after-response") {
      manageLoader(index);
    }
  }, [bookingStatus]);

  return {
    handleFailureIconClick,
    getDiningAddOns,
    facilitiesData,
    loading,
    filteredTreatsData,
    status,
    entertainmentData,
  };
};

export default BookingPreviewItemLogic;

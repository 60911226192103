import React from "react";

import GrayOutBackground from "../../../../components/UI/GrayOutBackground/GrayOutBackground";
import RegisteredCardLogic from "./RegisteredCardLogic";

import styles from "./RegisteredCard.module.css";

const RegisteredCard = ({ removeModal, view }) => {
  const { currentPage } = RegisteredCardLogic(removeModal, view);

  return (
    <GrayOutBackground>
      <div className={styles.RegisteredCard}>
        {currentPage}
      </div>
    </GrayOutBackground>
  );
};

export default RegisteredCard;

const MainTitleLogic = (setView) => {
  const handleClick = () => {
    if (!setView) return;

    setView("");
  };

  return { handleClick };
};

export default MainTitleLogic;

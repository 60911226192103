import React, { useContext } from "react";
import Markdown from "markdown-to-jsx";
import { DataContext } from "../../context/DataContext";
import Heading from "../../shared/Heading/Heading";
import InformationText from "../../shared/InformationText/InformationText";
import LoadingContainer from "../../shared/LoadingContainer/LoadingContainer";
import DiningLogic from "./DiningLogic";
import TypeCard from "../../components/TypeCard/TypeCard";
import ImageCarousel from "../../shared/ImageCarousel/ImageCarousel";
import Button from "../../shared/Button/Button";
import WindowWidth from "../../hooks/WindowWidth";
import { goToPage } from "../../helpingFunctions/linkChange";
import AlertBox from "../../components/AlertBox/AlertBox";

import styles from "./dining_desktop.module.css";
import GroupBookingAlert from "../../shared/GroupBookingAlert/GroupBookingAlert";

const DiningPreview = ({ view, setView }) => {
  const {
    landingPage,
    promotionalSpaceContent,
    itineraryData,
  } = useContext(DataContext);
  const { width } = WindowWidth();

  const { pageTextData } = DiningLogic(view);

  if (!itineraryData) {
    return <LoadingContainer />;
  }
  let randomPromIndex = Math.floor(Math.random() * (promotionalSpaceContent.length - 0 + 1));
  randomPromIndex = randomPromIndex === promotionalSpaceContent.length ? randomPromIndex - 1 : randomPromIndex;
  
  return (
    <>
      <div style={{ flex: "1 1", width: "80%", marginTop: "55px" }}>
        {pageTextData?.DesktopTitle ? (
          <div className={styles.headline}>
            <Heading
              txt={pageTextData?.DesktopTitle}
              paddingBottom={"20px"}
              fontSize={"24px"}
              fontFamily="Georgia"
              textTransform="uppercase"
            />
            <div className={styles.information}>
              <InformationText
                content={
                  pageTextData?.DesktopDescription_max_length_is_340 ? (
                    <Markdown>
                      {pageTextData?.DesktopDescription_max_length_is_340}
                    </Markdown>
                  ) : null
                }
                lineHeight={"1.5rem"}
              />
            </div>
          </div>
        ) : null}

        <div style={{ display: "flex", gap: "2%", marginTop: "30px" }}>
          <TypeCard
            paddingBottom={"20px"}
            width={"32%"}
            height={width < 600 ? "180px" : "260px"}
            setView={setView}
            type={"Breakfast"}
            src={landingPage?.BreakfastImage?.formats?.medium?.url}
            txt={landingPage.BreakfastText}
          />

          <TypeCard
            paddingBottom={"20px"}
            width={"32%"}
            height={width < 600 ? "180px" : "260px"}
            setView={setView}
            type={"Lunch"}
            src={landingPage?.LunchImage?.formats?.medium?.url}
            txt={landingPage.LunchText}
          />

          <TypeCard
            paddingBottom={"20px"}
            width={"32%"}
            height={width < 600 ? "180px" : "260px"}
            setView={setView}
            type={"Dinner"}
            src={landingPage?.DinnerImage?.formats?.medium?.url}
            txt={landingPage.DinnerText}
          />
        </div>
        {promotionalSpaceContent.length > 0 ? (
          <>
            {width > 800 ? (
              <div
                style={{
                  width: "100%",
                  height: "360px",
                  background: "var(--color-secondary)",
                  marginTop: "30px",
                  padding: "15px 25px",
                  display: "flex",
                  marginBottom: "35px",
                }}
              >
                <div
                  className={styles.headline}
                  style={{
                    width: "50%",
                    height: "100%",
                    paddingRight: "20px",
                  }}
                >
                  <Heading
                    txt={promotionalSpaceContent[randomPromIndex].Title}
                    paddingBottom={"10px"}
                    fontSize={"24px"}
                    new_styles={{ alignItems: "start", width: "100%" }}
                  />
                  <Heading
                    txt={promotionalSpaceContent[randomPromIndex].SubTitle}
                    paddingBottom={"20px"}
                    fontSize={"18px"}
                    new_styles={{ alignItems: "start", width: "100%" }}
                  />
                  <div className={styles.information}>
                    <InformationText
                      content={
                        promotionalSpaceContent[randomPromIndex].Description ? (
                          <Markdown>
                            {promotionalSpaceContent[randomPromIndex].Description}
                          </Markdown>
                        ) : null
                      }
                      lineHeight={"1.5rem"}
                    />
                  </div>

                  <div style={{ width: "100%" }}>
                    <Button
                      txt={promotionalSpaceContent[randomPromIndex].LinkButtonLabel}
                      onClick={() => goToPage(promotionalSpaceContent[randomPromIndex].Link)}
                      new_styles={{
                        color: "var(--color-primary)",
                        backgroundColor: "var(--color-secondary)",
                        border: "3px solid var(--color-primary)",
                        minWidth: "200px",
                      }}
                    />
                  </div>
                </div>
                <div style={{ width: "50%", height: "100%" }}>
                  <ImageCarousel
                    sliderImages={promotionalSpaceContent[randomPromIndex].Images}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "600px",
                  background: "var(--color-secondary)",
                  marginTop: "30px",
                  padding: "15px 25px",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "35px",
                }}
              >
                <div style={{ width: "100%", height: "50%" }}>
                  <ImageCarousel
                    sliderImages={promotionalSpaceContent[randomPromIndex].Images}
                  />
                </div>
                <div
                  className={styles.headline}
                  style={{
                    width: "100%",
                    height: "53%",
                  }}
                >
                  <Heading
                    txt={promotionalSpaceContent[randomPromIndex].Title}
                    paddingBottom={"10px"}
                    paddingTop={"15px"}
                    fontSize={"24px"}
                    new_styles={{ alignItems: "start", width: "100%" }}
                  />
                  <Heading
                    txt={promotionalSpaceContent[randomPromIndex].SubTitle}
                    paddingBottom={"15px"}
                    fontSize={"18px"}
                    new_styles={{ alignItems: "start", width: "100%" }}
                  />
                  <div className={styles.information}>
                    <InformationText
                      content={
                        promotionalSpaceContent[randomPromIndex].Description ? (
                          <Markdown>
                            {promotionalSpaceContent[randomPromIndex].Description}
                          </Markdown>
                        ) : null
                      }
                      lineHeight={"1.5rem"}
                    />
                  </div>

                  <div style={{ width: "100%" }}>
                    <Button
                      txt={promotionalSpaceContent[randomPromIndex].LinkButtonLabel}
                      onClick={() => goToPage(promotionalSpaceContent[randomPromIndex].Link)}
                      new_styles={{
                        color: "var(--color-primary)",
                        backgroundColor: "var(--color-secondary)",
                        minWidth: "200px",
                        fontWeight: "bold",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
      <GroupBookingAlert setView={setView}/>
    </>
  );
};

export default DiningPreview;

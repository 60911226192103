import axiosInstance from "../url/createAxios";

const ValidateLogInHook = () => {
  const instance = axiosInstance();
  const handleLogIn = async (reqBody) => {
    try {
      const { data } = await instance.post(`/login`, { ...reqBody });

      return {
        success: data.success,
        error: data.error,
      };
    } catch (e) {
      console.error("Error - handleRequest - ValidateLogInHook: ", { e });
      return {
        success: false,
        error: e.response
          ? e.response.data.error
          : "Network connection error. Please try again later!",
      };
    }
  };

  return { handleLogIn };
};

export default ValidateLogInHook;

import React from "react";

import TypeCardLogic from "./TypeCardLogic";
import Heading from "../../shared/Heading/Heading";

import styles from "./type-card.module.css";

const TypeCard = ({
  paddingBottom,
  width,
  height,
  type,
  txt,
  setView,
  src,
  hideItem = false,
  new_styles,
  onClick,
}) => {
  const { handleClick } = TypeCardLogic();
  if (hideItem) return null;

  return (
    <article
      className={styles.typeCard}
      style={{
        paddingBottom,
        width,
        height,
        backgroundImage: `linear-gradient(
          0deg,
          rgba(28, 28, 28, 1) 0%,
          rgba(168, 168, 168, 0.2) 100%
        ), url(${src})`,
        ...new_styles,
      }}
      onClick={
        type === "Folio" || type === "CreditCard" ? onClick : handleClick.bind(this, type, setView)
      }
    >
      <Heading txt={txt} fontSize={"24px"} />
    </article>
  );
};

export default TypeCard;

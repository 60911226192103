import { useContext, useState, useEffect } from "react";

import { DataContext } from "../../context/DataContext";
import { SavedPreSelectionsContext } from "../../context/SavedPreSelections";
import { SelectionContext } from "../../context/SelectionContext";
import { areEqual } from "../../helpingFunctions/arrays";

const BookingSectionLogic = (data, view) => {
  const { bookingSelections, skipDayHandler, skipedDays } = useContext(SelectionContext);
  const { reservation, facilitiesData, selectedDate, itineraryData, availibility, addOnsBookings, setFilteredTreatsData, treatsData } =
    useContext(DataContext);

  const [allTimes, setTimes] = useState([]);
  const [hasDiningAddOnsOnSkip, setHasDiningAddOnsOnSkip] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [restaurants, setRestaurants] = useState([]);
  const [clickedResId, setClickedResIdMobile] = useState(null);

  const [clicked, setClicked] = useState(false);
  const [isTimeGrayOut, setIsTimeGrayOut] = useState(false);
  const { dinningPreSelectionsSaved, setDinningPreSelectionsSaved } =
    useContext(SavedPreSelectionsContext);

  const structureAvailabilityData = (availabilityData) => {
    try {
      const structuredData = [];
      availabilityData.Facilities.map(({ Times, FacilityGUID }) =>
        Times.map(({ Availability, Time }) => { 
          
          const existingIndex = structuredData.findIndex(
            (item) => item.Time === Time
          );

          if (existingIndex >= 0) {
            
            structuredData[existingIndex].FacilityGUID.push(FacilityGUID);
          } else {
            structuredData.push({
              Time,
              Availability,
              FacilityGUID: [FacilityGUID],
            });
          }
        })
      );

      return createUISuportedStructure(structuredData);
    } catch (error) {
      console.log(
        `${new Date()} error in structureAvailabilityData func in AvailabilityLogic.js file ${error}`
      );
    }
  };

  const createUISuportedStructure = (structuredData) => {
    try {
      const uiSuportedList = [];
      structuredData.map(({ Availability, FacilityGUID, Time }) => {
        const existingIndex = uiSuportedList.findIndex((item) =>
          areEqual(item.FacilityGUID, FacilityGUID)
        );

        if (existingIndex >= 0) {
          uiSuportedList[existingIndex].Times.push({
            Time,
            Availability,
          });
        } else {
          uiSuportedList.push({
            FacilityGUID,
            Times: [
              {
                Time,
                Availability,
              },
            ],
          });
        }
      });

      return uiSuportedList;
    } catch (error) {
      console.log(
        `${new Date()} error in createUISuportedStructure func in AvailabilityLogic.js file ${error}`
      );
    }
  };
  const currentDayAvailability = (dateSelected, availability) => {
    try {
      if (availability?.length === 0) return;

      const currentDayIndex = availability?.findIndex(
        ({ Date }) => Date === dateSelected
      );
      let structuredTimesData = [];

      if (currentDayIndex < 0) {
        findMissingData(structuredTimesData);
        setTimes(structuredTimesData);
        return;
      }

      const availabilityData = JSON.parse(
        JSON.stringify(availability[currentDayIndex])
      );

      const sameTypeIds = restaurants.map(({ id }) => id);

      availabilityData.Facilities = availabilityData.Facilities.filter(
        ({ FacilityGUID }) => sameTypeIds.includes(FacilityGUID)
      );

      structuredTimesData = structureAvailabilityData(availabilityData);


      if (bookingSelections.length > 0) findMissingData(structuredTimesData);

      setTimes(structuredTimesData);
    } catch (error) {
      console.log(
        `${new Date()} error in manageSelectedDayAvailability func in AvailabilityLogic.js file ${error}`
      );
    }
  };

  const findMissingData = (structuredTimesData) => {
    const [unavaliableTimeSlotFromFunc] =
      findUnavailableTime(structuredTimesData);
    const unavaliableTimeSlotFromState = dinningPreSelectionsSaved.find(
      (unavailableTime) =>
        unavailableTime.selectedDate === selectedDate &&
        unavailableTime.view === view
    );

    const preSelection =
      unavaliableTimeSlotFromFunc ||
      unavaliableTimeSlotFromState?.unavailableTimesArray[0];

    if (!preSelection) return;

    if (structuredTimesData.length === 0) {
      structuredTimesData.push({
        FacilityGUID: [preSelection.facilityGUID[0]],
        Times: [
          {
            Time: preSelection.time,
            Availability: reservation.Adults,
          },
        ],
      });

      return;
    }

    const [preSelectionMatch] = structuredTimesData
      .map(({ Times, FacilityGUID }, index) => {
        const timeIndex = Times.findIndex(
          ({ Time }) => Time === preSelection.time
        );

        if (timeIndex >= 0) return { Times, FacilityGUID, index };
      })
      .filter((_) => _);

    if (preSelectionMatch) {
      structuredTimesData[preSelectionMatch.index].FacilityGUID.push(preSelection.facilityGUID[0]);
    } else {
      structuredTimesData.push({
        FacilityGUID: [preSelection.facilityGUID[0]],
        Times: [
          {
            Time: preSelection.time,
            Availability: reservation.Adults,
          },
        ],
      });
    }
  };

  const findUnavailableTime = (times) => {
    const unavailableTimesArray = bookingSelections
      .filter(({ day }) => day === selectedDate)
      .map((preSelection) => {
        const currentTimes = times.some(({ Times, FacilityGUID }) =>
          Times.some(
            ({ Time }) =>
              Time === preSelection.time &&
              FacilityGUID.includes(preSelection.facilityGUID[0])
          )
        );

        if (!currentTimes)
          return {
            ...preSelection,
          };
      });

    if (unavailableTimesArray.length > 0) {
      setDinningPreSelectionsSaved([
        ...dinningPreSelectionsSaved,
        { unavailableTimesArray, selectedDate, view },
      ]);
    }

    return unavailableTimesArray[0]?.time ? unavailableTimesArray : [undefined];
  };

  const structureData = async (data) => {
    try {
      currentDayAvailability(selectedDate, data);
    } catch (e) {
      console.error("findIfAlreadyClicked Error: ", e);
    }
  };

  const filterFacilities = () => {
    const restaurantsByType = facilitiesData.filter(
      ({ Type }) => Type === view
    );
    
    setRestaurants(restaurantsByType.sort(
      (a, b) =>
        (a.OrderPriority === null) - (b.OrderPriority === null) ||
        a.OrderPriority - b.OrderPriority ||
        a.id - b.id
    ))
  };

  const manageRestaurantMobileClicked = (id, action) => {
    if(id === clickedResId || action === 'deselect') {
      setClickedResIdMobile(null)
      return
    }
    setClickedResIdMobile(id)
  };

  const findSelectedItem = () => {
    const selectedItem = bookingSelections.find(
      ({ day, action }) => selectedDate === day && action !== "remove"
    );
    
    setSelectedItem(selectedItem);
  };

  const handleSkipWhenAddOns = () => {
    if (skipedDays.includes(selectedDate)) {
      skipDayHandler(selectedDate, view);
      return;
    }
    
    let keysChecked = 0;
    let keysLackingDining = 0;
    for (const [key, value] of Object.entries(addOnsBookings)) {
      keysChecked++;
      const diningAddOns = value.filter((row) => row.Date === selectedDate && row.Location === view && row.action !== "remove");

      if (diningAddOns.length === 0) {
        keysLackingDining++;
      }
    }

    if (keysChecked === keysLackingDining) {
      skipDayHandler(selectedDate, view);
    } else {
      setHasDiningAddOnsOnSkip(true);
    }
  };

  useEffect(() => {
    findSelectedItem();
  }, [bookingSelections, selectedDate]);
  useEffect(() => {
    if (availibility) structureData(data);
  }, [selectedDate, data, restaurants, itineraryData, availibility]);

  useEffect(() => {
    filterFacilities();
    
    const fillTreats = treatsData.filter((row) => {
      if (view === "Lunch") {
        return row.LunchServiceCode;
      } else {
        return row.DinnerServiceCode;
      }
    });
    setFilteredTreatsData(fillTreats);
  }, [view]);

  return {
    clicked,
    restaurants,
    isTimeGrayOut,
    selectedItem,
    clickedResId,
    allTimes,
    hasDiningAddOnsOnSkip, 
    setHasDiningAddOnsOnSkip,
    setIsTimeGrayOut,
    manageRestaurantMobileClicked,
    handleSkipWhenAddOns,
  };
};

export default BookingSectionLogic;

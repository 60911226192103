const getSelectionByDate = (bookingSelections, date) =>
  bookingSelections.find(({ day }) => day === date);

const findIfCurrentDayMatches = (avaliableDays, selections, selectedDate) => {
  if (avaliableDays.length <= 1 || selections.length === 0) return false;

  const [selection] = selections;
  const { day } = selection;

  return selectedDate === day;
};

export { getSelectionByDate, findIfCurrentDayMatches };

import React, { useContext, useEffect, useState } from "react";

import styles from "./popover.module.css";

const Popover = ({ content, newStyles }) => {
  return (
    <>
      <div
        className={styles.popover}
        style={{
          ...newStyles,
        }}
      >
        {content}
      </div>
    </>
  );
};

export default Popover;

const TypeCardLogic = () => {
  const handleClick = (type, setView) => {
    if (!type) return;
 
    setView({ type });
  };

  return { handleClick };
};

export default TypeCardLogic;

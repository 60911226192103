import React from "react";

import RestaurantCardLogic from "../RestaurantCardLogic";
import ImageCarousel from "../../../shared/ImageCarousel/ImageCarousel";
import VenueBanner from "../../../shared/VenueBanner/VenueBanner";
import WindowWidth from "../../../hooks/WindowWidth";
import { goToPage } from "../../../helpingFunctions/linkChange";

import change_styles from "./restaurant_card_changes.module.css";
import "../../../css/regularCss/selection_indications.css";
import RestaurantDetailsContainer from "../../../shared/Containers/RestaurantBoxDetails/RestaurantDetailsContainer";
import styles from "./restaurant_card.module.css";

const RestaurantCardDesktop = ({
  facilityGUID,
  Title,
  Image,
  Menu,
  Details,
  ShortDescription,
  LongDescription,
  skiped,
  isFullyBooked,
  selectStatus,
  restaurantsLength,
  newClass,
  view,
  availability,
}) => {
  const { width } = WindowWidth();
  const {
    handleClick,
    selectedDate,
    isGrayedOut,
    FullyBookedBanner,
    cardBannerContent,
    isBooked,
    hasSelection,
    openIframe,
    handleIframe,
    setShowRestDetails,
    showRestDetails,
  } = RestaurantCardLogic(
    facilityGUID,
    isFullyBooked,
    null,
    view,
    availability
  );
  
  const shouldShrink =
    selectStatus === "not-selected" && restaurantsLength > 2 && width > 1365;

  return (
    <>
      <div
        style={
          selectStatus === "selected"
            ? { border: "10px solid var(--color-primary)" }
            : width < 1365
            ? { padding: "10px", width: "584px" }
            : { padding: "10px" }
        }
      >
        <article
          className={`
        ${`${
          selectStatus === "selected"
            ? styles.focusedRestaurant
            : styles.restaurantCard
        }`} ${styles[newClass]}
        ${shouldShrink ? change_styles.restaurantImage_shrink : null}
        ${skiped ? styles.disabled : null}
        `}
          onClick={
            (hasSelection &&
              (selectStatus === "selected" ||
                (selectStatus === "default" && !isGrayedOut))) ||
            !hasSelection
              ? handleClick.bind(
                  this,
                  facilityGUID,
                  selectedDate,
                  Title,
                  "restaurant"
                )
              : null
          }
        >
          <div
            className={`${styles.restaurantImage} ${
              isGrayedOut && hasSelection ? styles.restaurantImageGray : ""
            }  `}
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(28,28,28) 0%, rgba(168,168,168,0.1) 100%), url(${Image[0]?.formats?.small?.url})`,
            }}
          >
            <h1
              className={styles.restaurantTitle}
              style={{ marginTop: FullyBookedBanner ? "30px" : "0px" }}
            >
              {Title}
            </h1>
          </div>
          <div className={styles.restaurantSliderWrapper}>
            <VenueBanner
              content={
                <>
                  {isBooked
                    ? cardBannerContent.Booked
                    : cardBannerContent.Selected}
                </>
              }
              new_styles_txt={{ fontWeight: "bold", gap: "10px" }}
              customIcon={
                <>
                  <img
                    className={styles.RestaurantCardDesktop__linkIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowRestDetails(true);
                    }}
                    src="/img/info_icons/info-information-white.svg"
                    alt={""}
                  />
                </>
              }
            />
            <ImageCarousel sliderImages={Image} autoSlide={true} />
          </div>
          <div
            className={styles.restaurantOpened}
            style={{ width: restaurantsLength > 2 ? "255px" : "282px" }}
          >
            <div
              className={styles.restaurantOverviewWrapper}
              style={{ zIndex: 140 }}
            >
              <h1 className={styles.title}>{Title}</h1>
              <span
                className={styles.menuLink}
                style={{
                  pointerEvents: Menu ? "pointer" : "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  goToPage(Menu);
                }}
              >
                <img
                  className={styles.RestaurantCardDesktop__linkIcon}
                  src="/img/info_icons/open_link.jpg"
                  alt={""}
                />
                View Sample Menu
              </span>
              <p className={styles.restaurantDescription}>{ShortDescription}</p>
            </div>
            <div className={styles.restaurantDetailsWrapper}>
              <ul className={styles.detailsList}>
                {Details?.map(({ icon, iconDescription }, index) =>
                  icon && iconDescription ? (
                    <li className={styles.detailsListWrapper} key={index}>
                      <img
                        className={styles.detailsIcon}
                        src={icon?.formats?.thumbnail?.url}
                        alt={""}
                      />
                      <p className={styles.detailsTxt}>{iconDescription}</p>
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </div>
          {FullyBookedBanner("40px", "18px")}
        </article>
      </div>

      {showRestDetails ? (
        <RestaurantDetailsContainer
          details={{ Title, Images: Image, ShortDescription, LongDescription }}
          toggleDetailsDialogHandler={setShowRestDetails}
          width={width}
        />
      ) : null}
      {/* {openIframe ? (
        <AlertBox
          customContent={
            <iframe
              src={"https://www.warnerleisurehotels.co.uk/Images/Brasserie32%20Menu_tcm13-44117.pdf&output=embed"}
              title={"Title"}
              width={"500"}
              height={"500"}
            />
          }
        />
      ) : null} */}
    </>
  );
};

export default RestaurantCardDesktop;

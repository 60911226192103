import { useContext, useEffect, useState } from "react";

import { findOneFromObjList } from "../../helpingFunctions/arrays";
import { DataContext } from "../../context/DataContext";

const DiningLogic = (view) => {
  const [pageTextData, setPageTextData] = useState({});
  const { pagesData } = useContext(DataContext);

  const getPageData = async () => {
    try {
      const data = findOneFromObjList(pagesData, "Type", view);
      
      setPageTextData(data);
    } catch (error) {}
  };

  useEffect(() => {
    getPageData();
  }, []);

  return { pageTextData };
};

export default DiningLogic;

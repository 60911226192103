import React from "react";
import WindowWidth from "../../hooks/WindowWidth";

const BookingFolioPostingsRow = ({
  Date,
  PostingCodeDescription,
  Voucher,
  Amount,
  newStyles,
  totalCharge,
  isLast,
  title,
}) => {
  const { width } = WindowWidth();

  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          marginLeft: width <= 500 ? "0" : "20px",
        }}
      >
        <p
          style={{
            width: "15%",
            minWidth: "100px",
            ...newStyles,
          }}
        >
          {Date}
        </p>
        <div
          style={{ display: "flex", width: "100%", flexDirection: "column" }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <p
              style={{
                width: "60%",
                minWidth: "100px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingLeft: "10px",
                ...newStyles,
              }}
            >
              {PostingCodeDescription}
            </p>
            <p
              style={{
                width: "15%",
                minWidth: "100px",
                paddingLeft: "10px",
                ...newStyles,
              }}
            >
              {Voucher}
            </p>
            <p
              style={{
                width: "15%",
                minWidth: "100px",
                paddingLeft: "10px",
                ...newStyles,
              }}
            >
              {Amount}
            </p>
          </div>

          {isLast ? (
            <div
              style={{
                marginTop: "10px",
                borderTop: "3px solid black",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                minWidth: "300px",
                paddingRight: "20px",
              }}
            >
              <p>{title}</p>
              <p>{`£${totalCharge}`}</p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default BookingFolioPostingsRow;

import { useContext, useEffect, useState } from "react";

import { DataContext } from "../context/DataContext";
import { SelectionContext } from "../context/SelectionContext";
import { getSelectionByDate } from "../helpingFunctions/selections";

const HandleQuantity = (id, view) => {
  const { reservation, selectedDate } = useContext(DataContext);
  const { bookingSelections, updateQuantity, setSelectedQuantity } =
    useContext(SelectionContext);

  const [quantitySelected, setQuantitySelected] = useState("");
  const [quantityOptions, setQuanityOptions] = useState([]);

  const constructQuantityOptions = () => {
    try {
      const maximum = view === "Spa" ? 1 : reservation.Adults
      Array.from({ length: maximum }, (_, i) => i + 1).map((num) => {
        setQuanityOptions((prevState) => [
          ...prevState,
          {
            id: num,
            Title: `${num} guest${num === 1 ? '' : 's'}`,
          },
        ]);
      });
    } catch (e) {
      console.log(
        `${new Date()} Error in constructQuantityOptions func in ActivityPreviewLogic.js ${e}`
      );
    }
  };

  const manageQuantity = (key, value) => {
    try {
      const result = updateQuantity([id], selectedDate, value, key, view);

      if (!result) {
        setSelectedQuantity(value);
      } else {
        setSelectedQuantity(-1);
      }
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const findQuantitySelection = () => {
    const selection = getSelectionByDate(bookingSelections, selectedDate);
    
    selection?.Quantity && selection?.action !== "remove"
      ? setQuantitySelected(`${selection.Quantity} guest${selection.Quantity === 1 ? '' : 's'}`)
      : setQuantitySelected("Select the number of guests here");
  };

  useEffect(() => {
    if (selectedDate) findQuantitySelection();
    
    setSelectedQuantity(-1);
  }, [selectedDate, bookingSelections]);

  useEffect(() => {
    constructQuantityOptions();
  }, []);

  return {
    quantityOptions,
    quantitySelected,
    manageQuantity,
    setQuantitySelected,
  };
};

export default HandleQuantity;

import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";
import { SelectionContext } from "../../../context/SelectionContext";
import { isValidDate } from "../../../helpingFunctions/bookingHelp";
import { filterTimes } from "../../../helpingFunctions/helpingFunctions";
import { getSelectionByDate } from "../../../helpingFunctions/selections";
import SortJSX from "../../../helpingFunctions/SortJSX";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import QuantityBox from "../../QuantityBox/QuanittyBox";
import { isBookingInThePast } from "../../../helpingFunctions/utilities";
import { ActivitiesTypes } from "../../../constants/constants";
import InformationalBoxHeavy from "../../../shared/Containers/InformationalBoxHeavy";

import styles from "./activity-time-slots.module.css";

const ActivityTimeSlotsMobile = ({
  date,
  findAvailibility,
  activity,
  view,
  spaAvailabilityForMobile,
  id,
  isReservationIn3Months,
}) => {
  const isFromActivity = ActivitiesTypes.includes(view);

  const [timesAvailable, setTimesAvailable] = useState(null);
  const [expand, setExpand] = useState(false);
  const [limit, setLimit] = useState(isFromActivity ? null : 1);

  const { reservation, setSelectedDate, itineraryData, availibility } =
    useContext(DataContext);
  const {
    bookingSelections,
    createBookingSelectionNew,
    removeBookingSelectionNew,
    mobileQuantity,
  } = useContext(SelectionContext);

  const manageTimeDisplay = () => {
    const timesAvailability = findAvailibility(id, date);
    setTimesAvailable(timesAvailability);
  };

  const manageExpantion = () => {
    setExpand(!expand);
  };

  const currentDateTime = new Date().toISOString().split("T");

  const handleClick = (
    facilityGUID,
    selectedDate,
    value,
    key,
    availability
  ) => {
    try {
      setSelectedDate(selectedDate);

      if (manageHilights(value)) {
        removeBookingSelectionNew(facilityGUID, selectedDate, "", key);
        setLimit(isFromActivity ? null : 1);
        return;
      }

      createBookingSelectionNew(
        facilityGUID,
        selectedDate,
        value,
        key,
        "add",
        view,
        mobileQuantity[selectedDate]
      );

      setLimit(isFromActivity ? parseInt(availability) : 1);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const manageHilights = (Time) =>
    bookingSelections?.some(({ day, time }) => day === date && time === Time);

  const currentDaySelection = bookingSelections?.find(
    ({ day }) => day === date
  );

  const manageTimeDisplaySpa = () => {
    try {
      if (spaAvailabilityForMobile.length > 0) {
        const { Times } = spaAvailabilityForMobile.find(
          ({ Date }) => date === Date
        );

        if (
          new Date(date).getTime() ===
          new Date(reservation.ArrivalDate.substr(0, 10)).getTime()
        ) {
          const filterOnlyAvailable = filterTimes(Times);
          setTimesAvailable([...filterOnlyAvailable]);
          return;
        }
        setTimesAvailable([...Times]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const manageGrayOut = (availability, Time) => {
    const parsedAvailability = parseInt(availability);

    const selection = getSelectionByDate(bookingSelections, date);
    if (selection?.Quantity) {
      if (!limit && selection.time === Time) {
        const parsedQuanitity = parseInt(selection?.Quantity);
        const totalQunatity = parsedQuanitity + parsedAvailability;
        const finalValue =
          totalQunatity > parseInt(reservation.Adults)
            ? parsedAvailability
            : totalQunatity;

        setLimit(finalValue);
      }

      return parseInt(availability) >= selection.Quantity;
    }
    return true;
  };

  useEffect(() => {
    view === "Spa" ? manageTimeDisplaySpa() : manageTimeDisplay();
  }, [spaAvailabilityForMobile, availibility]);

  useEffect(() => {
    if (timesAvailable?.length < 1) setExpand(true);
  }, [timesAvailable]);

  return (
    <div
      className={`${styles.activityTimeSlotsMobile} ${
        expand ? styles.activityTimeSlotsMobile__expand : ""
      }`}
    >
      {console.log({timesAvailable})}
      <div className={styles.activityTimeSlotsMobile__date_add}>
        <h3 className={styles.activityTimeSlotsMobile__date}>
          {`${moment(date).format("dddd[,] MMMM")} ${date.substr(
            8,
            date.length - 1
          )}`}
        </h3>
        {timesAvailable?.length > -1 &&
        (!timesAvailable || timesAvailable?.length > 0) ? (
          <p
            className={styles.activityTimeSlotsMobile__add}
            onClick={manageExpantion}
          >
            Show More
          </p>
        ) : null}
      </div>
      <>
        {!timesAvailable || timesAvailable?.length > 0 ? (
          <div className={styles.activityTimeSlotsMobile__hours}>
            {itineraryData && timesAvailable ? (
              <SortJSX by={"time"}>
                {timesAvailable.map(({ Availability, Time }, index) => (
                  <div
                    key={`${Time}_${index}`}
                    style={{
                      height: "49px",
                      border: "2px solid var(--color-primary)",
                      display: "flex",
                      cursor: "pointer",
                      fontWeight: "bold",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: manageHilights(Time) ? "var(--color-primary)" : null,
                      color: manageHilights(Time) ? "var(--color-secondary)" : "var(--color-primary)",
                      opacity: manageGrayOut(Availability, Time) ? "1" : "0.4",
                      pointerEvents:
                        manageGrayOut(Availability, Time) &&
                        isValidDate(date, Time)
                          ? "auto"
                          : "none",
                    }}
                    onClick={() =>
                      handleClick(
                        [activity.id],
                        date,
                        Time,
                        "time",
                        Availability
                      )
                    }
                  >
                    {Time?.substr(0, 5)}
                  </div>
                ))}
              </SortJSX>
            ) : (
              <LoadingContainer />
            )}
          </div>
        ) : (
          <InformationalBoxHeavy
            title={
              isReservationIn3Months
                ? null
                : view === "Spa"
                ? "Spa treatments for your break available soon..."
                : "Activities for your break available soon..."
            }
            content={
              isReservationIn3Months
                ? `Sorry, there are no available bookings/times for ${activity.Title}, there may be cancellations available, please check with guest services at the ${reservation.Hotel.Title}.`
                : "Sorry, we haven't released any availability for this date yet, however if you check back closer to your break date, we should have availability."
            }
            boxNewStyles={{ marginTop: "20px" }}
          />
        )}

        {!timesAvailable || timesAvailable?.length > 0 ? (
          <div className={`${styles.activityTimeSlotsMobile__quantity}`}>
            <p>Please select the number of guests</p>
            {itineraryData && timesAvailable ? (
              <div
                className={`${
                  isBookingInThePast(
                    currentDaySelection?.day,
                    currentDaySelection?.time,
                    currentDateTime[0],
                    currentDateTime[1]
                  )
                    ? "fadeElement"
                    : ""
                }`}
              >
                <QuantityBox id={id} view={view} date={date} limit={limit} />
              </div>
            ) : null}
          </div>
        ) : null}
      </>
    </div>
  );
};

export default ActivityTimeSlotsMobile;

import { useContext, useEffect, useState } from "react";

import { findOneFromObjList } from "../../helpingFunctions/arrays";
import { DataContext } from "../../context/DataContext";

const ActivitiesLogic = (showType) => {
  const [objects, setObjects] = useState([]);
  const [pageTextData, setPageTextData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { facilitiesData, availibility, pagesData } = useContext(DataContext);

  const findAcitivities = () => {
    const getObjects = facilitiesData.filter(
      ({ objType }) => objType === showType
    );

    if (showType === "Activity" || showType === "Treat") {
      setObjects(getObjects.filter(({ id }) => checkIfItHasAvailability(id)));
    } else {
      setObjects(getObjects);
    }

    setIsLoading(false);
  };

  const getPageData = async () => {
    try {
      const data = findOneFromObjList(pagesData, "Type", showType);

      setPageTextData(data);
    } catch (error) {}
  };

  const checkIfItHasAvailability = (objectId) => {
    const hasAvailability = availibility?.some(({ Facilities }) =>
      Facilities.some(
        ({ FacilityGUID, Times }) =>
          parseInt(FacilityGUID) === parseInt(objectId) && Times.length > 0
      )
    );

    return hasAvailability;
  };

  useEffect(() => {
    if (availibility && availibility.length > 0) findAcitivities();
  }, [availibility]);

  useEffect(() => {
    findAcitivities();
    getPageData();
  }, []);

  return { objects, isLoading, pageTextData };
};

export default ActivitiesLogic;

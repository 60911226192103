import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";

const TimeButtonLogic = (time, facilityGUID, view) => {
  const {
    getSelection,
    bookingSelections,
    createBookingSelectionNew,
    removeBookingSelectionNew,
  } = useContext(SelectionContext);

  const { selectedDate } = useContext(DataContext);
  const [selection, setSelection] = useState({});
  const [fadeElement, setFadeElement] = useState(null);

  const getThisSelection = () => {
    const currentTimeSelection = getSelection(facilityGUID);

    setSelection(currentTimeSelection);
  };

  const managefadeOfElement = () => {
    const elementMatched = bookingSelections.find(
      (booking) =>
        booking?.facilityGUID?.find((val) => !facilityGUID.includes(val)) &&
        booking.day === selectedDate
    );

    if (elementMatched && elementMatched.restaurant) {
      setFadeElement(elementMatched);
    } else {
      setFadeElement(null);
    }
  };
  const handleClick = (
    facilityGUID,
    selectedDate,
    value,
    key,
    view,
    setSpaSelection,
    spaSelection,
    setUnfinishedSelection,
    unfinishedSelection
  ) => {
    try {
      if (view === "Spa") {
        const index = spaSelection[selectedDate]?.findIndex(
          (row) => row.Time === value
        );

        if (index !== -1) {
          const spaOptions = { ...spaSelection };

          if (unfinishedSelection) {
            const previousSelectionIndex = spaOptions[selectedDate]?.findIndex(
              (row) => row.Time === unfinishedSelection.time
            );
            spaOptions[selectedDate][previousSelectionIndex].selected = false;
            if (unfinishedSelection.time === value) {
              setUnfinishedSelection(null);
            } else {
              spaOptions[selectedDate][index].selected =
                !spaOptions[selectedDate][index].selected;
              setUnfinishedSelection({ date: selectedDate, time: value });
            }
          } else {
            spaOptions[selectedDate][index].selected =
              !spaOptions[selectedDate][index].selected;
            setUnfinishedSelection({ date: selectedDate, time: value });
          }

          setSpaSelection(spaOptions);
        }
        return;
      }

      const selectedTime = getSelection(facilityGUID);

      if (
        selectedTime &&
        selectedTime.time === time
      ) {
        removeBookingSelectionNew(facilityGUID, selectedDate, value, key);

        return;
      }

      createBookingSelectionNew(
        facilityGUID,
        selectedDate,
        value,
        key,
        "add",
        view
      );
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      managefadeOfElement();
      getThisSelection();
    }
  }, [bookingSelections, selectedDate, facilityGUID]);

  return {
    handleClick,
    selection,
    selectedDate,
    bookingSelections,
    fadeElement,
  };
};

export default TimeButtonLogic;

import { useEffect, useState } from "react";

const SelectBoxLogic = (setFormData, selectedItem, setSelectedItem) => {
  const [dropDown, setdropDown] = useState(false);

  const handleItemClicked = (id, name, title) => {
    setSelectedItem(title);

    setFormData(name, id);
    setdropDown(false);
  };


  return { dropDown, selectedItem, handleItemClicked, setdropDown };
};

export default SelectBoxLogic;

import React from "react";
import Markdown from "markdown-to-jsx";
import Heading from "../../shared/Heading/Heading";

import ImageCarousel from "../../shared/ImageCarousel/ImageCarousel";

import styles from "../../css/info_box.module.css";
import style from "./entertainment-details-box.module.css";

const EntertainmentDetailsBox = ({
  details,
  toggleDetailsDialogHandler,
  width,
}) => {
  return (
    <div className={styles.popUpWrapper}>
      <div className={styles.grayOutScreen}></div>
      {width >= 600 ? (
        <div className={style.infoDetailsBox}>
          <img
            className={style.closeButtons}
            src={`/img/info_icons/error_.svg`}
            onClick={() => toggleDetailsDialogHandler(false, {})}
            alt=""
          />

          <div className={style.title}>
            <Heading 
              txt={details.EntertainmentPublished} 
              fontSize={"24px"} 
              fontFamily="Georgia"
              textTransform="uppercase" 
            />
          </div>

          <div className={style.contentWrapper}>
            <div className={style.imgSliderWrapper}>
              <ImageCarousel
                sliderImages={details.Images}
                view={"Entertainment"}
                containImg={true}
                customStyle={{height: "fit-content"}}
              />
            </div>

            <div className={style.entertainmentDetails}>
              {details.venueName ? (
                <p className={style.venueTitle}>{details.venueName}</p>
              ) : null}
              {details.ActDescription ? (
                <p className={style.ActDescription}>
                  <Markdown>{details.ActDescription}</Markdown>
                </p>
              ) : null}
              
              {details.Details ? (
                <ul className={style.detailsList}>
                  {details.Details.map(({ icon, iconDescription }, index) =>
                    icon && iconDescription ? (
                      <li className={style.detailsListWrapper} key={index}>
                        <img
                          className={style.detailsIcon}
                          src={icon?.formats.thumbnail.url}
                          alt=""
                        />
                        {/* <p className={style.detailsTxt}>{iconDescription}</p> */}
                      </li>
                    ) : null
                  )}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className={style.infoDetailsBox}>
          <img
            className={style.closeButtons}
            src={`/img/info_icons/error_.svg`}
            onClick={() => toggleDetailsDialogHandler(false, {})}
            alt=""
          />

          <div className={style.title}>
            <Heading txt={details.EntertainmentPublished} fontSize={"24px"} />
          </div>

          <div className={style.imgSliderWrapper}>
            <ImageCarousel sliderImages={details.Images} />
          </div>

          {details.Details ? (
            <ul className={style.detailsList}>
              {details.Details.map(({ icon, iconDescription }, index) =>
                icon && iconDescription ? (
                  <li className={style.detailsListWrapper} key={index}>
                    <img
                      className={style.detailsIcon}
                      src={icon?.formats.thumbnail.url}
                      alt=""
                    />
                    {/* <p className={style.detailsTxt}>{iconDescription}</p> */}
                  </li>
                ) : null
              )}
            </ul>
          ) : null}

          <div className={style.contentWrapper}>
            <div className={style.entertainmentDetails}>
              {details.venueName ? (
                <p className={style.venueTitle}>{details.venueName}</p>
              ) : null}
              {details.ActDescription ? (
                <p className={style.ActDescription}>
                  <Markdown>{details.ActDescription}</Markdown>
                </p>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EntertainmentDetailsBox;

import React, { useContext, useState } from "react";

import ActivityTimeSlotsMobile from "../../../components/ActivityTimeSlots/AcitivityTimeSlotsMobile/ActivityTimeSlotsMobile";
import Heading from "../../../shared/Heading/Heading";
import Button from "../../../shared/Button/Button";
import { SelectionContext } from "../../../context/SelectionContext";
import WindowWidth from "../../../hooks/WindowWidth";
import AllSelectionsPopUp from "../../../shared/AllSelectionPopUp/AllSelectionsPopUp";
import AlertBox from "../../../components/AlertBox/AlertBox";
import Activity_Spa_PreviewLogic from "../Activity_Spa_PreviewLogic";
import Details from "../../../components/ActivityAndSpa_Details/DetailsMobile/Details";
import ConflictAlertBox from "../../../shared/validation/ConflictAlertBox";

import styles from "./activity-preview.module.css";

const ActivityPreviewMobile = ({ id, view, setView, goTo }) => {
  const { bookingSelections, bookingStatus } =
    useContext(SelectionContext);
  const {
    findAvailibility,
    object,
    availableDates,
    alertStatus,
    alertMessage,
    priceTxt,
    isReservationIn3Months,
    manageBooking,
    goBack,
    closeAlert,
    showConflict,
    getActionButtonLabel,
  } = Activity_Spa_PreviewLogic(id, view, setView, goTo);
  const [highlightMobileTimes, setHighlightMobileTimes] = useState([]);
  const { width } = WindowWidth();

  return (
    <>
      {!object ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className={styles.activityPreviewMobile}>
            <Details object={object} closeFunc={goBack}priceTxt={priceTxt} />
            <div className={styles.activityPreviewMobile__details_selections}>
              <div className={styles.activityPreviewMobile__selections}>
                <Heading
                  txt={"Select date and time"}
                  paddingBottom={"20px"}
                  paddingTop={width >= 700 ? "40px" : "20px"}
                  fontSize={"22px"}
                />
                <div
                  className={styles.activityPreviewMobile__timeSlotsContatiner}
                >
                  {availableDates.map((date) => (
                    <ActivityTimeSlotsMobile
                      id={id}
                      key={date}
                      date={date}
                      findAvailibility={() => findAvailibility(id, date)}
                      activity={object}
                      setHighlightMobileTimes={setHighlightMobileTimes}
                      highlightMobileTimes={highlightMobileTimes}
                      view={view}
                      isReservationIn3Months={isReservationIn3Months}
                    />
                  ))}
                </div>
              </div>
              <div className={styles.bookingButtons}>
                <Button
                  txt={"Cancel"}
                  backgroundColor={"var(--color-secondary)"}
                  color={"var(--color-primary)"}
                  onClick={goBack}
                />
                {bookingSelections.length === 0 || bookingSelections.filter((row) => row.action !== "none").length === 0 ? null : (
                  <Button
                    txt={`${getActionButtonLabel() ? "Book" : "Modify"}`}
                    onClick={manageBooking}
                    backgroundColor={"var(--color-primary)"}
                    color={"var(--color-secondary)"}
                  />
                )}
              </div>
            </div>
          </div>

          {bookingStatus ? (
            <AllSelectionsPopUp view={view} setView={(v) => setView(v)} mainObjectId={id}/>
          ) : null}
          {alertStatus === "canceling" ? (
            <AlertBox
              txt={
                "We are proccessing your cancel request, this will take some time."
              }
            />
          ) : alertStatus === "non-valid" ? (
            <AlertBox
              txt={alertMessage}
              button={
                <>
                  <Button txt={"Ok"} backgroundColor={"var(--color-primary)"} color={"var(--color-secondary)"} onClick={closeAlert} />
                </>
              }
            />
          ) : ["strong-conflict", "soft-conflict", "average-conflict"].includes(
              alertStatus
            ) ? (
            <ConflictAlertBox
              alertMessage={alertMessage}
              alertStatus={alertStatus}
              showConflict={showConflict}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default ActivityPreviewMobile;

import React, { useLayoutEffect } from "react";
import AppendHead from "react-append-head";

import styles from '../../css/policies.module.css';

const CookiePolicies = ({ setView }) => {
  useLayoutEffect(() => {
    setTimeout(() => {
      window.OneTrust?.NoticeApi?.Initialized?.then(function () {
        window.OneTrust?.NoticeApi?.LoadNotices(
          [
            "https://privacyportal-eu-cdn.onetrust.com/75f6ad4c-3666-4142-a5d5-a8a3cdabae93/privacy-notices/draft/bdafa367-cd11-4ffe-8f3d-575f9541aed8.json",
          ],
          false
        );
      });
    }, 250);
  }, []);

  return (
    <div className={styles.policies}>
      <AppendHead>
        <script
          src="https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js"
          type="text/javascript"
          charset="UTF-8"
          id="otprivacy-notice-script"
        ></script>
      </AppendHead>
      <div
        id="otnotice-bdafa367-cd11-4ffe-8f3d-575f9541aed8"
        className="otnotice"
      ></div>
    </div>
  );
};

export default CookiePolicies;

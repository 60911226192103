import React from "react";
import ImageCarousel from "../../shared/ImageCarousel/ImageCarousel";
import Heading from "../../shared/Heading/Heading";
import Markdown from "markdown-to-jsx";
import Button from "../../shared/Button/Button";
import { goToPage } from "../../helpingFunctions/linkChange";

const PromotionalSpaceItineraryRow = ({
  content,
  setView,
  redirectionDate,
  setSelectedDate,
  newStylesWrapper,
  width,
}) => {
  const redirectView = (redirect) => {
    setView({ type: redirect });
    setSelectedDate(redirectionDate + "I");
  };

  return (
    <>
      <div
        style={{
          height: "215px",
          background: "#f2f2f4",
          margin: "5px 5px 0px 5px",
          display: "flex",
          ...newStylesWrapper
        }}
      >
        <div
          style={{
            width: width < 500 ? "100%" : "50%",
            height: width < 500 ? "115px" : "215px",
          }}
        >
          <ImageCarousel
            sliderImages={content.Images.length > 0 ? content.Images : []}
            autoSlide={true}
          />
        </div>
        <div
          style={{
            width: width < 500 ? "100%" : "50%",
            height: width < 500 ? "100px" : "215px",
            padding: "10px",
          }}
        >
          <Heading
            txt={content.Title}
            new_styles={{
              paddingBottom: "10px",
              fontSize: "18px",
              width: "100%",
            }}
          />
          <div
            style={{
              overflow: "auto",
              height: "93px",
              marginRight: "10px",
            }}
          >
            <Markdown>{content.Description}</Markdown>
          </div>

          <Button
            txt={content.ButtonLabel}
            onClick={() =>
              content.RedirectionType === "View"
                ? setView(() => redirectView(content.ButtonRedirect))
                : goToPage(content.ButtonRedirect)
            }
            new_styles={{
              color: "var(--color-primary)",
              backgroundColor: "var(--color-secondary)",
              minWidth: "125px",
              fontWeight: "bold",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PromotionalSpaceItineraryRow;

import React from "react";

import SelectBox from "../../shared/SelectBox/SelectBox";

import styles from "./input_card.module.css";

const InputCard = ({
  selectBox,
  label,
  placeholder,
  name,
  selectBoxContent,
  setFormData,
  selectedHotel,
  setSelectedHotel,
  value,
  newBoxStyles,
  newLabelStyles,
  readOnly = false
}) => {

  return (
    <article className={styles.inputCardWrapper}>
      <label className={styles.inputLabel} style={{ ...newLabelStyles }}>
        {label}
      </label>
      {selectBox ? (
        <SelectBox
          setFormData={setFormData}
          name={name}
          content={selectBoxContent}
          height={"50px"}
          icon={"arrow.svg"}
          selectedItem={selectedHotel}
          setSelectedItem={setSelectedHotel}
          placeholder={placeholder}
        />
      ) : (
        <input
          className={styles.input}
          style={{ ...newBoxStyles }}
          type={"text"}
          readOnly={readOnly}
          placeholder={placeholder}
          onChange={(e) => setFormData(name, e.target.value)}
          value={value}
        />
      )}
    </article>
  );
};

export default InputCard;

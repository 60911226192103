import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import Heading from "../../../../shared/Heading/Heading";
import ImageCarousel from "../../../../shared/ImageCarousel/ImageCarousel";
import InformationText from "../../../../shared/InformationText/InformationText";
import GuideItemDesktopLogic from "./GuideItemDesktopLogic";
import BookActivity from "../BookActivity/BookActivity";

import { DataContext } from "../../../../context/DataContext";
import { durationLabel, expand_collapse } from "../shared/helpingFunctions";
import { ALL_DAY_ACTIVITY, TV_TITLE, VERTICAL_ORDER } from "../../constants/constants";
import { handleNullDisplay } from "../../../../helpingFunctions/helpingFunctions";

import styles from "./GuideItemDesktop.module.css";
import { formatTimeWithoutAmPm } from "../../../../helpingFunctions/utilities";

const GuideItemDesktop = ({
  selectedItemId,
  setSelectedItemId,
  itemIndex,
  guide,
  setView,
  guideItem,
  internalActivityItinerary,
  internalActivityItineraryHandler,
}) => {
  const { search } = useLocation();
  const { orientation, screenView } = queryString.parse(search);
  const isInTVVertical =
    screenView === TV_TITLE && orientation === VERTICAL_ORDER;

  const {
    Description,
    Location,
    Price,
    Title,
    Images,
    activityId,
    time,
    duration,
    StartTime,
    EndTime
  } = guideItem;
  const { selectedDate } = useContext(DataContext);

  const id = `${activityId}_${itemIndex}_${selectedDate}`;
  const isSelected = selectedItemId === id || selectedItemId === ALL_DAY_ACTIVITY;
  const isAllDayActivity = ALL_DAY_ACTIVITY === guideItem.Type;
  const { formattedDesc } = GuideItemDesktopLogic(
    Description,
    isSelected,
    guide
  );

  let SeeMoreElement = null;

  if (formattedDesc.hasMoreContent) {
    SeeMoreElement = (
      <span className={styles.GuideItemDesktop__seeMore}>
        {isSelected ? "See less" : "See more"}
      </span>
    );
  }

  let Img = null;
  if (isInTVVertical) {
    const img = Images.length > 0 ? Images[0] : null;
    if (img) {
      Img = (
        <img
          src={img.formats.small ? img.formats.small.url : img.url}
          alt="activity image"
          width={"100%"}
          height={"100%"}
        />
      );
    }
  } else {
    Img = (
      <ImageCarousel
        sliderImages={Images.length > 0 ? Images : []}
        view={"WhatsOnGuide_Card"}
      />
    );
  }

  return (
    <article
      className={`${styles.GuideItemDesktop} ${
        isSelected ? styles.GuideItemDesktop_Expand : ""
      } ${isInTVVertical ? styles.GuideItemDesktop__TV : ""}`}
      id={guideItem.timeId ? guideItem.timeId : "All Day Activities"}
    >
      <div className={styles.GuideItemDesktop__images_wrapper}>{Img}</div>
      <div
        className={styles.GuideItemDesktop__details}
        onClick={expand_collapse.bind(this, setSelectedItemId, id)}
      >
        <div className={styles.GuideItemDesktop__headline}>
          <Heading
            txt={Title}
            new_styles={{
              color: "var(--color-primary)",
              fontSize: isInTVVertical ? "40px" : "18px",
              maxWidth: "fit-content",
            }}
          />
          {!isAllDayActivity && (
            <BookActivity
              guideItem={guideItem}
              isInternalActivity={activityId?.includes("internalAct_facility")}
              setView={setView}
              internalActivityItinerary={internalActivityItinerary}
              internalActivityItineraryHandler={
                internalActivityItineraryHandler
              }
            />
          )}
        </div>
        <div className={styles.GuideItemDesktop__data}>
          <ul className={styles.GuideItemDesktop__cost}>
            <li>
              <strong>Price:</strong>{" "}
              {handleNullDisplay(Price, "£", "Inclusive")}
            </li>
            <li>
              <strong>{isAllDayActivity ? "End Time:" : "Duration:"}</strong>{" "}
              {isAllDayActivity
                ? formatTimeWithoutAmPm(EndTime)
                : durationLabel(duration)}
            </li>
          </ul>
          <ul className={styles.GuideItemDesktop__time_location}>
            <li>
              <strong>Start:</strong>{" "}
              {isAllDayActivity ? formatTimeWithoutAmPm(StartTime) : time}
            </li>
            <li>
              <strong>Location:</strong>{" "}
              {isAllDayActivity ? Location : Location?.locationName}
            </li>
          </ul>
        </div>

        <InformationText
          new_styles={{
            paddingTop: "5px",
            fontSize: isInTVVertical ? "28px" : "",
          }}
          content={isInTVVertical ? Description : formattedDesc.desc}
        />
        {!isInTVVertical && SeeMoreElement}
      </div>
    </article>
  );
};

export default GuideItemDesktop;

import React from "react";

const InformationalBoxHeavy = ({ title, content, boxNewStyles, titleNewStyles }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "25px",
          backgroundColor: "var(--color-primary)",
          textAlign: "center",
          color: "var(--color-secondary)",
          ...boxNewStyles,
        }}
      >
        {title ? (
          <p style={{ marginBottom: "15px", fontWeight: "bold", ...titleNewStyles }}>{title}</p>
        ) : null}
        {content ? <p>{content}</p> : null}
      </div>
    </>
  );
};

export default InformationalBoxHeavy;

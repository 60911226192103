import Markdown from "markdown-to-jsx";
import React from "react";

import ActivityCardMobile from "../../../components/ActivityCard/ActivityCardMobile/ActivityCardMobile";
import { goToTheTop } from "../../../helpingFunctions/linkChange";
import WindowWidth from "../../../hooks/WindowWidth";
import Heading from "../../../shared/Heading/Heading";
import InformationText from "../../../shared/InformationText/InformationText";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import CategoryBanner from "../../../shared/Categories/CategoryBanner/CategoryBanner";
import HorisontalCardsGrid from "../../../shared/Categories/HorisontalCardsGrid/HorisontalCardsGrid";
import DetailsBox from "../../../shared/Categories/DetailsBox/DetailsBox";
import Sorting from "../../../shared/Categories/Sorting/Sorting";
import SelectionPopupBox from "../components/SelectionPopupBox";
import TreatsProcessingPopupBox from "../components/TreatsProcessingPopupBox";
import CategoriesLogic from "../../../shared/Categories/CategoriesLogic";
import TreatsLogic from "../logic/TreatsLogic";

import styles from "./TreatsCategoriesMobile.module.css";

const TreatsCategoriesMobile = ({ view, showType, setView }) => {
  const {
    isLoading,
    pageTextData,
    selectedCategory,
    clickedObject,
    originalData,
    manipulatedData,
    categoryBannersRef,
    selectedDate,
    categorySelectorHandler,
    handleCardClick,
    updateStateAfterSort,
    setSelectedCategory,
    findAcitivities,
  } = CategoriesLogic(showType);

  const {
    toggleOptionsScreen,
    processSelections,
    closePopUp,
    showOpt,
    setShowOpt,
    allDays,
    quantity,
    setQuantity,
    totalPriceToRemove,
    totalPriceToAdd,
    bookingStatus,
    addOnsBookings,
    handleAddOnSelection,
    itineraryData,
    hasSelectedAddOnDining,
    selectionForm,
    setSelectionForm,
    handleAddOnDiscard,
    handleRowOptions,
    setSelectedDate,
    reservation,
    checkAddOnDiningBiding,
  } = TreatsLogic(
    view,
    showType,
    clickedObject,
    setSelectedCategory,
    handleCardClick,
    findAcitivities
  );

  const { width } = WindowWidth();

  return (
    <>
      <div className={styles.activitesMobile}>
        <Heading
          fontSize={"24px"}
          paddingTop={width >= 700 ? "40px" : "20px"}
          paddingBottom={"20px"}
          txt={pageTextData?.MobileTitle}
          fontFamily="Georgia"
          textTransform="uppercase"
        />
        <InformationText
          content={
            pageTextData?.MobileDescription ? (
              <Markdown>{pageTextData?.MobileDescription}</Markdown>
            ) : null
          }
          new_styles={{ paddingBottom: "20px" }}
        />
        <Sorting initialData={originalData} setData={updateStateAfterSort} />
        {isLoading || !itineraryData ? (
          <LoadingContainer />
        ) : (
          <>
            {manipulatedData?.map(
              (
                {
                  ServiceTypeDescription,
                  TreatCategoryId,
                  CategoryImageLandscape,
                  objects,
                },
                index
              ) => (
                <span
                  key={`Wrapper_${TreatCategoryId}`}
                  style={{ width: "100%" }}
                >
                  {CategoryImageLandscape ? (
                    <CategoryBanner
                      key={`CategoryBanner_${TreatCategoryId}`}
                      handleClick={categorySelectorHandler.bind(
                        this,
                        TreatCategoryId
                      )}
                      isSelected={selectedCategory === TreatCategoryId}
                      buttonClick={goToTheTop}
                      categoryBannersRef={categoryBannersRef}
                      additionalData={{
                        TreatCategoryId,
                        index,
                        image: CategoryImageLandscape,
                        content: ServiceTypeDescription,
                      }}
                    />
                  ) : null}
                  <HorisontalCardsGrid
                    key={`HorisontalCardsGrid_${TreatCategoryId}`}
                    shouldOpen={
                      selectedCategory === TreatCategoryId ||
                      !CategoryImageLandscape
                    }
                    new_styles={{
                      gridGap: "10px",
                      paddingTop: "25px",
                      paddingBottom: "25px",
                      gridTemplateColumns:
                        width >= 700 ? "repeat(auto-fill, 200px)" : "1fr 1fr",
                      position: "relative",
                    }}
                  >
                    {objects.map((object) => (
                      <ActivityCardMobile
                        key={object.id}
                        {...object}
                        onClick={handleCardClick.bind(this, object, index)}
                        isSelected={clickedObject?.id === object.id}
                        view={view.type}
                      />
                    ))}
                  </HorisontalCardsGrid>
                  {clickedObject &&
                  clickedObject.TreatCategoryId === TreatCategoryId ? (
                    <DetailsBox
                      dimensionClass={"DetailsBoxMobile"}
                      clickedObject={clickedObject}
                      unselectCard={handleCardClick.bind(this, clickedObject)}
                      previewFunctionality={() => toggleOptionsScreen()}
                    />
                  ) : null}
                </span>
              )
            )}
          </>
        )}
      </div>

      {showOpt ? (
        <SelectionPopupBox
          setShowOpt={setShowOpt}
          clickedObject={clickedObject}
          selectedDate={selectedDate}
          showType={showType}
          setView={setView}
          quantity={quantity}
          setQuantity={setQuantity}
          allDays={allDays}
          processSelections={processSelections}
          totalPriceToRemove={totalPriceToRemove}
          totalPriceToAdd={totalPriceToAdd}
          addOnsBookings={addOnsBookings}
          handleAddOnSelection={handleAddOnSelection}
          selectionForm={selectionForm}
          hasSelectedAddOnDining={hasSelectedAddOnDining}
          setSelectionForm={setSelectionForm}
          handleAddOnDiscard={handleAddOnDiscard}
          handleRowOptions={handleRowOptions}
          setSelectedDate={setSelectedDate}
          reservation={reservation}
          checkAddOnDiningBiding={checkAddOnDiningBiding}
        />
      ) : null}

      {bookingStatus ? (
        <TreatsProcessingPopupBox
          setView={(v) => setView(v)}
          closePopUp={() => closePopUp()}
          view={showType}
          totalPriceToAdd={totalPriceToAdd}
          totalPriceToRemove={totalPriceToRemove}
          clickedObject={clickedObject}
        />
      ) : null}
    </>
  );
};

export default TreatsCategoriesMobile;
